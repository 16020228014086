import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import _ from 'lodash';


const OptionalDropdown = ({
  noneValue, noneText, sortOptions, options, value, onChange, ...props
}) => {
  if (sortOptions) {
    options = _.orderBy(
      options,
      x => (typeof x.text === 'string' ? x.text.toLowerCase() : x.text),
    );
  }

  return (
    <Dropdown
      {...props}
      options={[
        {
          text: noneText,
          value: noneValue,
        },
        ...options,
      ]}
      value={value == null ? noneValue : value}
      onChange={(e, data) => onChange(e, {
        ...data,
        value: data.value === noneValue ? null : data.value,
      })}
    />
  );
};

OptionalDropdown.defaultProps = {
  ...Dropdown.defaultProps,
  noneValue: 'none',
  noneText: '',
  sortOptions: false,
};

OptionalDropdown.propTypes = {
  ...Dropdown.propTypes,
  noneValue: PropTypes.string,
  noneText: PropTypes.node,
  sortOptions: PropTypes.bool,
};

export default OptionalDropdown;
