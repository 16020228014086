import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Icon, Form, Input, Button,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { ApiErrorType } from '~/common/types';
import '~/common/styles/components/EditableText.css';


const EditableText = ({
  type, value, error, required, modalHeader, children, onChange,
}) => {
  value = value == null ? '' : value;

  const [editValue, setEditValue] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!editMode) {
      setEditValue(value);
    }
  }, [value, editMode]);

  useEffect(() => {
    if (isSaving && (value === editValue || error !== null)) {
      setIsSaving(false);
      setEditMode(false);
    }
  }, [isSaving, value, editValue, error]);

  const handleOpen = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const handleCancel = useCallback(() => {
    if (isSaving) {
      return false;
    }

    setEditMode(false);
  }, [isSaving, setEditMode]);

  const handleSave = useCallback(() => {
    let newValue = editValue || null;
    if (required && !newValue) {
      return;
    }
    if (type === 'number') {
      newValue = parseFloat(newValue);
    }
    if (value === newValue) {
      setEditMode(false);
      return;
    }

    setIsSaving(true);
    onChange(newValue);
  }, [value, editValue, required, type, setEditMode, onChange]);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className="editable-text"
        onClick={handleOpen}
        onKeyDown={handleOpen}
      >
        {children || value}
        <Icon name="pencil" />
      </div>

      <Modal
        size="tiny"
        dimmer="inverted"
        open={editMode}
        onOpen={handleOpen}
        onClose={handleCancel}
      >
        {modalHeader && (
          <Modal.Header>{modalHeader}</Modal.Header>
        )}

        <Modal.Content>
          <Form onSubmit={handleSave}>
            <Input
              fluid
              autoFocus
              type={type}
              value={editValue}
              disabled={isSaving}
              onChange={(e, data) => setEditValue(data.value)}
            />
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button
            negative
            disabled={isSaving}
            onClick={handleCancel}
          >
            <FormattedMessage id="EditableText.cancel.label" defaultMessage="Cancel" />
          </Button>
          <Button
            primary
            loading={isSaving}
            disabled={isSaving || (required && editValue.length === 0)}
            onClick={handleSave}
          >
            <FormattedMessage id="EditableText.save.label" defaultMessage="Save" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

EditableText.defaultProps = {
  type: 'text',
  value: '',
  error: null,
  required: false,
  modalHeader: null,
  children: null,
  onChange: () => {},
};

EditableText.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  error: ApiErrorType,
  required: PropTypes.bool,
  modalHeader: PropTypes.node,
  children: PropTypes.node,
  onChange: PropTypes.func,
};

export default EditableText;
