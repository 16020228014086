import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Menu, Button, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { promisifyRoutine, bindPromiseCreators } from 'redux-saga-routines';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import _ from 'lodash';

import { JOB_STATUSES, JOB_STATUSES_DONE } from '~/common/constants';
import { JOB_STATUS_FILTERS } from '~/app/constants';
import { fetchJobs, refreshJobs } from '~/app/actions/JobsActions';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import JobList from '~/app/components/JobList';
import JobSortingOrderModal from '~/app/components/JobSortingOrderModal';
import View from '~/app/views/View';
import '~/app/styles/views/jobs/JobListView.css';


const PAGE_SIZE = 20;

const STATUS_FILTER_MAPPING = {
  [JOB_STATUS_FILTERS.READY]: [JOB_STATUSES.READY],
  [JOB_STATUS_FILTERS.IN_PROGRESS]: [JOB_STATUSES.IN_PROGRESS],
  [JOB_STATUS_FILTERS.FINISHED]: JOB_STATUSES_DONE,
};

const STATUS_FILTERS_MESSAGES = defineMessages({
  [JOB_STATUS_FILTERS.READY]: {
    id: 'JobListView.statusFilters.ready',
    defaultMessage: 'Pending',
  },
  [JOB_STATUS_FILTERS.IN_PROGRESS]: {
    id: 'JobListView.statusFilters.inProgress',
    defaultMessage: 'In\u00a0progress',
  },
  [JOB_STATUS_FILTERS.FINISHED]: {
    id: 'JobListView.statusFilters.finished',
    defaultMessage: 'Done',
  },
});

const JOB_COUNT_MESSAGES = defineMessages({
  [undefined]: {
    id: 'JobListView.jobCountMessages.all',
    defaultMessage: '{n} {n, plural, one {job} other {jobs}}',
  },
  [JOB_STATUS_FILTERS.READY]: {
    id: 'JobListView.jobCountMessages.ready',
    defaultMessage: '{n} pending {n, plural, one {job} other {jobs}}',
  },
  [JOB_STATUS_FILTERS.IN_PROGRESS]: {
    id: 'JobListView.jobCountMessages.inProgress',
    defaultMessage: '{n} {n, plural, one {job} other {jobs}} in progress',
  },
  [JOB_STATUS_FILTERS.FINISHED]: {
    id: 'JobListView.jobCountMessages.finished',
    defaultMessage: '{n} completed {n, plural, one {job} other {jobs}}',
  },
});

const EMPTY_LIST_MESSAGES = defineMessages({
  [undefined]: {
    id: 'JobListView.emptyListMessages.all',
    defaultMessage: 'You currently don\'t have any jobs.',
  },
  [JOB_STATUS_FILTERS.READY]: {
    id: 'JobListView.emptyListMessages.ready',
    defaultMessage: 'You currently don\'t have any pending jobs.',
  },
  [JOB_STATUS_FILTERS.IN_PROGRESS]: {
    id: 'JobListView.emptyListMessages.inProgress',
    defaultMessage: 'You currently don\'t have any jobs in progress.',
  },
  [JOB_STATUS_FILTERS.FINISHED]: {
    id: 'JobListView.emptyListMessages.finished',
    defaultMessage: 'You currently don\'t have any completed jobs.',
  },
});

const ERROR_MESSAGES = defineMessages({
  GEOLOCATION_UNSUPPORTED: {
    id: 'JobListView.errors.geolocationUnsupported',
    defaultMessage: 'The device does not support retrieving your position. Please change the sorting order.',
  },
  GEOLOCATION_UNAVAILABLE: {
    id: 'JobListView.errors.geolocationUnavailable',
    defaultMessage: 'Your current position couldn\'t be determined. Please try again or change the sorting order.',
  },
  GEOLOCATION_PERMISSION_DENIED: {
    id: 'JobListView.errors.geolocationPermissionDenied',
    defaultMessage: 'Your current position couldn\'t be determined. Please make sure that GPS is enabled and the app is allowed to retrieve your position.',
  },
});

class JobListView extends Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }

  state = {
    scrollTop: 0,
    search: '',
    statusFilter: _.get(this.props.location.state, 'statusFilter', JOB_STATUS_FILTERS.READY),
    sortBy: this.props.jobs.items.query.sortBy || 'appointment',
    orderBy: this.props.jobs.items.query.orderBy || 'asc',
    showSortingModal: false,
    selectedJobId: null,
  }

  componentDidMount() {
    this.fetchJobs({ refreshGeolocation: true });
  }

  fetchJobs = ({ pageNumber = 1, refreshGeolocation = false } = {}) => {
    const { statusFilter, search } = this.state;
    const { geolocation } = this.props.jobs.items.query;
    const { current: user } = this.props.users;

    const [sortBy, orderBy] = statusFilter === JOB_STATUS_FILTERS.FINISHED
      ? ['end_time', 'desc']
      : [this.state.sortBy, this.state.orderBy];

    this.props.fetchJobs({
      pageNumber,
      pageSize: PAGE_SIZE,
      query: {
        search,
        sortBy,
        orderBy,
        status: STATUS_FILTER_MAPPING[statusFilter],
        userId: user.id,
        companyId: user.company.id,
        geolocation: refreshGeolocation ? null : geolocation,
      },
      debounce: search.length > 0,
    });
  }

  refreshJobs = () => {
    const promise = this.props.refreshJobs({ pageSize: PAGE_SIZE });
    return promise.catch(() => {});
  }

  loadMoreJobs = () => {
    const { pages } = this.props.jobs.items;
    const pageNumber = Math.max(...Object.keys(pages)) + 1;
    this.fetchJobs({ pageNumber });
  }

  handleFilterChange = (statusFilter) => {
    if (this.state.statusFilter !== statusFilter) {
      this.setState({
        search: '',
        statusFilter,
      }, this.fetchJobs);
      this.scrollToTop();
    }
  }

  handleSearchChange = (search) => {
    if (this.state.search !== search) {
      this.setState({ search }, this.fetchJobs);
      this.scrollToTop();
    }
  }

  handleSortChange = (sortBy, orderBy) => {
    this.setState({ showSortingModal: false });

    if (this.state.sortBy !== sortBy || this.state.orderBy !== orderBy) {
      this.setState({ sortBy, orderBy }, this.fetchJobs);
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    this.setState({ scrollTop: 0 });
  }

  render() {
    const {
      scrollTop, statusFilter, search, selectedJobId,
    } = this.state;
    const { items, loading: jobsLoading, error } = this.props.jobs;
    const { current: user, loading: userLoading } = this.props.users;

    const jobs = [].concat(...Object.values(items.pages)).map(id => items.byId[id]);
    const prevStatusFilter = _.findKey(
      STATUS_FILTER_MAPPING,
      x => _.isEqual(x, items.query.status),
    );

    if (selectedJobId != null) {
      return <Redirect to={`/jobs/${this.state.selectedJobId}`} />;
    }

    return (
      <View loading={userLoading}>
        <View.Header
          hideBack
          primaryText={<FormattedMessage id="JobListView.header.primary" defaultMessage="Jobs" />}
          secondaryText={_.get(user, 'company.name')}
          action={this.props.logout}
          actionIcon="sign-out"
        />

        <View.Content compact fixed>
          <Menu compact pointing secondary widths={Object.keys(STATUS_FILTERS_MESSAGES).length}>
            {Object.entries(STATUS_FILTERS_MESSAGES).map(([filter, message]) => (
              <Menu.Item
                key={filter}
                name={this.props.intl.formatMessage(message)}
                active={filter === statusFilter}
                onClick={() => this.handleFilterChange(filter)}
              />
            ))}
          </Menu>

          <Menu compact pointing secondary widths={1}>
            <Menu.Item className="job-list-query-controls">
              <FormattedMessage id="JobListView.search.placeholder" defaultMessage="Search...">
                {placeholder => (
                  <Input
                    fluid
                    icon="search"
                    iconPosition="left"
                    className="search-bar"
                    maxLength="255"
                    value={search}
                    placeholder={placeholder}
                    onChange={(e, { value }) => this.handleSearchChange(value)}
                    action={{ icon: 'close', onClick: () => this.handleSearchChange('') }}
                  />
                )}
              </FormattedMessage>
              <Button
                icon="sort alphabet down"
                className="sort-button"
                disabled={statusFilter === JOB_STATUS_FILTERS.FINISHED}
                onClick={() => this.setState({ showSortingModal: true })}
              />
            </Menu.Item>
          </Menu>
        </View.Content>

        <View.Content compact>
          <JobList
            jobs={jobs}
            loading={jobsLoading}
            scrollTop={scrollTop}
            hasMore={items.total > jobs.length}
            onRefresh={this.refreshJobs}
            onLoadMore={this.loadMoreJobs}
            onClick={job => this.setState({ selectedJobId: job.id })}
            onScroll={value => this.setState({ scrollTop: value })}
            header={(
              this.props.intl.formatMessage(
                JOB_COUNT_MESSAGES[prevStatusFilter],
                { n: items.total },
              )
            )}
            placeholder={(
              search ? (
                <FormattedMessage
                  id="JobListView.emptyListMessages.search"
                  defaultMessage="No matching jobs found."
                />
              ) : (
                this.props.intl.formatMessage(EMPTY_LIST_MESSAGES[prevStatusFilter])
              )
            )}
            error={error && (
              <ApiErrorMessage
                error={error}
                header={(
                  <FormattedMessage
                    id="JobListView.errors.header"
                    defaultMessage="Jobs could not be retrieved"
                  />
                )}
                messages={ERROR_MESSAGES}
              />
            )}
          />

          <JobSortingOrderModal
            sortBy={this.state.sortBy}
            open={this.state.showSortingModal}
            onChange={this.handleSortChange}
            onClose={() => this.setState({ showSortingModal: false })}
          />
        </View.Content>

        {_.get(user, 'company.telephone') && (
          <View.Footer>
            <a href={`tel:${_.get(user, 'company.telephone')}`}>
              <Button fluid>
                <FormattedMessage id="JobListView.callDispatch.label" defaultMessage="Call dispatch" />
              </Button>
            </a>
          </View.Footer>
        )}
      </View>
    );
  }
}

JobListView.defaultProps = {
  jobs: {
    items: [],
  },
};

const mapStateToProps = state => ({
  users: state.users,
  jobs: state.jobs,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchJobs,
  }, dispatch),
  ...bindPromiseCreators({
    refreshJobs: promisifyRoutine(refreshJobs),
  }, dispatch),
});

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobListView));
