import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { ApiErrorType, IntlMessageType } from '~/common/types';
import ErrorMessage from '~/common/components/ErrorMessage';


const GLOBAL_ERROR_MESSAGES = defineMessages({
  NETWORK: {
    id: 'ApiErrorMessage.errors.network',
    defaultMessage: 'A network error has occurred. Please check your Internet connection and try again.',
  },
  OUTDATED_APP: {
    id: 'ApiErrorMessage.errors.outdatedApp',
    defaultMessage: 'A new version of the page is available. Please reload the page to continue.',
  },
  UNAVAILABLE: {
    id: 'ApiErrorMessage.errors.unavailable',
    defaultMessage: 'The service is currently unavailable. Please try again in a couple of seconds.',
  },
});


class ApiErrorMessage extends Component {
  render() {
    const {
      intl, error, header, messages: propMessages,
    } = this.props;
    const intlMessages = Object.assign(GLOBAL_ERROR_MESSAGES, propMessages);
    const intlMessage = intlMessages[error.code];
    const message = intlMessage ? intl.formatMessage(intlMessage) : error.message;

    return (
      <ErrorMessage
        header={header}
        message={`${message} (${error.code})`}
      />
    );
  }
}

ApiErrorMessage.defaultProps = {
  messages: {},
};

ApiErrorMessage.propTypes = {
  error: ApiErrorType.isRequired,
  header: PropTypes.node.isRequired,
  messages: PropTypes.objectOf(IntlMessageType),
};

export default injectIntl(ApiErrorMessage);
