import _ from 'lodash';

import { TASK_STATUSES_PENDING } from '~/common/constants';


export const getAlikeTasks = (tasks, filters) => _.filter(tasks, filters);

export const getPendingAlikeTasks = (tasks, filters) => {
  const alikeTasks = getAlikeTasks(tasks, filters);
  return alikeTasks.filter(t => TASK_STATUSES_PENDING.includes(t.status));
};

export const getAlikeTaskProgress = (tasks, filters) => {
  const alikeTasks = getAlikeTasks(tasks, filters);
  const pendingTasks = getPendingAlikeTasks(tasks, filters);
  const total = alikeTasks.length;
  const done = total - pendingTasks.length;

  return { done, total };
};
