import { defineMessages } from 'react-intl';


export default defineMessages({
  location_request: {
    id: 'HardwareFeatures.location_request',
    defaultMessage: 'Location request',
  },
  sleepy_devices: {
    id: 'HardwareFeatures.sleepy_devices',
    defaultMessage: 'Support for sleepy devices',
  },
  wake_on_radio: {
    id: 'HardwareFeatures.wake_on_radio',
    defaultMessage: 'Wake-on-Radio (P)',
  },
  retransmission: {
    id: 'HardwareFeatures.retransmission',
    defaultMessage: 'Wake-on-Radio (R)',
  },
  partial_config: {
    id: 'HardwareFeatures.partial_config',
    defaultMessage: 'Partial config updates',
  },
  extended_config_index: {
    id: 'HardwareFeatures.extended_config_index',
    defaultMessage: 'Support for more than 250 transponders',
  },
  knob_support: {
    id: 'HardwareFeatures.knob_support',
    defaultMessage: 'Linked knob',
  },
  nfc_cards: {
    id: 'HardwareFeatures.nfc_cards',
    defaultMessage: 'NFC',
  },
  desfire: {
    id: 'HardwareFeatures.desfire',
    defaultMessage: 'DESFire',
  },
  sticky: {
    id: 'HardwareFeatures.sticky',
    defaultMessage: 'Sticky Mode',
  },
  mount_command: {
    id: 'HardwareFeatures.mount_command',
    defaultMessage: 'Mount command',
  },
  actions_log: {
    id: 'HardwareFeatures.actions_log',
    defaultMessage: 'NFC actions log',
  },
  usage_report: {
    id: 'HardwareFeatures.usage_report',
    defaultMessage: 'Battery usage report',
  },
});
