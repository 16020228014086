import { HW_PRODUCTS, SENSOR_HW_TYPES, HW_VARIANTS } from '~/common/constants';


export const TOOLS = {
  MANUFACTURING: 'manufacturing',
  JOBS: 'jobs',
  DEVICE_CHECK: 'device-check',
};

export const TOOL_CONFIG = [
  {
    name: TOOLS.MANUFACTURING,
    path: '/manufacturing',
    icon: 'pallet',
    requiredScope: 'manufacturing',
    startPriority: 1,
    restoreStateOnSwitch: true,
  },
  {
    name: TOOLS.JOBS,
    path: '/jobs',
    icon: 'home',
    requiredScope: 'jobs',
    startPriority: 0,
    restoreStateOnSwitch: true,
  },
  {
    name: TOOLS.DEVICE_CHECK,
    path: '/device-check',
    icon: 'qrcode',
    startPriority: 2,
    restorePathOnSwitch: false,
  },
];

export const JOB_STATUS_FILTERS = {
  READY: 'ready',
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
};

export const TECH_SUPPORT_PHONE = '+4930609838336';

export const MAX_CONFIG_TIMEOUT = 240000;
export const APTERYX_SENSOR_CONFIG_TIMEOUT = 240000;
export const MQTT_GATEWAY_CONFIG_TIMEOUT = 10000;
export const MQTT_SENSOR_CONFIG_TIMEOUT = 30000;
export const REBOOT_TIMEOUT = 30000;
export const REBOOT_PING_DELAY = 2000;
export const REBOOT_STATUS_DELAY = 2000;
export const CONNECTIVITY_TIMEOUT = 10000;
export const UPDATE_GSM_INFO_TIMEOUT = 10000;
export const UPDATE_868_INFO_TIMEOUT = 10000;
export const BATTERY_INFO_TIMEOUT = 10000;
export const BACKGROUND_TASK_TIMEOUTS = {
  grantPermission: 10000,
  revokePermission: 10000,
  linkPerformanceCheck: 170000,
};

export const DEFAULT_POLL_INTERVAL = 1000;
export const HW_RESPONSE_POLL_INTERVAL = 500;

export const HW_RESPONSE_TIMEOUT = 10000;
export const HW_COMMAND_RETRIES = 2;

export const KI_SIGNAL_TEST_OPTIONS = {
  [SENSOR_HW_TYPES.DOOR]: {
    configOption: 'KI_SIGNAL_STRENGTH',
    stages: [0, 1, 2, 3, 4],
  },
  [SENSOR_HW_TYPES.NDS]: {
    configOption: 'KI_TX_POWER',
    stages: [-16, -12, -8, -4, 0],
  },
  [SENSOR_HW_TYPES.NDS_HYBRID]: {
    configOption: 'KI_TX_POWER',
    stages: [-16, -12, -8, -4, 0],
  },
};

export const APTERYX_GATEWAY_PING_INTERVAL = 35;
export const APTERYX_SENSOR_PING_INTERVAL = 5 * 60;
export const MQTT_GATEWAY_STATS_INTERVAL = 10 * 60;
export const MQTT_SENSOR_STATS_INTERVAL = 60 * 60;

export const DEVICE_CHECK_INCLUDED_RELATIONS = {
  gateway: [
    'organizations',
    'sensors',
    'sensors.status',
    'sensors.status.signal',
    'configuration',
    'status',
    'status.lastMessage',
    'status.lastConfigurationUpdate',
    'metrics',
  ],
  sensor: [
    'organizations',
    'kis',
    'gateways',
    'gateways.status',
    'gateways.status.signal',
    'primaryGateway',
    'configuration',
    'status',
    'status.gatewayUuid',
    'status.lastMessage',
    'status.lastConfigurationUpdate',
    'status.lastKisUpdate',
    'status.batteryInfo',
    'metrics',
  ],
  hybrid: [
    'organizations',
    'kis',
    'sensors',
    'sensors.status',
    'sensors.status.signal',
    'configuration',
    'status',
    'status.lastMessage',
    'status.lastConfigurationUpdate',
    'status.lastKisUpdate',
    'status.batteryInfo',
    'metrics',
  ],
};

export const HW_CONFIG_OVERRIDES = {
  [HW_PRODUCTS.GATEWAY]: {
    [HW_VARIANTS.NDS]: {
      TESTING: {
        mqtt: { 'keep-alive': 15 },
      },
      PRODUCTION: {
        mqtt: { 'keep-alive': 45 },
      },
    },
  },

  [HW_PRODUCTS.SMART_ENTRY]: {
    [HW_VARIANTS.REGULAR]: {
      TESTING: {
        validIdWetOutput: 2,
      },
      PRODUCTION: {
        validIdWetOutput: 1,
      },
    },
    [HW_VARIANTS.NDS]: {
      TESTING: {
        mqtt: { 'keep-alive': 15 },
        sensor: { config: { kiOpening: { wetOutput: 2 } } },
      },
      PRODUCTION: {
        mqtt: { 'keep-alive': 300 },
        sensor: { config: { kiOpening: { wetOutput: 1 } } },
      },
    },
  },

  [HW_PRODUCTS.SMART_ENTRY_HYBRID]: {
    [HW_VARIANTS.NDS]: {
      TESTING: {
        mqtt: { 'keep-alive': 15 },
        sensor: { config: { kiOpening: { wetOutput: 2 } } },
      },
      PRODUCTION: {
        mqtt: { 'keep-alive': 45 },
        sensor: { config: { kiOpening: { wetOutput: 1 } } },
      },
    },
  },
};

export const LINK_PERFORMANCE_CHECK_STAGES = [
  'latency_test',
  'time_dilation_test',
  'cell_quality_test',
  'download_test',
];
