import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '~/common/styles/components/Tag.css';

const Tag = ({
  children,
  className,
  color,
}) => {
  const tagClassName = color ? `tag ${color}` : 'tag grey';
  return (
    <span className={clsx(tagClassName, className)}>
      {children !== null && children !== undefined ? children : 'Tag'}
    </span>
  );
};

Tag.defaultProps = {
  children: null,
  className: null,
  color: 'grey',
};

Tag.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Tag;
