import React, { Component } from 'react';
import { defineMessages } from 'react-intl';

import { HW_PRODUCTS } from '~/common/constants';
import ManufacturingRoutineView from './ManufacturingRoutineView';
import SmartDoorVariantSelectionStep from './steps/SmartDoorVariantSelectionStep';
import HardwareRegistrationStep from './steps/HardwareRegistrationStep';
import ConnectivityTestingStep from './steps/ConnectivityTestingStep';
import PushSensorConfigurationStep from './steps/PushSensorConfigurationStep';
import GrantSensorPermissionStep from './steps/GrantSensorPermissionStep';
import RemoteOpeningTestingStep from './steps/RemoteOpeningTestingStep';
import KiClickOpeningTestingStep from './steps/KiClickOpeningTestingStep';
import RevokeSensorPermissionStep from './steps/RevokeSensorPermissionStep';
import HardwareResetStep from './steps/HardwareResetStep';
import SuccessStep from './steps/SuccessStep';


const STEP_MESSAGES = defineMessages({
  variantSelection: {
    id: 'SmartDoorManufacturingView.steps.variantSelection',
    defaultMessage: 'Selecting product variant',
  },
  hardwareRegistration: {
    id: 'SmartDoorManufacturingView.steps.hardwareRegistration',
    defaultMessage: 'Registering sensor in KIWI infrastructure',
  },
  connectivityTesting: {
    id: 'SmartDoorManufacturingView.steps.connectivityTesting',
    defaultMessage: 'Testing sensor connection',
  },
  pushConfiguration: {
    id: 'SmartDoorManufacturingView.steps.pushConfiguration',
    defaultMessage: 'Configuring sensor',
  },
  grantingPermission: {
    id: 'SmartDoorManufacturingView.steps.grantingPermission',
    defaultMessage: 'Granting temporary permission for testing',
  },
  remoteOpening: {
    id: 'SmartDoorManufacturingView.steps.remoteOpening',
    defaultMessage: 'Opening sensor remotely',
  },
  kiOpening: {
    id: 'SmartDoorManufacturingView.steps.kiOpening',
    defaultMessage: 'Open sensor with transponder',
  },
  revokingPermission: {
    id: 'SmartDoorManufacturingView.steps.revokingPermission',
    defaultMessage: 'Revoking temporary permission',
  },
  hardwareReset: {
    id: 'SmartDoorManufacturingView.steps.hardwareReset',
    defaultMessage: 'Resetting sensor',
  },
});


class SmartDoorManufacturingView extends Component {
  render() {
    const { hardwareUuid } = this.props.match.params;

    return (
      <ManufacturingRoutineView
        hardwareUuid={hardwareUuid}
        hardwareProduct={HW_PRODUCTS.SMART_DOOR}
        stepDescriptions={STEP_MESSAGES}
      >
        <SmartDoorVariantSelectionStep id="variantSelection" />
        <HardwareRegistrationStep id="hardwareRegistration" skipBackwards />
        <ConnectivityTestingStep id="connectivityTesting" skipBackwards />
        <PushSensorConfigurationStep id="pushConfiguration" skipBackwards />
        <GrantSensorPermissionStep id="grantingPermission" skipBackwards />
        <RemoteOpeningTestingStep id="remoteOpening" />
        <KiClickOpeningTestingStep id="kiOpening" />
        <RevokeSensorPermissionStep id="revokingPermission" skipBackwards />
        <HardwareResetStep id="hardwareReset" skipBackwards />
        <SuccessStep id="successStep" />
      </ManufacturingRoutineView>
    );
  }
}

export default SmartDoorManufacturingView;
