import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';


class UUIDInput extends Component {
  handleInputChange = (e, { value }) => {
    this.props.onChange(value.trim());
  };

  handleSubmit = () => {
    const { value } = this.props;
    if (value.length > 0) {
      this.props.onSubmit();
    }
  }

  render() {
    const { autoFocus, value } = this.props;

    return (
      <div className={this.props.className}>
        <Form onSubmit={this.handleSubmit}>
          <Form.Field>
            <label>
              <FormattedMessage id="UUIDInput.input.label" defaultMessage="Device UUID" />
            </label>
            <FormattedMessage id="UUIDInput.input.placeholder" defaultMessage="Device UUID">
              {placeholder => (
                <Input
                  fluid
                  maxLength={64}
                  autoFocus={autoFocus}
                  value={value}
                  placeholder={placeholder}
                  onChange={this.handleInputChange}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form>
      </div>
    );
  }
}

UUIDInput.defaultProps = {
  className: '',
  autoFocus: false,
  onChange: () => {},
  onSubmit: () => {},
};

UUIDInput.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default UUIDInput;
