import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  List,
  Checkbox,
  Button,
  Icon,
  Modal,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { FixingTaskType } from '~/common/types';
import {
  dismissError, createTaskImage, saveTaskImage, deleteTaskImage,
  fetchFixingActions, addTaskFixingAction, saveTaskFixingAction,
} from '~/app/actions/JobsActions';
import Placeholder from '~/common/components/Placeholder';
import FormattedText from '~/common/components/FormattedText';
import SegmentDivider from '~/common/components/SegmentDivider';
import FileInput from '~/common/components/FileInput';
import HorizontalImageGallery from '~/common/components/HorizontalImageGallery';
import AddFixingActionModal from '~/app/components/AddFixingActionModal';
import TaskImageModal from '~/app/components/TaskImageModal';


const FixingTaskDetails = ({ task }) => {
  const [addActionModalOpen, setAddActionModalOpen] = useState(false);
  const [isAddingAction, setIsAddingAction] = useState(false);

  const [selectedFile, setSelectedFile] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [isSavingImage, setIsSavingImage] = useState(false);
  const [isDeletingImage, setIsDeletingImage] = useState(false);
  const [showInvalidFileModal, setShowInvalidFileModal] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.jobs.loading);
  const error = useSelector(state => state.jobs.error);
  const allActions = useSelector(state => state.jobs.fixingActions);

  useEffect(() => {
    dispatch(fetchFixingActions());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && isAddingAction) {
      setIsAddingAction(false);
      if (!error) {
        setAddActionModalOpen(false);
      }
    }

    if (!loading && (isSavingImage || isDeletingImage)) {
      setIsSavingImage(false);
      setIsDeletingImage(false);
      if (!error) {
        setSelectedFile(null);
        setSelectedImage(null);
      }
    }
  }, [loading, error, isAddingAction, isSavingImage, isDeletingImage]);

  const handleActionCheckboxChange = (action, checked) => {
    dispatch(saveTaskFixingAction({
      task,
      action: {
        id: action.id,
        checked: checked ? (new Date()).toISOString() : null,
      },
    }));
  };

  const handleAddAction = (action) => {
    dispatch(addTaskFixingAction({
      task,
      action: {
        ...action,
        checked: (new Date()).toISOString(),
      },
    }));
    setIsAddingAction(true);
  };

  const handleCloseActionModal = () => {
    setAddActionModalOpen(false);
    dispatch(dismissError());
  };

  const handleSelectImage = (url) => {
    const image = task.images.find(i => i.url === url);
    setSelectedImage(image);
  };

  const handleSelectFile = (file) => {
    if (file.type.startsWith('image/')) {
      setSelectedFile(file);
    } else {
      setShowInvalidFileModal(true);
    }
  };

  const handleSaveImage = ({ file, image }) => {
    if (file) {
      dispatch(createTaskImage({
        task,
        file,
        description: image.description,
      }));
      setIsSavingImage(true);
    } else if (image) {
      dispatch(saveTaskImage({ task, image }));
      setIsSavingImage(true);
    }
  };

  const handleDeleteImage = () => {
    if (selectedImage) {
      dispatch(deleteTaskImage({
        task,
        image: selectedImage,
      }));
      setIsDeletingImage(true);
    }
  };

  const handleCloseImageModal = () => {
    setSelectedFile(null);
    setSelectedImage(null);
    dispatch(dismissError());
  };

  return (
    <div className="fixing-details-container">
      <Grid>
        <Grid.Row>
          <Grid.Column width={7} className="label-column">
            <FormattedMessage id="FixingTaskDetails.problem.label" defaultMessage="Problem" />
          </Grid.Column>
          <Grid.Column width={9}>
            {task.problem.name}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={7} className="label-column">
            <FormattedMessage id="FixingTaskDetails.notes.label" defaultMessage="Notes" />
          </Grid.Column>
          <Grid.Column width={9}>
            <Placeholder show={!task.dispatcherNotes}>
              <FormattedText text={task.dispatcherNotes} />
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <SegmentDivider>
        <FormattedMessage id="FixingTaskDetails.fixingActions.header" defaultMessage="Troubleshooting steps" />
      </SegmentDivider>

      <p>
        <FormattedMessage
          id="FixingTaskDetails.fixingActions.instructions"
          defaultMessage="Here are some troubleshooting steps that usually help fixing the problem. Please check each step you have tried."
        />
      </p>
      <List>
        {task.fixingActions.map(action => (
          <List.Item key={`${task.id}${action.id}`}>
            <Checkbox
              label={action.name}
              defaultChecked={action.checked != null}
              onChange={(e, { checked }) => handleActionCheckboxChange(action, checked)}
            />
          </List.Item>
        ))}
      </List>
      <Button compact size="tiny" onClick={() => setAddActionModalOpen(true)}>
        <Icon name="plus" />
        <FormattedMessage id="FixingTaskDetails.fixingActions.add.label" defaultMessage="Add new step" />
      </Button>
      <AddFixingActionModal
        open={addActionModalOpen}
        actions={allActions.filter(a1 => !task.fixingActions.some(a2 => a1.id === a2.id))}
        loading={isAddingAction}
        error={error}
        onSave={handleAddAction}
        onClose={handleCloseActionModal}
      />

      <SegmentDivider>
        <FormattedMessage id="FixingTaskDetails.images.header" defaultMessage="Photos" />
      </SegmentDivider>

      <HorizontalImageGallery
        images={task.images.map(({ url }) => url)}
        onClick={handleSelectImage}
      />
      <FileInput compact size="tiny" accept="image/*" onChange={handleSelectFile}>
        <Icon name="photo" />
        <FormattedMessage id="FixingTaskDetails.images.add.label" defaultMessage="Take a photo for documentation" />
      </FileInput>
      <TaskImageModal
        open={!!selectedFile || !!selectedImage}
        file={selectedFile}
        image={selectedImage}
        error={error}
        isSaving={isSavingImage}
        isDeleting={isDeletingImage}
        onSave={handleSaveImage}
        onDelete={handleDeleteImage}
        onClose={handleCloseImageModal}
      />
      <Modal
        closeOnEscape
        closeOnDimmerClick
        size="mini"
        dimmer="inverted"
        open={showInvalidFileModal}
        onClose={() => setShowInvalidFileModal(false)}
      >
        <Modal.Header>
          <FormattedMessage id="FixingTaskDetails.images.invalidFileModal.header" defaultMessage="Invalid file selected" />
        </Modal.Header>
        <Modal.Content>
          <FormattedMessage id="FixingTaskDetails.images.invalidFileModal.content" defaultMessage="The selected file is not an image." />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setShowInvalidFileModal(false)}>
            <FormattedMessage id="FixingTaskDetails.images.invalidFileModal.ok.label" defaultMessage="OK" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

FixingTaskDetails.propTypes = {
  task: FixingTaskType.isRequired,
};

export default FixingTaskDetails;
