import React from 'react';


export const NavigationContext = React.createContext({
  goBack: () => {},
  setBackAction: () => {},
  popBackAction: () => {},
  pushBackAction: () => {},
});

export const withNavigation = WrappedComponent => props => (
  <NavigationContext.Consumer>
    {navigator => (
      <WrappedComponent
        {...props}
        navigator={navigator}
      />
    )}
  </NavigationContext.Consumer>
);

export default NavigationContext;
