import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Message, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import QrReader from 'react-qr-reader';

import '~/app/styles/components/UUIDScanner.css';


const VIBRATION_TIME = 100;

class UUIDScanner extends Component {
  constructor(props) {
    super(props);
    this.qrReaderRef = React.createRef();
  }

  state = {
    imageScanned: false,
  }

  componentDidCatch(error) {
    this.props.onError(error);
  }

  handleScan = (data) => {
    this.setState({ imageScanned: true });
    if (data && data.length <= 64 && data !== this.state.uuid) {
      this.props.onScan(data.trim());
      this.vibrate();
    }
  };

  vibrate = () => {
    if (navigator.vibrate) {
      navigator.vibrate(VIBRATION_TIME);
    }
  }

  openImageDialog = () => {
    const qrReader = this.qrReaderRef.current;
    qrReader.openImageDialog();
  }

  render() {
    const { value } = this.props;
    const { imageScanned } = this.state;
    // Camera can't be accessed directly in stand-alone mode of Safari Mobile
    const legacyMode = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform) && navigator.standalone;

    return (
      <div className={this.props.className}>
        {value && (
          <Message positive>
            <Message.Header>
              <FormattedMessage
                id="UUIDScanner.validMessage.header"
                defaultMessage="UUID detected"
              />
            </Message.Header>
            {value}
          </Message>
        )}

        {!value && legacyMode && (
          <>
            {imageScanned && (
              <Message negative>
                <Message.Header>
                  <FormattedMessage
                    id="UUIDScanner.noQrCodeMessage.header"
                    defaultMessage="No QR code detected"
                  />
                </Message.Header>
                <FormattedMessage
                  id="UUIDScanner.noQrCodeMessage.content"
                  defaultMessage="No QR code was found in the photo. Please try again."
                />
              </Message>
            )}
            <Button primary fluid onClick={this.openImageDialog}>
              <FormattedMessage
                id="UUIDScanner.scanCode.label"
                defaultMessage="Scan QR code"
              />
            </Button>
          </>
        )}

        {!value && (
          <QrReader
            className={`qr-scanner ${legacyMode ? 'legacy-mode' : ''}`}
            ref={this.qrReaderRef}
            legacyMode={legacyMode}
            showViewFinder={false}
            onScan={this.handleScan}
            onError={this.props.onError}
          />
        )}
      </div>
    );
  }
}

UUIDScanner.defaultProps = {
  value: null,
  className: '',
  onScan: () => {},
  onError: () => {},
};

UUIDScanner.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onScan: PropTypes.func,
  onError: PropTypes.func,
};

export default UUIDScanner;
