import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Image, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { SAFE_HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import staticFile from '~/common/utils/staticFile';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


class AttachUUIDStickerStep extends Component {
  state = {
    next: false,
  }

  render() {
    const { next } = this.state;
    const { job, task } = this.props.routine;

    if (next) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="installation" />}
      >
        <StepView.Content>
          {SAFE_HW_PRODUCTS.includes(task.hardware.hardwareProduct) ? (
            <>
              <p>
                <FormattedMessage
                  id="AttachUUIDStickerStep.jobs.instructions.safe"
                  defaultMessage="Attach the QR code sticker to the bottom of the safe."
                />
              </p>
              <Image
                centered
                bordered
                size="large"
                className="instructions-image"
                src={staticFile('smart_installation/images/safe-uuid-sticker.svg')}
              />
            </>
          ) : (
            <>
              <p>
                <FormattedMessage
                  id="AttachUUIDStickerStep.jobs.instructions.door"
                  defaultMessage="Attach the QR code sticker into the embrasure of the door."
                />
              </p>
              <Image
                centered
                bordered
                size="large"
                className="instructions-image"
                src={staticFile('smart_installation/images/door-uuid-sticker.svg')}
              />
            </>
          )}
        </StepView.Content>

        <StepView.Footer>
          <Button primary fluid onClick={() => this.setState({ next: true })}>
            <FormattedMessage id="AttachUUIDStickerStep.jobs.next.label" defaultMessage="Next" />
          </Button>
        </StepView.Footer>
      </StepView>
    );
  }
}

AttachUUIDStickerStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

export default withRoutine(AttachUUIDStickerStep);
