import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import _ from 'lodash';

import { HW_PRODUCTS, SAFE_HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { openSensor, stopRoutines } from '~/app/actions/HardwareActions';
import ButtonGroup from '~/common/components/ButtonGroup';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';
import TimeoutProgress from '~/app/components/TimeoutProgress';


const TIMEOUT = 35000;

const ERROR_MESSAGES = defineMessages({
  TIMEOUT: {
    id: 'RemoteOpeningTestingStep.jobs.errors.timeout',
    defaultMessage: 'Sensor could not be activated!',
  },
  NOT_ALLOWED: {
    id: 'RemoteOpeningTestingStep.jobs.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = {
  [HW_PRODUCTS.SMART_ENTRY]: _.pick(FIXING_OPTION_MESSAGES,
    'smartEntryInstalledProperly',
    'smartEntryRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.SMART_ENTRY_HYBRID]: _.pick(FIXING_OPTION_MESSAGES,
    'smartEntryInstalledProperly',
    'smartEntryRestart'),
  [HW_PRODUCTS.SMART_DOOR]: _.pick(FIXING_OPTION_MESSAGES,
    'smartDoorInstalledProperly',
    'smartDoorClosed',
    'smartDoorRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.KNOB]: _.pick(FIXING_OPTION_MESSAGES,
    'knobInstalledProperly',
    'knobRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.HANDLE]: _.pick(FIXING_OPTION_MESSAGES,
    'handleInstalledProperly',
    'handleRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.SAFE]: _.pick(FIXING_OPTION_MESSAGES,
    'safeInstalledProperly',
    'safeRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.SAFE_TWO]: _.pick(FIXING_OPTION_MESSAGES,
    'safeInstalledProperly',
    'safeRestart',
    'gatewayRestart'),
};

class RemoteOpeningTestingStep extends Component {
  state = {
    failed: false,
    next: false,
  }

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    this.props.stopRoutines();
  }

  get error() {
    const { failed } = this.state;
    const { action } = this.props;
    const { error } = this.props.hardware;

    if (error) {
      return error;
    }
    if (failed) {
      if (action === 'lock') {
        return <FormattedMessage id="RemoteOpeningTestingStep.jobs.errors.lock" defaultMessage="Lock couldn't be locked." />;
      }
      if (action === 'unlock') {
        return <FormattedMessage id="RemoteOpeningTestingStep.jobs.errors.unlock" defaultMessage="Lock couldn't be unlocked" />;
      }
    }

    return null;
  }

  start = () => {
    const { action } = this.props;
    const { task } = this.props.routine;

    this.setState({ failed: false });
    if (task.hardware.hardwareProduct === HW_PRODUCTS.SMART_DOOR) {
      // Smart doors need a longer timeout as the OPEN response might be sent after
      // the door has been closed and the sensor is powered again
      this.props.openSensor({
        id: task.hardware.sensorId,
        responseTimeout: TIMEOUT,
      });
    } else {
      this.props.openSensor({ id: task.hardware.sensorId });
    }

    if (!action) {
      this.setState({ next: true });
    }
  }

  render() {
    const { next } = this.state;
    const { action } = this.props;
    const { job, task } = this.props.routine;
    const { open } = this.props.hardware;

    if (next && open.success) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="test" />}
        error={this.error}
        errorHeader={(
          <FormattedMessage
            id="RemoteOpeningTestingStep.jobs.errors.header"
            defaultMessage="Test failed"
          />
        )}
        errorMessages={ERROR_MESSAGES}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES[task.hardware.hardwareProduct]}
        retry={this.start}
      >
        <StepView.Content>
          {/* Trigger step */}
          {!(action && open.success) && (
            <>
              <p>
                <FormattedMessage
                  id="RemoteOpeningTestingStep.jobs.description"
                  defaultMessage="Trying to activate sensor remotely..."
                />
              </p>
              <TimeoutProgress timeout={TIMEOUT} />
            </>
          )}

          {/* Manual action step (optional) */}
          {action && (
            <>
              {[HW_PRODUCTS.KNOB, ...SAFE_HW_PRODUCTS]
                .includes(task.hardware.hardwareProduct)
                  && action === 'lock' && (
                    <FormattedMessage
                      id="RemoteOpeningTestingStep.jobs.instructions.knob.lock"
                      defaultMessage="When the activation signal sounds, turn the knob to lock the lock."
                    />
              )}
              {[HW_PRODUCTS.KNOB, ...SAFE_HW_PRODUCTS]
                .includes(task.hardware.hardwareProduct)
                  && action === 'unlock' && (
                    <FormattedMessage
                      id="RemoteOpeningTestingStep.jobs.instructions.knob.unlock"
                      defaultMessage="When the activation signal sounds, turn the knob to unlock the lock."
                    />
              )}
              {task.hardware.hardwareProduct === HW_PRODUCTS.HANDLE && action === 'unlock' && (
                <FormattedMessage
                  id="RemoteOpeningTestingStep.jobs.instructions.handle.unlock"
                  defaultMessage="When the activation signal sounds, push down the handle of the door to unlock the lock."
                />
              )}
            </>
          )}

          {task.hardware.hardwareProduct === HW_PRODUCTS.SMART_DOOR && (
            <p>
              <FormattedMessage
                id="RemoteOpeningTestingStep.jobs.instructions.smartDoor.close"
                defaultMessage="When the lock unlocks, please open and close the door again."
              />
            </p>
          )}
        </StepView.Content>

        <StepView.Footer>
          {/* Manual action step (optional) */}
          {action && open.success && (
            <ButtonGroup>
              <Button fluid color="red" onClick={() => this.setState({ failed: true })}>
                {action === 'lock' && (
                  <FormattedMessage id="RemoteOpeningTestingStep.jobs.failed.lock" defaultMessage="Lock couldn't be locked" />
                )}
                {action === 'unlock' && (
                  <FormattedMessage id="RemoteOpeningTestingStep.jobs.failed.unlock" defaultMessage="Lock couldn't be unlocked" />
                )}
              </Button>
              <Button primary fluid onClick={() => this.setState({ next: true })}>
                {action === 'lock' && (
                  <FormattedMessage id="RemoteOpeningTestingStep.jobs.success.lock" defaultMessage="Lock was locked" />
                )}
                {action === 'unlock' && (
                  <FormattedMessage id="RemoteOpeningTestingStep.jobs.success.unlock" defaultMessage="Lock was unlocked" />
                )}
              </Button>
            </ButtonGroup>
          )}
        </StepView.Footer>
      </StepView>
    );
  }
}

RemoteOpeningTestingStep.defaultProps = {
  action: null,
};

RemoteOpeningTestingStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
  action: PropTypes.oneOf([
    'lock',
    'unlock',
  ]),
};

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openSensor,
  stopRoutines,
}, dispatch);

export default withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoteOpeningTestingStep)));
