import React from 'react';
import PropTypes from 'prop-types';


const InlineList = ({ separator, children, className = null }) => (
  <span className={className}>
    {React.Children.toArray(children)
      .reduce((acc, curr, i) => (i === 0 ? [...acc, curr] : [...acc, separator, curr]), [])}
  </span>
);

InlineList.defaultProps = {
  separator: ', ',
  children: null,
};

InlineList.propTypes = {
  separator: PropTypes.node,
  children: PropTypes.node,
};

export default InlineList;
