import React from 'react';
import PropTypes from 'prop-types';

import FormattedDateTime from '~/common/components/FormattedDateTime';
import '~/app/styles/components/HardwareMessage.css';


const HardwareMessage = ({ message, timestamp }) => {
  if (!message) {
    return null;
  }

  return (
    <div className="hardware-message-container">
      <span className="message-string">
        {message}
      </span>
      {timestamp && (
        <span className="message-timestamp">
          <FormattedDateTime relative value={timestamp} />
        </span>
      )}
    </div>
  );
};

HardwareMessage.defaultProps = {
  message: null,
  timestamp: null,
};

HardwareMessage.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.string,
};

export default HardwareMessage;
