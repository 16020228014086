import React from 'react';
import PropTypes from 'prop-types';

import { UserType, OpsUserType } from '~/common/types';


const FormattedUser = ({ user, show }) => {
  const { firstName, lastName, username } = user || {};

  if (
    firstName && lastName
    && (!show || show === 'fullName')
  ) {
    return (
      <span>{`${firstName} ${lastName}`}</span>
    );
  }

  if (
    username
    && (!show || show === 'username')
  ) {
    return (
      <span>{username}</span>
    );
  }

  return null;
};

FormattedUser.defaultProps = {
  user: null,
  show: null,
};

FormattedUser.propTypes = {
  user: PropTypes.oneOfType([UserType, OpsUserType]),
  show: PropTypes.oneOf(['fullName', 'username']),
};

export default FormattedUser;
