import { defineMessages } from 'react-intl';


export default defineMessages({
  COMMUNAL: {
    id: 'DoorTypes.communal',
    defaultMessage: 'Communal',
  },
  PRIVATE: {
    id: 'DoorTypes.private',
    defaultMessage: 'Private',
  },
});
