import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'semantic-ui-react';


class TimeoutProgress extends Component {
  state = {
    time: 0,
  }

  componentDidMount() {
    const { stepInterval } = this.props;
    this.timerInterval = setInterval(() => this.tick(), stepInterval);
  }

  componentDidUpdate(prevProps) {
    const { timeout: prevTimeout } = prevProps;
    const { timeout: currentTimeout } = this.props;
    if (prevTimeout !== currentTimeout) {
      this.reset();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  reset = () => {
    this.setState({ time: 0 });
  }

  tick = () => {
    const { stepInterval, timeout } = this.props;
    this.setState(({ time }) => ({
      time: time + stepInterval,
    }), () => {
      const { time } = this.state;
      if (time >= timeout) {
        clearInterval(this.timerInterval);
        this.props.onTimeout();
      }
    });
  }

  render() {
    const { time } = this.state;
    const { timeout, size } = this.props;
    const progress = (time / timeout) * 100;

    return (
      <Progress percent={progress} size={size} />
    );
  }
}

TimeoutProgress.defaultProps = {
  timeout: 30000,
  stepInterval: 100,
  size: 'tiny',
  onTimeout: () => {},
};

TimeoutProgress.propTypes = {
  timeout: PropTypes.number,
  stepInterval: PropTypes.number,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'big']),
  onTimeout: PropTypes.func,
};

export default TimeoutProgress;
