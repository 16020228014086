import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import { HW_LIFECYCLE_STATES } from '~/common/constants';
import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { removeSensor } from '~/app/actions/HardwareActions';
import { withRoutine } from '~/app/contexts/RoutineContext';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import StepView from './StepView';


const ERROR_MESSAGES = defineMessages({
  NOT_ALLOWED: {
    id: 'HardwareResetStep.manufacturing.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = _.pick(FIXING_OPTION_MESSAGES, 'contactSupport');


class HardwareResetStep extends Component {
  state = {
    next: false,
  }

  componentDidMount() {
    this.uninstall();
  }

  uninstall = () => {
    const { hardwareUuid } = this.props.routine;

    this.props.removeSensor({
      uuid: hardwareUuid,
      lifecycleState: HW_LIFECYCLE_STATES.MANUFACTURED,
    });
    this.setState({ next: true });
  }

  render() {
    const { next } = this.state;
    const { error, hardwareRemoved } = this.props.hardware;

    if (next && hardwareRemoved) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        instructions={(
          <FormattedMessage
            id="HardwareResetStep.manufacturing.instructions"
            defaultMessage="Please wait until the sensor has been reset."
          />
        )}
        error={error}
        errorMessages={ERROR_MESSAGES}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES}
        retry={this.props.routine.cancel}
      />
    );
  }
}

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  removeSensor,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(HardwareResetStep));
