import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Checkbox, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import { migrateTaskHardware, dismissError } from '~/app/actions/JobsActions';
import { splitSmartEntryHybrid } from '~/app/utils/taskHardwareMigrations';
import ButtonGroup from '~/common/components/ButtonGroup';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


class SmartEntryInstallationPreCheckStep extends Component {
  state = {
    intercomWorks: false,
    openingWorks: false,
    migrateTask: false,
    next: false,
  }

  next = () => {
    this.setState({ next: true });
  }

  migrateHybridToSensor = () => {
    const { task, steps } = this.props.routine;
    this.props.migrateTaskHardware(splitSmartEntryHybrid({ task, steps }));
    this.setState({ migrateTask: true });
  };

  dismissError = () => {
    this.setState({ migrateTask: false, next: false });
    this.props.dismissError();
  }

  render() {
    const { job, task } = this.props.routine;
    const { loading, error } = this.props.jobs;
    const {
      intercomWorks, openingWorks,
      migrateTask, next,
    } = this.state;
    const canContinue = intercomWorks && openingWorks;

    if (next) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        loading={loading}
        header={<JobProgressHeader job={job} task={task} stage="installation" />}
      >
        <StepView.Content>
          <JobSaveErrorModal
            error={migrateTask ? error : null}
            onDismiss={this.dismissError}
          />

          <p>
            <FormattedMessage
              id="SmartEntryInstallationPreCheckStep.jobs.instructions"
              defaultMessage="Please test the doorbell system before installing the sensor."
            />
          </p>
          <Form>
            <Form.Field>
              <FormattedMessage
                id="SmartEntryInstallationPreCheckStep.jobs.checks.intercomWorks"
                defaultMessage="Using the intercom with a tenant works"
              >
                {label => (
                  <Checkbox
                    label={label}
                    checked={this.state.intercomWorks}
                    onChange={(e, { checked }) => this.setState({ intercomWorks: checked })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
            <Form.Field>
              <FormattedMessage
                id="SmartEntryInstallationPreCheckStep.jobs.checks.openingWorks"
                defaultMessage="Opening the door through a tenant works"
              >
                {label => (
                  <Checkbox
                    label={label}
                    checked={this.state.openingWorks}
                    onChange={(e, { checked }) => this.setState({ openingWorks: checked })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
          </Form>
        </StepView.Content>

        <StepView.Footer>
          <ButtonGroup>
            <Button fluid color="red" disabled={canContinue} onClick={this.props.requestSkipTask}>
              <FormattedMessage
                id="SmartEntryInstallationPreCheckStep.jobs.notInstallable.label"
                defaultMessage="Device can't be installed"
              />
            </Button>
            {task.hardware.hardwareProduct === HW_PRODUCTS.SMART_ENTRY_HYBRID && (
              <Button fluid onClick={this.migrateHybridToSensor}>
                <FormattedMessage
                  id="SmartEntryInstallationPreCheckStep.jobs.migrateHybridToSensor.label"
                  defaultMessage="Install Gateway and Sensor separately"
                />
              </Button>
            )}
            <Button fluid primary disabled={!canContinue} onClick={this.next}>
              <FormattedMessage
                id="SmartEntryInstallationPreCheckStep.jobs.next.label"
                defaultMessage="Next"
              />
            </Button>
          </ButtonGroup>
        </StepView.Footer>
      </StepView>
    );
  }
}

SmartEntryInstallationPreCheckStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  jobs: state.jobs,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  requestSkipTask,
  migrateTaskHardware,
  dismissError,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmartEntryInstallationPreCheckStep));
