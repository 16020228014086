import _ from 'lodash';


export default (value, thresholds) => {
  if (thresholds) {
    const { ideal, good } = thresholds;
    const sortedThresholds = _.sortBy(Object.entries(thresholds), x => x[1]);
    if (ideal >= good) {
      return sortedThresholds
        .reverse()
        .filter(([, threshold]) => value >= threshold)
        .map(([x]) => x)[0] || 'poor';
    }
    if (good >= ideal) {
      return sortedThresholds
        .filter(([, threshold]) => value <= threshold)
        .map(([x]) => x)[0] || 'poor';
    }
  }

  return null;
};
