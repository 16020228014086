import { useState, useRef, useEffect } from 'react';
import _ from 'lodash';


export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};


export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return [storedValue, setValue];
};


export const useEventListener = (target, type, listener, options = {}) => {
  const { debounce } = options;

  const savedListener = useRef();

  useEffect(() => {
    savedListener.current = debounce
      ? _.debounce(listener, debounce)
      : listener;
  }, [listener, debounce]);

  useEffect(() => {
    if (!target) return;

    const currentListener = event => savedListener.current(event);
    target.addEventListener(type, currentListener);

    return () => target.removeEventListener(type, currentListener);
  }, [target, type]);
};


export const useInterval = (handler, timeout) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (timeout !== null) {
      const currentHandler = () => savedHandler.current();
      const handle = setInterval(currentHandler, timeout);

      return () => clearInterval(handle);
    }
  }, [timeout]);
};


export const usePermission = (name) => {
  const [state, setState] = useState();

  useEffect(() => {
    if (!navigator.permissions) return;

    let cleanup = () => {};
    const handleChange = ({ target }) => setState(target.state);

    navigator.permissions.query({ name }).then((status) => {
      setState(status.state);

      status.addEventListener('change', handleChange);
      cleanup = () => status.removeEventListener('change', handleChange);
    });

    return () => cleanup();
  }, [name]);

  return {
    state,
    isSupported: !!navigator.permissions,
  };
};
