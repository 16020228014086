import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';


const FileInput = ({
  accept, onChange, children, ...remainingProps
}) => {
  const fileInputRef = useRef();

  const handleClick = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };

  return (
    <>
      <Button {...remainingProps} onClick={handleClick}>
        {children}
      </Button>
      <input
        hidden
        type="file"
        accept={accept}
        ref={fileInputRef}
        onChange={e => onChange(e.target.files[0])}
      />
    </>
  );
};

FileInput.defaultProps = {
  accept: null,
  onChange: () => {},
  children: null,
};

FileInput.propTypes = {
  accept: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default FileInput;
