const extractVersionParts = version => version
  .split('.')
  .map((part) => {
    const match = /\d+/.exec(part);
    return match ? parseInt(match[0], 10) : null;
  })
  .filter(part => part != null);


export default (version1, version2) => {
  const parts1 = extractVersionParts(version1);
  const parts2 = extractVersionParts(version2);
  const length = Math.max(parts1.length, parts2.length);

  for (let i = 0; i < length; i += 1) {
    const part1 = parts1[i] || 0;
    const part2 = parts2[i] || 0;

    if (part1 < part2) {
      return -1;
    }
    if (part1 > part2) {
      return 1;
    }
  }

  return 0;
};
