import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Header, Form, Checkbox, Button,
} from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { TASK_TYPES, HW_PRODUCTS, GATEWAY_HW_PRODUCTS } from '~/common/constants';
import { TECH_SUPPORT_PHONE } from '~/app/constants';
import {
  JobType, TaskType, IntlMessageType, ApiErrorType,
} from '~/common/types';
import attachCompounds from '~/common/utils/attachCompounds';
import { addUnplannedTask, requestSkipTask, requestCancelTask } from '~/app/actions/RoutineActions';
import { withRoutine } from '~/app/contexts/RoutineContext';
import ButtonGroup from '~/common/components/ButtonGroup';
import ErrorMessage from '~/common/components/ErrorMessage';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import FormattedAddress from '~/common/components/FormattedAddress';
import View from '~/app/views/View';
import '~/app/styles/views/jobs/routines/steps/StepView.css';


class StepView extends Component {
  state = {
    retried: false,
    calledSupport: false,
    fixingOptions: Object.keys(this.props.fixingOptionMessages).reduce((result, name) => ({
      ...result,
      [name]: {
        checked: false,
        tested: false,
      },
    }), {}),
  }

  get troubleshootingStage() {
    const { noSkip } = this.props;
    const { retried, calledSupport, fixingOptions } = this.state;

    if (!retried || !Object.values(fixingOptions).every(o => o.tested)) {
      return 'retry';
    }
    if (!calledSupport || noSkip) {
      return 'techSupport';
    }
    return 'skip';
  }

  handleFixingOptionChecked = (name, checked) => {
    const { fixingOptions } = this.state;
    fixingOptions[name].checked = checked;
    this.setState({ fixingOptions });
  }

  handleGoBack = () => {
    const { onNavigateAway } = this.props;
    if (!onNavigateAway || onNavigateAway()) {
      this.props.routine.previous();
    }
  }

  handleCancel = () => {
    const { onNavigateAway } = this.props;
    if (!onNavigateAway || onNavigateAway()) {
      this.props.requestCancelTask();
    }
  }

  retry = () => {
    const { fixingOptions } = this.state;
    for (const option of Object.values(fixingOptions).filter(o => o.checked)) {
      option.tested = true;
    }
    this.setState({ fixingOptions, retried: true });
    this.props.retry();
  }

  installUnplannedGateway = () => {
    const { job, task } = this.props.routine;

    this.props.addUnplannedTask({
      jobId: job.id,
      type: TASK_TYPES.INSTALLATION,
      hardware: {
        hardwareProduct: HW_PRODUCTS.GATEWAY,
      },
      contextData: {
        requiredBy: [task.id],
      },
    });
  }

  callSupport = () => {
    window.location = `tel:${TECH_SUPPORT_PHONE}`;
    this.setState({ calledSupport: true });
  }

  render() {
    const { fixingOptions } = this.state;
    const {
      loading, routineLoading, header, retry,
      error, errorHeader, errorMessages, errorContext,
      allowUnplannedGateway, fixingOptionMessages,
    } = this.props;
    const {
      job, task, next,
    } = this.props.routine;

    const canRetry = retry !== null;
    const canInstallUnplannedGateway = allowUnplannedGateway
      && job.tasks
        .filter(t => t.contextData.requiredBy && t.contextData.requiredBy.includes(task.id))
        .every(t => !GATEWAY_HW_PRODUCTS.includes(t.hardware.hardwareProduct));

    return (
      <View loading={loading && !routineLoading} onGoBack={this.handleGoBack}>
        <View.Header
          primaryText={header}
          secondaryText={<FormattedAddress address={task.hardware.address || job.address} />}
          action={this.handleCancel}
          actionIcon="cancel"
        />

        {/* Default View */}
        {!error && (
          this.props.children
        )}

        {/* Error View */}
        {error && (
          <>
            <View.Content>
              <div className="error-message-container">
                {error.code && (
                  <ApiErrorMessage
                    error={error}
                    header={errorHeader}
                    messages={errorMessages}
                  />
                )}
                {!error.code && (
                  <ErrorMessage
                    header={errorHeader}
                    message={error.message || error}
                  />
                )}
              </div>

              {errorContext}

              {Object.keys(fixingOptionMessages).length > 0 && (
                <>
                  <Header size="tiny" className="troubleshooting-header">
                    <FormattedMessage
                      id="StepView.jobs.troubleshooting.header"
                      defaultMessage="Troubleshooting guide"
                    />
                  </Header>
                  <p>
                    <FormattedMessage
                      id="StepView.jobs.troubleshooting.instruction"
                      defaultMessage="Check out the possible fixing options below and run the test again."
                    />
                  </p>
                  <Form className="troubleshooting-form">
                    {Object.entries(fixingOptionMessages).map(([name, message]) => (
                      <Form.Field key={name}>
                        <Checkbox
                          label={this.props.intl.formatMessage(message)}
                          disabled={fixingOptions[name].tested}
                          checked={fixingOptions[name].checked}
                          onChange={(e, data) => this.handleFixingOptionChecked(name, data.checked)}
                        />
                      </Form.Field>
                    ))}
                  </Form>
                </>
              )}
            </View.Content>

            <View.Footer>
              <ButtonGroup>
                {global.ALLOW_SKIP_STEPS && (
                  <Button fluid color="yellow" onClick={next}>
                    <FormattedMessage id="StepView.jobs.skipStep.label" defaultMessage="Skip step (for testing)" />
                  </Button>
                )}
                {this.troubleshootingStage === 'skip' && (
                  <Button fluid color="red" onClick={this.props.requestSkipTask}>
                    <FormattedMessage id="StepView.jobs.notInstallable.label" defaultMessage="Device can't be installed" />
                  </Button>
                )}
                {['techSupport', 'skip'].includes(this.troubleshootingStage) && (
                  <Button fluid primary onClick={this.callSupport}>
                    <FormattedMessage id="StepView.jobs.callSupport.label" defaultMessage="Call technical support" />
                  </Button>
                )}
                {canInstallUnplannedGateway && (
                  <Button fluid onClick={this.installUnplannedGateway}>
                    <FormattedMessage id="StepView.jobs.unplannedGateway.label" defaultMessage="Install additional gateway" />
                  </Button>
                )}
                {canRetry && (
                  <Button fluid primary={this.troubleshootingStage === 'retry'} onClick={this.retry}>
                    <FormattedMessage id="StepView.jobs.tryAgain.label" defaultMessage="Try again" />
                  </Button>
                )}
              </ButtonGroup>
            </View.Footer>
          </>
        )}
      </View>
    );
  }
}

StepView.defaultProps = {
  loading: false,
  error: null,
  children: null,
  errorHeader: (
    <FormattedMessage
      id="StepView.jobs.errors.header"
      defaultMessage="An unexpected error occurred"
    />
  ),
  errorMessages: {},
  errorContext: null,
  allowUnplannedGateway: false,
  fixingOptionMessages: {},
  retry: null,
  onNavigateAway: null,
  noSkip: false,
};

StepView.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType,
    previous: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([
    ApiErrorType,
    PropTypes.node,
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  ]),
  errorHeader: PropTypes.node,
  errorMessages: PropTypes.objectOf(IntlMessageType),
  errorContext: PropTypes.node,
  allowUnplannedGateway: PropTypes.bool,
  fixingOptionMessages: PropTypes.objectOf(IntlMessageType),
  retry: PropTypes.func,
  onNavigateAway: PropTypes.func,
  noSkip: PropTypes.bool,
  children: PropTypes.node,
};

const mapStateToProps = state => ({
  routineLoading: state.routine.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  addUnplannedTask,
  requestSkipTask,
  requestCancelTask,
}, dispatch);

export default attachCompounds(withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(StepView))), {
  Content: View.Content,
  Footer: View.Footer,
});
