import {
  takeLeading, select, put, all,
} from 'redux-saga/effects';
import _ from 'lodash';

import { TASK_STATUSES, TASK_STATUSES_SKIPPED } from '~/common/constants';
import {
  startJob, resumeJob, finishJob, cancelJob,
  startTask, finishTask, cancelTask, skipTask, navigate,
} from '~/app/actions/RoutineActions';
import {
  saveJob, handoverJob, resetJob,
  createTask, saveTask, handoverTask, resetTask,
} from '~/app/actions/JobsActions';
import { stopRoutines } from '~/app/actions/HardwareActions';
import { registerSagas, callAction, SagaError } from '~/app/sagas';


function* startJobSaga({ job, user }) {
  const result = yield callAction(saveJob, {
    id: job.id,
    installer: user,
    startTime: job.startTime || (new Date()).toISOString(),
    drivingDistance: job.drivingDistance,
    drivingTime: job.drivingTime,
  });

  return { job: result.job };
}

function* resumeJobSaga({ job }) {
  const resumableTasks = job.tasks.filter(t => t.status === TASK_STATUSES.IN_PROGRESS);
  const task = _.orderBy(resumableTasks, 'startTime', 'desc')[0];
  if (task) {
    yield callAction(startTask, { job, task });
  }

  return { job, task };
}

function* finishJobSaga(job) {
  yield callAction(saveJob, {
    id: job.id,
    workingTime: job.workingTime,
    endTime: (new Date()).toISOString(),
  });
  yield callAction(handoverJob, job);
}

function* cancelJobSaga(job) {
  yield put(stopRoutines());
  yield callAction(resetJob, job);
}

function* startTaskSaga({ job, task }) {
  if (!task.id) {
    const result = yield callAction(createTask, task);
    task = result.task;
  }
  const parentTaskIds = task.contextData.requiredBy || [];
  const tasksToBeCancelled = job.tasks.filter(t => (
    t.id !== task.id
    && !parentTaskIds.includes(t.id)
    && t.status === TASK_STATUSES.IN_PROGRESS));

  yield all(tasksToBeCancelled.map(t => callAction(resetTask, t)));

  const result = yield callAction(saveTask, {
    id: task.id,
    status: TASK_STATUSES.IN_PROGRESS,
    startTime: task.startTime || (new Date()).toISOString(),
  });

  return { task: result.task };
}

function* finishTaskSaga({ job, task }) {
  ({ task } = yield callAction(saveTask, {
    id: task.id,
    status: TASK_STATUSES.SUCCESS,
    causingParty: task.causingParty,
    installerNotes: task.installerNotes,
    endTime: (new Date()).toISOString(),
  }));
  yield callAction(handoverTask, task);

  // Update tasks array of job with updated task
  job = {
    ...job,
    tasks: [
      ...job.tasks.filter(t => t.id !== task.id),
      task,
    ],
  };

  // Check, if there is a task to continue with
  // (parent task or another task already in progress)
  const parentTaskIds = task.contextData.requiredBy || [];
  const parentTasks = job.tasks.filter(t => parentTaskIds.includes(t.id));
  const tasksInProgress = job.tasks.filter(t => t.status === TASK_STATUSES.IN_PROGRESS);
  const nextTask = _.orderBy(parentTasks, [t => t.startTime || '', t => t.created], ['desc', 'desc'])[0]
    || _.orderBy(tasksInProgress, 'startTime', 'desc')[0];
  if (nextTask) {
    yield callAction(startTask, { job, task: nextTask });
  }

  return {
    task,
    nextTask,
  };
}

function* cancelTaskSaga(task) {
  yield put(stopRoutines());
  yield callAction(resetTask, task);
}

function* skipTaskSaga({ job, task }) {
  if (!TASK_STATUSES_SKIPPED.includes(task.status)) {
    throw new SagaError('The provided status is not a valid skipping status', 'STATUS_NOT_VALID');
  }

  ({ task } = yield callAction(saveTask, {
    id: task.id,
    status: task.status,
    causingParty: task.causingParty,
    installerNotes: task.installerNotes,
    endTime: (new Date()).toISOString(),
  }));

  // Update tasks array of job with updated task
  job = {
    ...job,
    tasks: [
      ...job.tasks.filter(t => t.id !== task.id),
      task,
    ],
  };

  const nextTasks = job.tasks.filter(t => t.status === TASK_STATUSES.IN_PROGRESS);
  const nextTask = _.orderBy(nextTasks, 'date', 'desc')[0];
  if (nextTask) {
    yield callAction(startTask, { job, task: nextTask });
  }

  return {
    task,
    nextTask,
  };
}

function* navigateSaga(stepId) {
  const oldStepId = yield select(state => state.routine.stepId);
  if (oldStepId !== stepId) {
    const taskId = yield select(state => state.routine.taskId);
    yield callAction(saveTask, {
      id: taskId,
      step: stepId,
    });
  }

  return { stepId };
}

export default registerSagas([
  [startJob, startJobSaga],
  [resumeJob, resumeJobSaga],
  [finishJob, finishJobSaga],
  [cancelJob, cancelJobSaga],
  [startTask, startTaskSaga],
  [finishTask, finishTaskSaga],
  [cancelTask, cancelTaskSaga],
  [skipTask, skipTaskSaga],
  [navigate, navigateSaga, { effectCreator: takeLeading }],
]);
