import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import { saveTask, dismissError as dismissJobsError } from '~/app/actions/JobsActions';
import { fetchGateway, editGateway, dismissError as dismissHardwareError } from '~/app/actions/HardwareActions';
import ComboBox from '~/common/components/ComboBox';
import ButtonGroup from '~/common/components/ButtonGroup';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const POWER_TYPE_OPTIONS = [
  {
    value: 'transformer',
    text: <FormattedMessage id="GatewayInstallationDataInputStep.jobs.powerType.options.transformer" defaultMessage="Connected to transformer" />,
  },
  {
    value: 'socket',
    text: <FormattedMessage id="GatewayInstallationDataInputStep.jobs.powerType.options.socket" defaultMessage="Connected to socket" />,
  },
];

class GatewayInstallationDataInputStep extends Component {
  state = {
    location: _.get(this.props.routine.gateway, 'installationLocation') || '',
    powerType: this.props.routine.task.contextData.powerType || null,
    next: false,
  }

  componentDidMount() {
    const { task } = this.props.routine;
    this.props.fetchGateway({ id: task.hardware.gatewayId });
  }

  componentDidUpdate(prevProps) {
    const { gateway } = this.props.routine;
    const { gateway: prevGateway } = prevProps.routine;

    if (gateway && gateway !== prevGateway) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ location: gateway.installationLocation || '' });
    }
  }

  validate = () => {
    const { location, powerType } = this.state;
    return [
      location,
      powerType,
    ].every(value => value != null && value.length > 0);
  }

  next = () => {
    if (this.validate()) {
      const { task } = this.props.routine;
      const { location, powerType } = this.state;

      this.props.editGateway({
        id: task.hardware.gatewayId,
        installationLocation: location,
      });
      this.props.saveTask({
        id: task.id,
        installationLocation: location,
        contextData: {
          ...task.contextData,
          powerType,
        },
      });
      this.setState({ next: true });
    }
  }

  dismissError = () => {
    this.setState({ next: false });
    this.props.dismissJobsError();
    this.props.dismissHardwareError();
  }

  render() {
    const { location, powerType, next } = this.state;
    const { job, task } = this.props.routine;
    const { taskSaved, loading: jobsLoading, error: jobsError } = this.props.jobs;
    const { hardwareEdited, loading: hardwareLoading, error: hardwareError } = this.props.hardware;
    const validForm = this.validate();

    const error = next ? (jobsError || hardwareError) : null;
    const loading = error == null
      ? (jobsLoading || hardwareLoading)
      : false;

    if (next && hardwareEdited && taskSaved) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        loading={loading}
        header={<JobProgressHeader job={job} task={task} stage="installation" />}
      >
        <StepView.Content>
          <JobSaveErrorModal
            error={error}
            onDismiss={this.dismissError}
          />
          <p>
            <FormattedMessage
              id="GatewayInstallationDataInputStep.jobs.instructions"
              defaultMessage="Please install the gateway and answer the following questions about the device:"
            />
          </p>
          <Form onSubmit={this.next}>
            <Form.Field>
              <label>
                <FormattedMessage id="GatewayInstallationDataInputStep.jobs.location.label" defaultMessage="Location" />
              </label>
              <FormattedMessage id="GatewayInstallationDataInputStep.jobs.location.placeholder" defaultMessage="Location">
                {placeholder => (
                  <Input
                    fluid
                    maxLength="255"
                    value={location}
                    placeholder={placeholder}
                    onChange={(e, { value }) => this.setState({ location: value })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage id="GatewayInstallationDataInputStep.jobs.powerType.label" defaultMessage="Type of power connection" />
              </label>
              <FormattedMessage id="GatewayInstallationDataInputStep.jobs.powerType.placeholder" defaultMessage="Type of power connection">
                {placeholder => (
                  <ComboBox
                    fluid
                    value={powerType}
                    placeholder={placeholder}
                    options={POWER_TYPE_OPTIONS}
                    onChange={value => this.setState({ powerType: value })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
          </Form>
        </StepView.Content>

        <StepView.Footer>
          <ButtonGroup>
            <Button fluid color="red" disabled={validForm} onClick={this.props.requestSkipTask}>
              <FormattedMessage id="GatewayInstallationDataInputStep.jobs.notInstallable.label" defaultMessage="Device can't be installed" />
            </Button>
            <Button primary fluid disabled={!validForm} onClick={this.next}>
              <FormattedMessage id="GatewayInstallationDataInputStep.jobs.test.label" defaultMessage="Test Gateway" />
            </Button>
          </ButtonGroup>
        </StepView.Footer>
      </StepView>
    );
  }
}

GatewayInstallationDataInputStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  jobs: state.jobs,
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveTask,
  dismissJobsError,
  fetchGateway,
  editGateway,
  dismissHardwareError,
  requestSkipTask,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(GatewayInstallationDataInputStep));
