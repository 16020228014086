import _ from 'lodash';

import { HW_PRODUCTS, HW_PRODUCT_VARIANTS, HW_VARIANTS } from '~/common/constants';
import HW_PRODUCT_VARIANT_MESSAGES from '~/common/locales/HardwareProductVariants';


// Until installation tasks have been refactored to use product ids, labels
// for certain hardware variants are changed in dependence on the corresponding product
const PRODUCT_VARIANT_MAPPING = {
  [HW_PRODUCTS.GATEWAY]: {
    [HW_VARIANTS.APTERYX]: HW_PRODUCT_VARIANTS.GW1,
    [HW_VARIANTS.MQTT]: HW_PRODUCT_VARIANTS.GWSDS,
    [HW_VARIANTS.NDS]: HW_PRODUCT_VARIANTS.GW2,
  },
  [HW_PRODUCTS.SMART_ENTRY]: {
    [HW_VARIANTS.REGULAR]: HW_PRODUCT_VARIANTS.SE1,
    [HW_VARIANTS.NDS]: HW_PRODUCT_VARIANTS.SE2,
  },
  [HW_PRODUCTS.SMART_ENTRY_HYBRID]: {
    [HW_VARIANTS.NDS]: HW_PRODUCT_VARIANTS.SE2,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const formatProductVariant = (intl, hardwareProduct, hardwareVariant) => {
  const productVariant = _.get(
    PRODUCT_VARIANT_MAPPING,
    [hardwareProduct, hardwareVariant],
    hardwareVariant,
  );

  const message = HW_PRODUCT_VARIANT_MESSAGES[productVariant];
  if (message) {
    return intl.formatMessage(message);
  }
};
