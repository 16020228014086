import PropTypes from 'prop-types';

import {
  TASK_TYPES, TASK_STATUSES, HW_PRODUCTS, HW_VARIANTS,
  DOOR_TYPES, DEVICE_USAGES,
} from '~/common/constants';


export const IntlMessageType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  description: PropTypes.string,
  defaultMessage: PropTypes.string,
});


export const ErrorType = PropTypes.shape({
  code: PropTypes.string,
  message: PropTypes.string.isRequired,
});


export const ApiErrorType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  status: PropTypes.number,
});


export const OpsUserType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.email,
});

export const InstallerCompanyType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  telephone: PropTypes.string,
});


export const UserType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
});

export const CoordinatorUserType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  telephone: PropTypes.string,
});


export const AddressType = PropTypes.shape({
  street: PropTypes.string.isRequired,
  postalCode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
});


export const OrganizationType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});


export const JobDocumentType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string,
  mimeType: PropTypes.string,
  url: PropTypes.string,
});


export const TaskImageType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  url: PropTypes.string,
  description: PropTypes.string,
});


export const FixingActionType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.string,
});


export const FixingProblemType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  fixingActions: PropTypes.arrayOf(FixingActionType),
});


const CommonTaskPropTypes = {
  id: PropTypes.number.isRequired,
  jobId: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(TASK_TYPES)).isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  created: PropTypes.string,
  step: PropTypes.string,
  status: PropTypes.oneOf(Object.values(TASK_STATUSES)).isRequired,
  planned: PropTypes.bool.isRequired,
  dispatcherNotes: PropTypes.string,
  installerNotes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  contextData: PropTypes.object.isRequired,
  hardware: PropTypes.shape({
    sensorId: PropTypes.number,
    gatewayId: PropTypes.number,
    uuid: PropTypes.string,
    hardwareProduct: PropTypes.oneOf(Object.values(HW_PRODUCTS)).isRequired,
    hardwareVariant: PropTypes.oneOf(Object.values(HW_VARIANTS)).isRequired,
    floor: PropTypes.number,
    doorNumber: PropTypes.number,
    doorType: PropTypes.oneOf(Object.values(DOOR_TYPES)),
    specifier: PropTypes.string,
    usage: PropTypes.oneOf(Object.values(DEVICE_USAGES)).isRequired,
  }),
  images: PropTypes.arrayOf(TaskImageType),
};

export const InstallationTaskType = PropTypes.shape({
  ...CommonTaskPropTypes,
  // eslint-disable-next-line react/forbid-prop-types
  settingsOverride: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  configurationOverride: PropTypes.object,
});

export const FixingTaskType = PropTypes.shape({
  ...CommonTaskPropTypes,
  problem: FixingProblemType.isRequired,
  fixingActions: PropTypes.arrayOf(FixingActionType).isRequired,
  causingParty: PropTypes.string,
  allowHardwareExchange: PropTypes.bool.isRequired,
});

export const TaskType = PropTypes.oneOfType([InstallationTaskType, FixingTaskType]);


export const JobType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  company: InstallerCompanyType,
  installer: OpsUserType,
  coordinator: CoordinatorUserType,
  priority: PropTypes.number,
  appointment: PropTypes.string,
  organization: OrganizationType,
  address: AddressType.isRequired,
  owner: UserType,
  admin: UserType,
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  created: PropTypes.string,
  createdBy: OpsUserType,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  duration: PropTypes.number,
  timeSinceLastJob: PropTypes.number,
  drivingDistance: PropTypes.number,
  drivingTime: PropTypes.number,
  workingTime: PropTypes.number,
  notes: PropTypes.string,
  tasksProgress: PropTypes.shape(Object.values(TASK_TYPES).reduce((typeAgg, type) => ({
    ...typeAgg,
    [type]: PropTypes.shape(Object.values(TASK_STATUSES).reduce((statusAgg, status) => ({
      ...statusAgg,
      [status]: PropTypes.number.isRequired,
    }), {})),
  }), {})),
  tasks: PropTypes.arrayOf(TaskType),
});


export const GatewayType = PropTypes.shape({
  gatewayId: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  address: AddressType,
  organizations: PropTypes.arrayOf(OrganizationType),
  specifier: PropTypes.string,
  installationLocation: PropTypes.string,
  hardwareType: PropTypes.string,
  hardwareRevision: PropTypes.string,
  firmware: PropTypes.string,
  protocol: PropTypes.string.isRequired,
  manufactureDate: PropTypes.string,
  installationDate: PropTypes.string,
  usage: PropTypes.oneOf(Object.values(DEVICE_USAGES)).isRequired,
  link868: PropTypes.string,
  status: PropTypes.shape({
    isOnline: PropTypes.bool.isRequired,
    timestamp: PropTypes.string,
    lastMessage: PropTypes.shape({
      message: PropTypes.string,
      timestamp: PropTypes.string,
    }),
    signal: PropTypes.shape({
      rssi: PropTypes.number,
      bitErrorRate: PropTypes.number,
    }),
  }),
  sensors: PropTypes.arrayOf((...args) => SensorType(...args)),
});


export const SensorType = PropTypes.shape({
  sensorId: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string,
  address: AddressType,
  organizations: PropTypes.arrayOf(OrganizationType),
  specifier: PropTypes.string,
  installationLocation: PropTypes.string,
  doorNumber: PropTypes.number,
  doorType: PropTypes.oneOf(Object.values(DOOR_TYPES)),
  hardwareType: PropTypes.string,
  hardwareRevision: PropTypes.string,
  firmware: PropTypes.string,
  protocol: PropTypes.string.isRequired,
  manufactureDate: PropTypes.string,
  installationDate: PropTypes.string,
  usage: PropTypes.oneOf(Object.values(DEVICE_USAGES)).isRequired,
  link868: PropTypes.string,
  lastConfigurationUpdate: PropTypes.shape({
    pushed: PropTypes.string,
    updated: PropTypes.string,
    result: PropTypes.string,
    resultTimestamp: PropTypes.string,
    success: PropTypes.bool,
  }),
  lastKisUpdate: PropTypes.shape({
    pushed: PropTypes.string,
    updated: PropTypes.string,
    result: PropTypes.string,
    resultTimestamp: PropTypes.string,
    success: PropTypes.bool,
  }),
  status: PropTypes.shape({
    isOnline: PropTypes.bool.isRequired,
    timestamp: PropTypes.string,
    batteryInfo: PropTypes.shape({
      level: PropTypes.number,
      step: PropTypes.number,
    }),
    lastMessage: PropTypes.shape({
      message: PropTypes.string,
      timestamp: PropTypes.string,
    }),
    signal: PropTypes.shape({
      snr: PropTypes.number,
    }),
  }),
  gateways: PropTypes.arrayOf(GatewayType),
});
