import { createSelector } from 'reselect';

const getMessages = state => state.locale.messages;
const getCurrentLocale = state => state.locale.currentLocale;

export const getAvailableLocales = createSelector(
  [getMessages],
  messages => Object.keys(messages),
);

export const getCurrentMessages = createSelector(
  [getMessages, getCurrentLocale],
  (messages, locale) => messages[locale],
);
