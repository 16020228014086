import React, { Component } from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


class SuccessStep extends Component {
  state = {
    backToStart: false,
  }

  componentDidMount() {
    setTimeout(() => this.setState({ backToStart: true }), 3000);
  }

  render() {
    const { backToStart } = this.state;

    if (backToStart) {
      return <Redirect to="/manufacturing" />;
    }

    return (
      <StepView {...this.props}>
        {({ stepList }) => (
          <StepView.Content>
            <Message icon positive>
              <Icon name="check circle" />
              <Message.Content>
                <Message.Header>
                  <FormattedMessage
                    id="SuccessStep.manufacturing.message.header"
                    defaultMessage="Manufacturing completed!"
                  />
                </Message.Header>
                <FormattedMessage
                  id="SuccessStep.manufacturing.message.text"
                  defaultMessage="The device has been setup and tested successfully."
                />
              </Message.Content>
            </Message>

            { stepList }
          </StepView.Content>
        )}
      </StepView>
    );
  }
}

export default withRoutine(SuccessStep);
