import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import PullToRefresh from 'pulltorefreshjs';

import '~/app/styles/components/PullToRefreshWrapper.css';


const MESSAGES = defineMessages({
  instructionsPullToRefresh: {
    id: 'PullToRefreshWrapper.pullToRefresh',
    defaultMessage: 'Pull down to refresh',
  },
  instructionsReleaseToRefresh: {
    id: 'PullToRefreshWrapper.releaseToRefresh',
    defaultMessage: 'Release to refresh',
  },
  instructionsRefreshing: {
    id: 'PullToRefreshWrapper.refreshing',
    defaultMessage: 'Refreshing',
  },
});

class PullToRefreshWrapper extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.initializePullToRefresh();
  }

  componentDidUpdate(prevProps) {
    // Reinitialize PullToRefresh.js if any of its options has changed
    const ptrOptions = ['refresh', 'shouldPullToRefresh'];
    if (ptrOptions.some(x => prevProps[x] !== this.props[x])) {
      this.destroyPullToRefresh();
      this.initializePullToRefresh();
    }
  }

  componentWillUnmount() {
    this.destroyPullToRefresh();
  }

  initializePullToRefresh = () => {
    const { formatMessage } = this.props.intl;

    let { shouldPullToRefresh } = this.props;
    if (!shouldPullToRefresh) {
      shouldPullToRefresh = () => this.containerRef.current.parentElement.scrollTop === 0;
    }

    this.instance = PullToRefresh.init({
      shouldPullToRefresh,
      onRefresh: this.props.refresh,
      mainElement: this.containerRef.current,
      triggerElement: this.containerRef.current,
      instructionsPullToRefresh: formatMessage(MESSAGES.instructionsPullToRefresh),
      instructionsReleaseToRefresh: formatMessage(MESSAGES.instructionsReleaseToRefresh),
      instructionsRefreshing: formatMessage(MESSAGES.instructionsRefreshing),
    });
  }

  destroyPullToRefresh = () => {
    if (this.instance) {
      this.instance.destroy();
    }
  }

  render() {
    return (
      <div className="ptr-container" ref={this.containerRef}>
        {this.props.children}
      </div>
    );
  }
}

PullToRefreshWrapper.defaultProps = {
  shouldPullToRefresh: null,
};

PullToRefreshWrapper.propTypes = {
  refresh: PropTypes.func.isRequired,
  shouldPullToRefresh: PropTypes.func,
};

export default injectIntl(PullToRefreshWrapper);
