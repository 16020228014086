import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { usePrevious } from '~/common/utils/hooks';
import '~/common/styles/components/ComboBox.css';


const OTHER_VALUE = 'other';


const ComboBox = ({
  fluid, disabled, maxLength, placeholder, otherLabel, options, value, onChange,
}) => {
  const [dropdownValue, setDropdownValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const prevValue = usePrevious(value);
  useEffect(() => {
    if (prevValue === value) return;

    const isOption = value !== inputValue && options.some(o => o.value === value);
    if (isOption || value == null) {
      setDropdownValue(value);
      setInputValue('');
    } else {
      setDropdownValue(OTHER_VALUE);
      setInputValue(value);
    }
  }, [options, value, prevValue, inputValue]);

  const handleDropdownChange = useCallback((e, { value: newValue }) => {
    setDropdownValue(newValue);
    onChange(newValue === OTHER_VALUE ? inputValue : newValue);
  }, [inputValue, onChange]);

  const handleInputChange = useCallback((e, { value: newValue }) => {
    setInputValue(newValue);
    onChange(newValue);
  }, [onChange]);

  const allOptions = options.concat({
    value: OTHER_VALUE,
    className: 'other-option',
    text: otherLabel || (
      <FormattedMessage id="ComboBox.options.other" defaultMessage="Other" />
    ),
  });

  return (
    <div className="combo-box-container">
      <Dropdown
        selection
        fluid={fluid}
        disabled={disabled}
        value={dropdownValue}
        placeholder={placeholder}
        options={allOptions}
        onChange={handleDropdownChange}
      />
      {dropdownValue === OTHER_VALUE && (
        <Input
          autoFocus
          fluid={fluid}
          disabled={disabled}
          maxLength={maxLength}
          value={inputValue}
          placeholder={placeholder}
          onChange={handleInputChange}
        />
      )}
    </div>
  );
};

ComboBox.defaultProps = {
  fluid: false,
  disabled: false,
  maxLength: null,
  placeholder: '',
  otherLabel: null,
  value: null,
  onChange: () => {},
};

ComboBox.propTypes = {
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.node.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  placeholder: PropTypes.string,
  otherLabel: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ComboBox;
