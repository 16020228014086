import {
  useRef, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { promisifyRoutine } from 'redux-saga-routines';
import _ from 'lodash';

import { DEVICE_TYPES } from '~/common/constants';
import { DEVICE_CHECK_INCLUDED_RELATIONS } from '~/app/constants';
import { usePrevious, useInterval } from '~/common/utils/hooks';
import includesRelations from '~/common/utils/includesRelations';
import {
  fetchGateway, fetchSensor, fetchHybrid, editGateway, editSensor,
  updateGatewayStatus, updateGatewayMetrics, updateGatewaySensors,
  updateSensorStatus, updateSensorMetrics, updateSensorGateways,
  stopRoutines,
} from '~/app/actions/HardwareActions';


const MIN_REFRESH_DELAY = 10000;
const STATUS_REFRESH_INTERVAL = 30000;
const METRICS_REFRESH_INTERVAL = 45000;
const CONNECTED_DEVICES_REFRESH_INTERVAL = 65000;
const REFRESH_CHECK_INTERVAL = 1000;

const deriveDeviceType = (gatewayId, sensorId) => {
  if (gatewayId != null && sensorId != null) {
    return DEVICE_TYPES.HYBRID;
  }
  if (gatewayId != null) {
    return DEVICE_TYPES.GATEWAY;
  }
  if (sensorId != null) {
    return DEVICE_TYPES.SENSOR;
  }
};

const needsRefresh = (started, finished, refreshInterval) => {
  const now = Date.now();
  const sinceStarted = now - started;
  const sinceFinished = now - finished;
  const updatePending = !finished || started > finished;

  return !updatePending
    && sinceStarted > refreshInterval
    && sinceFinished > MIN_REFRESH_DELAY;
};

const DeviceCheckHardwareProvider = ({
  gatewayId, sensorId, deviceType, children,
}) => {
  const statusUpdateStarted = useRef();
  const statusUpdateFinished = useRef();
  const metricsUpdateStarted = useRef();
  const metricsUpdateFinished = useRef();
  const connectedDevicesUpdateStarted = useRef();
  const connectedDevicesUpdateFinished = useRef();

  const dispatch = useDispatch();
  const sensor = useSelector(state => state.hardware.sensors.byId[sensorId]);
  const gateway = useSelector(state => state.hardware.gateways.byId[gatewayId]);
  const hardware = useMemo(() => _.merge(sensor, gateway), [sensor, gateway]);
  const loading = useSelector(state => state.hardware.loading);
  const error = useSelector(state => state.hardware.error);

  // If device type is unknown, derive from passed ids
  if (deviceType == null) {
    deviceType = deriveDeviceType(gatewayId, sensorId);
  }

  const relationsToFetch = DEVICE_CHECK_INCLUDED_RELATIONS[deviceType];
  const isHardwareFetched = useMemo(
    () => includesRelations(hardware, relationsToFetch),
    [hardware, relationsToFetch],
  );

  const refreshHardware = useCallback(() => {
    const now = Date.now();
    statusUpdateStarted.current = now;
    metricsUpdateStarted.current = now;
    connectedDevicesUpdateStarted.current = now;

    let action;
    let params = { include: relationsToFetch };
    if (deviceType === DEVICE_TYPES.HYBRID) {
      action = fetchHybrid;
      params = { ...params, sensorId, gatewayId };
    } else if (deviceType === DEVICE_TYPES.GATEWAY) {
      action = fetchGateway;
      params = { ...params, id: gatewayId };
    } else if (deviceType === DEVICE_TYPES.SENSOR) {
      action = fetchSensor;
      params = { ...params, id: sensorId };
    } else {
      return Promise.resolve();
    }

    return promisifyRoutine(action)(params, dispatch).catch(() => {});
  }, [dispatch, sensorId, gatewayId, deviceType, relationsToFetch]);

  const refreshStatus = useCallback(() => {
    statusUpdateStarted.current = Date.now();

    const [actionCreator, id] = gatewayId != null
      ? [updateGatewayStatus, gatewayId]
      : [updateSensorStatus, sensorId];

    const prefix = 'status.';
    const include = relationsToFetch
      .filter(x => x.startsWith(prefix))
      .map(x => x.slice(prefix.length));

    dispatch(actionCreator({ id, include }));
  }, [dispatch, sensorId, gatewayId, relationsToFetch]);

  const refreshMetrics = useCallback(() => {
    metricsUpdateStarted.current = Date.now();

    const [actionCreator, id] = gatewayId != null
      ? [updateGatewayMetrics, gatewayId]
      : [updateSensorMetrics, sensorId];

    dispatch(actionCreator({ id }));
  }, [dispatch, gatewayId, sensorId]);

  const refreshConnectedDevices = useCallback(() => {
    connectedDevicesUpdateStarted.current = Date.now();

    const [actionCreator, id] = gatewayId != null
      ? [updateGatewaySensors, gatewayId]
      : [updateSensorGateways, sensorId];

    const prefix = gatewayId != null ? 'sensors.' : 'gateways.';
    const include = relationsToFetch
      .filter(x => x.startsWith(prefix))
      .map(x => x.slice(prefix.length));

    dispatch(actionCreator({ id, include }));
  }, [dispatch, sensorId, gatewayId, relationsToFetch]);

  useEffect(() => {
    if (!isHardwareFetched) {
      refreshHardware();
    }
  }, [isHardwareFetched, refreshHardware]);

  const editHardware = useCallback((attributes) => {
    const [actionCreator, id] = gatewayId != null
      ? [editGateway, gatewayId]
      : [editSensor, sensorId];

    dispatch(actionCreator({ id, ...attributes }));
  }, [dispatch, gatewayId, sensorId]);

  const prevHardware = usePrevious(hardware);
  useEffect(() => {
    if (hardware === prevHardware) return;

    const now = Date.now();
    const {
      status, metrics, gateways, sensors,
    } = hardware;
    const {
      status: prevStatus,
      metrics: prevMetrics,
      gateways: prevGateways,
      sensors: prevSensors,
    } = prevHardware || {};

    if (status !== prevStatus) {
      statusUpdateFinished.current = now;
    }
    if (metrics !== prevMetrics) {
      metricsUpdateFinished.current = now;
    }
    if (gateways !== prevGateways || sensors !== prevSensors) {
      connectedDevicesUpdateFinished.current = now;
    }
  }, [hardware, prevHardware]);

  useEffect(() => () => dispatch(stopRoutines()), [dispatch]);

  const checkForRefresh = useCallback(() => {
    if (document.visibilityState !== 'visible') return;

    const needsStatusUpdate = needsRefresh(
      statusUpdateStarted.current,
      statusUpdateFinished.current,
      STATUS_REFRESH_INTERVAL,
    );
    if (needsStatusUpdate) {
      refreshStatus();
    }

    const isOnline = _.get(hardware, 'status.isOnline', false);
    const needsMetricsUpdate = needsRefresh(
      metricsUpdateStarted.current,
      metricsUpdateFinished.current,
      METRICS_REFRESH_INTERVAL,
    );
    if (isOnline && needsMetricsUpdate) {
      refreshMetrics();
    }

    const needsConnectedDevicesUpdate = needsRefresh(
      connectedDevicesUpdateStarted.current,
      connectedDevicesUpdateFinished.current,
      CONNECTED_DEVICES_REFRESH_INTERVAL,
    );
    if (needsConnectedDevicesUpdate) {
      refreshConnectedDevices();
    }
  }, [
    hardware,
    statusUpdateStarted, statusUpdateFinished, refreshStatus,
    metricsUpdateStarted, metricsUpdateFinished, refreshMetrics,
    connectedDevicesUpdateStarted, connectedDevicesUpdateFinished, refreshConnectedDevices,
  ]);

  useInterval(checkForRefresh, REFRESH_CHECK_INTERVAL);

  return children({
    hardware,
    loading,
    error,
    isHardwareFetched,
    refreshHardware,
    refreshStatus,
    refreshMetrics,
    refreshConnectedDevices,
    editHardware,
  });
};

DeviceCheckHardwareProvider.defaultProps = {
  gatewayId: null,
  sensorId: null,
  deviceType: null,
  children: null,
};

DeviceCheckHardwareProvider.propTypes = {
  gatewayId: PropTypes.number,
  sensorId: PropTypes.number,
  deviceType: PropTypes.oneOf(Object.values(DEVICE_TYPES)),
  children: PropTypes.func,
};

export default DeviceCheckHardwareProvider;
