import { call, put } from 'redux-saga/effects';

import { fetchCurrentOpsUser } from '~/app/actions/UsersActions';
import { invalidateSession } from '~/app/actions/AuthActions';
import { registerSagas } from '~/app/sagas';
import * as api from '~/common/services/ApiService';


function* fetchCurrentOpsUserSaga() {
  try {
    const { user } = yield call(api.fetchCurrentOpsUser);
    return { user };
  } catch (e) {
    if (e.code === 'NOT_ALLOWED') {
      yield put(invalidateSession());
    }
    throw e;
  }
}

export default registerSagas([
  [fetchCurrentOpsUser, fetchCurrentOpsUserSaga],
]);
