import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TimeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import _ from 'lodash';


const formatDuration = (totalSeconds) => {
  const duration = moment.duration(totalSeconds * 1000);
  const hours = _.padStart(duration.hours(), 2, '0');
  const minutes = _.padStart(duration.minutes(), 2, '0');
  return `${hours}:${minutes}`;
};


const DurationInput = ({ value, onChange, ...otherProps }) => {
  const handleChange = useCallback((e, data) => {
    onChange(e, {
      ...data,
      value: data.value ? moment.duration(data.value).asSeconds() : null,
    });
  }, [onChange]);

  return (
    <TimeInput
      {...otherProps}
      value={value != null ? formatDuration(value) : ''}
      onChange={handleChange}
    />
  );
};

DurationInput.defaultProps = {
  value: null,
  onChange: () => {},
};

DurationInput.propTypes = {
  ...TimeInput.propTypes,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default DurationInput;
