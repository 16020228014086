import { SagaError } from '~/app/sagas';


const getCurrentPositionWrapper = options => new Promise((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(resolve, reject, options);
});


export default function* getGeolocation({
  timeout = 10000,
  minAccuracy = 200,
  maximumAge = 60 * 1000,
} = {}) {
  if (!navigator.geolocation) {
    throw new SagaError('The device does not support geolocation.', 'GEOLOCATION_UNSUPPORTED');
  }

  const timeoutTime = Date.now() + timeout;
  while (Date.now() < timeoutTime) {
    let coords;
    try {
      ({ coords } = yield getCurrentPositionWrapper({
        maximumAge,
        enableHighAccuracy: true,
        timeout: timeoutTime - Date.now(),
      }));
    } catch (e) {
      throw e.code === 1
        ? new SagaError('No permission to retrieve the geolocation.', 'GEOLOCATION_PERMISSION_DENIED')
        : new SagaError('Geolocation is unavailable.', 'GEOLOCATION_UNAVAILABLE');
    }

    if (!minAccuracy || coords.accuracy <= minAccuracy) {
      return {
        lat: coords.latitude,
        lng: coords.longitude,
      };
    }

    // If accuracy is not high enough, ensure to get a fresh position on next try
    maximumAge = 0;
  }

  throw new SagaError('Geolocation is unavailable.', 'GEOLOCATION_UNAVAILABLE');
}
