import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { HW_PROTOCOLS } from '~/common/constants';
import { GatewayType, SensorType } from '~/common/types';
import FormattedDateTime from '~/common/components/FormattedDateTime';
import '~/common/styles/components/HardwareOnlineStatus.css';


const HardwareOnlineStatus = ({
  hardware, isOnline, icon, compact,
}) => {
  if (isOnline == null && (!hardware || !hardware.status)) {
    return null;
  }

  if (hardware && hardware.status) {
    isOnline = hardware.status.isOnline;
  }

  return (
    <div className="hardware-online-status">
      <p className={`status ${isOnline ? 'online' : 'offline'}`}>
        {icon && (
          <Icon name="circle" size="small" />
        )}
        {isOnline ? (
          <FormattedMessage id="HardwareOnlineStatus.online" defaultMessage="online" />
        ) : (
          <FormattedMessage id="HardwareOnlineStatus.offline" defaultMessage="offline" />
        )}
      </p>
      {!compact && hardware && hardware.protocol !== HW_PROTOCOLS.MQTT && (
        <p className="last-seen">
          <FormattedMessage
            id="HardwareOnlineStatus.lastSeen"
            defaultMessage="(last seen: {timestamp})"
            values={{
              timestamp: hardware.status.timestamp ? (
                <FormattedDateTime relative value={hardware.status.timestamp} />
              ) : (
                <FormattedMessage id="HardwareOnlineStatus.lastSeen.never" defaultMessage="never" />
              ),
            }}
          />
        </p>
      )}
    </div>
  );
};

HardwareOnlineStatus.defaultProps = {
  isOnline: null,
  hardware: null,
  icon: false,
  compact: false,
};

HardwareOnlineStatus.propTypes = {
  isOnline: PropTypes.bool,
  hardware: PropTypes.oneOfType([
    GatewayType,
    SensorType,
  ]),
  icon: PropTypes.bool,
  compact: PropTypes.bool,
};

export default HardwareOnlineStatus;
