import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import { TOOLS, TOOL_CONFIG } from '~/app/constants';
import { logout } from '~/app/actions/AuthActions';
import { fetchCurrentOpsUser } from '~/app/actions/UsersActions';
import ToolsContext from '~/app/contexts/ToolsContext';
import AppSidebar from '~/app/components/AppSidebar';
import JobContainer from '~/app/containers/JobContainer';
import ManufacturingContainer from '~/app/containers/ManufacturingContainer';
import DeviceCheckContainer from '~/app/containers/DeviceCheckContainer';
import LoadingView from '~/app/views/LoadingView';


const TOOL_COMPONENTS = {
  [TOOLS.MANUFACTURING]: ManufacturingContainer,
  [TOOLS.JOBS]: JobContainer,
  [TOOLS.DEVICE_CHECK]: DeviceCheckContainer,
};


class ToolsContainer extends Component {
  state = {
    toolPaths: Object.assign(...TOOL_CONFIG.map(c => ({ [c.name]: c.path }))),
    sidebarVisible: false,
  }

  componentDidMount() {
    this.props.fetchCurrentOpsUser();

    const { history } = this.props;
    this.removeHistoryListener = history.listen(({ pathname }) => {
      const tool = this.getToolFromPath(pathname);
      if (tool && tool !== this.activeTool) {
        this.handleToolSwitch();
      }
    });
  }

  componentDidUpdate() {
    if (this.availableTools.length === 0) {
      this.props.invalidateSession();
    }
  }

  componentWillUnmount() {
    if (this.removeHistoryListener) {
      this.removeHistoryListener();
    }
  }

  get availableTools() {
    const { scopes } = this.props.auth;
    return TOOL_CONFIG.filter(c => !c.requiredScope || scopes.includes(c.requiredScope));
  }

  get activeTool() {
    const { location } = this.props;
    return this.getToolFromPath(location.pathname);
  }

  getToolFromPath = path => TOOL_CONFIG
    .filter(c => path.startsWith(c.path))
    .map(c => c.name)[0];

  getConfig = tool => TOOL_CONFIG
    .filter(config => config.name === tool)[0];

  handleToolSwitch = () => {
    // Store current path to be able to restore the state
    // of the currently active tool (if configured)
    const { pathname } = this.props.location;
    const activeToolConfig = this.getConfig(this.activeTool);
    if (activeToolConfig && activeToolConfig.restoreStateOnSwitch) {
      this.setState(prevState => ({
        toolPaths: {
          ...prevState.toolPaths,
          [this.activeTool]: pathname,
        },
      }));
    }
  }

  switchTool = (tool) => {
    const toolPath = this.state.toolPaths[tool];
    if (toolPath && tool !== this.activeTool) {
      const { restoreStateOnSwitch } = this.getConfig(tool);
      this.props.history.replace(toolPath, { restoreState: restoreStateOnSwitch });
    }
  }

  showSidebar = () => {
    this.setState({ sidebarVisible: true });
  }

  hideSidebar = () => {
    this.setState({ sidebarVisible: false });
  }

  render() {
    const { sidebarVisible } = this.state;
    const { current: currentUser } = this.props.users;

    const defaultTool = _.sortBy(this.availableTools, c => c.startPriority)[0];

    if (!currentUser) {
      return <LoadingView />;
    }

    return (
      <ToolsContext.Provider
        value={{
          activeTool: this.activeTool,
          availableTools: this.availableTools,
          switchTool: this.switchTool,
          showSidebar: this.showSidebar,
          hideSidebar: this.hideSidebar,
        }}
      >
        <AppSidebar
          visible={sidebarVisible}
          currentUser={currentUser}
          logout={this.props.logout}
        >
          <Switch>
            {this.availableTools.map(tool => (
              <Route key={tool.name} path={tool.path} component={TOOL_COMPONENTS[tool.name]} />
            ))}
            <Redirect to={defaultTool.path} />
          </Switch>
        </AppSidebar>
      </ToolsContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  users: state.users,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCurrentOpsUser,
  logout,
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToolsContainer));
