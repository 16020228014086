import { defineMessages } from 'react-intl';


export default {
  GATEWAY_868_MODE: defineMessages({
    true: {
      id: 'ConfigurationOptions.gateway868Mode.apteryx',
      defaultMessage: 'Apteryx',
    },
    false: {
      id: 'ConfigurationOptions.gateway868Mode.mqtt',
      defaultMessage: 'MQTT',
    },
  }),
  KI_SIGNAL_STRENGTH: defineMessages({
    0: {
      id: 'ConfigurationOptions.kiSignalStrength.veryLow',
      defaultMessage: '0 - Very low',
    },
    1: {
      id: 'ConfigurationOptions.kiSignalStrength.low',
      defaultMessage: '1 - Low',
    },
    2: {
      id: 'ConfigurationOptions.kiSignalStrength.medium',
      defaultMessage: '2 - Medium',
    },
    3: {
      id: 'ConfigurationOptions.kiSignalStrength.high',
      defaultMessage: '3 - High',
    },
    4: {
      id: 'ConfigurationOptions.kiSignalStrength.veryHigh',
      defaultMessage: '4 - Very high',
    },
  }),
};
