import React from 'react';
import _ from 'lodash';

import { HW_VARIANTS } from '~/common/constants';
import { HW_CONFIG_OVERRIDES } from '~/app/constants';
import JobRoutineView from './JobRoutineView';
import CheckFixingActionsStep from './steps/CheckFixingActionsStep';
import ConnectivityTestingStep from './steps/ConnectivityTestingStep';
import ConfigurationStep from './steps/ConfigurationStep';
import TrackHardwareStatusStep from './steps/TrackHardwareStatusStep';
import SuccessStep from './steps/SuccessStep';


const GatewayFixingView = (props) => {
  const { task: { hardware } } = props;
  const { hardwareProduct, hardwareVariant } = hardware;

  const isApteryx = hardwareVariant === HW_VARIANTS.APTERYX;
  const configOverrides = _.get(HW_CONFIG_OVERRIDES, [hardwareProduct, hardwareVariant], {});

  return (
    <JobRoutineView {...props}>
      <TrackHardwareStatusStep id="checkStatusStart" atStart skipBackwards />
      <ConfigurationStep id="pushTestingConfig" override={configOverrides.TESTING} disabled={isApteryx || !configOverrides.TESTING} skipWaiting skipBackwards />
      <CheckFixingActionsStep id="checkActions" />
      {/* TODO: Disable signal strength check for now until further testing has been done */}
      <ConnectivityTestingStep id="connectivityTesting" ignoreSignalStrength skipBackwards />
      <ConfigurationStep id="pushProductionConfig" override={configOverrides.PRODUCTION} disabled={isApteryx} skipBackwards />
      <TrackHardwareStatusStep id="checkStatusEnd" atEnd skipBackwards />
      <SuccessStep id="success" />
    </JobRoutineView>
  );
};

export default GatewayFixingView;
