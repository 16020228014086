import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { JOB_STATUSES, TASK_STATUSES_DONE } from '~/common/constants';
import { JOB_STATUS_FILTERS } from '~/app/constants';
import { JobType } from '~/common/types';
import { fetchJobTasks } from '~/app/actions/JobsActions';
import {
  requestStartJob, requestFinishJob, requestCancelJob,
  resumeJob, addUnplannedTask, dismissError,
} from '~/app/actions/RoutineActions';
import View from '~/app/views/View';
import ButtonGroup from '~/common/components/ButtonGroup';
import FormattedAddress from '~/common/components/FormattedAddress';
import JobDetails from '~/app/components/JobDetails';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';


class JobDetailsView extends Component {
  componentDidMount() {
    const { job } = this.props;
    this.props.fetchJobTasks(job);
  }

  getJobStatusFilter = () => {
    const { job } = this.props;
    switch (job.status) {
      case JOB_STATUSES.READY:
        return JOB_STATUS_FILTERS.READY;
      case JOB_STATUSES.IN_PROGRESS:
        return JOB_STATUS_FILTERS.IN_PROGRESS;
      case JOB_STATUSES.SUCCESS:
      case JOB_STATUSES.FAILED:
        return JOB_STATUS_FILTERS.FINISHED;
      default:
        return JOB_STATUS_FILTERS.READY;
    }
  }

  startJob = () => {
    const { job } = this.props;
    this.props.requestStartJob({ job });
  }

  resumeJob = () => {
    const { job } = this.props;
    this.props.resumeJob({ job });
  }

  addUnplannedTask = () => {
    const { job } = this.props;
    this.props.addUnplannedTask({
      jobId: job.id,
    });
  }

  finishJob = () => {
    this.props.requestFinishJob();
  }

  render() {
    const { job } = this.props;
    const { loading: jobsLoading } = this.props.jobs;
    const { loading: routineLoading, error: routineError } = this.props.routine;
    const hasTasks = job.tasks && job.tasks.length > 0;
    const tasksDone = job.tasks && job.tasks.every(t => TASK_STATUSES_DONE.includes(t.status));
    const statusFilter = this.getJobStatusFilter();

    return (
      <View loading={jobsLoading && !routineLoading} onGoBack={({ replace }) => replace('/jobs', { statusFilter })}>
        <View.Header
          primaryText={<FormattedMessage id="JobDetailsView.header.primary" defaultMessage="Job" />}
          secondaryText={<FormattedAddress address={job.address} />}
          action={job.status === JOB_STATUSES.IN_PROGRESS ? (
            () => this.props.requestCancelJob(job)
          ) : (
            null
          )}
          actionIcon="cancel"
        />

        <View.Content>
          <JobSaveErrorModal
            error={routineError}
            onDismiss={this.props.dismissError}
          />
          <JobDetails job={job} loading={jobsLoading} />
        </View.Content>

        <View.Footer>
          <ButtonGroup>
            {job.status === JOB_STATUSES.READY && (
              <Button primary fluid disabled={!hasTasks} onClick={this.startJob}>
                <FormattedMessage id="JobDetailsView.start.label" defaultMessage="Start job" />
              </Button>
            )}
            {job.status === JOB_STATUSES.IN_PROGRESS && !tasksDone && (
              <Button primary fluid disabled={!hasTasks} onClick={this.resumeJob}>
                <FormattedMessage id="JobDetailsView.resume.label" defaultMessage="Resume job" />
              </Button>
            )}
            {job.status === JOB_STATUSES.IN_PROGRESS && tasksDone && (
              <>
                <Button fluid disabled={!hasTasks} onClick={this.addUnplannedTask}>
                  <FormattedMessage id="JobDetailsView.additionalDevice.label" defaultMessage="Install additional device" />
                </Button>
                <Button primary fluid disabled={!hasTasks} onClick={this.finishJob}>
                  <FormattedMessage id="JobDetailsView.finish.label" defaultMessage="Finish job" />
                </Button>
              </>
            )}
          </ButtonGroup>
        </View.Footer>
      </View>
    );
  }
}

JobDetailsView.propTypes = {
  job: JobType.isRequired,
};

const mapStateToProps = state => ({
  user: state.users.current,
  jobs: state.jobs,
  routine: state.routine,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchJobTasks,
  requestStartJob,
  requestFinishJob,
  requestCancelJob,
  resumeJob,
  addUnplannedTask,
  dismissError,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobDetailsView);
