import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { usePrevious } from '~/common/utils/hooks';
import { navigate, pauseTask, dismissError } from '~/app/actions/RoutineActions';
import RoutineView from '~/app/views/RoutineView';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';


const JobRoutineView = ({ children }) => {
  const dispatch = useDispatch();

  const [containerKey, setContainerKey] = useState(Math.random());

  const stepId = useSelector(state => state.routine.stepId);
  const loading = useSelector(state => state.routine.loading);
  const error = useSelector(state => state.routine.error);
  const jobId = useSelector(state => state.routine.jobId);
  const taskId = useSelector(state => state.routine.taskId);

  const job = useSelector(state => state.jobs.items.byId[jobId]);
  const task = job && taskId
    ? job.tasks.find(t => t.id === taskId)
    : null;

  const sensor = useSelector(state => (task
    ? state.hardware.sensors.byId[task.hardware.sensorId]
    : null
  ));
  const gateway = useSelector(state => (task
    ? state.hardware.gateways.byId[task.hardware.gatewayId]
    : null
  ));

  // Navigate if step of a task has changed, unless triggered by usual routine navigation
  const { step: taskStepId } = task;
  const prevTaskStepId = usePrevious(task && task.step);
  const needsNavigationToTaskStep = taskStepId
    && taskStepId !== prevTaskStepId
    && taskStepId !== stepId;
  useEffect(() => {
    if (needsNavigationToTaskStep && !loading) {
      dispatch(navigate(taskStepId));
    }
  }, [needsNavigationToTaskStep, loading, taskStepId, dispatch]);

  // Make sure to set navigating to true if we're about to navigate
  // to the task step after it has changed to prevent concurrent
  // navigation requests by other components
  const navigating = loading || needsNavigationToTaskStep;

  const context = useMemo(() => ({
    job, task, sensor, gateway,
  }), [job, task, sensor, gateway]);

  const handleNavigate = useCallback(
    newStepId => dispatch(navigate(newStepId)),
    [dispatch],
  );

  const handleBack = useCallback(
    () => dispatch(pauseTask(task)),
    [dispatch, task],
  );

  const handleDismissError = useCallback(() => {
    dispatch(dismissError());

    // Force remount of step component
    setContainerKey(Math.random());
  }, [dispatch]);

  return (
    <>
      {/* Key is used to force step component to reset on navigation errors by remounting */}
      <div key={containerKey}>
        <RoutineView
          stepId={stepId}
          context={context}
          navigate={handleNavigate}
          onBack={handleBack}
          navigating={navigating}
        >
          {children}
        </RoutineView>
      </div>

      <JobSaveErrorModal
        error={error}
        onDismiss={handleDismissError}
      />
    </>
  );
};

JobRoutineView.defaultProps = {
  children: null,
};

JobRoutineView.propTypes = {
  children: PropTypes.node,
};

export default JobRoutineView;
