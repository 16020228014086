import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import _ from 'lodash';

import { FormattedMessage } from 'react-intl';
import {
  SENSOR_HW_TYPES,
  GATEWAY_RSSI_THRESHOLDS,
  GATEWAY_BER_THRESHOLDS,
  SENSOR_SIGNAL_THRESHOLDS,
  UNIT_DBM,
} from '~/common/constants';
import { SensorType, GatewayType } from '~/common/types';
import Placeholder from '~/common/components/Placeholder';
import FormattedTimespan from '~/common/components/FormattedTimespan';
import FormattedMetric from '~/common/components/FormattedMetric';
import '~/app/styles/components/HardwareConnectivitySummary.css';


class HardwareConnectivitySummary extends Component {
  renderOnlineStatus = (status, forceOffline = false) => {
    const isOnline = status.isOnline && !forceOffline;
    const lastOnline = status.timestamp
      ? (new Date() - Date.parse(status.timestamp)) / 1000
      : null;

    return (
      <span className={isOnline ? 'online' : 'offline'}>
        {isOnline && (
          <FormattedMessage id="HardwareConnectivitySummary.onlineStatus.online" defaultMessage="online" />
        )}
        {!isOnline && (
          <>
            <FormattedMessage id="HardwareConnectivitySummary.onlineStatus.offline" defaultMessage="offline" />
            <span className="last-online">
              <FormattedMessage
                id="HardwareConnectivitySummary.lastOnline.timespan"
                defaultMessage="(last seen: {timespan})"
                values={{
                  timespan: lastOnline !== null ? (
                    <FormattedTimespan context="past" totalSeconds={lastOnline} />
                  ) : (
                    <FormattedMessage id="HardwareConnectivitySummary.lastOnline.never" defaultMessage="never" />
                  ),
                }}
              />
            </span>
          </>
        )}
      </span>
    );
  }

  renderGatewayRows = (gateway, forceOffline = false) => (
    <>
      <Table.Row className="uuid-row">
        <Table.Cell>
          {Object.values(SENSOR_HW_TYPES).includes(gateway.hardwareType) ? (
            <FormattedMessage id="HardwareConnectivitySummary.hybrid.label" defaultMessage="Hybrid" />
          ) : (
            <FormattedMessage id="HardwareConnectivitySummary.gateway.label" defaultMessage="Gateway" />
          )}
        </Table.Cell>
        <Table.Cell>
          {gateway.uuid}
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>
          <FormattedMessage id="HardwareConnectivitySummary.gateway.onlineStatus.label" defaultMessage="Status" />
        </Table.Cell>
        <Table.Cell>
          {this.renderOnlineStatus(gateway.status, forceOffline)}
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>
          <FormattedMessage id="HardwareConnectivitySummary.gateway.firmware.label" defaultMessage="Firmware" />
        </Table.Cell>
        <Table.Cell className="firmware">
          <Placeholder>
            {gateway.firmware}
          </Placeholder>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>
          <FormattedMessage id="HardwareConnectivitySummary.gateway.rssi.label" defaultMessage="RSSI" />
        </Table.Cell>
        <Table.Cell>
          <Placeholder show={gateway.status.signal.rssi == null}>
            <FormattedMetric
              value={gateway.status.signal.rssi}
              unit={UNIT_DBM}
              thresholds={GATEWAY_RSSI_THRESHOLDS}
            />
          </Placeholder>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>
          <FormattedMessage id="HardwareConnectivitySummary.gateway.ber.label" defaultMessage="Bit error rate" />
        </Table.Cell>
        <Table.Cell>
          <Placeholder show={gateway.status.signal.bitErrorRate == null}>
            <FormattedMetric
              value={gateway.status.signal.bitErrorRate}
              unit="%"
              thresholds={GATEWAY_BER_THRESHOLDS}
            />
          </Placeholder>
        </Table.Cell>
      </Table.Row>
    </>
  );

  renderSensorRows = (sensor, forceOffline = false) => (
    <>
      <Table.Row className="uuid-row">
        <Table.Cell>
          <FormattedMessage id="HardwareConnectivitySummary.sensor.label" defaultMessage="Sensor" />
        </Table.Cell>
        <Table.Cell>
          {sensor.uuid}
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>
          <FormattedMessage id="HardwareConnectivitySummary.sensor.onlineStatus.label" defaultMessage="Status" />
        </Table.Cell>
        <Table.Cell>
          {this.renderOnlineStatus(sensor.status, forceOffline)}
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>
          <FormattedMessage id="HardwareConnectivitySummary.sensor.firmware.label" defaultMessage="Firmware" />
        </Table.Cell>
        <Table.Cell className="firmware">
          <Placeholder>
            {sensor.firmware}
          </Placeholder>
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>
          <FormattedMessage
            id="HardwareConnectivitySummary.sensor.signal.label"
            defaultMessage="Signal strength"
          />
        </Table.Cell>
        <Table.Cell>
          <Placeholder show={sensor.status.signal.signal == null}>
            <FormattedMetric
              value={sensor.status.signal.signal}
              thresholds={SENSOR_SIGNAL_THRESHOLDS}
            />
          </Placeholder>
        </Table.Cell>
      </Table.Row>

      {sensor.status.gateway && (
        this.renderGatewayRows(sensor.status.gateway)
      )}
      {!sensor.status.gateway && (
        <Table.Row className="uuid-row">
          <Table.Cell>
            <FormattedMessage id="HardwareConnectivitySummary.sensor.gateway.label" defaultMessage="Gateway" />
          </Table.Cell>
          <Table.Cell className="no-gateway">
            <FormattedMessage id="HardwareConnectivitySummary.sensor.gateway.notConnected" defaultMessage="(not connected)" />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );

  render() {
    const { sensor, gateway, forceOffline } = this.props;
    const device = _.merge(gateway, sensor);

    let rows = null;
    if (gateway != null) {
      rows = this.renderGatewayRows(device, forceOffline);
    } else if (sensor != null) {
      rows = this.renderSensorRows(device, forceOffline);
    }

    return (
      <div className="hardware-status-container">
        <Table unstackable compact basic="very" size="small">
          <Table.Body>
            {rows}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

HardwareConnectivitySummary.defaultProps = {
  sensor: null,
  gateway: null,
  forceOffline: false,
};

HardwareConnectivitySummary.propTypes = {
  sensor: SensorType,
  gateway: GatewayType,
  forceOffline: PropTypes.bool,
};

export default HardwareConnectivitySummary;
