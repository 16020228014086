import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Menu, Icon, Header } from 'semantic-ui-react';
import Sidebar from 'react-sidebar';

import { OpsUserType } from '~/common/types';
import TOOL_MESSAGES from '~/app/locales/Tools';
import { withTools } from '~/app/contexts/ToolsContext';
import { withNavigation } from '~/app/contexts/NavigationContext';
import '~/app/styles/components/AppSidebar.css';


class AppSidebar extends Component {
  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    const { visible: prevVisible } = prevProps;

    // Navigating back should hide the sidebar
    if (!prevVisible && visible) {
      this.props.navigator.pushBackAction(this.props.tools.hideSidebar);
    }
    if (prevVisible && !visible) {
      this.props.navigator.popBackAction(this.props.tools.hideSidebar);
    }
  }

  switchTool = (name) => {
    this.props.tools.hideSidebar();
    this.props.tools.switchTool(name);
  }

  logout = () => {
    this.props.tools.showSidebar();
    this.props.logout();
  }

  renderSidebarContent() {
    const { currentUser } = this.props;
    const { activeTool, availableTools } = this.props.tools;

    return (
      <Menu vertical inverted size="huge" className="app-sidebar">
        {availableTools.length > 1 && (
          <Menu.Item>
            <Menu.Header>
              <FormattedMessage id="AppSidebar.tools.header" defaultMessage="Tools" />
            </Menu.Header>
            <Menu.Menu>
              {availableTools.map(tool => (
                <Menu.Item
                  key={tool.name}
                  active={tool.name === activeTool}
                  onClick={() => this.switchTool(tool.name)}
                >
                  <Icon name={tool.icon} />
                  {this.props.intl.formatMessage(TOOL_MESSAGES[tool.name])}
                </Menu.Item>
              ))}
            </Menu.Menu>
          </Menu.Item>
        )}

        {currentUser && (
          <Menu.Item>
            <Menu.Header>
              <Header inverted size="tiny">
                <Header.Content>
                  {currentUser.firstName || currentUser.lastName ? (
                    `${currentUser.firstName} ${currentUser.lastName}`
                  ) : (
                    currentUser.username
                  )}
                  <Header.Subheader>
                    {currentUser.company && currentUser.company.name}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Menu.Header>
            <Menu.Menu>
              <Menu.Item onClick={this.logout}>
                <Icon name="sign-out" />
                <FormattedMessage id="AppSidebar.logout.label" defaultMessage="Logout" />
              </Menu.Item>
            </Menu.Menu>
          </Menu.Item>
        )}
      </Menu>
    );
  }

  render() {
    const { visible } = this.props;
    const { showSidebar, hideSidebar } = this.props.tools;

    return (
      <Sidebar
        pullRight
        open={visible}
        touchHandleWidth={15}
        onSetOpen={open => (open ? showSidebar() : hideSidebar())}
        sidebar={this.renderSidebarContent()}
        // Make sure that sidebar is on top of Semantic UI components
        styles={{
          sidebar: { zIndex: 2001 },
          overlay: { zIndex: 2000 },
          dragHandle: { zIndex: 2000 },
        }}
      >
        {this.props.children}
      </Sidebar>
    );
  }
}

AppSidebar.defaultProps = {
  visible: false,
  currentUser: null,
  children: null,
};

AppSidebar.propTypes = {
  logout: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  currentUser: OpsUserType,
  children: PropTypes.node,
};

export default withTools(withNavigation(withRouter(injectIntl(AppSidebar))));
