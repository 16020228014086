import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { openSensor } from '~/app/actions/HardwareActions';
import ButtonGroup from '~/common/components/ButtonGroup';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const CURRENT_FIXING_OPTION_MESSAGES = _.pick(FIXING_OPTION_MESSAGES,
  'smartDoorPowered',
  'kiBattery',
  'smartDoorReposition24GhzAntenna');


class KiClickOpeningTestingStep extends Component {
  state = {
    sensorOpened: null,
  }

  get error() {
    const { sensorOpened } = this.state;
    if (sensorOpened === false) {
      return (
        <FormattedMessage
          id="KiClickOpeningTestingStep.manufacturing.errors.noOpen"
          defaultMessage="The door could not be opened"
        />
      );
    }

    return null;
  }

  retry = () => {
    this.setState({ sensorOpened: null });
  }

  render() {
    const { sensorOpened } = this.state;

    if (sensorOpened) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        instructions={(
          <FormattedMessage
            id="KiClickOpeningTestingStep.manufacturing.instructions"
            defaultMessage="Please press the KIWI Klick and report whether the door opens successfully."
          />
        )}
        error={this.error}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES}
        retry={this.retry}
      >
        {({ stepList, instructions }) => (
          <>
            <StepView.Content>
              {stepList}
              {instructions}
            </StepView.Content>

            <StepView.Footer>
              <ButtonGroup>
                <Button fluid primary onClick={() => this.setState({ sensorOpened: true })}>
                  <FormattedMessage id="KiClickOpeningTestingStep.manufacturing.tests.yes" defaultMessage="Yes" />
                </Button>
                <Button fluid negative onClick={() => this.setState({ sensorOpened: false })}>
                  <FormattedMessage id="KiClickOpeningTestingStep.manufacturing.tests.no" defaultMessage="No" />
                </Button>
              </ButtonGroup>
            </StepView.Footer>
          </>
        )}
      </StepView>
    );
  }
}

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openSensor,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(KiClickOpeningTestingStep));
