import React, { Component } from 'react';

import View from '~/app/views/View';


export default class LoadingView extends Component {
  render() {
    return <View loading />;
  }
}
