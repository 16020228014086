import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { ErrorType } from '~/common/types';


class JobSaveErrorModal extends Component {
  render() {
    const { error, onDismiss } = this.props;
    const open = error && error.code !== 'OUTDATED_APP';

    return (
      <Modal
        size="mini"
        dimmer="inverted"
        open={open}
        onClose={onDismiss}
      >
        <Modal.Header>
          <FormattedMessage
            id="JobSaveErrorModal.header"
            defaultMessage="Error"
          />
        </Modal.Header>
        <Modal.Content>
          <p>
            <FormattedMessage
              id="JobSaveErrorModal.message"
              defaultMessage="The current job could not be updated."
            />
          </p>
          <p>
            <FormattedMessage
              id="JobSaveErrorModal.instructions"
              defaultMessage="Please check your Internet connection and try again."
            />
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={onDismiss}>
            <FormattedMessage
              id="JobSaveErrorModal.ok"
              defaultMessage="OK"
            />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

JobSaveErrorModal.defaultProps = {
  error: null,
  onDismiss: () => {},
};

JobSaveErrorModal.propTypes = {
  error: ErrorType,
  onDismiss: PropTypes.func,
};

export default JobSaveErrorModal;
