import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import _ from 'lodash';

import {
  JOB_STATUSES, JOB_STATUSES_DONE,
  TASK_STATUSES_DONE, TASK_TYPES, HW_PRODUCTS,
} from '~/common/constants';
import { JOB_STATUS_FILTERS } from '~/app/constants';
import {
  resumeJob, cancelJob, dismissCancelJob, cancelTask, dismissCancelTask, resetRoutine,
} from '~/app/actions/RoutineActions';
import LoadingSpinner from '~/common/components/LoadingSpinner';
import CancellationModal from '~/app/components/CancellationModal';
import JobDetailsView from '~/app/views/jobs/JobDetailsView';
import JobStartView from '~/app/views/jobs/JobStartView';
import JobFinishView from '~/app/views/jobs/JobFinishView';
import TaskListView from '~/app/views/jobs/TaskListView';
import TaskSkipView from '~/app/views/jobs/TaskSkipView';
import TaskCreationView from '~/app/views/jobs/TaskCreationView';
import GatewayInstallationView from '~/app/views/jobs/routines/GatewayInstallationView';
import SmartEntryInstallationView from '~/app/views/jobs/routines/SmartEntryInstallationView';
import SmartEntryInstallationHybridView from '~/app/views/jobs/routines/SmartEntryHybridInstallationView';
import SmartDoorInstallationView from '~/app/views/jobs/routines/SmartDoorInstallationView';
import KnobInstallationView from '~/app/views/jobs/routines/KnobInstallationView';
import HandleInstallationView from '~/app/views/jobs/routines/HandleInstallationView';
import SafeInstallationView from '~/app/views/jobs/routines/SafeInstallationView';
import GatewayFixingView from '~/app/views/jobs/routines/GatewayFixingView';
import SmartEntryFixingView from '~/app/views/jobs/routines/SmartEntryFixingView';
import SmartEntryHybridFixingView from '~/app/views/jobs/routines/SmartEntryHybridFixingView';
import SmartDoorFixingView from '~/app/views/jobs/routines/SmartDoorFixingView';
import KnobFixingView from '~/app/views/jobs/routines/KnobFixingView';
import HandleFixingView from '~/app/views/jobs/routines/HandleFixingView';
import SafeFixingView from '~/app/views/jobs/routines/SafeFixingView';


const ROUTINE_VIEWS = {
  [TASK_TYPES.INSTALLATION]: {
    [HW_PRODUCTS.GATEWAY]: GatewayInstallationView,
    [HW_PRODUCTS.SMART_ENTRY]: SmartEntryInstallationView,
    [HW_PRODUCTS.SMART_ENTRY_HYBRID]: SmartEntryInstallationHybridView,
    [HW_PRODUCTS.SMART_DOOR]: SmartDoorInstallationView,
    [HW_PRODUCTS.KNOB]: KnobInstallationView,
    [HW_PRODUCTS.HANDLE]: HandleInstallationView,
    [HW_PRODUCTS.SAFE]: SafeInstallationView,
    [HW_PRODUCTS.SAFE_TWO]: SafeInstallationView,
  },
  [TASK_TYPES.FIXING]: {
    [HW_PRODUCTS.GATEWAY]: GatewayFixingView,
    [HW_PRODUCTS.SMART_ENTRY]: SmartEntryFixingView,
    [HW_PRODUCTS.SMART_ENTRY_HYBRID]: SmartEntryHybridFixingView,
    [HW_PRODUCTS.SMART_DOOR]: SmartDoorFixingView,
    [HW_PRODUCTS.KNOB]: KnobFixingView,
    [HW_PRODUCTS.HANDLE]: HandleFixingView,
    [HW_PRODUCTS.SAFE]: SafeFixingView,
    [HW_PRODUCTS.SAFE_TWO]: SafeFixingView,
  },
};

class JobRoutineContainer extends Component {
  componentDidMount() {
    const resume = _.get(this.props.location.state, 'restoreState', false);
    const canResume = this.job && this.job.status === JOB_STATUSES.IN_PROGRESS;
    if (resume && canResume) {
      this.props.resumeJob({ job: this.job });
    }
  }

  componentWillUnmount() {
    this.props.resetRoutine();
  }

  get job() {
    const { items } = this.props.jobs;
    const { jobId } = this.props.match.params;
    return items.byId[parseInt(jobId, 10)];
  }

  get task() {
    const { job } = this;
    const { taskId } = this.props.routine;
    if (job && job.tasks && taskId) {
      return job.tasks.find(t => t.id === taskId);
    }

    return null;
  }

  renderView = () => {
    const {
      startJobRequested, jobStarted, finishJobRequested, jobFinished,
      jobCancelled, taskStarted, skipTaskRequested, addUnplannedTask,
    } = this.props.routine;

    // Redirect to job list when job has been cancelled or finished
    if (!this.job || jobCancelled || jobFinished) {
      const statusFilter = jobFinished ? JOB_STATUS_FILTERS.FINISHED : JOB_STATUS_FILTERS.READY;
      return <Redirect to={{ pathname: '/jobs', state: { statusFilter } }} />;
    }

    // Render start job view when user is about to start job
    if (this.job && startJobRequested) {
      return <JobStartView job={this.job} />;
    }

    // Render finish job view when user is about to finish job
    if (this.job && finishJobRequested) {
      return <JobFinishView job={this.job} />;
    }

    // Render job details view if job has not been started or all tasks have been finished
    const jobDone = JOB_STATUSES_DONE.includes(this.job.status);
    const tasksDone = this.job.tasks
                      && this.job.tasks.every(t => TASK_STATUSES_DONE.includes(t.status));
    if ((!jobStarted || jobDone || tasksDone) && !addUnplannedTask) {
      return <JobDetailsView job={this.job} />;
    }

    // Render task list view if no task has been started, yet
    if (!this.task || !taskStarted) {
      if (addUnplannedTask) {
        return <TaskCreationView job={this.job} task={addUnplannedTask} />;
      }
      return <TaskListView job={this.job} />;
    }

    // Render task skip view if skipping has been requested
    if (this.task && skipTaskRequested) {
      return <TaskSkipView job={this.job} task={this.task} />;
    }

    // Render routine view if a task has been started
    if (this.task && taskStarted) {
      const { type, hardware: { hardwareProduct } } = this.task;
      const RoutineView = ROUTINE_VIEWS[type][hardwareProduct];
      return <RoutineView job={this.job} task={this.task} />;
    }
  }

  render() {
    const { loading, cancelJobRequested, cancelTaskRequested } = this.props.routine;

    return (
      <Container>
        <LoadingSpinner active={loading} />

        {this.renderView()}

        <CancellationModal
          entity="job"
          open={cancelJobRequested}
          onConfirm={() => this.props.cancelJob(this.job)}
          onClose={this.props.dismissCancelJob}
        />
        <CancellationModal
          entity="task"
          open={cancelTaskRequested}
          onConfirm={() => this.props.cancelTask(this.task)}
          onClose={this.props.dismissCancelTask}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  jobs: state.jobs,
  routine: state.routine,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  resumeJob,
  cancelJob,
  dismissCancelJob,
  cancelTask,
  dismissCancelTask,
  resetRoutine,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobRoutineContainer);
