import React, { Component } from 'react';

import JobRoutineView from './JobRoutineView';
import SmartLockInstallationInstructions from './steps/SmartLockInstallationInstructions';
import NotesStep from './steps/NotesStep';
import UUIDInputStep from './steps/UUIDInputStep';
import HardwareRegistrationStep from './steps/HardwareRegistrationStep';
import SmartLockPowerOnStep from './steps/SmartLockPowerOnStep';
import ConnectivityTestingStep from './steps/ConnectivityTestingStep';
import SmartLockBatteryCheckStep from './steps/SmartLockBatteryCheckStep';
import ConfigurationStep from './steps/ConfigurationStep';
import RemoteOpeningTestingStep from './steps/RemoteOpeningTestingStep';
import GrantSensorPermissionStep from './steps/GrantSensorPermissionStep';
import KiOpeningTestingStep from './steps/KiOpeningTestingStep';
import KnobPutOnCoverStep from './steps/KnobPutOnCoverStep';
import TrackHardwareStatusStep from './steps/TrackHardwareStatusStep';
import AttachUUIDStickerStep from './steps/AttachUUIDStickerStep';
import SuccessStep from './steps/SuccessStep';


class SafeInstallationView extends Component {
  render() {
    const { task: { dispatcherNotes } } = this.props;

    return (
      <JobRoutineView {...this.props}>
        <NotesStep id="notes" disabled={!dispatcherNotes} />
        <SmartLockInstallationInstructions id="installationInstructions" />
        <UUIDInputStep id="uuidInput" />
        <HardwareRegistrationStep id="hardwareRegistration" skipBackwards />
        <SmartLockPowerOnStep id="powerOn" />
        {/* TODO: Disable signal strength check for now until further testing has been done */}
        <ConnectivityTestingStep id="connectivityTesting" skipBackwards ignoreSignalStrength />
        <SmartLockBatteryCheckStep id="batteryCheck" skipBackwards />
        <ConfigurationStep id="pushConfig" full skipBackwards />
        <RemoteOpeningTestingStep id="remoteOpeningTesting" action="lock" skipBackwards />
        <GrantSensorPermissionStep id="grantingPermission" skipBackwards />
        <KiOpeningTestingStep id="kiNfcOpeningTesting" trigger="nfc" action="unlock" />
        <KnobPutOnCoverStep id="putOnCover" />
        <TrackHardwareStatusStep id="checkStatusEnd" atEnd skipBackwards />
        <AttachUUIDStickerStep id="attachUuidSticker" />
        <SuccessStep id="success" />
      </JobRoutineView>
    );
  }
}

export default SafeInstallationView;
