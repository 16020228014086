import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { JobType } from '~/common/types';
import Placeholder from '~/common/components/Placeholder';
import FormattedDateTime from '~/common/components/FormattedDateTime';
import FormattedAddress from '~/common/components/FormattedAddress';
import TaskProgressByType from '~/common/components/TaskProgressByType';
import TabularList from '~/common/components/TabularList';
import '~/app/styles/components/JobList.css';


const JobList = ({ jobs, onClick, ...remainingProps }) => (
  <TabularList arrow className="job-list" {...remainingProps}>
    {jobs.map(job => (
      <TabularList.Row
        key={job.id}
        className={`priority-${job.priority}`}
        onClick={() => onClick(job)}
      >
        <TabularList.Cell>
          <Placeholder
            show={!job.appointment}
            text={(
              <FormattedMessage id="JobList.appointment.placeholder" defaultMessage="(no appointment)" />
            )}
          >
            <FormattedDateTime multiLine yearFormat="2-digit" value={job.appointment} />
          </Placeholder>
        </TabularList.Cell>
        <TabularList.Cell>
          <FormattedAddress breakLines address={job.address} />
        </TabularList.Cell>
        <TabularList.Cell>
          <TaskProgressByType job={job} />
        </TabularList.Cell>
      </TabularList.Row>
    ))}
  </TabularList>
);

JobList.defaultProps = {
  jobs: [],
  onClick: () => {},
};

JobList.propTypes = {
  ...TabularList.propTypes,
  jobs: PropTypes.arrayOf(JobType),
  onClick: PropTypes.func,
};

export default JobList;
