import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import '~/common/styles/components/Placeholder.css';


const Placeholder = ({ show, text, children }) => {
  const hasValue = React.Children.toArray(children).filter(x => x !== '').length > 0;
  if (show || !hasValue) {
    return (
      <span className="placeholder-container">
        {text || (
          <FormattedMessage id="Placeholder.defaultText" defaultMessage="(n/a)" />
        )}
      </span>
    );
  }

  return children;
};

Placeholder.defaultProps = {
  show: false,
  text: null,
  children: null,
};

Placeholder.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.node,
  children: PropTypes.node,
};

export default Placeholder;
