import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Form, TextArea, Button, Checkbox, Divider,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { TASK_TYPES, TASK_STATUSES, HW_PRODUCTS } from '~/common/constants';
import { JobType, TaskType } from '~/common/types';
import { requestCancelTask, skipTask, dismissSkipTask } from '~/app/actions/RoutineActions';
import View from '~/app/views/View';
import ButtonGroup from '~/common/components/ButtonGroup';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import FormattedAddress from '~/common/components/FormattedAddress';
import CausingPartyComboBox from '~/app/components/CausingPartyComboBox';


class TaskSkipView extends Component {
  state = {
    reason: '',
    causingParty: null,
    status: null,
  }

  skipTask = () => {
    const { job, task } = this.props;
    const { reason, causingParty, status } = this.state;

    this.props.skipTask({
      job,
      task: {
        ...task,
        causingParty,
        installerNotes: (status !== TASK_STATUSES.UNNECESSARY && reason !== '') ? reason : null,
        status: status || TASK_STATUSES.FAILED,
      },
    });
  }

  render() {
    const { job, task } = this.props;
    const { reason, causingParty, status } = this.state;

    const expectsCausingParty = task.type === TASK_TYPES.FIXING;
    const isValid = status === TASK_STATUSES.UNNECESSARY
      || (reason.length > 0 && (!expectsCausingParty || (causingParty && causingParty.length > 0)));

    const showStatusSelection = task.hardware.hardwareProduct === HW_PRODUCTS.GATEWAY
      && task.type !== TASK_TYPES.FIXING;

    return (
      <View onGoBack={this.props.dismissSkipTask}>
        <View.Header
          primaryText={<JobProgressHeader job={job} task={task} stage="skip" />}
          secondaryText={<FormattedAddress address={job.address} />}
          action={this.props.requestCancelTask}
          actionIcon="cancel"
        />
        <View.Content>
          <p>
            <FormattedMessage
              id="TaskSkipView.instructions"
              defaultMessage="Please let the technical support know what went wrong."
            />
          </p>
          {showStatusSelection && (
            <Form>
              <Form.Field>
                <Checkbox
                  radio
                  value={TASK_STATUSES.FAILED}
                  checked={status === TASK_STATUSES.FAILED}
                  onChange={(e, { value }) => this.setState({ status: value })}
                  label={(
                    <label>
                      <FormattedMessage
                        id="TaskSkipView.status.failed"
                        defaultMessage="The device can't be installed"
                      />
                    </label>
                  )}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  radio
                  value={TASK_STATUSES.UNNECESSARY}
                  checked={status === TASK_STATUSES.UNNECESSARY}
                  onChange={(e, { value }) => this.setState({ status: value })}
                  label={(
                    <label>
                      <FormattedMessage
                        id="TaskSkipView.status.unnecessary"
                        defaultMessage="Device is unnecessary"
                      />
                    </label>
                  )}
                />
              </Form.Field>
            </Form>
          )}
          {(!showStatusSelection || status === TASK_STATUSES.FAILED) && (
            <Form onSubmit={isValid ? this.skipTask : null}>
              {expectsCausingParty && (
              <Form.Field>
                <FormattedMessage
                  id="TaskSkipView.causingParty.label"
                  defaultMessage="Who is to blame for the problem?"
                >
                  {label => (
                    <>
                      <label>{label}</label>
                      <CausingPartyComboBox
                        fluid
                        value={causingParty}
                        placeholder={label}
                        onChange={value => this.setState({ causingParty: value })}
                      />
                    </>
                  )}
                </FormattedMessage>
              </Form.Field>
              )}
              <Form.Field>
                <FormattedMessage
                  id="TaskSkipView.reason.note.label"
                  defaultMessage="Message to technical support"
                >
                  {label => (
                    <>
                      <Divider hidden />
                      <label>{label}</label>
                      <TextArea
                        placeholder={label}
                        value={reason}
                        onChange={(e, { value }) => this.setState({ reason: value })}
                      />
                    </>
                  )}
                </FormattedMessage>
              </Form.Field>
            </Form>
          )}
        </View.Content>
        <View.Footer>
          <ButtonGroup>
            <Button fluid onClick={this.props.dismissSkipTask}>
              <FormattedMessage id="TaskSkipView.dismiss.label" defaultMessage="Cancel" />
            </Button>
            <Button fluid color="red" disabled={!isValid} onClick={this.skipTask}>
              <FormattedMessage id="TaskSkipView.confirm.label" defaultMessage="Skip device" />
            </Button>
          </ButtonGroup>
        </View.Footer>
      </View>
    );
  }
}

TaskSkipView.propTypes = {
  job: JobType.isRequired,
  task: TaskType.isRequired,
};


const mapDispatchToProps = dispatch => bindActionCreators({
  skipTask,
  dismissSkipTask,
  requestCancelTask,
}, dispatch);

export default connect(null, mapDispatchToProps)(TaskSkipView);
