import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { HW_PRODUCTS, HW_LIFECYCLE_STATES } from '~/common/constants';
import { stopRoutines, removeSensor } from '~/app/actions/HardwareActions';
import { IntlMessageType } from '~/common/types';
import LoadingSpinner from '~/common/components/LoadingSpinner';
import CancellationModal from '~/app/components/CancellationModal';
import RoutineView from '~/app/views/RoutineView';


class ManufacturingRoutineView extends Component {
  state = {
    stepId: null,
    hardwareVariant: null,
    sensorId: null,
    cancelled: false,
    cancelRequested: false,
  }

  cancel = () => {
    const { sensorId } = this.state;

    this.props.stopRoutines();
    if (sensorId) {
      this.props.removeSensor({
        id: sensorId,
        lifecycleState: HW_LIFECYCLE_STATES.UNMANUFACTURED,
      });
    }

    this.setState({
      cancelRequested: false,
      cancelled: true,
    });
  }

  render() {
    const { hardwareRemoved } = this.props.hardware;
    const {
      stepDescriptions, hardwareUuid, hardwareProduct,
    } = this.props;
    const {
      stepId, hardwareVariant, sensorId, cancelled, cancelRequested,
    } = this.state;

    if (cancelled && (!sensorId || hardwareRemoved)) {
      return <Redirect to="/manufacturing" />;
    }

    return (
      <>
        <RoutineView
          stepId={stepId}
          context={{
            stepDescriptions,
            sensorId,
            hardwareUuid,
            hardwareProduct,
            hardwareVariant,
            setHardwareVariant: variant => this.setState({ hardwareVariant: variant }),
            setSensorId: id => this.setState({ sensorId: id }),
            cancel: this.cancel,
            requestCancel: () => this.setState({ cancelRequested: true }),
          }}
          navigate={newStepId => this.setState({ stepId: newStepId })}
          onBack={() => this.setState({ cancelRequested: true })}
        >
          {this.props.children}
        </RoutineView>

        <LoadingSpinner active={cancelled} />
        <CancellationModal
          entity="manufacturing"
          open={cancelRequested}
          onConfirm={this.cancel}
          onClose={() => this.setState({ cancelRequested: false })}
        />
      </>
    );
  }
}

ManufacturingRoutineView.defaultProps = {
  children: null,
};

ManufacturingRoutineView.propTypes = {
  hardwareUuid: PropTypes.string.isRequired,
  hardwareProduct: PropTypes.oneOf(Object.values(HW_PRODUCTS)).isRequired,
  stepDescriptions: PropTypes.objectOf(IntlMessageType).isRequired,
  children: PropTypes.node,
};

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  stopRoutines,
  removeSensor,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManufacturingRoutineView);
