import React from 'react';
import PropTypes from 'prop-types';

const HardwareOpsAppLink = ({
  sensorId, gatewayId, disabled, children,
}) => {
  if (disabled) return children;

  const link = sensorId ? `/operations/access-points/${sensorId}` : `/operations/gateway/${gatewayId}`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

HardwareOpsAppLink.defaultProps = {
  sensorId: null,
  gatewayId: null,
  disabled: false,
  children: null,
};

HardwareOpsAppLink.propTypes = {
  sensorId: PropTypes.number,
  gatewayId: PropTypes.number,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default HardwareOpsAppLink;
