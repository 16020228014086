import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import { AddressType } from '~/common/types';


class MapButton extends Component {
  openMap = () => {
    const {
      street, postalCode, city, country,
    } = this.props.address;
    const addressString = `${street} ${postalCode} ${city} ${country}`;
    if (['iPhone', 'iPad', 'iPod'].includes(navigator.platform)) {
      window.open(`maps://maps.google.com/maps?q=${addressString}`);
    } else {
      window.open(`https://maps.google.com/maps?q=${addressString}`);
    }
  }

  render() {
    const { className } = this.props;

    return (
      <div className={clsx('map-button-container', className)}>
        <Button icon labelPosition="left" size="mini" onClick={this.openMap}>
          <Icon name="map" />
          <FormattedMessage id="MapButton.label" defaultMessage="Show on map" />
        </Button>
      </div>
    );
  }
}

MapButton.defaultProps = {
  className: null,
};

MapButton.propTypes = {
  address: AddressType.isRequired,
  className: PropTypes.string,
};

export default MapButton;
