import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { HW_PRODUCTS, HW_PRODUCT_HW_VARIANTS } from '~/common/constants';
import HW_VARIANT_MESSAGES from '~/common/locales/HardwareVariants';
import { withRoutine } from '~/app/contexts/RoutineContext';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import ButtonGroup from '~/common/components/ButtonGroup';
import StepView from './StepView';


class SmartDoorVariantSelectionStep extends Component {
  render() {
    const { hardwareVariant, setHardwareVariant } = this.props.routine;

    if (hardwareVariant) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        instructions={(
          <FormattedMessage
            id="SmartDoorVariantSelectionStep.manufacturing.instructions"
            defaultMessage="Please select the product variant of the SmartDOOR."
          />
        )}
      >
        {({ stepList, instructions }) => (
          <>
            <StepView.Content>
              {stepList}
              {instructions}
            </StepView.Content>

            <StepView.Footer>
              <ButtonGroup>
                {HW_PRODUCT_HW_VARIANTS[HW_PRODUCTS.SMART_DOOR].map(variant => (
                  <Button fluid key={variant} onClick={() => setHardwareVariant(variant)}>
                    {this.props.intl.formatMessage(HW_VARIANT_MESSAGES[variant])}
                  </Button>
                ))}
              </ButtonGroup>
            </StepView.Footer>
          </>
        )}
      </StepView>
    );
  }
}

export default withRoutine(injectIntl(SmartDoorVariantSelectionStep));
