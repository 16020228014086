import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { LINK_PERFORMANCE_CHECK_STAGES } from '~/app/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { checkGatewayLinkPerformance, stopRoutines } from '~/app/actions/HardwareActions';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import CheckList from '~/app/components/CheckList';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const ERROR_MESSAGES = defineMessages({
  NOT_ALLOWED: {
    id: 'LinkPerformanceTestingStep.jobs.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
  DEVICE_OFFLINE: {
    id: 'LinkPerformanceTestingStep.jobs.errors.deviceOffline',
    defaultMessage: 'The device is offline.',
  },
  BACKGROUND_TASK_ERROR: {
    id: 'LinkPerformanceTestingStep.jobs.errors.backgroundTaskError',
    defaultMessage: 'The link performance tests could not be completed.',
  },
  BACKGROUND_TASK_TIMEOUT: {
    id: 'LinkPerformanceTestingStep.jobs.errors.backgroundTaskTimeout',
    defaultMessage: 'The link performance tests could not be completed due to a timeout.',
  },
});

const LinkPerformanceTestingStep = (props) => {
  const { routine: { job, task } } = props;

  const [next, setNext] = useState(false);
  const [showNavigateAwayModal, setShowNavigateAwayModal] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.hardware.loading);
  const error = useSelector(state => state.hardware.error);
  const status = useSelector(state => state.hardware.linkPerformance.status);

  const stageIndex = LINK_PERFORMANCE_CHECK_STAGES.indexOf(status);
  const performedStages = stageIndex < 0
    ? []
    : LINK_PERFORMANCE_CHECK_STAGES.slice(0, stageIndex);

  const checkLinkPerformance = useCallback(() => {
    dispatch(checkGatewayLinkPerformance({
      id: task.hardware.gatewayId,
    }));
    setNext(true);
  }, [task.hardware.gatewayId, dispatch]);

  const handleNavigateAway = useCallback(() => {
    if (loading) {
      setShowNavigateAwayModal(true);
      return false;
    }

    return true;
  }, [loading]);

  useEffect(() => checkLinkPerformance(), [checkLinkPerformance]);
  useEffect(() => () => dispatch(stopRoutines()), [dispatch]);

  if (next && status === 'done') {
    return <RoutineNavigation nextStep />;
  }

  return (
    <StepView
      {...props}
      header={<JobProgressHeader job={job} task={task} stage="test" />}
      error={error}
      errorHeader={(
        <FormattedMessage
          id="LinkPerformanceTestingStep.jobs.errors.header"
          defaultMessage="Test failed"
        />
      )}
      errorMessages={ERROR_MESSAGES}
      retry={checkLinkPerformance}
      onNavigateAway={handleNavigateAway}
    >
      <StepView.Content>
        <p>
          <FormattedMessage
            id="LinkPerformanceTestingStep.jobs.instructions"
            defaultMessage="This step aims to collect information about the link performance of the device. Please wait until all tests have been completed."
          />
        </p>

        <CheckList>
          <CheckList.Item
            loading={status === 'initializing' || status === 'latency_test'}
            unknown={!performedStages.includes('latency_test')}
            success={performedStages.includes('latency_test')}
          >
            <FormattedMessage
              id="LinkPerformanceTestingStep.jobs.tests.latency"
              defaultMessage="Latency"
            />
          </CheckList.Item>

          <CheckList.Item
            loading={status === 'time_dilation_test'}
            unknown={!performedStages.includes('time_dilation_test')}
            success={performedStages.includes('time_dilation_test')}
          >
            <FormattedMessage
              id="LinkPerformanceTestingStep.jobs.tests.timeDilation"
              defaultMessage="Time deviation"
            />
          </CheckList.Item>

          <CheckList.Item
            loading={status === 'cell_quality_test'}
            unknown={!performedStages.includes('cell_quality_test')}
            success={performedStages.includes('cell_quality_test')}
          >
            <FormattedMessage
              id="LinkPerformanceTestingStep.jobs.test.cellQuality"
              defaultMessage="Cell quality"
            />
          </CheckList.Item>

          <CheckList.Item
            loading={status === 'download_test'}
            unknown={!performedStages.includes('download_test')}
            success={performedStages.includes('download_test')}
          >
            <FormattedMessage
              id="LinkPerformanceTestingStep.jobs.tests.download"
              defaultMessage="Download rate"
            />
          </CheckList.Item>
        </CheckList>

        <Modal
          size="tiny"
          dimmer="inverted"
          open={showNavigateAwayModal}
          onClose={() => setShowNavigateAwayModal(false)}
        >
          <Modal.Header>
            <FormattedMessage
              id="LinkPerformanceTestingStep.jobs.cancelModal.header"
              defaultMessage="Tests are running"
            />
          </Modal.Header>
          <Modal.Content>
            <FormattedMessage
              id="LinkPerformanceTestingStep.jobs.cancelModal.message"
              defaultMessage="The tests cannot be interrupted. Please wait until all tests have been completed."
            />
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => setShowNavigateAwayModal(false)}>
              <FormattedMessage
                id="LinkPerformanceTestingStep.jobs.cancelModal.ok"
                defaultMessage="OK"
              />
            </Button>
          </Modal.Actions>
        </Modal>
      </StepView.Content>
    </StepView>
  );
};

LinkPerformanceTestingStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

export default withRoutine(LinkPerformanceTestingStep);
