import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { grantSensorPermission } from '~/app/actions/HardwareActions';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const ERROR_MESSAGES = defineMessages({
  NO_API_USER: {
    id: 'GrantSensorPermissionStep.manufacturing.errors.noApiUser',
    defaultMessage: 'Your account is not associated with a KIWI account.',
  },
  NOT_ALLOWED: {
    id: 'GrantSensorPermissionStep.manufacturing.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
  BACKGROUND_TASK_TIMEOUT: {
    id: 'GrantSensorPermissionStep.manufacturing.errors.backgroundTaskTimeout',
    defaultMessage: 'The permission could not be granted.',
  },
  BACKGROUND_TASK_ERROR: {
    id: 'GrantSensorPermissionStep.manufacturing.errors.backgroundTaskError',
    defaultMessage: 'The permission could not be granted.',
  },
  CONF_TIMEOUT: {
    id: 'GrantSensorPermissionStep.manufacturing.errors.confTimeout',
    defaultMessage: 'The sensor could not be updated with permissions.',
  },
  CONF_ERROR: {
    id: 'GrantSensorPermissionStep.manufacturing.errors.confError',
    defaultMessage: 'The sensor could not be updated with permissions.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = _.pick(FIXING_OPTION_MESSAGES,
  'smartDoorPowered',
  'smartDoorReposition868MhzAntenna',
  'smartDoorReplaceSensor');


class GrantSensorPermissionStep extends Component {
  state = {
    next: false,
  }

  componentDidMount() {
    this.grantPermission();
  }

  grantPermission = () => {
    const { sensorId } = this.props.routine;
    this.props.grantSensorPermission({ id: sensorId });
    this.setState({ next: true });
  }

  render() {
    const { next } = this.state;
    const { error, permission } = this.props.hardware;

    if (next && permission.granted) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        instructions={(
          <FormattedMessage
            id="GrantSensorPermissionStep.manufacturing.instructions"
            defaultMessage="Please wait until the permission has been granted successfully."
          />
        )}
        error={error}
        errorMessages={ERROR_MESSAGES}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES}
        retry={this.grantPermission}
      />
    );
  }
}

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  grantSensorPermission,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(GrantSensorPermissionStep));
