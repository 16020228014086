import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Header } from 'semantic-ui-react';

import { HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import { migrateTaskHardware, dismissError } from '~/app/actions/JobsActions';
import { splitSmartEntryHybrid } from '~/app/utils/taskHardwareMigrations';
import { withRoutine } from '~/app/contexts/RoutineContext';
import ButtonGroup from '~/common/components/ButtonGroup';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import FormattedText from '~/common/components/FormattedText';
import StepView from './StepView';


const NotesStep = (props) => {
  const [next, setNext] = useState(false);
  const [migrateTask, setMigrateTask] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.jobs.loading);
  const error = useSelector(state => state.jobs.error);

  const { routine: { task, job, steps } } = props;
  const { dispatcherNotes, hardware: { hardwareProduct } } = task;

  const migrateHybridToSensor = () => {
    dispatch(migrateTaskHardware(splitSmartEntryHybrid({ task, steps })));
    setMigrateTask(true);
  };

  const handleDismissError = () => {
    dispatch(dismissError());
    setMigrateTask(false);
  };

  if (next) return <RoutineNavigation nextStep />;

  return (
    <StepView
      {...props}
      loading={loading}
      header={<JobProgressHeader job={job} task={task} stage="installation" />}
    >
      <StepView.Content>
        <JobSaveErrorModal
          error={migrateTask ? error : null}
          onDismiss={handleDismissError}
        />
        <Header as="h4">
          <FormattedMessage
            id="NotesStep.header"
            defaultMessage="Please observe the following notes from the dispatcher"
          />
        </Header>
        <FormattedText text={dispatcherNotes} />
      </StepView.Content>
      <StepView.Footer>
        <ButtonGroup>
          <Button fluid color="red" onClick={() => dispatch(requestSkipTask())}>
            <FormattedMessage id="NotesStep.cancelLabel" defaultMessage="Skip task" />
          </Button>
          {hardwareProduct === HW_PRODUCTS.SMART_ENTRY_HYBRID && (
            <Button fluid onClick={migrateHybridToSensor}>
              <FormattedMessage
                id="NotesStep.migrateHybridToSensor.label"
                defaultMessage="Install Gateway and Sensor separately"
              />
            </Button>
          )}
          <Button primary fluid onClick={() => setNext(true)}>
            <FormattedMessage id="NotesStep.nextLabel" defaultMessage="Continue" />
          </Button>
        </ButtonGroup>
      </StepView.Footer>
    </StepView>
  );
};

NotesStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

export default withRoutine(NotesStep);
