import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import ToolsContainer from '~/app/containers/ToolsContainer';
import LoginView from '~/app/views/LoginView';
import PasswordResetRequestView from '~/app/views/PasswordResetRequestView';
import PasswordResetConfirmView from '~/app/views/PasswordResetConfirmView';


class RootContainer extends Component {
  render() {
    const { location, auth } = this.props;

    return (
      <Switch>
        <Route path="/login" exact component={LoginView} />
        <Route path="/password-reset" exact component={PasswordResetRequestView} />
        <Route path="/password-reset/:uid/:token" exact component={PasswordResetConfirmView} />

        {auth.loggedIn && (
          <ToolsContainer />
        )}

        {!auth.loggedIn && (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                redirectTo: auth.loggedOut ? null : location.pathname,
              },
            }}
          />
        )}
      </Switch>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default withRouter(connect(
  mapStateToProps,
  null,
)(RootContainer));
