import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Grid, Loader } from 'semantic-ui-react';
import _ from 'lodash';

import {
  GATEWAY_HW_PRODUCTS, GATEWAY_RSSI_THRESHOLDS,
  SENSOR_SIGNAL_THRESHOLDS,
  TASK_TYPES,
  UNIT_DBM,
} from '~/common/constants';
import { TaskType, GatewayType, SensorType } from '~/common/types';
import FormattedMetric from '~/common/components/FormattedMetric';
import HardwareOnlineStatus from '~/common/components/HardwareOnlineStatus';
import HardwareOpsAppLink from '~/common/components/HardwareOpsAppLink';
import Placeholder from '~/common/components/Placeholder';
import Tag from '~/common/components/Tag';
import '~/app/styles/components/HardwareStatusTaskDetails.css';


const STATUS_TYPES = {
  now: 'now',
  start: 'start',
};

const renderDeviceMetricsStatus = (deviceStatus, isPending) => {
  if (deviceStatus.type === STATUS_TYPES.now) {
    if (isPending) {
      return <Loader active inline size="mini" />;
    }
    if (deviceStatus.value) {
      return <FormattedMetric {...deviceStatus.value} />;
    }
    return <HardwareOnlineStatus compact isOnline={false} />;
  }
  if (deviceStatus.value) {
    return <FormattedMetric {...deviceStatus.value} />;
  }
  return <Placeholder show />;
};

const HardwareStatusTaskDetails = ({
  hardware, isPending, type, fixingDescription, user,
}) => {
  const {
    hardwareProduct, statusStart, statusNow, sensorId, gatewayId,
  } = hardware;

  const labelStarted = <FormattedMessage id="TaskSummary.JobTaskRow.HardwareStatusTaskDetails.before" defaultMessage="before" />;
  const labelNow = <FormattedMessage id="TaskSummary.JobTaskRow.HardwareStatusTaskDetails.now" defaultMessage="now" />;
  const labelRssi = <FormattedMessage id="TaskSummary.JobTaskRow.HardwareStatusTaskDetails.rssi" defaultMessage="RSSI" />;
  const labelSignal = <FormattedMessage id="TaskSummary.JobTaskRow.HardwareStatusTaskDetails.signal" defaultMessage="Signal" />;
  const rssiData = { unit: UNIT_DBM, thresholds: GATEWAY_RSSI_THRESHOLDS };
  const signalData = { unit: UNIT_DBM, thresholds: SENSOR_SIGNAL_THRESHOLDS };
  /* eslint-disable no-unused-vars */
  const noiseSignal = <FormattedMessage id="TaskSummary.JobTaskRow.HardwareStatusTaskDetails.noise" defaultMessage="Noise" />;
  const noiseData = { unit: 'dB' };
  /* eslint-enable */
  const deviceStatusArray = [];
  if (GATEWAY_HW_PRODUCTS.includes(hardwareProduct)) {
    if (type === TASK_TYPES.FIXING) {
      deviceStatusArray.push({
        value: statusStart && ({ value: statusStart.rssi, ...rssiData }),
        label: labelStarted,
        metricLabel: labelRssi,
        type: STATUS_TYPES.start,
      });
    }
    deviceStatusArray.push({
      value: statusNow && statusNow.isOnline && statusNow.rssi
        ? ({ value: statusNow.rssi, ...rssiData }) : null,
      label: labelNow,
      metricLabel: labelRssi,
      type: STATUS_TYPES.now,
    });
  } else {
    if (type === TASK_TYPES.FIXING) {
      deviceStatusArray.push({
        value: statusStart && ({ value: statusStart.signal, ...signalData }),
        label: labelStarted,
        metricLabel: labelSignal,
        type: STATUS_TYPES.start,
      });
    }
    deviceStatusArray.push({
      value: statusNow && statusNow.isOnline && statusNow.signal
        ? ({ value: statusNow.signal, ...signalData }) : null,
      label: labelNow,
      metricLabel: labelSignal,
      type: STATUS_TYPES.now,
    });
    /* Display noise levels should be implemented once
       it is consistenly added to Smart Installtion app
    */
    // if (type === TASK_TYPES.FIXING) {
    //   deviceStatusArray.push({
    //     value: statusStart && statusStart.noise ? ({ value: statusStart.noise, ...noiseData })
    //       : null,
    //     label: labelStarted,
    //     metricLabel: noiseSignal,
    //     type: STATUS_TYPES.start,
    //   });
    // }
    // deviceStatusArray.push({
    //   value: statusNow && statusNow.isOnline && statusNow.noise
    //     ? ({ value: statusNow.noise, ...noiseData }) : null,
    //   label: labelNow,
    //   metricLabel: noiseSignal,
    //   type: STATUS_TYPES.now,
    // });
  }


  return (
    <div className="hardware-status">
      <Grid>
        {fixingDescription && (
          <Grid.Row>
            <Grid.Column>
              <Tag color="yellow">{fixingDescription}</Tag>
            </Grid.Column>
          </Grid.Row>
        )}
        {deviceStatusArray.map(deviceStatus => (
          <Grid.Row key={_.uniqueId('hsjd_')}>
            <Grid.Column width={10}>
              {deviceStatus.metricLabel}
              <span>&nbsp;</span>
              {deviceStatus.label}
              :
            </Grid.Column>
            <Grid.Column width={3}>
              {renderDeviceMetricsStatus(deviceStatus, isPending)}
            </Grid.Column>
          </Grid.Row>
        ))}
        {(sensorId || gatewayId) && (
          <Grid.Row>
            <Grid.Column>
              <HardwareOpsAppLink
                disabled={!user.permissions.canViewOpsapp}
                gatewayId={gatewayId}
                sensorId={sensorId}
              >
                <FormattedMessage
                  id="TaskSummary.JobTaskRow.HardwareStatusTaskDetails.showInOpsApp"
                  defaultMessage="Show in Ops App"
                />
              </HardwareOpsAppLink>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  );
};

HardwareStatusTaskDetails.defaultProps = {
  fixingDescription: null,
};

HardwareStatusTaskDetails.propTypes = {
  hardware: PropTypes.oneOfType([
    GatewayType,
    SensorType,
  ]).isRequired,
  isPending: PropTypes.bool.isRequired,
  type: TaskType.isRequired,
  fixingDescription: PropTypes.string,
};

export default HardwareStatusTaskDetails;
