import {
  delay, all, put, race, fork, take, takeEvery, actionChannel,
} from 'redux-saga/effects';
import _ from 'lodash';

import { invalidateSession } from '~/app/actions/AuthActions';
import AuthSagas from './AuthSagas';
import LocaleSagas from './LocaleSagas';
import JobsSagas from './JobsSagas';
import HardwareSagas from './HardwareSagas';
import UsersSagas from './UsersSagas';
import RoutineSagas from './RoutineSagas';


export class SagaError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

function* handleAction(actionType, saga, { handleAuth = true, cancelAction = null } = {}, action) {
  let { payload } = action;
  if (payload && payload.debounce) {
    payload = _.omit(payload, 'debounce');
    yield delay(500);
  }

  try {
    const tasks = { result: saga(payload) };
    if (cancelAction) {
      tasks.cancelled = take(cancelAction.toString());
    }
    const { result, cancelled } = yield race(tasks);

    if (!cancelled) {
      yield put(actionType.success(result));
    }
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.error(e);
    }

    const error = {
      code: e.code,
      message: e.message,
      status: e.status,
      trigger: action,
    };
    yield put(actionType.failure({ error }));

    // Logout user, if session is invalid
    if (handleAuth && error.status === 401) {
      yield put(invalidateSession());
    }
  } finally {
    yield put(actionType.fulfill(payload));
  }
}

export function registerSagas(configs) {
  return configs.map((config) => {
    // Strangely, the array destructuring operator doesn't work here
    const action = config[0];
    const saga = config[1];
    const options = config[2] || {};
    const { effectCreator = takeEvery } = options;

    return effectCreator(
      action,
      handleAction.bind(null, action, saga, options),
    );
  });
}

function* takeAndWaitEveryGenerator(actionType, saga, ...args) {
  const channel = yield actionChannel(actionType.TRIGGER);
  while (true) {
    const action = yield take(channel);
    yield fork(saga, ...args.concat(action));
    yield take(actionType.FULFILL);
  }
}

export function takeAndWaitEvery(...args) {
  return fork(takeAndWaitEveryGenerator, ...args);
}

export function* callAction(actionType, payload) {
  yield put(actionType.trigger(payload));
  const { success, failure } = yield race({
    success: take(actionType.SUCCESS),
    failure: take(actionType.FAILURE),
  });
  yield take(actionType.FULFILL);

  if (failure) {
    throw failure.payload.error;
  }

  return success.payload;
}

export function* ignoreErrors(effect, { code = null, errorValue = null } = {}) {
  try {
    return yield effect();
  } catch (e) {
    if (!code || e.code === code) {
      return errorValue;
    }
    throw e;
  }
}

export default function* rootSaga() {
  yield all([
    ...AuthSagas,
    ...LocaleSagas,
    ...JobsSagas,
    ...HardwareSagas,
    ...UsersSagas,
    ...RoutineSagas,
  ]);
}
