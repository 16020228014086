import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';


class ErrorMessage extends Component {
  render() {
    const { header, message } = this.props;

    return (
      <Message negative>
        <Message.Content>
          <Message.Header>
            {header}
          </Message.Header>
          {message}
        </Message.Content>
      </Message>
    );
  }
}

ErrorMessage.propTypes = {
  header: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
};

export default ErrorMessage;
