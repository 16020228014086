import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ConfirmModal from '~/common/components/ConfirmModal';


const ENTITY_TYPES = {
  JOB: 'job',
  TASK: 'task',
  MANUFACTURING: 'manufacturing',
};

const CancellationModal = ({ entity, ...remainingProps }) => (
  <ConfirmModal {...remainingProps}>
    <ConfirmModal.Header>
      {entity === ENTITY_TYPES.JOB && (
        <FormattedMessage id="CancellationModal.job.header" defaultMessage="Cancel job" />
      )}
      {entity === ENTITY_TYPES.TASK && (
        <FormattedMessage id="CancellationModal.task.header" defaultMessage="Cancel task" />
      )}
      {entity === ENTITY_TYPES.MANUFACTURING && (
        <FormattedMessage id="CancellationModal.manufacturing.header" defaultMessage="Cancel manufacturing" />
      )}
    </ConfirmModal.Header>
    <ConfirmModal.Content>
      <p>
        {entity === ENTITY_TYPES.JOB && (
          <FormattedMessage id="CancellationModal.job.content" defaultMessage="Do you really want to cancel this job?" />
        )}
        {entity === ENTITY_TYPES.TASK && (
          <FormattedMessage id="CancellationModal.task.content" defaultMessage="Do you really want to cancel this task?" />
        )}
        {entity === ENTITY_TYPES.MANUFACTURING && (
          <FormattedMessage id="CancellationModal.manufacturing.content" defaultMessage="Do you really want to cancel the manufacturing of this device?" />
        )}
      </p>
      <p>
        {entity === ENTITY_TYPES.JOB && (
          <FormattedMessage id="CancellationModal.job.warning" defaultMessage="All previous steps of this job will be reverted!" />
        )}
        {entity === ENTITY_TYPES.TASK && (
          <FormattedMessage id="CancellationModal.task.warning" defaultMessage="All previous steps of this task will be reverted!" />
        )}
        {entity === ENTITY_TYPES.MANUFACTURING && (
          <FormattedMessage id="CancellationModal.manufacturing.warning" defaultMessage="All previous manufacturing steps will be reverted!" />
        )}
      </p>
    </ConfirmModal.Content>
  </ConfirmModal>
);

CancellationModal.defaultProps = {
  entity: 'job',
};

CancellationModal.propTypes = {
  ...ConfirmModal.propTypes,
  entity: PropTypes.oneOf(Object.values(ENTITY_TYPES)),
};

export default CancellationModal;
