import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { checkSensorConnectivity, stopRoutines } from '~/app/actions/HardwareActions';
import { withRoutine } from '~/app/contexts/RoutineContext';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import StepView from './StepView';


const CURRENT_FIXING_OPTION_MESSAGES = _.pick(FIXING_OPTION_MESSAGES,
  'smartDoorPowered',
  'smartDoorSensorConnectedProperly',
  'correctUuid',
  'smartDoorReposition868MhzAntenna');


class ConnectivityTestingStep extends Component {
  state = {
    next: false,
  }

  componentDidMount() {
    this.checkConnectivity();
  }

  componentWillUnmount() {
    this.props.stopRoutines();
  }

  get error() {
    const { hardwareConnectivity: connectivity, error } = this.props.hardware;

    if (error) {
      return error;
    }
    if (connectivity && !connectivity.isOnline) {
      return (
        <FormattedMessage
          id="ConnectivityTestingStep.manufacturing.errors.noConnection"
          defaultMessage="Connection to sensor could not be established!"
        />
      );
    }

    return null;
  }

  checkConnectivity = () => {
    const { sensorId } = this.props.routine;
    this.props.checkSensorConnectivity({
      id: sensorId,
      ignoreSignalStrength: true,
    });
    this.setState({ next: true });
  }

  render() {
    const { next } = this.state;
    const { hardwareConnectivity: connectivity } = this.props.hardware;

    if (next && connectivity && connectivity.isOnline) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        instructions={(
          <FormattedMessage
            id="ConnectivityTestingStep.manufacturing.instructions"
            defaultMessage="Please wait until a connection to the sensor has been established."
          />
        )}
        error={this.error}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES}
        retry={this.checkConnectivity}
      />
    );
  }
}

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  checkSensorConnectivity,
  stopRoutines,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectivityTestingStep));
