import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Progress, Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import _ from 'lodash';

import { HW_PRODUCTS } from '~/common/constants';
import { MAX_CONFIG_TIMEOUT } from '~/app/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import {
  startKiSignalTest, resetKiSignalTest,
  kiSignalTestDoorOpened, kiSignalTestRepositioned,
  stopRoutines,
} from '~/app/actions/HardwareActions';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import TimeoutProgress from '~/app/components/TimeoutProgress';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const WAIT_TIMEOUT = 20000;

const ERROR_MESSAGES = defineMessages({
  NO_API_USER: {
    id: 'KiSignalStrengthAdjustmentStep.jobs.errors.noApiUser',
    defaultMessage: 'Your account is not associated with a KIWI account.',
  },
  NOT_ALLOWED: {
    id: 'KiSignalStrengthAdjustmentStep.jobs.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
  MAX_SIGNAL_STRENGTH: {
    id: 'KiSignalStrengthAdjustmentStep.jobs.errors.maxSignalStrength',
    defaultMessage: 'The door could not be opened.',
  },
  CONF_TIMEOUT: {
    id: 'KiSignalStrengthAdjustmentStep.jobs.errors.configTimeout',
    defaultMessage: 'The signal strength of the sensor could not be configured.',
  },
  CONF_ERROR: {
    id: 'KiSignalStrengthAdjustmentStep.jobs.errors.confError',
    defaultMessage: 'The signal strength of the sensor could not be configured.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = {
  [HW_PRODUCTS.SMART_ENTRY]: _.pick(FIXING_OPTION_MESSAGES,
    'kiHandsfreeMode',
    'smartEntryInstalledProperly',
    'smartEntryReposition24GhzAntenna',
    'smartEntryRestart'),
  [HW_PRODUCTS.SMART_ENTRY]: _.pick(FIXING_OPTION_MESSAGES,
    'kiHandsfreeMode',
    'smartEntryInstalledProperly',
    'smartEntryReposition24GhzAntenna',
    'smartEntryRestart'),
  [HW_PRODUCTS.SMART_DOOR]: _.pick(FIXING_OPTION_MESSAGES,
    'kiHandsfreeMode',
    'smartDoorInstalledProperly',
    'smartDoorRestart'),
};


class KiSignalStrengthAdjustmentStep extends Component {
  state = {
    next: false,
  }

  componentWillUnmount() {
    this.props.stopRoutines();
    this.props.resetKiSignalTest();
  }

  start = () => {
    const { hardware } = this.props.routine.task;

    this.props.startKiSignalTest({
      id: hardware.sensorId,
      waitTimeout: WAIT_TIMEOUT,
    });
    this.setState({ next: true });
  }

  renderContent = () => {
    const { kiSignalTest: { status, progress } } = this.props.hardware;

    if (status === null || status === 'repositioning') {
      // Instructions before test is running
      return (
        <>
          {status === null && (
            <>
              <p>
                <FormattedMessage
                  id="KiSignalStrengthAdjustmentStep.jobs.instructions.introduction"
                  defaultMessage="The following test aims to determine the optimal signal strength of the sensor. For this test, your KIWI Klick / Transponder must be in hands-free mode."
                />
              </p>
              <p>
                <FormattedMessage
                  id="KiSignalStrengthAdjustmentStep.jobs.instructions.handsfreeMode"
                  defaultMessage="
                  On the Klick you can check this by pressing the button on the Klick once.
                  If the LED blinks three times,hands-free mode is already enabled.
                  Otherwise please press the button on the Klick 3 times in quick succession to switch the mode.
                  On the Transponder you can check the slider switch position inside the battery compartment of the Transponder.
                  Make sure it is set to &quot;H&quot; for hands-free mode."
                />
              </p>
            </>
          )}
          <p>
            <FormattedMessage
              id="KiSignalStrengthAdjustmentStep.jobs.instructions.preparation"
              defaultMessage="Please take your KIWI Klick / Transponder and stand {distance}&nbsp; meters away from the door you are installing. Click &quot;Next&quot; when ready."
              values={{ distance: status === null ? 3 : 5 }}
            />
          </p>
        </>
      );
    }

    // Instructions while test is running
    if (status === 'testing') {
      return (
        <>
          <p>
            <FormattedMessage
              id="KiSignalStrengthAdjustmentStep.jobs.description.adjusting"
              defaultMessage="Adjust antenna gain of sensor..."
            />
          </p>
          <Progress percent={progress} size="tiny" />
          <p>
            <FormattedMessage
              id="KiSignalStrengthAdjustmentStep.jobs.instructions.running"
              defaultMessage="Please press the &quot;Door has opened&quot; button as soon the the door opens."
            />
          </p>
        </>
      );
    }

    // Instructions for final adjustment
    if (status === 'optimizing') {
      return (
        <>
          <p>
            <FormattedMessage
              id="KiSignalStrengthAdjustmentStep.jobs.instructions.done"
              defaultMessage="Please wait until the final signal strength has been set."
            />
          </p>
          <TimeoutProgress timeout={MAX_CONFIG_TIMEOUT} />
        </>
      );
    }
  }

  render() {
    const { next } = this.state;
    const { job, task } = this.props.routine;
    const { error, kiSignalTest } = this.props.hardware;

    if (next && kiSignalTest.success) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        loading={this.props.loading || kiSignalTest.status === 'initializing'}
        header={<JobProgressHeader job={job} task={task} stage="test" />}
        error={error}
        errorHeader={(
          <FormattedMessage
            id="KiSignalStrengthAdjustmentStep.jobs.errors.header"
            defaultMessage="Test failed"
          />
        )}
        errorMessages={ERROR_MESSAGES}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES[task.hardware.hardwareProduct]}
        retry={this.props.resetKiSignalTest}
      >
        <StepView.Content>
          {this.renderContent()}
        </StepView.Content>

        <StepView.Footer>
          {kiSignalTest.status === null && (
            <Button primary fluid onClick={this.start}>
              <FormattedMessage id="KiSignalStrengthAdjustmentStep.jobs.start.label" defaultMessage="Next" />
            </Button>
          )}
          {kiSignalTest.status === 'repositioning' && (
            <Button primary fluid onClick={this.props.kiSignalTestRepositioned}>
              <FormattedMessage id="KiSignalStrengthAdjustmentStep.jobs.repositioned.label" defaultMessage="Next" />
            </Button>
          )}
          {kiSignalTest.status === 'testing' && (
            <Button primary fluid onClick={this.props.kiSignalTestDoorOpened}>
              <FormattedMessage id="KiSignalStrengthAdjustmentStep.jobs.doorHasOpened.label" defaultMessage="Door has opened" />
            </Button>
          )}
        </StepView.Footer>
      </StepView>
    );
  }
}

KiSignalStrengthAdjustmentStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  startKiSignalTest,
  resetKiSignalTest,
  kiSignalTestDoorOpened,
  kiSignalTestRepositioned,
  stopRoutines,
}, dispatch);

export default withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(KiSignalStrengthAdjustmentStep)));
