import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { pushSensorConfiguration } from '~/app/actions/HardwareActions';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const ERROR_MESSAGES = defineMessages({
  NOT_ALLOWED: {
    id: 'PushSensorConfigurationStep.manufacturing.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
  CONF_TIMEOUT: {
    id: 'PushSensorConfigurationStep.manufacturing.errors.timeout',
    defaultMessage: 'The sensor could not be configured.',
  },
  CONF_ERROR: {
    id: 'PushSensorConfigurationStep.manufacturing.errors.confError',
    defaultMessage: 'The sensor could not be configured.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = _.pick(FIXING_OPTION_MESSAGES,
  'smartDoorPowered',
  'smartDoorReposition868MhzAntenna',
  'smartDoorReplaceSensor');


class PushSensorConfigurationStep extends Component {
  state = {
    next: false,
  }

  componentDidMount() {
    this.pushConfiguration();
  }

  pushConfiguration = () => {
    const { sensorId } = this.props.routine;
    this.props.pushSensorConfiguration({ id: sensorId, full: true });
    this.setState({ next: true });
  }

  render() {
    const { next } = this.state;
    const { error, configurationUpdate } = this.props.hardware;

    if (next && configurationUpdate.success) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        instructions={(
          <FormattedMessage
            id="PushSensorConfigurationStep.manufacturing.instructions"
            defaultMessage="Please wait until the sensor has been configured successfully."
          />
        )}
        error={error}
        errorMessages={ERROR_MESSAGES}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES}
        retry={this.pushConfiguration}
      />
    );
  }
}

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  pushSensorConfiguration,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushSensorConfigurationStep));
