import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { openSensor } from '~/app/actions/HardwareActions';
import ButtonGroup from '~/common/components/ButtonGroup';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const CURRENT_FIXING_OPTION_MESSAGES = _.pick(FIXING_OPTION_MESSAGES,
  'smartDoorPowered',
  'smartDoorSensorConnectedProperly',
  'smartDoorMechanics');


class RemoteOpeningTestingStep extends Component {
  state = {
    sensorOpened: null,
  }

  componentDidMount() {
    this.open();
  }

  get error() {
    const { sensorOpened } = this.state;
    const { error } = this.props.hardware;
    if (sensorOpened === false) {
      return (
        <FormattedMessage
          id="RemoteOpeningTestingStep.manufacturing.errors.noOpen"
          defaultMessage="The door could not be opened"
        />
      );
    }
    if (error) {
      return error;
    }

    return null;
  }

  open = () => {
    const { sensorId } = this.props.routine;
    this.props.openSensor({
      id: sensorId,
      responseTimeout: 0,
    });
    this.setState({ sensorOpened: null });
  }

  render() {
    const { sensorOpened } = this.state;

    if (sensorOpened) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        instructions={(
          <FormattedMessage
            id="RemoteOpeningTestingStep.manufacturing.instructions"
            defaultMessage="The sensor has been triggered remotely. Please report whether the door opened successfully."
          />
        )}
        error={this.error}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES}
        retry={this.open}
      >
        {({ stepList, instructions }) => (
          <>
            <StepView.Content>
              {stepList}
              {instructions}
            </StepView.Content>

            <StepView.Footer>
              <ButtonGroup>
                <Button fluid primary onClick={() => this.setState({ sensorOpened: true })}>
                  <FormattedMessage id="RemoteOpeningTestingStep.manufacturing.tests.yes" defaultMessage="Yes" />
                </Button>
                <Button fluid negative onClick={() => this.setState({ sensorOpened: false })}>
                  <FormattedMessage id="RemoteOpeningTestingStep.manufacturing.tests.no" defaultMessage="No" />
                </Button>
              </ButtonGroup>
            </StepView.Footer>
          </>
        )}
      </StepView>
    );
  }
}

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openSensor,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoteOpeningTestingStep));
