import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Image } from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import { HW_VARIANTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import staticFile from '~/common/utils/staticFile';
import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import {
  initializeSensorRebatt,
  finalizeSensorRebatt,
  stopRoutines,
} from '~/app/actions/HardwareActions';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import TimeoutProgress from '~/app/components/TimeoutProgress';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const TIMEOUT = 35000;

const ERROR_MESSAGES = defineMessages({
  TIMEOUT: {
    id: 'KnobPutOnCoverStep.jobs.errors.timeout',
    defaultMessage: 'Battery change mode could not be toggled.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = _.pick(FIXING_OPTION_MESSAGES,
  'knobInstalledProperly',
  'knobRestart',
  'gatewayRestart');


class KnobPutOnCoverStep extends Component {
  state = {
    putOnCover: false,
  }

  componentDidMount() {
    this.initialize();
  }

  componentWillUnmount() {
    this.props.stopRoutines();
  }

  get isCompact() {
    const { task } = this.props.routine;
    return [HW_VARIANTS.COMPACT, HW_VARIANTS.COMPACT_OUTDOOR].includes(
      task.hardware.hardwareVariant,
    );
  }

  initialize = () => {
    if (!this.isCompact) {
      const { task } = this.props.routine;
      this.props.initializeSensorRebatt({ id: task.hardware.sensorId });
    }
  }

  finalize = () => {
    if (!this.isCompact) {
      const { task } = this.props.routine;
      this.props.finalizeSensorRebatt({ id: task.hardware.sensorId });
    }

    this.setState({ putOnCover: true });
  }

  render() {
    const { putOnCover } = this.state;
    const { job, task } = this.props.routine;
    const { loading, error, rebatt } = this.props.hardware;

    const instructionImage = this.isCompact
      ? staticFile('smart_installation/images/compact-knob-cover-put-on.svg')
      : staticFile('smart_installation/images/knob-battery.svg');

    if (putOnCover && (!rebatt.active || this.isCompact)) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="test" />}
        error={error}
        errorHeader={(
          <FormattedMessage
            id="KnobPutOnCoverStep.jobs.errors.header"
            defaultMessage="Operation failed"
          />
        )}
        errorMessages={ERROR_MESSAGES}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES}
        retry={putOnCover ? this.finalize : this.initialize}
      >
        <StepView.Content>
          {loading && (
            <>
              <p>
                {putOnCover && (
                  <FormattedMessage
                    id="KnobPutOnCoverStep.jobs.disableBatteryChangeMode"
                    defaultMessage="Disable battery change mode..."
                  />
                )}
                {!putOnCover && (
                  <FormattedMessage
                    id="KnobPutOnCoverStep.jobs.enableBatteryChangeMode"
                    defaultMessage="Enable battery change mode to be able to put on the cover..."
                  />
                )}
              </p>
              <TimeoutProgress timeout={TIMEOUT} />
            </>
          )}

          {!loading && !putOnCover && (
            <>
              <p>
                <FormattedMessage
                  id="KnobPutOnCoverStep.jobs.putOnCover"
                  defaultMessage="Please put on the cover of the knob."
                />
              </p>
              <Image centered bordered size="large" className="instructions-image" src={instructionImage} />
            </>
          )}
        </StepView.Content>

        <StepView.Footer>
          {!loading && (rebatt.active || this.isCompact) && (
            <Button primary fluid onClick={this.finalize}>
              <FormattedMessage id="KnobPutOnCoverStep.jobs.next.label" defaultMessage="Next" />
            </Button>
          )}
        </StepView.Footer>
      </StepView>
    );
  }
}

KnobPutOnCoverStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  initializeSensorRebatt,
  finalizeSensorRebatt,
  stopRoutines,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(KnobPutOnCoverStep));
