import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown, Flag } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';


class LanguageSelector extends Component {
  handleChange = (e, { value }) => {
    this.props.onChange(value);
  }

  render() {
    const { value } = this.props;
    const languageOptions = [
      {
        value: 'de',
        text: (
          <span>
            <Flag name="de" />
            Deutsch
          </span>
        ),
      },
      {
        value: 'en',
        text: (
          <span>
            <Flag name="us" />
            English
          </span>
        ),
      },
    ];

    return (
      <Form>
        <Form.Field>
          <label>
            <FormattedMessage id="LanguageSelector.label" defaultMessage="Language" />
          </label>
          <Dropdown
            fluid
            selection
            options={languageOptions}
            value={value}
            onChange={this.handleChange}
          />
        </Form.Field>
      </Form>
    );
  }
}

LanguageSelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LanguageSelector;
