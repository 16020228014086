import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { MAX_CONFIG_TIMEOUT } from '~/app/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { grantSensorPermissions } from '~/app/actions/JobsActions';
import { awaitSensorKisUpdate, stopRoutines } from '~/app/actions/HardwareActions';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import TimeoutProgress from '~/app/components/TimeoutProgress';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const ERROR_MESSAGES = defineMessages({
  NO_API_USER: {
    id: 'GrantSensorPermissionStep.jobs.errors.noApiUser',
    defaultMessage: 'Your account is not associated with a KIWI account.',
  },
  NO_KI: {
    id: 'GrantSensorPermissionStep.jobs.errors.noKi',
    defaultMessage: 'Your account is not associated with a transponder.',
  },
  NO_NFC_KI: {
    id: 'GrantSensorPermissionStep.jobs.errors.noNfcKi',
    defaultMessage: 'Your account is not associated with a NFC transponder.',
  },
  NOT_ALLOWED: {
    id: 'GrantSensorPermissionStep.jobs.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
  BACKGROUND_TASK_TIMEOUT: {
    id: 'GrantSensorPermissionStep.jobs.errors.backgroundTaskTimeout',
    defaultMessage: 'The permission could not be granted.',
  },
  BACKGROUND_TASK_ERROR: {
    id: 'GrantSensorPermissionStep.jobs.errors.backgroundTaskError',
    defaultMessage: 'The permission could not be granted.',
  },
  CONF_TIMEOUT: {
    id: 'GrantSensorPermissionStep.jobs.errors.confTimeout',
    defaultMessage: 'The sensor could not be updated with permissions.',
  },
  CONF_ERROR: {
    id: 'GrantSensorPermissionStep.jobs.errors.confError',
    defaultMessage: 'The sensor could not be updated with permissions.',
  },
});


class GrantSensorPermissionStep extends Component {
  state = {
    next: false,
  }

  componentDidMount() {
    this.grantPermissionOrAwaitKisUpdate();
  }

  componentDidUpdate(prevProps) {
    const { task } = this.props.routine;
    const { task: prevTask } = prevProps.routine;

    if (prevTask && !prevTask.hardware.hasPermission && task.hardware.hasPermission) {
      this.awaitKisUpdate();
    }
  }

  componentWillUnmount() {
    this.props.stopRoutines();
  }

  grantPermissionOrAwaitKisUpdate = () => {
    const { task } = this.props.routine;
    if (task.hardware.hasPermission) {
      this.awaitKisUpdate();
    } else {
      this.grantPermission();
    }
  }

  grantPermission = () => {
    const { job, task } = this.props.routine;
    this.props.grantSensorPermissions({ job, tasks: [task] });
  }

  awaitKisUpdate = () => {
    const { task } = this.props.routine;
    this.props.awaitSensorKisUpdate({ id: task.hardware.sensorId });
    this.setState({ next: true });
  }

  render() {
    const { next } = this.state;
    const { job, task } = this.props.routine;
    const { error: jobsError } = this.props.jobs;
    const { kisUpdate, error: hardwareError } = this.props.hardware;

    if (next && task.hardware.hasPermission && kisUpdate.success) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="test" />}
        error={jobsError || hardwareError}
        errorHeader={(
          <FormattedMessage
            id="GrantSensorPermissionStep.jobs.errors.header"
            defaultMessage="Permission Error"
          />
        )}
        errorMessages={ERROR_MESSAGES}
        retry={this.grantPermissionOrAwaitKisUpdate}
      >
        <StepView.Content>
          <p>
            <FormattedMessage
              id="GrantSensorPermissionStep.jobs.description"
              defaultMessage="Granting temporary permission for the sensor..."
            />
          </p>
          <TimeoutProgress timeout={MAX_CONFIG_TIMEOUT} />
        </StepView.Content>
      </StepView>
    );
  }
}

GrantSensorPermissionStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  jobs: state.jobs,
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  grantSensorPermissions,
  awaitSensorKisUpdate,
  stopRoutines,
}, dispatch);

export default withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(GrantSensorPermissionStep)));
