import React from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';


const LoadingSpinner = ({ active, page, topMost }) => (
  <Dimmer.Inner
    inverted
    active={active}
    page={page}
    style={{ zIndex: topMost ? null : 9 }}
  >
    <Loader size="medium" />
  </Dimmer.Inner>
);

LoadingSpinner.defaultProps = {
  active: true,
  page: false,
  topMost: true,
};

LoadingSpinner.propTypes = {
  active: PropTypes.bool,
  page: PropTypes.bool,
  topMost: PropTypes.bool,
};

export default LoadingSpinner;
