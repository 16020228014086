import { handleActions, combineActions } from 'redux-actions';
import _ from 'lodash';

import {
  requestStartJob, dismissStartJob, startJob, requestFinishJob, dismissFinishJob, finishJob,
  requestCancelJob, dismissCancelJob, cancelJob, pauseJob, resumeJob, addUnplannedTask,
  startTask, pauseTask, finishTask, requestCancelTask, dismissCancelTask, cancelTask,
  requestSkipTask, dismissSkipTask, skipTask, navigate, resetRoutine, dismissError,
} from '~/app/actions/RoutineActions';


const initialState = {
  jobId: null,
  taskId: null,
  stepId: null,
  jobStarted: false,
  jobFinished: false,
  jobCancelled: false,
  taskStarted: false,
  taskFinished: false,
  taskCancelled: false,
  addUnplannedTask: false,
  startJobRequested: false,
  finishJobRequested: false,
  cancelJobRequested: false,
  cancelTaskRequested: false,
  skipTaskRequested: false,
  loading: null,
  error: null,
};

const RoutineReducer = handleActions({
  // Plain actions

  [pauseJob]: state => ({
    ...state,
    jobStarted: false,
    jobFinished: false,
    jobCancelled: false,
    taskStarted: false,
    taskFinished: false,
    taskCancelled: false,
    addUnplannedTask: false,
  }),

  [pauseTask]: state => ({
    ...state,
    taskStarted: false,
    taskFinished: false,
    taskCancelled: false,
    addUnplannedTask: false,
  }),

  [addUnplannedTask]: (state, { payload = {} }) => ({
    ...state,
    taskId: null,
    taskStarted: false,
    taskFinished: false,
    taskCancelled: false,
    addUnplannedTask: {
      ...payload,
      planned: false,
    },
  }),

  [requestStartJob]: (state, { payload }) => ({
    ...state,
    jobId: payload.job.id,
    startJobRequested: true,
  }),

  [dismissStartJob]: state => ({
    ...state,
    startJobRequested: false,
  }),

  [requestFinishJob]: state => ({
    ...state,
    finishJobRequested: true,
  }),

  [dismissFinishJob]: state => ({
    ...state,
    finishJobRequested: false,
  }),

  [requestCancelJob]: state => ({
    ...state,
    cancelJobRequested: true,
  }),

  [dismissCancelJob]: state => ({
    ...state,
    cancelJobRequested: false,
  }),

  [requestCancelTask]: state => ({
    ...state,
    cancelTaskRequested: true,
  }),

  [dismissCancelTask]: state => ({
    ...state,
    cancelTaskRequested: false,
  }),

  [requestSkipTask]: state => ({
    ...state,
    skipTaskRequested: true,
  }),

  [dismissSkipTask]: state => ({
    ...state,
    skipTaskRequested: false,
  }),

  [dismissError]: state => ({
    ...state,
    error: null,
  }),

  [resetRoutine]: () => initialState,

  // Routine TRIGGER

  [combineActions(
    startJob.TRIGGER,
    resumeJob.TRIGGER,
    finishJob.TRIGGER,
    startTask.TRIGGER,
    finishTask.TRIGGER,
    navigate.TRIGGER,
  )]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [cancelJob.TRIGGER]: state => ({
    ...state,
    loading: true,
    error: null,
    cancelJobRequested: false,
  }),

  [cancelTask.TRIGGER]: state => ({
    ...state,
    loading: true,
    error: null,
    cancelTaskRequested: false,
  }),

  [skipTask.TRIGGER]: state => ({
    ...state,
    loading: true,
    error: null,
    skipTaskRequested: false,
  }),

  // Routine SUCCESS

  [startJob.SUCCESS]: (state, { payload }) => ({
    ...state,
    jobId: payload.job.id,
    taskId: null,
    jobStarted: true,
    jobFinished: false,
    jobCancelled: false,
    taskStarted: false,
    taskFinished: false,
    taskCancelled: false,
    addUnplannedTask: false,
    startJobRequested: false,
    finishJobRequested: false,
  }),

  [resumeJob.SUCCESS]: (state, { payload }) => ({
    ...state,
    jobId: payload.job.id,
    taskId: payload.task ? payload.task.id : null,
    jobStarted: true,
    jobFinished: false,
    jobCancelled: false,
    taskStarted: payload.task != null,
    taskFinished: false,
    taskCancelled: false,
    startJobRequested: false,
    finishJobRequested: false,
  }),

  [finishJob.SUCCESS]: state => ({
    ...state,
    jobId: null,
    taskId: null,
    stepId: null,
    jobFinished: true,
    jobCancelled: false,
    taskFinished: true,
    taskCancelled: false,
    addUnplannedTask: false,
    startJobRequested: false,
    finishJobRequested: false,
  }),

  [cancelJob.SUCCESS]: state => ({
    ...state,
    jobId: null,
    taskId: null,
    stepId: null,
    jobFinished: false,
    jobCancelled: true,
    taskFinished: false,
    taskCancelled: true,
    addUnplannedTask: false,
    startJobRequested: false,
    finishJobRequested: false,
  }),

  [startTask.SUCCESS]: (state, { payload }) => ({
    ...state,
    jobId: payload.task.jobId,
    taskId: payload.task.id,
    stepId: payload.task.step,
    jobStarted: true,
    jobFinished: false,
    taskStarted: true,
    taskFinished: false,
    taskCancelled: false,
    addUnplannedTask: false,
  }),

  [cancelTask.SUCCESS]: state => ({
    ...state,
    jobStarted: false,
    jobFinished: false,
    taskStarted: false,
    taskFinished: false,
    taskCancelled: true,
    addUnplannedTask: false,
  }),

  [combineActions(
    skipTask.SUCCESS,
    finishTask.SUCCESS,
  )]: (state, { payload: { nextTask } = {} }) => ({
    ...state,
    taskId: _.get(nextTask, 'id', null),
    stepId: _.get(nextTask, 'step', null),
    taskStarted: !!nextTask,
    taskFinished: !nextTask,
    taskCancelled: false,
    addUnplannedTask: false,
  }),

  [navigate.SUCCESS]: (state, { payload }) => ({
    ...state,
    stepId: payload.stepId,
  }),

  // Routine FAILURE

  [combineActions(
    startJob.FAILURE,
    resumeJob.FAILURE,
    finishJob.FAILURE,
    cancelJob.FAILURE,
    startTask.FAILURE,
    finishTask.FAILURE,
    cancelTask.FAILURE,
    skipTask.FAILURE,
    navigate.FAILURE,
  )]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),

  // Routine FULFILL

  [combineActions(
    startJob.FULFILL,
    resumeJob.FULFILL,
    finishJob.FULFILL,
    cancelJob.FULFILL,
    startTask.FULFILL,
    finishTask.FULFILL,
    cancelTask.FULFILL,
    skipTask.FULFILL,
    navigate.FULFILL,
  )]: state => ({
    ...state,
    loading: false,
  }),
}, initialState);

export default RoutineReducer;
