import React, { Component } from 'react';

import JobRoutineView from './JobRoutineView';
import NotesStep from './steps/NotesStep';
import UUIDInputStep from './steps/UUIDInputStep';
import HardwareRegistrationStep from './steps/HardwareRegistrationStep';
import ConnectivityTestingStep from './steps/ConnectivityTestingStep';
import ConfigurationStep from './steps/ConfigurationStep';
import RemoteOpeningTestingStep from './steps/RemoteOpeningTestingStep';
import KiOpeningTestingStep from './steps/KiOpeningTestingStep';
import GrantSensorPermissionStep from './steps/GrantSensorPermissionStep';
import TrackHardwareStatusStep from './steps/TrackHardwareStatusStep';
import SuccessStep from './steps/SuccessStep';


class SmartDoorInstallationView extends Component {
  render() {
    const { task: { dispatcherNotes } } = this.props;

    return (
      <JobRoutineView {...this.props}>
        <NotesStep id="notes" disabled={!dispatcherNotes} />
        <UUIDInputStep id="uuidInput" />
        <HardwareRegistrationStep id="hardwareRegistration" skipBackwards />
        <ConnectivityTestingStep id="connectivityTesting" ignoreSignalStrength skipBackwards />
        <ConfigurationStep id="pushConfig" full skipBackwards />
        <RemoteOpeningTestingStep id="remoteOpeningTesting" skipBackwards />
        <GrantSensorPermissionStep id="grantingPermission" skipBackwards />
        <KiOpeningTestingStep id="kiClickOpeningTesting" trigger="click" />
        <TrackHardwareStatusStep id="checkStatusEnd" atEnd fresh skipBackwards />
        <SuccessStep id="success" />
      </JobRoutineView>
    );
  }
}

export default SmartDoorInstallationView;
