
import { HYBRID_HW_TYPES } from '~/common/constants';

// eslint-disable-next-line import/prefer-default-export
export const getDeviceCheckPath = ({ gatewayId, sensorId, hardwareType }) => {
  const isHybrid = (gatewayId != null && sensorId != null)
    || Object.values(HYBRID_HW_TYPES).includes(hardwareType);
  const basePath = isHybrid ? '/device-check/hybrid' : '/device-check';

  if (gatewayId != null) {
    return `${basePath}/gateway/${gatewayId}`;
  }
  if (sensorId != null) {
    return `${basePath}/sensor/${sensorId}`;
  }
};
