import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';

import { persistor } from '~/app/store';
import { logout } from '~/app/actions/AuthActions';
import { getCurrentMessages } from '~/app/selectors/LocaleSelectors';
import LoadingView from '~/app/views/LoadingView';
import ErrorView from '~/app/views/ErrorView';
import RootContainer from '~/app/containers/RootContainer';
import AppVersionChecker from '~/app/components/AppVersionChecker';
import NavigationProvider from '~/app/components/NavigationProvider';

import 'semantic-ui-less/semantic.less';
import '~/app/styles/App.css';


class App extends Component {
  static getDerivedStateFromError() {
    return { hasUnhandledError: true };
  }

  state = {
    hasUnhandledError: false,
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    const { hasUnhandledError } = this.state;
    const { locale, messages, errors: { error } } = this.props;

    return (
      <IntlProvider key={locale} locale={locale} messages={messages}>
        <PersistGate loading={<LoadingView />} persistor={persistor}>
          {hasUnhandledError && (
            <ErrorView />
          )}
          {!hasUnhandledError && (
            <Router basename="smart_installation">
              <NavigationProvider>
                <AppVersionChecker error={error} />
                <RootContainer />
              </NavigationProvider>
            </Router>
          )}
        </PersistGate>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  locale: state.locale.currentLocale,
  messages: getCurrentMessages(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
