import { call } from 'redux-saga/effects';

import {
  login,
  logout,
  requestPasswordReset,
  confirmPasswordReset,
} from '~/app/actions/AuthActions';
import { registerSagas } from '~/app/sagas';
import * as api from '~/common/services/ApiService';


function* loginSaga({ username, password }) {
  const result = yield call(api.login, {
    username,
    password,
  });

  return {
    scopes: result.scopes,
    sessionKey: result.sessionKey,
  };
}

function* logoutSaga() {
  yield call(api.logout);
}

function* requestPasswordResetSaga({ username }) {
  yield call(api.requestPasswordReset, username);
  return { username };
}

function* confirmPasswordResetSaga({ uid, token, password }) {
  const { username } = yield call(api.confirmPasswordReset, uid, token, password);
  return { username };
}

export default registerSagas([
  [login, loginSaga, { handleAuth: false }],
  [logout, logoutSaga, { handleAuth: false }],
  [requestPasswordReset, requestPasswordResetSaga, { handleAuth: false }],
  [confirmPasswordReset, confirmPasswordResetSaga, { handleAuth: false }],
]);
