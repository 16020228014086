import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Input } from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import { requestPasswordReset, dismissError } from '~/app/actions/AuthActions';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import AuthView from '~/app/views/AuthView';


const ERROR_MESSAGES = defineMessages({
  NO_EMAIL: {
    id: 'PasswordResetRequestView.errors.noEmail',
    defaultMessage: 'You cannot reset your password because there is no e-mail address associated with it.',
  },
});


class PasswordResetRequestView extends Component {
  state = {
    username: _.get(this.props.location.state, 'username', ''),
    submitted: false,
  }

  componentDidMount() {
    this.props.dismissError();
  }

  requestPasswordReset = () => {
    const { username } = this.state;
    const { currentLocale: lang } = this.props.locale;

    this.props.requestPasswordReset({ username, lang });
    this.setState({ submitted: true });
  }

  render() {
    const { username, submitted } = this.state;
    const { loading, error, passwordReset } = this.props.auth;

    if (submitted && passwordReset.requested) {
      return <Redirect to={{ pathname: '/login', state: { username } }} />;
    }

    return (
      <AuthView
        loading={loading}
        header={(
          <FormattedMessage id="PasswordResetRequestView.header" defaultMessage="Password Reset" />
        )}
      >
        {error && (
          <ApiErrorMessage
            error={error}
            header={(
              <FormattedMessage id="PasswordResetRequestView.errorHeader" defaultMessage="An error occurred!" />
            )}
            messages={ERROR_MESSAGES}
          />
        )}

        <Form onSubmit={this.requestPasswordReset}>
          <Form.Field>
            <label>
              <FormattedMessage id="PasswordResetRequestView.username.label" defaultMessage="Username" />
            </label>
            <FormattedMessage id="PasswordResetRequestView.username.placeholder" defaultMessage="Username">
              {placeholder => (
                <Input
                  autoCapitalize="none"
                  placeholder={placeholder}
                  icon="user"
                  value={username}
                  onChange={e => this.setState({ username: e.target.value })}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <Button primary fluid disabled={username.length === 0}>
              <FormattedMessage id="PasswordResetRequestView.resetPassword.label" defaultMessage="Reset password" />
            </Button>
          </Form.Field>
          <Form.Field>
            <Link to={{ pathname: '/login', state: { username } }}>
              <Button fluid>
                <FormattedMessage id="PasswordResetRequestView.backToLogin.label" defaultMessage="Back to login" />
              </Button>
            </Link>
          </Form.Field>
        </Form>
      </AuthView>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  locale: state.locale,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  requestPasswordReset,
  dismissError,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetRequestView);
