import { defineMessages } from 'react-intl';


// defineMessages does not allow to pass HW_TYPE constants
export default defineMessages({
  GLORIA: {
    id: 'HardwareTypes.gloria',
    defaultMessage: 'Gloria',
  },
  SDS_GATEWAY: {
    id: 'HardwareTypes.sdsGateway',
    defaultMessage: 'SDS',
  },
  NDS_GATEWAY: {
    id: 'HardwareTypes.ndsGateway',
    defaultMessage: 'Gateway 2',
  },
  NDS_HYBRID: {
    id: 'HardwareTypes.ndsHybrid',
    defaultMessage: 'Smart Entry 2 Hybrid',
  },
  DOOR: {
    id: 'HardwareTypes.door',
    defaultMessage: 'Smart Entry',
  },
  KIWI_UZ_KNOB: {
    id: 'HardwareTypes.kiwiUzKnob',
    defaultMessage: 'U&Z Knob',
  },
  KIWI_UZ_KNOB_SB: {
    id: 'HardwareTypes.kiwiUzKnobSb',
    defaultMessage: 'U&Z Knob with NFC & Wake-on-Radio',
  },
  KIWI_UZ_COMPACT_KNOB_SB: {
    id: 'HardwareTypes.kiwiUzCompactKnobSb',
    defaultMessage: 'U&Z Compact Knob with NFC & Wake-on-Radio',
  },
  UZ_KNOB_KIWI_DOOR_INTEGRATION: {
    id: 'HardwareTypes.uzKnobKiwiDoorIntegration',
    defaultMessage: 'U&Z Knob (Partial Integration)',
  },
  KIWI_UZ_KNOB_OUTDOOR: {
    id: 'HardwareTypes.kiwiUzKnobOutdoor',
    defaultMessage: 'U&Z Knob Outdoor (Partial Integration)',
  },
  UZ_KNOB_KIWI_DOOR_HUB: {
    id: 'HardwareTypes.uzKnobKiwiDoorHub',
    defaultMessage: 'Door Hub for U&Z Knob',
  },
  UZ_KNOB_KIWI_DOOR_HUB_OUTDOOR: {
    id: 'HardwareTypes.uzKnobKiwiDoorHubOutdoor',
    defaultMessage: 'Door Hub for U&Z Knob Outdoor',
  },
  KIWI_UZ_HANDLE_SB: {
    id: 'HardwareTypes.kiwiUzHandleSb',
    defaultMessage: 'U&Z Handle with NFC & Wake-on-Radio',
  },
  UZ_HANDLE_KIWI_DOOR_INTEGRATION: {
    id: 'HardwareTypes.uzHandleKiwiDoorIntegration',
    defaultMessage: 'U&Z Handle (Partial Integration)',
  },
  KIWI_UZ_HANDLE_OUTDOOR: {
    id: 'HardwareTypes.kiwiUzHandleOutdoor',
    defaultMessage: 'U&Z Handle Outdoor (Partial Integration)',
  },
  UZ_HANDLE_KIWI_DOOR_HUB: {
    id: 'HardwareTypes.uzHandleKiwiDoorHub',
    defaultMessage: 'Door Hub for U&Z Handle',
  },
  UZ_HANDLE_KIWI_DOOR_HUB_OUTDOOR: {
    id: 'HardwareTypes.uzHandleKiwiDoorHubOutdoor',
    defaultMessage: 'Door Hub for U&Z Handle Outdoor',
  },
  KIWI_SAFE: {
    id: 'HardwareTypes.kiwiSafe',
    defaultMessage: 'Safe',
  },
  KIWI_SAFE_TWO: {
    id: 'HardwareTypes.kiwiSafeTwo',
    defaultMessage: 'Safe 2',
  },
  SDS_SENSOR: {
    id: 'HardwareTypes.sdsSensor',
    defaultMessage: 'SmartDOOR',
  },
  NDS_SENSOR: {
    id: 'HardwareTypes.ndsSensor',
    defaultMessage: 'Smart Entry 2',
  },
  LYCHEE: {
    id: 'HardwareTypes.lychee',
    defaultMessage: 'Lychee Lock',
  },
});
