import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';


export const requestStartJob = createAction('ROUTINE_JOB_START_REQUEST');
export const dismissStartJob = createAction('ROUTINE_JOB_START_DISMISS');
export const startJob = createRoutine('ROUTINE_JOB_START');
export const pauseJob = createAction('ROUTINE_JOB_PAUSE');
export const resumeJob = createRoutine('ROUTINE_JOB_RESUME');
export const addUnplannedTask = createAction('ROUTINE_JOB_ADD_UNPLANNED_TASK');
export const requestFinishJob = createAction('ROUTINE_JOB_FINISH_REQUEST');
export const dismissFinishJob = createAction('ROUTINE_JOB_FINISH_DISMISS');
export const finishJob = createRoutine('ROUTINE_JOB_FINISH');
export const requestCancelJob = createAction('ROUTINE_JOB_CANCEL_REQUEST');
export const dismissCancelJob = createAction('ROUTINE_JOB_CANCEL_DISMISS');
export const cancelJob = createRoutine('ROUTINE_JOB_CANCEL');

export const startTask = createRoutine('ROUTINE_TASK_START');
export const pauseTask = createAction('ROUTINE_TASK_PAUSE');
export const finishTask = createRoutine('ROUTINE_TASK_FINISH');
export const requestCancelTask = createAction('ROUTINE_TASK_CANCEL_REQUEST');
export const dismissCancelTask = createAction('ROUTINE_TASK_CANCEL_DISMISS');
export const cancelTask = createRoutine('ROUTINE_TASK_CANCEL');
export const requestSkipTask = createAction('ROUTINE_TASK_SKIP_REQUEST');
export const dismissSkipTask = createAction('ROUTINE_TASK_SKIP_DISMISS');
export const skipTask = createRoutine('ROUTINE_TASK_SKIP');

export const navigate = createRoutine('ROUTINE_NAVIGATE');

export const dismissError = createAction('ROUTINE_ERROR_DISMISS');
export const resetRoutine = createAction('ROUTINE_RESET');
