import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';


const TIME_PARTS = ['days', 'hours', 'minutes', 'seconds'];
const SHORT_TIME_PARTS = ['hours', 'minutes', 'seconds'];

const TIME_PART_MESSAGES = defineMessages({
  days: {
    id: 'FormattedTimespan.parts.days',
    defaultMessage: '{value}\u00a0{value, plural, one {day} other {days}}',
  },
  hours: {
    id: 'FormattedTimespan.parts.hours',
    defaultMessage: '{value}\u00a0{value, plural, one {hour} other {hours}}',
  },
  minutes: {
    id: 'FormattedTimespan.parts.minutes',
    defaultMessage: '{value}\u00a0{value, plural, one {minute} other {minutes}}',
  },
  seconds: {
    id: 'FormattedTimespan.parts.seconds',
    defaultMessage: '{value}\u00a0{value, plural, one {second} other {seconds}}',
  },
  conjunction: {
    id: 'FormattedTimespan.parts.conjunction',
    defaultMessage: 'and',
  },
});

const CONTEXT_MESSAGES = defineMessages({
  past: {
    id: 'FormattedTimespan.context.past',
    defaultMessage: '{timespan} ago',
  },
  future: {
    id: 'FormattedTimespan.context.future',
    defaultMessage: 'in {timespan}',
  },
});

const formatPartsLong = (intl, parts, precision, context) => {
  const timeParts = TIME_PARTS.slice(0, TIME_PARTS.indexOf(precision) + 1);
  const messageParts = timeParts
    .map(part => [part, parts[part]])
    .filter(([, value]) => value)
    .map(([part, value]) => intl.formatMessage(TIME_PART_MESSAGES[part], { value, context }));

  let timespan;
  if (messageParts.length === 0) {
    const timePart = timeParts.slice(-1)[0];
    timespan = intl.formatMessage(TIME_PART_MESSAGES[timePart], { value: 0 });
  } else {
    timespan = messageParts.reduce((message, part, index) => {
      if (index === 0) {
        return part;
      }
      if (index === messageParts.length - 1) {
        const conjunction = intl.formatMessage(TIME_PART_MESSAGES.conjunction);
        return `${message} ${conjunction} ${part}`;
      }

      return `${message}, ${part}`;
    });
  }

  return timespan;
};

const formatPartsShort = (intl, parts, precision, context) => {
  const timeParts = TIME_PARTS.slice(0, TIME_PARTS.indexOf(precision) + 1);
  const longTimeString = timeParts
    .filter(part => !SHORT_TIME_PARTS.includes(part))
    .map(part => [part, parts[part]])
    .filter(([, value]) => value)
    .map(([part, value]) => intl.formatMessage(TIME_PART_MESSAGES[part], { value, context }))
    .join(', ');
  const shortTimeString = timeParts
    .filter(part => SHORT_TIME_PARTS.includes(part))
    .map(part => parts[part])
    .map(value => (value < 10 ? `0${value}` : value))
    .join(':');

  return [longTimeString, shortTimeString]
    .filter(x => x)
    .join(', ');
};

const FormattedTimespan = ({
  intl, totalSeconds, shortFormat, context, precision,
}) => {
  if (totalSeconds == null) {
    return null;
  }

  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const days = Math.floor(totalHours / 24);
  const hours = Math.floor(totalHours % 24);
  const minutes = totalMinutes % 60;
  const seconds = Math.floor(totalSeconds % 60);
  const parts = {
    days, hours, minutes, seconds,
  };
  const timespan = shortFormat
    ? formatPartsShort(intl, parts, precision, context)
    : formatPartsLong(intl, parts, precision, context);

  return (
    <span>
      {context ? (
        intl.formatMessage(CONTEXT_MESSAGES[context], { timespan })
      ) : (
        timespan
      )}
    </span>
  );
};

FormattedTimespan.defaultProps = {
  totalSeconds: null,
  shortFormat: false,
  context: null,
  precision: 'seconds',
};

FormattedTimespan.propTypes = {
  totalSeconds: PropTypes.number,
  shortFormat: PropTypes.bool,
  context: PropTypes.oneOf(['past', 'future']),
  precision: PropTypes.oneOf(['days', 'hours', 'minutes', 'seconds']),
};

export default injectIntl(FormattedTimespan);
