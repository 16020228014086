import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { HW_PRODUCTS, SAFE_HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import ButtonGroup from '~/common/components/ButtonGroup';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const CURRENT_FIXING_OPTION_MESSAGES = {
  [HW_PRODUCTS.SMART_ENTRY]: _.pick(FIXING_OPTION_MESSAGES,
    'kiManualMode',
    'smartEntryInstalledProperly',
    'smartEntryReposition24GhzAntenna',
    'smartEntryRestart'),
  [HW_PRODUCTS.SMART_ENTRY_HYBRID]: _.pick(FIXING_OPTION_MESSAGES,
    'kiManualMode',
    'smartEntryInstalledProperly',
    'smartEntryReposition24GhzAntenna',
    'smartEntryRestart'),
  [HW_PRODUCTS.SMART_DOOR]: _.pick(FIXING_OPTION_MESSAGES,
    'kiManualMode',
    'smartDoorInstalledProperly',
    'smartDoorRestart'),
  [HW_PRODUCTS.KNOB]: _.pick(FIXING_OPTION_MESSAGES,
    'kiHoldCloseToSensor',
    'knobInstalledProperly',
    'knobRestart'),
  [HW_PRODUCTS.HANDLE]: _.pick(FIXING_OPTION_MESSAGES,
    'kiHoldCloseToSensor',
    'handleInstalledProperly',
    'handleRestart'),
  [HW_PRODUCTS.SAFE]: _.pick(FIXING_OPTION_MESSAGES,
    'kiHoldCloseToSensor',
    'safeInstalledProperly',
    'safeRestart'),
  [HW_PRODUCTS.SAFE_TWO]: _.pick(FIXING_OPTION_MESSAGES,
    'kiHoldCloseToSensor',
    'safeInstalledProperly',
    'safeRestart'),
};


class KiOpeningTestingStep extends Component {
  state = {
    next: false,
    failed: false,
  }

  get error() {
    const { failed } = this.state;
    const { action } = this.props;

    if (failed) {
      if (!action) {
        return <FormattedMessage id="KiOpeningTestingStep.jobs.errors.open" defaultMessage="Door didn't open." />;
      }
      if (action === 'lock') {
        return <FormattedMessage id="KiOpeningTestingStep.jobs.errors.lock" defaultMessage="Lock couldn't be locked." />;
      }
      if (action === 'unlock') {
        return <FormattedMessage id="KiOpeningTestingStep.jobs.errors.unlock" defaultMessage="Lock couldn't be unlocked" />;
      }
    }

    return null;
  }

  render() {
    const { next } = this.state;
    const { trigger, action } = this.props;
    const { job, task } = this.props.routine;

    if (next) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="test" />}
        error={this.error}
        errorHeader={(
          <FormattedMessage
            id="KiOpeningTestingStep.jobs.errors.header"
            defaultMessage="Test failed"
          />
        )}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES[task.hardware.hardwareProduct]}
        retry={() => this.setState({ failed: false })}
      >
        <StepView.Content>
          {/* Trigger instructions */}
          {trigger === 'click' && (
            <>
              <p>
                <FormattedMessage
                  id="KiOpeningTestingStep.jobs.instructions.click.manualMode.klick"
                  defaultMessage="
                    For this test, your KIWI Klick / Transponder must be in manual mode.
                    On the Klick you can check this by pressing the button once.
                    If the LED of the Klick blinks once, manual mode is already enabled.
                    Otherwise please press the button on the Klick 3 times in quick succession to switch the mode."
                />
              </p>
              <p>
                <FormattedMessage
                  id="KiOpeningTestingStep.jobs.instructions.click.manualMode.transponder"
                  defaultMessage="
                    On the Transponder you can check the slider switch position inside the battery compartment of the Transponder. Make sure it is set to &quot;M&quot; for manual mode."
                />
              </p>
              <p>
                <FormattedMessage
                  id="KiOpeningTestingStep.jobs.instructions.click"
                  defaultMessage="Please take your KIWI Klick / Transponder and stand 3&nbsp;meters away from the door you are installing. Press the button of the KIWI Klick / Transponder to activate the sensor."
                />
              </p>
            </>
          )}
          {trigger === 'nfc' && (
            <p>
              <FormattedMessage id="KiOpeningTestingStep.jobs.instructions.nfc" defaultMessage="Please hold your KIWI Klick close to the sensor." />
            </p>
          )}

          {/* Action instructions */}
          {[HW_PRODUCTS.KNOB, ...SAFE_HW_PRODUCTS]
            .includes(task.hardware.hardwareProduct)
              && action === 'lock' && (
                <FormattedMessage
                  id="KiOpeningTestingStep.jobs.instructions.knob.lock"
                  defaultMessage="When the activation signal sounds, turn the knob to lock."
                />
          )}
          {[HW_PRODUCTS.KNOB, ...SAFE_HW_PRODUCTS]
            .includes(task.hardware.hardwareProduct)
              && action === 'unlock' && (
                <FormattedMessage
                  id="KiOpeningTestingStep.jobs.instructions.knob.unlock"
                  defaultMessage="When the activation signal sounds, turn the knob to unlock the lock."
                />
          )}
          {task.hardware.hardwareProduct === HW_PRODUCTS.HANDLE && action === 'unlock' && (
            <FormattedMessage
              id="KiOpeningTestingStep.jobs.instructions.handle.unlock"
              defaultMessage="When the activation signal sounds, push down the handle of the door to unlock the lock."
            />
          )}
        </StepView.Content>

        <StepView.Footer>
          <ButtonGroup>
            <Button fluid color="red" onClick={() => this.setState({ failed: true })}>
              {!action && (
                <FormattedMessage id="KiOpeningTestingStep.jobs.failed.open" defaultMessage="Door didn't open" />
              )}
              {action === 'lock' && (
                <FormattedMessage id="KiOpeningTestingStep.jobs.failed.lock" defaultMessage="Lock couldn't be locked" />
              )}
              {action === 'unlock' && (
                <FormattedMessage id="KiOpeningTestingStep.jobs.failed.unlock" defaultMessage="Lock couldn't be unlocked" />
              )}
            </Button>
            <Button primary fluid onClick={() => this.setState({ next: true })}>
              {!action && (
                <FormattedMessage id="KiOpeningTestingStep.jobs.success.open" defaultMessage="Door has opened" />
              )}
              {action === 'lock' && (
                <FormattedMessage id="KiOpeningTestingStep.jobs.success.lock" defaultMessage="Lock was locked" />
              )}
              {action === 'unlock' && (
                <FormattedMessage id="KiOpeningTestingStep.jobs.success.unlock" defaultMessage="Lock was unlocked" />
              )}
            </Button>
          </ButtonGroup>
        </StepView.Footer>
      </StepView>
    );
  }
}

KiOpeningTestingStep.defaultProps = {
  action: null,
};

KiOpeningTestingStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
  trigger: PropTypes.oneOf([
    'click',
    'nfc',
  ]).isRequired,
  action: PropTypes.oneOf([
    'lock',
    'unlock',
  ]),
};

const mapDispatchToProps = dispatch => bindActionCreators({
  requestSkipTask,
}, dispatch);

export default withRoutine(connect(
  null,
  mapDispatchToProps,
)(KiOpeningTestingStep));
