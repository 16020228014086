import { handleActions } from 'redux-actions';
import { fetchCurrentOpsUser, dismissError } from '~/app/actions/UsersActions';

const initialState = {
  current: null,
  loading: false,
  error: null,
};

const UsersReducer = handleActions({
  // Plain actions

  [dismissError]: state => ({
    ...state,
    error: null,
  }),

  // Routine TRIGGER

  [fetchCurrentOpsUser.TRIGGER]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  // Routine SUCCESS

  [fetchCurrentOpsUser.SUCCESS]: (state, { payload }) => ({
    ...state,
    current: payload.user,
  }),

  // Routine FAILURE

  [fetchCurrentOpsUser.FAILURE]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),

  // Routine FULFILL

  [fetchCurrentOpsUser.FULFILL]: state => ({
    ...state,
    loading: false,
  }),
}, initialState);

export default UsersReducer;
