import { defineMessages } from 'react-intl';


export default defineMessages({
  // General
  correctUuid: {
    id: 'FixingOptions.general.correctUuid',
    defaultMessage: 'Correct UUID was scanned or entered',
  },
  contactSupport: {
    id: 'FixingOptions.general.callSupport',
    defaultMessage: 'Contact technical support of KIWI',
  },

  // Gateway
  gatewayInstalledProperly: {
    id: 'FixingOptions.gateway.installedProperly',
    defaultMessage: 'Gateway is installed properly',
  },
  gatewayRestart: {
    id: 'FixingOptions.gateway.restart',
    defaultMessage: 'Restart Gateway',
  },
  gatewayRepositionGsmAntenna: {
    id: 'FixingOptions.gateway.repositionGsmAntenna',
    defaultMessage: 'Reposition GSM antenna of the gateway',
  },
  gatewayReposition868MhzAntenna: {
    id: 'FixingOptions.gateway.reposition868MhzAntenna',
    defaultMessage: 'Reposition 868MHz antenna of the gateway',
  },

  // Smart Entry
  smartEntryInstalledProperly: {
    id: 'FixingOptions.smartEntry.installedProperly',
    defaultMessage: 'Smart Entry is installed properly',
  },
  smartEntryRestart: {
    id: 'FixingOptions.smartEntry.restart',
    defaultMessage: 'Restart Smart Entry',
  },
  smartEntryRepositionGsmAntenna: {
    id: 'FixingOptions.smartEntry.repositionGsmAntenna',
    defaultMessage: 'Reposition GSM antenna of the Smart Entry',
  },
  smartEntryReposition868MhzAntenna: {
    id: 'FixingOptions.smartEntry.reposition868MhzAntenna',
    defaultMessage: 'Reposition 868MHz antenna of the Smart Entry',
  },
  smartEntryReposition24GhzAntenna: {
    id: 'FixingOptions.smartEntry.reposition24GhzAntenna',
    defaultMessage: 'Reposition 2.4GHz antenna of the Smart Entry',
  },

  // Smart Door
  smartDoorInstalledProperly: {
    id: 'FixingOptions.smartDoor.installedProperly',
    defaultMessage: 'SmartDOOR is installed properly',
  },
  smartDoorClosed: {
    id: 'FixingOptions.smartDoor.closed',
    defaultMessage: 'SmartDOOR is closed',
  },
  smartDoorRestart: {
    id: 'FixingOptions.smartDoor.restart',
    defaultMessage: 'Restart SmartDOOR (open and close door)',
  },
  smartDoorPowered: {
    id: 'FixingOptions.smartDoor.powered',
    defaultMessage: 'SmartDOOR is connected to the power',
  },
  smartDoorSensorConnectedProperly: {
    id: 'FixingOptions.smartDoor.sensorConnectedProperty',
    defaultMessage: 'Sensor is connected properly',
  },
  smartDoorReplaceSensor: {
    id: 'FixingOptions.smartDoor.replaceSensor',
    defaultMessage: 'Replace the sensor',
  },
  smartDoorMechanics: {
    id: 'FixingOptions.smartDoor.doorMechanics',
    defaultMessage: 'Door mechanics are fine',
  },
  smartDoorReposition868MhzAntenna: {
    id: 'FixingOptions.smartDoor.reposition868MhzAntenna',
    defaultMessage: 'Reposition 868MHz antenna of the sensor',
  },
  smartDoorReposition24GhzAntenna: {
    id: 'FixingOptions.smartDoor.reposition24GhzAntenna',
    defaultMessage: 'Reposition 2.4GHz antenna of the sensor',
  },

  // Knob
  knobInstalledProperly: {
    id: 'FixingOptions.knob.installedProperly',
    defaultMessage: 'Knob is installed properly',
  },
  knobRestart: {
    id: 'FixingOptions.knob.restart',
    defaultMessage: 'Restart knob',
  },

  // Handle
  handleInstalledProperly: {
    id: 'FixingOptions.handle.installedProperly',
    defaultMessage: 'Handle is installed properly',
  },
  handleRestart: {
    id: 'FixingOptions.handle.restart',
    defaultMessage: 'Restart handle',
  },

  // Knob
  safeInstalledProperly: {
    id: 'FixingOptions.safe.installedProperly',
    defaultMessage: 'Safe is installed properly',
  },
  safeRestart: {
    id: 'FixingOptions.safe.restart',
    defaultMessage: 'Restart safe',
  },

  // Ki
  kiHandsfreeMode: {
    id: 'FixingOptions.ki.handsfreeMode',
    defaultMessage: 'KIWI Klick is in hands-free mode',
  },
  kiManualMode: {
    id: 'FixingOptions.ki.manualMode',
    defaultMessage: 'KIWI Klick is in manual mode',
  },
  kiBattery: {
    id: 'FixingOptions.ki.battery',
    defaultMessage: 'KIWI Klick lights up when pressing it\'s button',
  },
  kiHoldCloseToSensor: {
    id: 'FixingOptions.ki.holdCloseToSensor',
    defaultMessage: 'Hold the KIWI Klick very close to the front of the sensor',
  },
});
