export default async ({ fetchPageFn, parseResponseFn }) => {
  let pageNumber = 1;
  let allResults = [];
  let totalResults = null;
  while (totalResults === null || allResults.length < totalResults) {
    // eslint-disable-next-line no-await-in-loop
    const response = await fetchPageFn(pageNumber);
    const { total, results } = parseResponseFn(response);

    pageNumber += 1;
    totalResults = total;
    allResults = [...allResults, ...results];
  }

  return allResults;
};
