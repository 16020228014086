import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from 'semantic-ui-react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { TaskType } from '~/common/types';
import HW_PRODUCT_MESSAGES from '~/common/locales/HardwareProducts';
import { TASK_TYPES } from '~/common/constants';
import JobTaskRow from '~/app/components/TaskSummary/JobTaskRow';
import '~/app/styles/components/TaskSummary.css';

class TaskSummary extends Component {
  getInstallationDescription = (product) => {
    const message = HW_PRODUCT_MESSAGES[product];
    return this.props.intl.formatMessage(message);
  };

  renderInstallationsSection = installations => (
    <>
      <Grid.Row className="task-summary-row task-summary-section-title">
        <Grid.Column>
          <FormattedMessage id="TaskSummary.installations" defaultMessage="Installations" />
          <Divider className="task-summary-section-title-divider" />
        </Grid.Column>
      </Grid.Row>
      {installations.map(task => (
        <JobTaskRow task={task} key={task.id} />
      ))}
    </>
  )

  renderFixingsSection = fixings => (
    <>
      <Grid.Row className="task-summary-row task-summary-section-title">
        <Grid.Column>
          <FormattedMessage id="TaskSummary.fixings" defaultMessage="Fixings" />
          <Divider className="task-summary-section-title-divider" />
        </Grid.Column>
      </Grid.Row>
      {fixings.map(task => (
        <JobTaskRow task={task} key={task.id} />
      ))}
    </>
  );

  render() {
    const { tasks } = this.props;
    const installations = tasks.filter(t => t.type === TASK_TYPES.INSTALLATION);
    const fixings = tasks.filter(t => t.type === TASK_TYPES.FIXING);

    return (
      <Grid className="task-summary-container">
        {installations.length > 0 && this.renderInstallationsSection(installations)}
        {fixings.length > 0 && this.renderFixingsSection(fixings)}
      </Grid>
    );
  }
}

TaskSummary.defaultProps = {
  tasks: [],
};

TaskSummary.propTypes = {
  tasks: PropTypes.arrayOf(TaskType),
};

export default injectIntl(TaskSummary);
