import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Form, Dropdown, Input, Button,
} from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';

import {
  TASK_TYPES, HW_PRODUCTS, GATEWAY_HW_PRODUCTS,
  HW_PRODUCT_HW_VARIANTS, HW_VARIANTS, SAFE_HW_PRODUCTS,
} from '~/common/constants';
import { JobType } from '~/common/types';
import { formatProductVariant } from '~/common/utils/productVariants';
import { hasConfigOption, getConfigOption, setConfigOption } from '~/common/utils/hardwareConfiguration';
import HW_PRODUCT_MESSAGES from '~/common/locales/HardwareProducts';
import { resetTask } from '~/app/actions/JobsActions';
import { pauseJob, startTask, dismissError } from '~/app/actions/RoutineActions';
import FormattedAddress from '~/common/components/FormattedAddress';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';
import View from '~/app/views/View';


const GATEWAY_868_MODE_OPTIONS = [
  {
    value: true,
    text: (
      <FormattedMessage
        id="TaskCreationView.gateway868Mode.enable"
        defaultMessage="Smart Entry 1 / Smart Locks / Safe (Apteryx)"
      />
    ),
  },
  {
    value: false,
    text: (
      <FormattedMessage
        id="TaskCreationView.gateway868Mode.disable"
        defaultMessage="Smart Entry 2 / SmartDOORs (MQTT)"
      />
    ),
  },
];

class TaskCreationView extends Component {
  state = {
    taskType: _.get(this.props.task, 'type', TASK_TYPES.INSTALLATION),
    hardwareProduct: _.get(this.props.task, 'hardware.hardwareProduct', null),
    hardwareVariant: _.get(this.props.task, 'hardware.hardwareVariant', null),
    configurationOverride: _.get(this.props.task, 'configurationOverride', {}),
    floor: _.get(this.props.task, 'hardware.floor', ''),
    specifier: _.get(this.props.task, 'hardware.specifier', ''),
    doorNumber: _.get(this.props.task, 'hardware.doorNumber', ''),
  }

  get expectsGateway868Mode() {
    const { hardwareProduct, hardwareVariant } = this.state;
    return (
      GATEWAY_HW_PRODUCTS.includes(hardwareProduct)
      && hardwareVariant === HW_VARIANTS.NDS
    );
  }

  get expectsSpecifier() {
    const { hardwareProduct, hardwareVariant } = this.state;
    return (
      SAFE_HW_PRODUCTS.includes(hardwareProduct)
        && hardwareVariant !== HW_VARIANTS.PORTABLE
    );
  }

  get expectsDoorNumber() {
    const { hardwareProduct, hardwareVariant } = this.state;
    return (
      ![HW_PRODUCTS.GATEWAY, ...SAFE_HW_PRODUCTS].includes(hardwareProduct)
      && hardwareVariant !== HW_VARIANTS.PORTABLE
    );
  }

  get isValid() {
    const {
      hardwareProduct, hardwareVariant, configurationOverride,
      floor, specifier, doorNumber,
    } = this.state;
    const enableApteryxMode = getConfigOption(configurationOverride, 'GATEWAY_868_MODE');

    return (
      hardwareProduct != null
      && hardwareVariant != null
      && typeof floor === 'number'
      && (!this.expectsGateway868Mode || enableApteryxMode != null)
      && (!this.expectsSpecifier || specifier.length > 0)
      && (!this.expectsDoorNumber || typeof doorNumber === 'number')
    );
  }

  handleHardwareProductChange = (e, { value }) => {
    const hardwareVariants = HW_PRODUCT_HW_VARIANTS[value];
    const hardwareVariant = hardwareVariants.length === 1
      ? hardwareVariants[0]
      : null;

    this.setState({
      hardwareProduct: value,
      hardwareVariant,
    });
  }

  handleHardwareVariantChange = (e, { value }) => {
    this.setState({ hardwareVariant: value });
  }

  handleGateway868ModeChange = (e, { value }) => {
    this.setState(({ configurationOverride }) => ({
      configurationOverride: setConfigOption(configurationOverride, 'GATEWAY_868_MODE', value),
    }));
  }

  handleFloorChange = (e, { value }) => {
    let floor = parseInt(value, 10);
    if (Number.isNaN(floor)) {
      floor = value;
    }
    this.setState({ floor });
  }

  handleSpecifierChange = (e, { value }) => {
    this.setState({ specifier: value });
  }

  handleDoorNumberChange = (e, { value }) => {
    let doorNumber = parseInt(value, 10);
    if (Number.isNaN(doorNumber)) {
      doorNumber = value;
    }
    this.setState({ doorNumber });
  }

  next = () => {
    const { task: propsTask, job } = this.props;
    const {
      taskType, hardwareProduct, hardwareVariant, floor,
    } = this.state;

    let { configurationOverride, specifier, doorNumber } = this.state;
    if (!this.expectsGateway868Mode) {
      configurationOverride = null;
    }
    if (!this.expectsSpecifier) {
      if (this.expectsDoorNumber) {
        specifier = doorNumber.toString();
      } else {
        specifier = this.props.intl.formatMessage(HW_PRODUCT_MESSAGES[hardwareProduct]);
      }
    }
    if (!this.expectsDoorNumber) {
      doorNumber = null;
    }

    if (this.isValid) {
      const task = {
        ...propsTask,
        type: taskType,
        hardware: {
          hardwareProduct,
          hardwareVariant,
          configurationOverride,
          floor,
          specifier,
          doorNumber,
        },
      };
      this.props.startTask({ job, task });
    }
  }

  cancel = () => {
    this.props.pauseJob();
  }

  render() {
    const { job, task } = this.props;
    const { error } = this.props.routine;
    const {
      hardwareProduct, hardwareVariant, configurationOverride,
      floor, specifier, doorNumber,
    } = this.state;

    const hardwareProductOptions = Object.values(HW_PRODUCTS).map(hw => ({
      value: hw,
      text: this.props.intl.formatMessage(HW_PRODUCT_MESSAGES[hw]),
    }));

    let hardwareVariantOptions = [];
    if (hardwareProduct) {
      hardwareVariantOptions = HW_PRODUCT_HW_VARIANTS[hardwareProduct].map(value => ({
        value,
        text: formatProductVariant(this.props.intl, hardwareProduct, value),
      }));
    }

    return (
      <View onGoBack={this.props.pauseJob}>
        <View.Header
          primaryText={<FormattedMessage id="TaskCreationView.header.primary" defaultMessage="Select device" />}
          secondaryText={<FormattedAddress address={job.address} />}
          action={this.cancel}
          actionIcon="cancel"
        />

        <View.Content>
          <JobSaveErrorModal
            error={error}
            onDismiss={this.props.dismissError}
          />
          <p>
            <FormattedMessage
              id="TaskCreationView.instructions"
              defaultMessage="Please start with selecting the device you want to setup."
            />
          </p>
          <Form onSubmit={this.next}>
            <Form.Field>
              <label>
                <FormattedMessage id="TaskCreationView.hardwareProduct.label" defaultMessage="Product" />
              </label>
              <FormattedMessage id="TaskCreationView.hardwareProduct.placeholder" defaultMessage="Select product...">
                {placeholder => (
                  <Dropdown
                    fluid
                    selection
                    value={hardwareProduct}
                    placeholder={placeholder}
                    options={hardwareProductOptions}
                    disabled={task.hardware && task.hardware.hardwareProduct != null}
                    onChange={this.handleHardwareProductChange}
                  />
                )}
              </FormattedMessage>
            </Form.Field>

            <Form.Field>
              <label>
                <FormattedMessage id="TaskCreationView.hardwareVariant.label" defaultMessage="Variant" />
              </label>
              <FormattedMessage id="TaskCreationView.hardwareVariant.placeholder" defaultMessage="Select variant...">
                {placeholder => (
                  <Dropdown
                    fluid
                    selection
                    value={hardwareVariant}
                    placeholder={placeholder}
                    options={hardwareVariantOptions}
                    disabled={task.hardware && task.hardware.hardwareVariant != null}
                    onChange={this.handleHardwareVariantChange}
                  />
                )}
              </FormattedMessage>
            </Form.Field>

            {hardwareProduct && hardwareVariant && (
              <>
                {this.expectsGateway868Mode && (
                  <Form.Field>
                    <label>
                      <FormattedMessage id="TaskCreationView.gateway868Mode.label" defaultMessage="Which KIWI products should be connected to the Gateway?" />
                    </label>
                    <FormattedMessage id="TaskCreationView.gateway868Mode.placeholder" defaultMessage="Select KIWI products...">
                      {placeholder => (
                        <Dropdown
                          fluid
                          selection
                          value={getConfigOption(configurationOverride, 'GATEWAY_868_MODE')}
                          placeholder={placeholder}
                          options={GATEWAY_868_MODE_OPTIONS}
                          disabled={task.hardware && hasConfigOption(task.hardware.configurationOverride, 'GATEWAY_868_MODE')}
                          onChange={this.handleGateway868ModeChange}
                        />
                      )}
                    </FormattedMessage>
                  </Form.Field>
                )}

                <Form.Field>
                  <label>
                    <FormattedMessage id="TaskCreationView.floor.label" defaultMessage="Floor" />
                  </label>
                  <FormattedMessage id="TaskCreationView.floor.placeholder" defaultMessage="Enter floor...">
                    {placeholder => (
                      <Input
                        fluid
                        type="number"
                        value={floor}
                        placeholder={placeholder}
                        disabled={task.hardware && task.hardware.floor != null}
                        onChange={this.handleFloorChange}
                      />
                    )}
                  </FormattedMessage>
                </Form.Field>

                {this.expectsSpecifier && (
                  <Form.Field>
                    <label>
                      <FormattedMessage id="TaskCreationView.specifier.label" defaultMessage="Specifier" />
                    </label>
                    <FormattedMessage id="TaskCreationView.specifier.placeholder" defaultMessage="Enter specifier...">
                      {placeholder => (
                        <Input
                          fluid
                          value={specifier}
                          placeholder={placeholder}
                          disabled={task.hardware && task.hardware.specifier != null}
                          onChange={this.handleSpecifierChange}
                        />
                      )}
                    </FormattedMessage>
                  </Form.Field>
                )}

                {this.expectsDoorNumber && (
                  <Form.Field>
                    <label>
                      <FormattedMessage id="TaskCreationView.doorNumber.label" defaultMessage="Door number" />
                    </label>
                    <FormattedMessage id="TaskCreationView.doorNumber.placeholder" defaultMessage="Enter door number...">
                      {placeholder => (
                        <Input
                          fluid
                          type="number"
                          value={doorNumber}
                          placeholder={placeholder}
                          disabled={task.hardware && task.hardware.doorNumber != null}
                          onChange={this.handleDoorNumberChange}
                        />
                      )}
                    </FormattedMessage>
                  </Form.Field>
                )}
              </>
            )}
          </Form>
        </View.Content>

        <View.Footer>
          <Button primary fluid disabled={!this.isValid} onClick={this.next}>
            <FormattedMessage id="TaskCreationView.next.label" defaultMessage="Next" />
          </Button>
        </View.Footer>
      </View>
    );
  }
}

TaskCreationView.defaultProps = {
  task: null,
};

TaskCreationView.propTypes = {
  job: JobType.isRequired,
  task: PropTypes.shape({
    jobId: PropTypes.number.isRequired,
    type: PropTypes.oneOf(Object.values(TASK_TYPES)),
    floor: PropTypes.number,
    specifier: PropTypes.string,
    hardwareProduct: PropTypes.oneOf(Object.values(HW_PRODUCTS)),
    hardwareVariant: PropTypes.oneOf(Object.values(HW_VARIANTS)),
    planned: PropTypes.bool,
    installerNotes: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    contextData: PropTypes.object,
  }),
};

const mapStateToProps = state => ({
  jobs: state.jobs,
  routine: state.routine,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  pauseJob,
  startTask,
  resetTask,
  dismissError,
}, dispatch);

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskCreationView));
