import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

export const fetchHardware = createRoutine('HARDWARE_FETCH');
export const registerHardware = createRoutine('HARDWARE_REGISTER');

export const fetchHybrid = createRoutine('HARDWARE_HYBRID_FETCH');

export const fetchGateway = createRoutine('HARDWARE_GATEWAY_FETCH');
export const fetchGateways = createRoutine('HARDWARE_GATEWAY_FETCH_LIST');
export const editGateway = createRoutine('HARDWARE_GATEWAY_EDIT');
export const removeGateway = createRoutine('HARDWARE_GATEWAY_REMOVE');
export const getGatewayWithStatus = createRoutine('HARDWARE_GATEWAY_GET_WITH_STATUS');
export const getSensorWithStatus = createRoutine('HARDWARE_SENSOR_GET_WITH_STATUS');
export const updateGatewayStatus = createRoutine('HARDWARE_GATEWAY_UPDATE_STATUS');
export const updateGatewayMetrics = createRoutine('HARDWARE_GATEWAY_UPDATE_METRICS');
export const updateGatewaySensors = createRoutine('HARDWARE_GATEWAY_UPDATE_SENSORS');
export const checkGatewayConnectivity = createRoutine('HARDWARE_GATEWAY_CHECK_CONNECTIVITY');
export const checkGatewayLinkPerformance = createRoutine('HARDWARE_GATEWAY_LINK_PERFORMANCE_CHECK');
export const getGatewayGsmInfo = createRoutine('HARDWARE_GATEWAY_GET_GSM_INFO');
export const pingGateway = createRoutine('HARDWARE_GATEWAY_PING');
export const rebootGateway = createRoutine('HARDWARE_GATEWAY_REBOOT');

export const pushGatewayConfiguration = createRoutine('HARDWARE_GATEWAY_PUSH_CONFIGURATION');
export const updateGatewayConfiguration = createRoutine('HARDWARE_GATEWAY_UPDATE_CONFIGURATION');
export const gatewayConfigurationUpdated = createAction('HARDWARE_GATEWAY_CONFIGURATION_UPDATED');

export const awaitGatewayConfigurationUpdate = createRoutine('HARDWARE_GATEWAY_AWAIT_CONFIG_UPDATE');

export const fetchSensor = createRoutine('HARDWARE_SENSOR_FETCH');
export const fetchSensors = createRoutine('HARDWARE_SENSOR_FETCH_LIST');
export const editSensor = createRoutine('HARDWARE_SENSOR_EDIT');
export const removeSensor = createRoutine('HARDWARE_SENSOR_REMOVE');
export const updateSensorStatus = createRoutine('HARDWARE_SENSOR_UPDATE_STATUS');
export const updateSensorMetrics = createRoutine('HARDWARE_SENSOR_UPDATE_METRICS');
export const updateSensorGateways = createRoutine('HARDWARE_SENSOR_UPDATE_GATEWAYS');
export const checkSensorConnectivity = createRoutine('HARDWARE_SENSOR_CHECK_CONNECTIVITY');
export const getSensorBatteryInfo = createRoutine('HARDWARE_SENSOR_GET_BATTERY_INFO');
export const initializeSensorRebatt = createRoutine('HARDWARE_SENSOR_INITIALIZE_REBATT');
export const finalizeSensorRebatt = createRoutine('HARDWARE_SENSOR_FINALIZE_REBATT');
export const grantSensorPermission = createRoutine('HARDWARE_GRANT_SENSOR_PERMISSION');
export const revokeSensorPermission = createRoutine('HARDWARE_REVOKE_SENSOR_PERMISSION');
export const getSensor868Info = createRoutine('HARDWARE_SENSOR_GET_868_INFO');
export const openSensor = createRoutine('HARDWARE_SENSOR_OPEN');
export const pingSensor = createRoutine('HARDWARE_SENSOR_PING');
export const rebootSensor = createRoutine('HARDWARE_SENSOR_REBOOT');

export const pushSensorConfiguration = createRoutine('HARDWARE_SENSOR_PUSH_CONFIGURATION');
export const updateSensorConfiguration = createRoutine('HARDWARE_SENSOR_UPDATE_CONFIGURATION');
export const sensorConfigurationUpdated = createAction('HARDWARE_SENSOR_CONFIGURATION_UPDATED');

export const awaitSensorKisUpdate = createRoutine('HARDWARE_SENSOR_AWAIT_KIS_UPDATE');
export const awaitSensorConfigurationUpdate = createRoutine('HARDWARE_SENSOR_AWAIT_CONFIG_UPDATE');

export const startKiSignalTest = createRoutine('HARDWARE_SENSOR_KI_SIGNAL_TEST_START');
export const resetKiSignalTest = createAction('HARDWARE_SENSOR_KI_SIGNAL_TEST_RESET');
export const kiSignalTestProgress = createAction('HARDWARE_SENSOR_KI_SIGNAL_TEST_PROGRESS');
export const kiSignalTestDoorOpened = createAction('HARDWARE_SENSOR_KI_SIGNAL_TEST_OPENED');
export const kiSignalTestRepositioned = createAction('HARDWARE_SENSOR_KI_SIGNAL_TEST_REPOSITIONED');

export const linkPerformanceCheckProgress = createAction('HARDWARE_LINK_PERFORMANCE_CHECK_PROGRESS');

export const stopRoutines = createAction('HARDWARE_STOP_ROUTINES');
export const dismissError = createAction('HARDWARE_ERRORS_DISMISS');
