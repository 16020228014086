import React from 'react';


export const RoutineContext = React.createContext();

export const withRoutine = WrappedComponent => props => (
  <RoutineContext.Consumer>
    {context => <WrappedComponent {...props} routine={context} />}
  </RoutineContext.Consumer>
);

export default RoutineContext;
