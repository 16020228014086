import React, { Component } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { ErrorType } from '~/common/types';


class AppVersionChecker extends Component {
  render() {
    const { error } = this.props;
    const newVersionAvailable = error && error.code === 'OUTDATED_APP';

    return (
      <Modal dimmer="inverted" open={newVersionAvailable}>
        <Modal.Header>
          <FormattedMessage
            id="AppVersionChecker.header"
            defaultMessage="Update available"
          />
        </Modal.Header>
        <Modal.Content>
          <FormattedMessage
            id="AppVersionChecker.content"
            defaultMessage="A new version of the smart installation app is available. Please restart the app to continue."
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary icon labelPosition="left" onClick={() => window.location.reload()}>
            <Icon name="refresh" />
            <FormattedMessage
              id="AppVersionChecker.restart.label"
              defaultMessage="Restart app"
            />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

AppVersionChecker.defaultProps = {
  error: null,
};

AppVersionChecker.propTypes = {
  error: ErrorType,
};

export default AppVersionChecker;
