import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Image,
  Button,
  Form,
  Ref,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { usePrevious } from '~/common/utils/hooks';
import { ErrorType, TaskImageType } from '~/common/types';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import ConfirmModal from '~/common/components/ConfirmModal';
import '~/app/styles/components/TaskImageModal.css';


const TaskImageModal = ({
  open, image, file, error, isSaving, isDeleting,
  onSave, onDelete, onClose,
}) => {
  const contentRef = useRef(null);

  const [objectUrl, setObjectUrl] = useState();
  const [description, setDescription] = useState((image && image.description) || '');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const prevOpen = usePrevious(open);
  useEffect(() => {
    if (prevOpen && !open) {
      setDescription((image && image.description) || '');
    }
  }, [prevOpen, open, image]);

  useEffect(() => {
    setDescription((image && image.description) || '');
  }, [image]);

  useEffect(() => {
    if (!file) return;

    const url = URL.createObjectURL(file);
    setObjectUrl(url);
    setDescription('');

    return () => {
      URL.revokeObjectURL(url);
      setObjectUrl(null);
    };
  }, [file]);

  const handleSave = () => onSave({
    file,
    image: {
      ...(image || {}),
      description: description || null,
    },
  });

  const handleDelete = () => {
    setShowDeleteModal(false);
    onDelete();
  };

  const loading = isSaving || isDeleting;

  return (
    <Modal
      size="large"
      dimmer="inverted"
      className="task-image-modal"
      open={open}
      onClose={onClose}
      closeOnEscape={!loading}
      closeOnDimmerClick={!loading}
    >
      <Modal.Header>
        {image ? (
          <FormattedMessage id="TaskImageModal.header.edit" defaultMessage="Edit task image" />
        ) : (
          <FormattedMessage id="TaskImageModal.header.create" defaultMessage="Create task image" />
        )}
      </Modal.Header>

      <Ref innerRef={contentRef}>
        <Modal.Content image>
          <Image wrapped src={image ? image.url : objectUrl} />
          <Modal.Description>
            {error && (
              <ApiErrorMessage
                error={error}
                header={(
                  <FormattedMessage
                    id="TaskImageModal.errors.header"
                    defaultMessage="An error occurred!"
                  />
                )}
              />
            )}
            <Form>
              <FormattedMessage id="TaskImageModal.description.label" defaultMessage="Description">
                {label => (
                  <Form.Input
                    fluid
                    label={label}
                    placeholder={label}
                    disabled={loading}
                    value={description}
                    onChange={(e, { value }) => setDescription(value)}
                  />
                )}
              </FormattedMessage>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Ref>

      <Modal.Actions>
        {image && (
          <Button
            color="red"
            icon="trash"
            floated="left"
            loading={isDeleting}
            disabled={loading && !isDeleting}
            onClick={() => setShowDeleteModal(true)}
          />
        )}
        <Button disabled={loading} onClick={onClose}>
          <FormattedMessage id="TaskImageModal.cancel.label" defaultMessage="Cancel" />
        </Button>
        <Button
          primary
          loading={isSaving}
          disabled={loading && !isSaving}
          onClick={handleSave}
        >
          <FormattedMessage id="TaskImageModal.save.label" defaultMessage="Save" />
        </Button>
      </Modal.Actions>

      <ConfirmModal
        size="mini"
        open={showDeleteModal}
        onConfirm={handleDelete}
        onClose={() => setShowDeleteModal(false)}
        mountNode={contentRef.current}
      >
        <ConfirmModal.Header>
          <FormattedMessage id="TaskImageModal.deleteModal.header" defaultMessage="Delete task image?" />
        </ConfirmModal.Header>
        <ConfirmModal.Content>
          <FormattedMessage
            id="TaskImageModal.deleteModal.content"
            defaultMessage="Do you really want to delete this task image?"
          />
        </ConfirmModal.Content>
      </ConfirmModal>
    </Modal>
  );
};

TaskImageModal.defaultProps = {
  open: false,
  file: null,
  image: null,
  error: null,
  isSaving: false,
  isDeleting: false,
  onSave: () => {},
  onDelete: () => {},
  onClose: () => {},
};

TaskImageModal.propTypes = {
  open: PropTypes.bool,
  file: PropTypes.oneOfType([
    PropTypes.instanceOf(Blob),
    PropTypes.instanceOf(File),
  ]),
  image: TaskImageType,
  error: ErrorType,
  isSaving: PropTypes.bool,
  isDeleting: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
};

export default TaskImageModal;
