import React from 'react';
import { List } from 'semantic-ui-react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';

import { TASK_STATUSES_DONE } from '~/common/constants';


const TASK_PROGRESS_MESSAGES = defineMessages({
  installation: {
    id: 'TaskProgressByType.installation',
    defaultMessage: '{done}/{total}\u00a0{total, plural, one {installation} other {installations}}',
  },
  fixing: {
    id: 'TaskProgressByType.fixing',
    defaultMessage: '{done}/{total}\u00a0{total, plural, one {fixing} other {fixings}}',
  },
});


const TaskProgressByType = ({ job: { tasksProgress } }) => {
  const intl = useIntl();

  if (!tasksProgress || Object.keys(tasksProgress).length === 0) {
    return (
      <FormattedMessage id="TaskProgressByType.noTasks" defaultMessage="No tasks" />
    );
  }

  return (
    <List>
      {Object.entries(tasksProgress).map(([taskType, counts]) => (
        <List.Item key={taskType}>
          {intl.formatMessage(TASK_PROGRESS_MESSAGES[taskType], {
            done: Object.entries(counts)
              .filter(([status]) => TASK_STATUSES_DONE.includes(status))
              .map(([, count]) => count)
              .reduce((a, b) => a + b, 0),
            total: Object.values(counts).reduce((a, b) => a + b, 0),
          })}
        </List.Item>
      ))}
    </List>
  );
};

export default TaskProgressByType;
