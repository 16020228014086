import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import JobListView from '~/app/views/jobs/JobListView';
import JobRoutineContainer from '~/app/containers/JobRoutineContainer';


class JobContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={JobListView} />
        <Route path={`${match.url}/:jobId`} component={JobRoutineContainer} />
        <Redirect to={match.url} />
      </Switch>
    );
  }
}

export default JobContainer;
