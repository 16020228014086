import { handleActions, combineActions } from 'redux-actions';

import {
  login,
  logout,
  requestPasswordReset,
  confirmPasswordReset,
  invalidateSession,
  dismissError,
} from '~/app/actions/AuthActions';


const initialState = {
  loggedIn: false,
  loggedOut: false,
  scopes: [],
  sessionKey: null,
  sessionExpired: false,
  loading: false,
  error: null,
  passwordReset: {
    username: null,
    requested: false,
    confirmed: false,
  },
};

const AuthReducer = handleActions({
  // Plain actions

  [invalidateSession]: state => ({
    ...state,
    loggedIn: false,
    loggedOut: false,
    scopes: [],
    sessionKey: null,
    sessionExpired: true,
    loading: false,
    error: null,
  }),

  [dismissError]: state => ({
    ...state,
    error: null,
  }),

  // Routine TRIGGER

  [combineActions(
    login.TRIGGER,
    logout.TRIGGER,
    requestPasswordReset.TRIGGER,
    confirmPasswordReset.TRIGGER,
  )]: state => ({
    ...state,
    loading: true,
    error: null,
    passwordReset: {
      username: null,
      requested: false,
      confirmed: false,
    },
  }),

  // Routine SUCCESS

  [login.SUCCESS]: (state, { payload }) => ({
    ...state,
    loggedIn: true,
    loggedOut: false,
    scopes: payload.scopes,
    sessionKey: payload.sessionKey,
    sessionExpired: false,
  }),

  [logout.SUCCESS]: state => ({
    ...state,
    loggedIn: false,
    loggedOut: true,
    scopes: [],
    sessionKey: null,
    sessionExpired: false,
  }),

  [requestPasswordReset.SUCCESS]: (state, { payload }) => ({
    ...state,
    passwordReset: {
      ...state.passwordReset,
      username: payload.username,
      requested: true,
    },
  }),

  [confirmPasswordReset.SUCCESS]: (state, { payload }) => ({
    ...state,
    passwordReset: {
      ...state.passwordReset,
      username: payload.username,
      confirmed: true,
    },
  }),

  // Routine FAILURE

  [combineActions(
    login.FAILURE,
    logout.FAILURE,
    requestPasswordReset.FAILURE,
    confirmPasswordReset.FAILURE,
  )]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),

  // Routine FULFILL

  [combineActions(
    login.FULFILL,
    logout.FULFILL,
    requestPasswordReset.FULFILL,
    confirmPasswordReset.FULFILL,
  )]: state => ({
    ...state,
    loading: false,
  }),
}, initialState);

export default AuthReducer;
