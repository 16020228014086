import { handleActions, combineActions } from 'redux-actions';
import _ from 'lodash';

import {
  dismissError,
  fetchJobs, refreshJob, refreshJobs, saveJob, handoverJob, resetJob,
  fetchJobTasks, refreshTask, createTask, saveTask,
  migrateTaskHardware, handoverTask, resetTask,
  createTaskImage, saveTaskImage, deleteTaskImage,
  fetchFixingActions, addTaskFixingAction, saveTaskFixingAction,
  grantSensorPermissions, revokeSensorPermissions,
} from '~/app/actions/JobsActions';


const initialState = {
  items: {
    byId: {},
    pages: {},
    total: null,
    query: {
      search: '',
      sortBy: null,
      orderBy: null,
      statusFilter: null,
      geolocation: null,
    },
  },
  fixingActions: [],
  loading: false,
  error: null,
  jobSaved: false,
  taskSaved: false,
};

const itemsFromJobsPayload = (state, payload, clearJobs) => {
  const {
    jobs, total, pageNumber, query,
  } = payload;
  let pages = {
    [pageNumber]: jobs.map(j => j.id),
  };
  let byId = Object.assign({}, ...jobs.map(j => ({ [j.id]: j })));

  if (!clearJobs) {
    pages = {
      ...state.items.pages,
      ...pages,
    };
    byId = {
      ...state.items.byId,
      ...byId,
    };
  }

  return {
    total, pages, byId, query,
  };
};

const updateJob = (state, jobId, updater) => {
  if (typeof updater !== 'function') {
    const value = updater;
    updater = () => value;
  }

  return {
    ...state,
    items: {
      ...state.items,
      byId: {
        ...state.items.byId,
        [jobId]: updater(state.items.byId[jobId]),
      },
    },
  };
};

const updateTask = (state, jobId, taskId, updater) => {
  if (typeof updater !== 'function') {
    const value = updater;
    updater = () => value;
  }

  return updateJob(
    state,
    jobId,
    j => ({
      ...j,
      tasks: j.tasks.map(
        t => (t.id === taskId ? updater(t) : t),
      ),
    }),
  );
};

const JobsReducer = handleActions({
  // Plain Actions

  [dismissError]: state => ({
    ...state,
    error: null,
  }),

  // Routine TRIGGER

  [combineActions(
    fetchJobs.TRIGGER,
    refreshJob.TRIGGER,
    refreshJobs.TRIGGER,
    handoverJob.TRIGGER,
    resetJob.TRIGGER,
    fetchJobTasks.TRIGGER,
    refreshTask.TRIGGER,
    createTask.TRIGGER,
    migrateTaskHardware.TRIGGER,
    handoverTask.TRIGGER,
    resetTask.TRIGGER,
    createTaskImage.TRIGGER,
    saveTaskImage.TRIGGER,
    deleteTaskImage.TRIGGER,
    fetchFixingActions.TRIGGER,
    addTaskFixingAction.TRIGGER,
    saveTaskFixingAction.TRIGGER,
    grantSensorPermissions.TRIGGER,
    revokeSensorPermissions.TRIGGER,
  )]: state => ({
    ...state,
    error: null,
    loading: true,
  }),

  [saveJob.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    jobSaved: false,
  }),

  [saveTask.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    taskSaved: false,
  }),

  // Routine SUCCESS

  [fetchJobs.SUCCESS]: (state, { payload }) => ({
    ...state,
    items: itemsFromJobsPayload(state, payload, !_.isEqual(state.items.query, payload.query)),
  }),

  [refreshJobs.SUCCESS]: (state, { payload }) => ({
    ...state,
    items: itemsFromJobsPayload(state, payload, true),
  }),

  [fetchJobTasks.SUCCESS]: (state, { payload }) => updateJob(
    state,
    payload.job.id,
    j => ({ ...j, tasks: payload.items }),
  ),

  [combineActions(
    refreshJob.SUCCESS,
    saveJob.SUCCESS,
    resetJob.SUCCESS,
  )]: (state, { payload }) => ({
    ...updateJob(
      state,
      payload.job.id,
      j => ({ ...j, ...payload.job }),
    ),
    jobSaved: true,
  }),

  [createTask.SUCCESS]: (state, { payload }) => updateJob(
    state,
    payload.task.jobId,
    j => ({
      ...j,
      tasks: [...j.tasks, payload.task],
    }),
  ),

  [refreshTask.SUCCESS]: (state, { payload }) => updateTask(
    state,
    payload.task.jobId,
    payload.task.id,
    payload.task,
  ),

  [migrateTaskHardware.SUCCESS]: (state, { payload }) => updateJob(
    state,
    payload.task.jobId,
    j => ({
      ...j,
      tasks: [
        ...j.tasks.map(
          t => (t.id === payload.task.id ? payload.task : t),
        ),
        ...(payload.newTasks || []),
      ],
    }),
  ),

  [saveTask.SUCCESS]: (state, { payload }) => ({
    ...updateTask(
      state,
      payload.task.jobId,
      payload.task.id,
      payload.task,
    ),
    taskSaved: true,
  }),

  [createTaskImage.SUCCESS]: (state, { payload }) => updateTask(
    state,
    payload.task.jobId,
    payload.task.id,
    t => ({
      ...t,
      images: [...t.images, payload.image],
    }),
  ),

  [saveTaskImage.SUCCESS]: (state, { payload }) => updateTask(
    state,
    payload.task.jobId,
    payload.task.id,
    t => ({
      ...t,
      images: t.images.map(i => (i.id === payload.image.id ? payload.image : i)),
    }),
  ),

  [deleteTaskImage.SUCCESS]: (state, { payload }) => updateTask(
    state,
    payload.task.jobId,
    payload.task.id,
    t => ({
      ...t,
      images: t.images.filter(i => i.id !== payload.image.id),
    }),
  ),

  [addTaskFixingAction.SUCCESS]: (state, { payload }) => ({
    ...updateTask(
      state,
      payload.task.jobId,
      payload.task.id,
      t => ({
        ...t,
        fixingActions: [...t.fixingActions, payload.action],
      }),
    ),
    fixingActions: state.fixingActions.some(a => a.id === payload.action.id)
      ? state.fixingActions
      : [...state.fixingActions, _.omit(payload.action, ['checked'])],
  }),

  [saveTaskFixingAction.SUCCESS]: (state, { payload }) => updateTask(
    state,
    payload.task.jobId,
    payload.task.id,
    t => ({
      ...t,
      fixingActions: t.fixingActions.map(a => (a.id === payload.action.id ? payload.action : a)),
    }),
  ),

  [fetchFixingActions.SUCCESS]: (state, { payload }) => ({
    ...state,
    fixingActions: payload.actions,
  }),

  [resetTask.SUCCESS]: (state, { payload }) => updateJob(
    state,
    payload.task.jobId,
    j => ({
      ...j,
      tasks: (payload.deleted
        ? j.tasks.filter(t => (t.id !== payload.task.id))
        : j.tasks.map(t => (t.id === payload.task.id ? payload.task : t))
      ),
    }),
  ),

  [grantSensorPermissions.SUCCESS]: (state, { payload }) => payload.tasks.reduce(
    (newState, task) => updateTask(
      newState,
      task.jobId,
      task.id,
      t => ({ ...t, hardware: { ...t.hardware, hasPermission: true } }),
    ),
    state,
  ),

  [revokeSensorPermissions.SUCCESS]: (state, { payload }) => updateJob(
    state,
    payload.job.id,
    (j) => {
      const taskIds = (payload.tasks || j.tasks)
        .filter(t => t.hardware.hasPermission)
        .map(t => t.id);

      return {
        ...j,
        tasks: j.tasks.map(t => (taskIds.includes(t.id)
          ? ({ ...t, hardware: { ...t.hardware, hasPermission: false } })
          : t)),
      };
    },
  ),

  // Routine FAILURE

  [combineActions(
    refreshJob.FAILURE,
    saveJob.FAILURE,
    handoverJob.FAILURE,
    resetJob.FAILURE,
    fetchJobTasks.FAILURE,
    refreshTask.FAILURE,
    createTask.FAILURE,
    saveTask.FAILURE,
    migrateTaskHardware.FAILURE,
    handoverTask.FAILURE,
    resetTask.FAILURE,
    createTaskImage.FAILURE,
    saveTaskImage.FAILURE,
    deleteTaskImage.FAILURE,
    fetchFixingActions.FAILURE,
    addTaskFixingAction.FAILURE,
    saveTaskFixingAction.FAILURE,
    grantSensorPermissions.FAILURE,
    revokeSensorPermissions.FAILURE,
  )]: (state, { payload }) => ({
    ...state,
    error: payload.error,
  }),

  [fetchJobs.FAILURE]: (state, { payload }) => {
    const { error } = payload;
    const triggerPayload = error.trigger.payload;
    const items = _.isEqual(state.items.query, triggerPayload.query)
      ? state.items
      : {
        ...state.items,
        byId: {},
        pages: {},
        total: null,
        query: triggerPayload.query,
      };

    return {
      ...state,
      items,
      error,
    };
  },

  [refreshJobs.FAILURE]: (state, { payload }) => {
    const { error } = payload;
    const isGeolocationError = payload.error.code
      && payload.error.code.startsWith('GEOLOCATION');

    return {
      ...state,
      error,
      items: {
        ...state.items,
        query: {
          ...state.items.query,
          geolocation: isGeolocationError ? null : state.items.query.geolocation,
        },
      },
    };
  },

  // Routine FULFILL

  [combineActions(
    fetchJobs.FULFILL,
    refreshJob.FULFILL,
    refreshJobs.FULFILL,
    saveJob.FULFILL,
    handoverJob.FULFILL,
    resetJob.FULFILL,
    fetchJobTasks.FULFILL,
    refreshTask.FULFILL,
    createTask.FULFILL,
    saveTask.FULFILL,
    migrateTaskHardware.FULFILL,
    handoverTask.FULFILL,
    resetTask.FULFILL,
    createTaskImage.FULFILL,
    saveTaskImage.FULFILL,
    deleteTaskImage.FULFILL,
    fetchFixingActions.FULFILL,
    addTaskFixingAction.FULFILL,
    saveTaskFixingAction.FULFILL,
    grantSensorPermissions.FULFILL,
    revokeSensorPermissions.FULFILL,
  )]: state => ({
    ...state,
    loading: false,
  }),
}, initialState);

export default JobsReducer;
