import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Header, Segment, List, Button,
} from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ApiErrorType, IntlMessageType } from '~/common/types';
import HW_PRODUCT_MESSAGES from '~/common/locales/HardwareProducts';
import attachCompounds from '~/common/utils/attachCompounds';
import CheckList from '~/app/components/CheckList';
import ErrorMessage from '~/common/components/ErrorMessage';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import View from '~/app/views/View';


class StepView extends Component {
  renderStepList = () => {
    const { error } = this.props;
    const { currentStep, steps, stepDescriptions } = this.props.routine;

    return (
      <Segment vertical>
        <Header size="tiny">
          <FormattedMessage
            id="StepView.manufacturing.steps.header"
            defaultMessage="Manufacturing steps"
          />
        </Header>
        <CheckList>
          {steps.filter(step => step in stepDescriptions).map(step => (
            <CheckList.Item
              key={step}
              loading={!error && step === currentStep}
              success={steps.indexOf(step) < steps.indexOf(currentStep)}
              error={error && step === currentStep}
            >
              {this.props.intl.formatMessage(stepDescriptions[step])}
            </CheckList.Item>
          ))}
        </CheckList>
      </Segment>
    );
  }

  renderInstructions = () => {
    const { instructions } = this.props;

    if (instructions) {
      return (
        <Segment vertical>
          <Header size="tiny">
            <FormattedMessage
              id="StepView.manufacturing.instructions.header"
              defaultMessage="Instructions"
            />
          </Header>
          {instructions}
        </Segment>
      );
    }
  }

  render() {
    const {
      loading, error, errorHeader, errorMessages,
      fixingOptionMessages, fixingActions,
    } = this.props;
    const { hardwareUuid, hardwareProduct } = this.props.routine;
    const hardwareName = this.props.intl.formatMessage(HW_PRODUCT_MESSAGES[hardwareProduct]);

    return (
      <View loading={loading} onGoBack={this.props.routine.requestCancel}>
        <View.Header
          primaryText={(
            <FormattedMessage
              id="StepView.manufacturing.header.primary"
              defaultMessage="Manufacture {hardware}"
              values={{ hardware: hardwareName }}
            />
          )}
          secondaryText={hardwareUuid}
          action={this.props.routine.requestCancel}
          actionIcon="cancel"
        />

        {/* Default View */}
        {!error && (
          this.props.children({
            stepList: this.renderStepList(),
            instructions: this.renderInstructions(),
          })
        )}

        {/* Error View */}
        {error && (
          <>
            <View.Content>
              { this.renderStepList() }

              <Segment vertical>
                {error.code && (
                  <ApiErrorMessage
                    error={error}
                    header={errorHeader}
                    messages={errorMessages}
                  />
                )}
                {!error.code && (
                  <ErrorMessage
                    header={errorHeader}
                    message={error.message || error}
                  />
                )}
                {Object.keys(fixingOptionMessages).length > 0 && (
                  <>
                    <Header size="tiny" className="troubleshooting-header">
                      <FormattedMessage
                        id="StepView.manufacturing.troubleshooting.header"
                        defaultMessage="Troubleshooting guide"
                      />
                    </Header>
                    <p>
                      <FormattedMessage
                        id="StepView.manufacturing.troubleshooting.instructions"
                        defaultMessage="Check out the possible fixing options below and run the test again."
                      />
                    </p>
                    <List ordered>
                      {Object.entries(fixingOptionMessages).map(([name, message]) => (
                        <List.Item key={name}>
                          {this.props.intl.formatMessage(message)}
                        </List.Item>
                      ))}
                    </List>
                  </>
                )}
              </Segment>
            </View.Content>

            <View.Footer>
              {fixingActions}
              {this.props.retry && (
                <Button fluid onClick={this.props.retry}>
                  <FormattedMessage id="StepView.manufacturing.tryAgain.label" defaultMessage="Try again" />
                </Button>
              )}
            </View.Footer>
          </>
        )}
      </View>
    );
  }
}

StepView.defaultProps = {
  loading: false,
  error: null,
  errorHeader: (
    <FormattedMessage
      id="StepView.manufacturing.errors.header"
      defaultMessage="Manufacturing failed"
    />
  ),
  errorMessages: {},
  fixingOptionMessages: {},
  fixingActions: null,
  retry: null,
  children: ({ stepList, instructions }) => (
    <View.Content>
      {stepList}
      {instructions}
    </View.Content>
  ),
};

StepView.propTypes = {
  routine: PropTypes.shape({
    currentStep: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
    stepDescriptions: PropTypes.objectOf(IntlMessageType).isRequired,
    hardwareUuid: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([
    ApiErrorType,
    PropTypes.node,
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  ]),
  errorHeader: PropTypes.node,
  errorMessages: PropTypes.objectOf(IntlMessageType),
  fixingOptionMessages: PropTypes.objectOf(IntlMessageType),
  fixingActions: PropTypes.node,
  retry: PropTypes.func,
  children: PropTypes.func,
};

export default attachCompounds(injectIntl(StepView), {
  Content: View.Content,
  Footer: View.Footer,
});
