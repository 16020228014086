import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RoutineContext from '~/app/contexts/RoutineContext';
import View from '~/app/views/View';


class RoutineView extends Component {
  componentDidMount() {
    this.checkForUnknownStep();
  }

  componentDidUpdate() {
    this.checkForUnknownStep();
  }

  get steps() {
    const { children } = this.props;
    return React.Children.map(children, child => ({
      id: child.props.id,
      disabled: child.props.disabled,
      skipForwards: child.props.skipForwards,
      skipBackwards: child.props.skipBackwards,
    })).filter(child => !child.disabled);
  }

  get routineContext() {
    const { stepId, context } = this.props;
    return {
      ...context,
      previous: this.previous,
      next: this.next,
      currentStep: stepId,
      steps: this.steps.map(step => step.id),
    };
  }

  previous = () => this.go(-1);

  next = () => this.go(1);

  go = (n) => {
    if (this.props.navigating) return false;

    const { stepId } = this.props;
    const index = this.steps.findIndex(s => s.id === stepId);
    let newStep;
    if (n > 0) {
      ([newStep] = this.steps.slice(index + 1).filter(s => !s.skipForwards));
    } else {
      ([newStep] = this.steps.slice(0, index).filter(s => !s.skipBackwards).reverse());
    }

    if (newStep) {
      this.props.navigate(newStep.id);
    } else {
      const callback = n < 0 ? this.props.onBack : this.props.onNext;
      callback(this.routineContext);
    }

    return true;
  }

  checkForUnknownStep = () => {
    if (this.props.navigating) return;

    if (!this.steps.some(({ id }) => id === this.props.stepId)) {
      this.props.navigate(this.steps[0].id);
    }
  }

  render() {
    const { stepId, children } = this.props;
    const step = React.Children.toArray(children).find(child => child.props.id === stepId);

    return (
      <RoutineContext.Provider value={this.routineContext}>
        {step || (
          <View loading />
        )}
      </RoutineContext.Provider>
    );
  }
}

RoutineView.defaultProps = {
  stepId: null,
  context: {},
  onBack: () => {},
  onNext: () => {},
  navigating: false,
  children: null,
};

RoutineView.propTypes = {
  stepId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  context: PropTypes.object,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  navigating: PropTypes.bool,
  children: PropTypes.node,
};

export default RoutineView;
