import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { saveTask, dismissError } from '~/app/actions/JobsActions';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import { withRoutine } from '~/app/contexts/RoutineContext';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';
import StepView from './StepView';
import UUIDScanner from '~/app/components/UUIDScanner';
import UUIDInput from '~/app/components/UUIDInput';


class UUIDInputStep extends Component {
  state = {
    uuid: '',
    manualInput: false,
    next: false,
  }

  handleScan = (uuid) => {
    this.handleChange(uuid);
    setTimeout(this.next, 2000);
  };

  handleScanError = (err) => {
    console.warn(err);
    this.setState({ manualInput: true });
  }

  handleChange = (uuid) => {
    this.setState({ uuid });
  }

  next = () => {
    const { task } = this.props.routine;
    const { uuid } = this.state;

    this.props.saveTask({
      id: task.id,
      hardware: { uuid },
    });
    this.setState({ next: true });
  }

  dismissError = () => {
    const { manualInput } = this.state;
    if (!manualInput) {
      this.setState({
        uuid: '',
        next: false,
      });
    }
    this.props.dismissError();
  }

  render() {
    const { job, task } = this.props.routine;
    const { loading, taskSaved, error } = this.props.jobs;
    const { uuid, manualInput, next } = this.state;

    if (next && taskSaved) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        loading={loading}
        header={<JobProgressHeader job={job} task={task} stage="installation" />}
      >
        <StepView.Content>
          <JobSaveErrorModal
            error={next ? error : null}
            onDismiss={this.dismissError}
          />
          {!manualInput && (
            <>
              <p>
                <FormattedMessage
                  id="UUIDInputStep.jobs.scan.instructions"
                  defaultMessage="Now point your camera towards the QR code of this device to scan it's UUID."
                />
              </p>
              <UUIDScanner className="uuid-scanner" value={uuid} onScan={this.handleScan} onError={this.handleScanError} />
            </>
          )}
          {manualInput && (
            <>
              <p>
                <FormattedMessage
                  id="UUIDInputStep.jobs.manual.instructions"
                  defaultMessage="Now enter the UUID of this device."
                />
              </p>
              <UUIDInput autoFocus value={uuid} onChange={this.handleChange} onSubmit={this.next} />
            </>
          )}
        </StepView.Content>

        <StepView.Footer>
          <Button fluid color="red" onClick={this.props.requestSkipTask}>
            <FormattedMessage id="UUIDInputStep.jobs.skipTask.label" defaultMessage="Skip Task" />
          </Button>
          {manualInput && (
            <Button primary fluid disabled={!uuid} onClick={this.next}>
              <FormattedMessage id="UUIDInputStep.jobs.manual.confirm.label" defaultMessage="OK" />
            </Button>
          )}
          {!manualInput && !uuid && (
            <Button fluid onClick={() => this.setState({ manualInput: true })}>
              <FormattedMessage id="UUIDInputStep.jobs.manual.activate.label" defaultMessage="Enter UUID manually" />
            </Button>
          )}
        </StepView.Footer>
      </StepView>
    );
  }
}

UUIDInputStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  jobs: state.jobs,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveTask,
  dismissError,
  requestSkipTask,
}, dispatch);

export default withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UUIDInputStep)));
