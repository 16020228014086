import React from 'react';

import '~/common/styles/components/ButtonGroup.css';


const ButtonGroup = ({ children }) => (
  <div className="button-group">
    {children}
  </div>
);

export default ButtonGroup;
