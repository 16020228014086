import { takeEvery, select } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/src/constants';

import { changeLocale } from '~/app/actions/LocaleActions';
import { registerSagas, SagaError } from '~/app/sagas';
import { getAvailableLocales } from '~/app/selectors/LocaleSelectors';
import * as api from '~/common/services/ApiService';


function* changeLocaleSaga(locale) {
  const availableLocales = yield select(getAvailableLocales);

  if (availableLocales.includes(locale)) {
    api.setAcceptedLanguage(locale);
    return { locale };
  }

  throw new SagaError(`Invalid language selected: ${locale}`, 'INVALID_LANGUAGE');
}

function* handleRehydrate() {
  const locale = yield select(state => state.locale.currentLocale);
  api.setAcceptedLanguage(locale);
}

export default [
  ...registerSagas([
    [changeLocale, changeLocaleSaga],
  ]),
  takeEvery(
    REHYDRATE,
    handleRehydrate,
  ),
];
