import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { JobType } from '~/common/types';
import Placeholder from '~/common/components/Placeholder';
import FormattedAddress from '~/common/components/FormattedAddress';
import MapButton from '~/app/components/MapButton';
import TaskSummary from '~/app/components/TaskSummary/TaskSummary';
import FormattedDateTime from '~/common/components/FormattedDateTime';
import FormattedText from '~/common/components/FormattedText';
import JOB_PRIORITY_MESSAGES from '~/common/locales/JobPriorities';
import CallButton from '~/app/components/CallButton';
import '~/app/styles/components/JobDetails.css';


class JobDetails extends Component {
  renderTaskDependentNode = (node) => {
    const { job, loading } = this.props;
    if (!job.tasks || job.tasks.length === 0) {
      if (loading) {
        return (
          <span className="loading-message">
            <FormattedMessage id="JobDetails.loading" defaultMessage="Loading..." />
          </span>
        );
      }
      return (
        <span className="error-message">
          <FormattedMessage id="JobDetails.errors.noTasks" defaultMessage="Tasks could not be loaded" />
        </span>
      );
    }
    return node;
  }

  render() {
    const { job, intl } = this.props;
    return (
      <div className="job-details-container">
        <Grid>
          <Grid.Row className="details-row">
            <Grid.Column width={4} className="label-column">
              <FormattedMessage id="JobDetails.appointment.label" defaultMessage="Appointment" />
            </Grid.Column>
            <Grid.Column width={12} className="value-column">
              <Placeholder show={!job.appointment}>
                <FormattedDateTime value={job.appointment} />
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={4} className="label-column">
              <FormattedMessage id="JobDetails.priority.label" defaultMessage="Priority" />
            </Grid.Column>
            <Grid.Column width={12} className="value-column">
              {intl.formatMessage(JOB_PRIORITY_MESSAGES[job.priority])}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={4} className="label-column">
              <FormattedMessage id="JobDetails.organization.label" defaultMessage="Customer" />
            </Grid.Column>
            <Grid.Column width={12} className="value-column">
              <Placeholder show={!job.organization}>
                {job.organization && job.organization.name}
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={4} className="label-column">
              <FormattedMessage id="JobDetails.address.label" defaultMessage="Address" />
            </Grid.Column>
            <Grid.Column width={12} className="value-column">
              <FormattedAddress breakLines address={job.address} />
              <MapButton address={job.address} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={4} className="label-column">
              <FormattedMessage id="JobDetails.coordination.label" defaultMessage="Coordination" />
            </Grid.Column>
            <Grid.Column width={12} className="value-column">
              <Placeholder show={!job.coordinator}>
                {job.coordinator?.username}
                {job.coordinator?.telephone && (
                  <CallButton telephone={job.coordinator.telephone}>
                    <FormattedMessage id="JobDetails.coordinator.telephone.label" defaultMessage="Call Coordinator" />
                  </CallButton>
                )}
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={4} className="label-column">
              <FormattedMessage id="JobDetails.contact.label" defaultMessage="Contact" />
            </Grid.Column>
            <Grid.Column width={12} className="value-column">
              <Placeholder show={!job.contactName}>
                {job.contactName}
                {job.contactPhone && (
                  <CallButton telephone={job.contactPhone}>
                    <FormattedMessage id="JobDetails.contact.phone.label" defaultMessage="Call contact" />
                  </CallButton>
                )}
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={4} className="label-column">
              <FormattedMessage id="JobDetails.tasks.label" defaultMessage="Tasks" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} className="value-column">
              {this.renderTaskDependentNode(<TaskSummary tasks={job.tasks} />)}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={4} className="label-column">
              <FormattedMessage id="JobDetails.notes.label" defaultMessage="Notes" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} className="value-column">
              <Placeholder show={!job.notes}>
                <FormattedText text={job.notes} />
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

JobDetails.defaultProps = {
  loading: false,
};

JobDetails.propTypes = {
  job: JobType.isRequired,
  loading: PropTypes.bool,
};

export default injectIntl(JobDetails);
