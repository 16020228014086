const setDeep = (object, path, value) => {
  if (!Array.isArray(object)) {
    path = path.split('.');
  }

  return path.reduce((acc, key, level) => {
    if (level === path.length - 1) {
      acc[key] = value;
      return object;
    }

    if (typeof acc[key] === 'undefined') {
      acc[key] = {};
    }

    return acc[key];
  }, object);
};

export default setDeep;
