import { defineMessages } from 'react-intl';


export default defineMessages({
  REGULAR: {
    id: 'HardwareVariants.regular',
    defaultMessage: 'Regular',
  },
  APTERYX: {
    id: 'HardwareVariants.apteryx',
    defaultMessage: 'Apteryx',
  },
  MQTT: {
    id: 'HardwareVariants.mqtt',
    defaultMessage: 'MQTT',
  },
  NDS: {
    id: 'HardwareVariants.nds',
    defaultMessage: 'NDS',
  },
  OUTDOOR: {
    id: 'HardwareVariants.outdoor',
    defaultMessage: 'Outdoor',
  },
  COMPACT: {
    id: 'HardwareVariants.compact',
    defaultMessage: 'Compact',
  },
  COMPACT_OUTDOOR: {
    id: 'HardwareVariants.compactOutdoor',
    defaultMessage: 'Compact Outdoor',
  },
  PREMIUM: {
    id: 'HardwareVariants.premium',
    defaultMessage: 'Premium',
  },
  COMFORT: {
    id: 'HardwareVariants.comfort',
    defaultMessage: 'Comfort',
  },
  STEEL: {
    id: 'HardwareVariants.steel',
    defaultMessage: 'Steel',
  },
  PORTABLE: {
    id: 'HardwareVariants.portable',
    defaultMessage: 'Portable',
  },
});
