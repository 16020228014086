import { defineMessages } from 'react-intl';
import { HW_PRODUCTS } from '~/common/constants';


export default defineMessages({
  [HW_PRODUCTS.GATEWAY]: {
    id: 'HardwareProducts.gateway',
    defaultMessage: 'Gateway',
  },
  [HW_PRODUCTS.SMART_ENTRY]: {
    id: 'HardwareProducts.smartEntry',
    defaultMessage: 'Smart Entry',
  },
  [HW_PRODUCTS.SMART_ENTRY_HYBRID]: {
    id: 'HardwareProducts.smartEntryHybrid',
    defaultMessage: 'Smart Entry Hybrid',
  },
  [HW_PRODUCTS.SMART_DOOR]: {
    id: 'HardwareProducts.smartDoor',
    defaultMessage: 'SmartDOOR',
  },
  [HW_PRODUCTS.KNOB]: {
    id: 'HardwareProducts.knob',
    defaultMessage: 'Knob',
  },
  [HW_PRODUCTS.HANDLE]: {
    id: 'HardwareProducts.handle',
    defaultMessage: 'Handle',
  },
  [HW_PRODUCTS.SAFE]: {
    id: 'HardwareProducts.safe',
    defaultMessage: 'Safe',
  },
  [HW_PRODUCTS.SAFE_TWO]: {
    id: 'HardwareProducts.safe_two',
    defaultMessage: 'Safe 2',
  },
});
