import React from 'react';
import PropTypes from 'prop-types';
import { Header, Icon } from 'semantic-ui-react';
import { useIntl, FormattedMessage } from 'react-intl';

import { TASK_TYPES } from '~/common/constants';
import { JobType, TaskType } from '~/common/types';
import { formatProductVariant } from '~/common/utils/productVariants';
import { hasConfigOption, getConfigOption } from '~/common/utils/hardwareConfiguration';
import HW_PRODUCTS from '~/common/locales/HardwareProducts';
import TASK_TYPE_MESSAGES from '~/common/locales/TaskTypes';
import CONFIG_OPTION_MESSAGES from '~/common/locales/HardwareConfigOptions';
import FormattedFloor from '~/common/components/FormattedFloor';
import FormattedAddress from '~/common/components/FormattedAddress';
import InlineList from '~/common/components/InlineList';
import TabularList from '~/common/components/TabularList';
import '~/app/styles/components/TaskList.css';


const HardwareDescription = ({ job, hardware }) => {
  const intl = useIntl();

  const {
    uuid, hardwareProduct, hardwareVariant,
    specifier, floor, doorNumber,
    installationLocation, configurationOverride,
  } = hardware;

  const enableApteryxMode = getConfigOption(configurationOverride, 'GATEWAY_868_MODE');

  return (
    <div>
      <div className="section">
        <Header size="tiny">
          <InlineList>
            {intl.formatMessage(HW_PRODUCTS[hardwareProduct])}
            {formatProductVariant(intl, hardwareProduct, hardwareVariant)}
          </InlineList>
        </Header>

        {hasConfigOption(configurationOverride, 'GATEWAY_868_MODE') && (
          <FormattedMessage
            id="TaskList.gateway868Mode"
            defaultMessage="Configuration: {configuration}"
            values={{
              configuration: intl.formatMessage(
                CONFIG_OPTION_MESSAGES.GATEWAY_868_MODE[enableApteryxMode],
              ),
            }}
          />
        )}
      </div>

      <div className="section">
        {specifier}
        <br />
        <InlineList>
          <FormattedFloor value={floor} />
          {doorNumber != null && (
            <FormattedMessage
              id="TaskList.doorNumber"
              defaultMessage=" Door: {doorNumber}"
              values={{ doorNumber }}
            />
          )}
          {installationLocation}
        </InlineList>
      </div>

      {hardware.address && hardware.address.id !== job.address.id && (
        <div className="section">
          <FormattedAddress breakLines address={hardware.address} />
          <span className="address-warning-icon">
            &nbsp;
            <Icon name="warning sign" color="grey" />
          </span>
        </div>
      )}

      {uuid && (
        <div className="section">{uuid}</div>
      )}
    </div>
  );
};


const TaskList = ({
  job, tasks, onClick, ...remainingProps
}) => {
  const intl = useIntl();

  return (
    <TabularList arrow className="task-list" {...remainingProps}>
      {tasks.map(task => (
        <TabularList.Row key={task.id} onClick={() => onClick(task)}>
          <TabularList.Cell>
            <Header size="tiny">
              {intl.formatMessage(TASK_TYPE_MESSAGES[task.type])}
            </Header>
            {task.type === TASK_TYPES.FIXING && (
              task.problem.name
            )}
          </TabularList.Cell>
          <TabularList.Cell>
            <HardwareDescription job={job} hardware={task.hardware} />
          </TabularList.Cell>
        </TabularList.Row>
      ))}
    </TabularList>
  );
};

TaskList.defaultProps = {
  tasks: [],
  onClick: () => {},
};

TaskList.propTypes = {
  ...TabularList.propTypes,
  job: JobType.isRequired,
  tasks: PropTypes.arrayOf(TaskType),
  onClick: PropTypes.func,
};

export default TaskList;
