import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SelectDeviceView from '~/app/views/device-check/SelectDeviceView';
import NearbyDevicesView from '~/app/views/device-check/NearbyDevicesView';
import DeviceCheckResultView from '~/app/views/device-check/DeviceCheckResultView';


class DeviceCheckContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={SelectDeviceView} />
        <Route path={`${match.url}/nearby-devices`} exact component={NearbyDevicesView} />
        <Route path={`${match.url}/:deviceType(gateway)/:gatewayId(\\d+)`} component={DeviceCheckResultView} />
        <Route path={`${match.url}/:deviceType(sensor)/:sensorId(\\d+)`} component={DeviceCheckResultView} />
        <Route path={`${match.url}/:deviceType(hybrid)/sensor/:sensorId(\\d+)`} component={DeviceCheckResultView} />
        <Route path={`${match.url}/:deviceType(hybrid)/gateway/:gatewayId(\\d+)`} component={DeviceCheckResultView} />
        <Redirect to={match.url} />
      </Switch>
    );
  }
}

export default DeviceCheckContainer;
