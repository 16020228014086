import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ManufacturingStartView from '~/app/views/manufacturing/ManufacturingStartView';
import SmartDoorManufacturingView from '~/app/views/manufacturing/routines/SmartDoorManufacturingView';


class ManufacturingContainer extends Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.url}/`} exact component={ManufacturingStartView} />
        <Route path={`${match.url}/smart-door/:hardwareUuid`} exact component={SmartDoorManufacturingView} />
        <Redirect to={match.url} />
      </Switch>
    );
  }
}

export default ManufacturingContainer;
