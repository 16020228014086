import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import { ErrorType, FixingActionType } from '~/common/types';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import ComboBox from '~/common/components/ComboBox';


const ERROR_MESSAGES = defineMessages({
  EXISTING_ACTION: {
    id: 'AddFixingActionModal.errors.existingAction',
    defaultMessage: 'A step with this name is already associated with the task.',
  },
});


const AddFixingActionModal = ({
  open, actions, loading, error, onSave, onClose,
}) => {
  const [action, setAction] = useState();

  useEffect(() => {
    if (!open) {
      setAction(null);
    }
  }, [open]);

  const handleChange = (name) => {
    const selectedAction = actions.find(a => a.name === name) || { name };
    setAction(selectedAction);
  };

  const options = _.orderBy(
    actions.map(({ name }) => ({ value: name, text: name })),
    x => x.text,
  );

  let value = action && action.name;
  if (value == null) {
    // Preselect create new option if
    // there are no actions to select from
    value = actions.length === 0 ? '' : null;
  }

  return (
    <Modal
      size="mini"
      dimmer="inverted"
      open={open}
      onClose={onClose}
      closeOnEscape={!loading}
      closeOnDimmerClick={!loading}
    >
      <Modal.Header>
        <FormattedMessage id="AddFixingActionModal.header.create" defaultMessage="Add troubleshooting step" />
      </Modal.Header>

      <Modal.Content>
        {error && (
          <ApiErrorMessage
            error={error}
            messages={ERROR_MESSAGES}
            header={(
              <FormattedMessage
                id="AddFixingActionModal.errors.header"
                defaultMessage="An error occurred!"
              />
            )}
          />
        )}
        <Form>
          <FormattedMessage id="AddFixingActionModal.name.label" defaultMessage="Name">
            {label => (
              <Form.Field>
                <label>{label}</label>
                <ComboBox
                  fluid
                  options={options}
                  disabled={loading}
                  placeholder={label}
                  otherLabel={
                    <FormattedMessage id="AddFixingActionModal.create.label" defaultMessage="Create new..." />
                  }
                  value={value}
                  onChange={handleChange}
                />
              </Form.Field>
            )}
          </FormattedMessage>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button disabled={loading} onClick={onClose}>
          <FormattedMessage id="AddFixingActionModal.cancel.label" defaultMessage="Cancel" />
        </Button>
        <Button
          primary
          loading={loading}
          disabled={!action || !action.name}
          onClick={() => onSave(action)}
        >
          <FormattedMessage id="AddFixingActionModal.add.label" defaultMessage="Add" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

AddFixingActionModal.defaultProps = {
  open: false,
  actions: [],
  loading: false,
  error: null,
  onSave: () => {},
  onClose: () => {},
};

AddFixingActionModal.propTypes = {
  open: PropTypes.bool,
  actions: PropTypes.arrayOf(FixingActionType),
  loading: PropTypes.bool,
  error: ErrorType,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default AddFixingActionModal;
