import React from 'react';

import JobRoutineView from './JobRoutineView';
import PermissionApprovalStep from './steps/PermissionApprovalStep';
import CheckFixingActionsStep from './steps/CheckFixingActionsStep';
import ConnectivityTestingStep from './steps/ConnectivityTestingStep';
import RemoteOpeningTestingStep from './steps/RemoteOpeningTestingStep';
import KiOpeningTestingStep from './steps/KiOpeningTestingStep';
import TrackHardwareStatusStep from './steps/TrackHardwareStatusStep';
import SuccessStep from './steps/SuccessStep';


const SafeFixingView = props => (
  <JobRoutineView {...props}>
    <PermissionApprovalStep id="permissionApproval" skipBackwards />
    <TrackHardwareStatusStep id="checkStatusStart" atStart skipBackwards />
    <CheckFixingActionsStep id="checkActions" />
    {/* TODO: Disable signal strength check for now until further testing has been done */}
    <ConnectivityTestingStep id="connectivityTesting" ignoreSignalStrength skipBackwards />
    <RemoteOpeningTestingStep id="remoteOpeningTesting" action="unlock" skipBackwards />
    <KiOpeningTestingStep id="kiNfcOpeningTesting" trigger="nfc" action="lock" />
    <TrackHardwareStatusStep id="checkStatusEnd" atEnd skipBackwards />
    <SuccessStep id="success" />
  </JobRoutineView>
);

export default SafeFixingView;
