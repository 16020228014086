import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, List } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';

import {
  GATEWAY_HW_TYPES, UZ_HW_TYPES, HW_PROTOCOLS, MAX_BATTERY_LEVELS,
} from '~/common/constants';
import { ApiErrorType, GatewayType, SensorType } from '~/common/types';
import HW_TYPE_MESSAGES from '~/common/locales/HardwareTypes';
import HW_VARIANT_MESSAGES from '~/common/locales/HardwareVariants';
import HW_FEATURE_MESSAGES from '~/common/locales/HardwareFeatures';
import DOOR_TYPE_MESSAGES from '~/common/locales/DoorTypes';
import DEVICE_USAGE_MESSAGES from '~/common/locales/DeviceUsages';
import Placeholder from '~/common/components/Placeholder';
import MonospaceText from '~/common/components/MonospaceText';
import ExpandableList from '~/app/components/ExpandableList';
import FormattedAddress from '~/common/components/FormattedAddress';
import FormattedFloor from '~/common/components/FormattedFloor';
import FormattedDateTime from '~/common/components/FormattedDateTime';
import EditableText from '~/common/components/EditableText';
import MapButton from '~/app/components/MapButton';
import HardwareMessage from '~/app/components/HardwareMessage';
import '~/app/styles/components/HardwareDetails.css';


class HardwareDetails extends Component {
  formatMessage = (messages, ...path) => {
    const message = _.get(messages, path);
    if (!message) {
      return path.slice(-1)[0];
    }
    return this.props.intl.formatMessage(message);
  }

  renderUpdateStatus = (lastUpdate) => {
    const {
      isUpdating, pushed, updated, numPendingMessages,
    } = lastUpdate;

    if (isUpdating) {
      return (
        <span>
          <FormattedMessage id="HardwareDetails.update.status.updating" defaultMessage="Updating..." />
          <span className="pending-config-messages">
            <FormattedMessage
              id="HardwareDetails.update.pendingMessages"
              defaultMessage="({n}&nbsp;{n, plural, one {message} other {messages}} pending)"
              values={{ n: numPendingMessages }}
            />
          </span>
        </span>
      );
    }

    if (updated > pushed) {
      return (
        <FormattedMessage id="HardwareDetails.update.status.upToDate" defaultMessage="Up to date" />
      );
    }

    return (
      <FormattedMessage id="HardwareDetails.update.status.outdated" defaultMessage="Outdated" />
    );
  }

  renderUpdateSection = ({ lastUpdate, label }) => (
    <>
      <Grid.Row className="details-row">
        <Grid.Column width={7} className="label-column">
          {label}
        </Grid.Column>
        <Grid.Column width={9} className="value-column">
          {this.renderUpdateStatus(lastUpdate)}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="details-row">
        <Grid.Column width={7} className="label-column">
          <FormattedMessage id="HardwareDetails.update.started.label" defaultMessage="Last update started" />
        </Grid.Column>
        <Grid.Column width={9} className="value-column">
          <Placeholder
            show={!lastUpdate.pushed}
            text={<FormattedMessage id="HardwareDetails.update.started.placeholder" defaultMessage="(never before)" />}
          >
            <FormattedDateTime
              relative
              value={lastUpdate.pushed}
            />
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="details-row">
        <Grid.Column width={7} className="label-column">
          <FormattedMessage id="HardwareDetails.update.finished.label" defaultMessage="Last update finished" />
        </Grid.Column>
        <Grid.Column width={9} className="value-column">
          <Placeholder
            show={!lastUpdate.updated}
            text={<FormattedMessage id="HardwareDetails.update.finished.placeholder" defaultMessage="(never before)" />}
          >
            <FormattedDateTime
              relative
              value={lastUpdate.updated}
            />
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
    </>
  )

  render() {
    const { hardware, error } = this.props;

    const batteryLevel = _.get(hardware, 'status.batteryInfo.level');
    const maxBatteryLevel = MAX_BATTERY_LEVELS[hardware.hardwareType];

    const showConfigUpdate = hardware.hardwareType !== GATEWAY_HW_TYPES.GLORIA
      && (!UZ_HW_TYPES.includes(hardware.hardwareType) || hardware.features.includes('nfc_cards'))
      && hardware.protocol !== HW_PROTOCOLS.KLAP;
    const showKisUpdate = showConfigUpdate
      && hardware.sensorId != null
      && hardware.protocol === HW_PROTOCOLS.MQTT;

    return (
      <div className="hardware-details-container">
        <Grid>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.organizations.label" defaultMessage="Owners" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder show={!hardware.organizations || hardware.organizations.length === 0}>
                {_.orderBy(hardware.organizations, [org => org.name]).map(org => (
                  <p key={org.id}>{org.name}</p>
                ))}
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.address.label" defaultMessage="Address" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder show={!hardware.address}>
                {hardware.address && (
                  <>
                    <FormattedAddress breakLines address={hardware.address} />
                    <MapButton className="map-button" address={hardware.address} />
                  </>
                )}
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.floor.label" defaultMessage="Floor" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <EditableText
                required
                type="number"
                modalHeader={(
                  <FormattedMessage
                    id="HardwareDetails.floor.editModal.header"
                    defaultMessage="Edit floor"
                  />
                )}
                error={error}
                value={hardware.floor?.toString()}
                onChange={value => this.props.onEditHardware({ floor: value })}
              >
                <Placeholder show={hardware.floor == null}>
                  <FormattedFloor value={hardware.floor} />
                </Placeholder>
              </EditableText>
            </Grid.Column>
          </Grid.Row>
          {hardware.sensorId != null && (
            <>
              <Grid.Row className="details-row">
                <Grid.Column width={7} className="label-column">
                  <FormattedMessage id="HardwareDetails.doorNumber.label" defaultMessage="Door number" />
                </Grid.Column>
                <Grid.Column width={9} className="value-column">
                  <Placeholder>
                    {hardware.doorNumber}
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="details-row">
                <Grid.Column width={7} className="label-column">
                  <FormattedMessage id="HardwareDetails.doorType.label" defaultMessage="Door type" />
                </Grid.Column>
                <Grid.Column width={9} className="value-column">
                  <Placeholder>
                    {this.formatMessage(DOOR_TYPE_MESSAGES, hardware.doorType)}
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.specifier.label" defaultMessage="Specifier" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder>
                {hardware.specifier}
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.installationLocation.label" defaultMessage="Location" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <EditableText
                required
                modalHeader={(
                  <FormattedMessage
                    id="HardwareDetails.installationLocation.editModal.header"
                    defaultMessage="Edit location"
                  />
                )}
                error={error}
                value={hardware.installationLocation}
                onChange={value => this.props.onEditHardware({ installationLocation: value })}
              >
                <Placeholder show={hardware.installationLocation == null}>
                  {hardware.installationLocation}
                </Placeholder>
              </EditableText>
            </Grid.Column>
          </Grid.Row>

          <Divider />

          {maxBatteryLevel != null && (
            <Grid.Row className="details-row">
              <Grid.Column width={7} className="label-column">
                <FormattedMessage id="HardwareDetails.batteryLevel.label" defaultMessage="Battery level" />
              </Grid.Column>
              <Grid.Column width={9}>
                <Placeholder>
                  {batteryLevel != null && (
                    `${batteryLevel}/${maxBatteryLevel}`
                  )}
                </Placeholder>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.manufactureDate.label" defaultMessage="Manufacture date" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder show={!hardware.manufactureDate}>
                <FormattedDateTime value={hardware.manufactureDate} />
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.installationDate.label" defaultMessage="Installation date" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder show={!hardware.installationDate}>
                <FormattedDateTime value={hardware.installationDate} />
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.usage.label" defaultMessage="Usage" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder>
                {this.formatMessage(DEVICE_USAGE_MESSAGES, hardware.usage)}
              </Placeholder>
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.hardwareType.label" defaultMessage="Hardware type" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder show={!hardware.hardwareType}>
                {this.formatMessage(HW_TYPE_MESSAGES, hardware.hardwareType)}
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.hardwareVariant.label" defaultMessage="Hardware variant" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder show={!hardware.hardwareVariant}>
                {this.formatMessage(HW_VARIANT_MESSAGES, hardware.hardwareVariant)}
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.hardwareRevision.label" defaultMessage="Hardware revision" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder show={!hardware.hardwareRevision}>
                <MonospaceText>{hardware.hardwareRevision}</MonospaceText>
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.firmware.label" defaultMessage="Firmware version" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder show={!hardware.firmware}>
                <MonospaceText>{hardware.firmware}</MonospaceText>
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="details-row">
            <Grid.Column width={7} className="label-column">
              <FormattedMessage id="HardwareDetails.link868.label" defaultMessage="868 Link" />
            </Grid.Column>
            <Grid.Column width={9} className="value-column">
              <Placeholder show={!hardware.link868}>
                <MonospaceText>{hardware.link868}</MonospaceText>
              </Placeholder>
            </Grid.Column>
          </Grid.Row>

          {hardware.protocol === HW_PROTOCOLS.APTERYX && (
            <>
              <Divider />
              <Grid.Row className="details-row">
                <Grid.Column width={7} className="label-column">
                  <FormattedMessage id="HardwareDetails.features.label" defaultMessage="Features" />
                </Grid.Column>
                <Grid.Column width={9} className="value-column">
                  <Placeholder show={hardware.features.length === 0}>
                    <List>
                      {hardware.features.map(feature => (
                        <List.Item key={feature}>
                          {this.formatMessage(HW_FEATURE_MESSAGES, feature)}
                        </List.Item>
                      ))}
                    </List>
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
            </>
          )}


          {hardware.sensorId != null && hardware.protocol !== HW_PROTOCOLS.KLAP && (
            <>
              <Divider />
              <Grid.Row className="details-row">
                <Grid.Column width={7} className="label-column">
                  <FormattedMessage id="HardwareDetails.kis.label" defaultMessage="Transponders" />
                </Grid.Column>
                <Grid.Column width={9} className="value-column">
                  <Placeholder show={hardware.kis.length === 0}>
                    <ExpandableList>
                      {_.orderBy(hardware.kis, [ki => ki.uuid]).map(ki => (
                        <ExpandableList.Item key={ki.uuid}>
                          <MonospaceText>{ki.uuid}</MonospaceText>
                        </ExpandableList.Item>
                      ))}
                    </ExpandableList>
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
            </>
          )}

          {showConfigUpdate && (
            <>
              <Divider />
              {this.renderUpdateSection({
                lastUpdate: hardware.status.lastConfigurationUpdate,
                label: (
                  <FormattedMessage id="HardwareDetails.update.status.configuration.label" defaultMessage="Configuration status" />
                ),
              })}
            </>
          )}

          {showKisUpdate && (
            <>
              <Divider />
              {this.renderUpdateSection({
                lastUpdate: hardware.status.lastKisUpdate,
                label: (
                  <FormattedMessage id="HardwareDetails.update.status.kis.label" defaultMessage="Transponder status" />
                ),
              })}
            </>
          )}

          {hardware.protocol !== HW_PROTOCOLS.KLAP && (
            <>
              <Divider />
              <Grid.Row className="details-row">
                <Grid.Column width={7} className="label-column">
                  <FormattedMessage id="HardwareDetails.lastMessage.label" defaultMessage="Last message" />
                </Grid.Column>
                <Grid.Column width={9} className="value-column">
                  <Placeholder show={!hardware.status.lastMessage.message}>
                    <HardwareMessage
                      message={hardware.status.lastMessage.message}
                      timestamp={hardware.status.lastMessage.timestamp}
                    />
                  </Placeholder>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </div>
    );
  }
}

HardwareDetails.defaultProps = {
  error: null,
};

HardwareDetails.propTypes = {
  hardware: PropTypes.oneOfType([
    GatewayType,
    SensorType,
  ]).isRequired,
  error: ApiErrorType,
  onEditHardware: PropTypes.func.isRequired,
};

export default injectIntl(HardwareDetails);
