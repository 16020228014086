import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';


const LoginForm = ({
  autoFocus, initialUsername, onSubmit, onPasswordReset,
}) => {
  const [username, setUsername] = useState(initialUsername || '');
  const [password, setPassword] = useState('');

  const usernameInputRef = useRef();
  useEffect(() => {
    if (autoFocus) {
      usernameInputRef.current.focus();
    }
  }, [autoFocus]);

  const handleSubmit = () => {
    setPassword('');
    onSubmit({ username, password });
  };
  const handlePasswordReset = () => onPasswordReset({ username });


  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field>
        <label>
          <FormattedMessage id="LoginForm.username.label" defaultMessage="Username" />
        </label>
        <FormattedMessage id="LoginForm.username.placeholder" defaultMessage="Username">
          {placeholder => (
            <Input
              ref={usernameInputRef}
              icon="user"
              autoCapitalize="none"
              placeholder={placeholder}
              value={username}
              onChange={(e, { value }) => setUsername(value)}
            />
          )}
        </FormattedMessage>
      </Form.Field>

      <Form.Field>
        <label>
          <FormattedMessage id="LoginForm.password.label" defaultMessage="Password" />
        </label>
        <FormattedMessage id="LoginForm.password.placeholder" defaultMessage="Password">
          {placeholder => (
            <Input
              icon="lock"
              type="password"
              placeholder={placeholder}
              value={password}
              onChange={(e, { value }) => setPassword(value)}
            />
          )}
        </FormattedMessage>
      </Form.Field>

      <Form.Button primary fluid disabled={!username || !password}>
        <FormattedMessage id="LoginForm.login.label" defaultMessage="Login" />
      </Form.Button>

      {onPasswordReset && (
        <Form.Button fluid onClick={handlePasswordReset}>
          <FormattedMessage id="LoginForm.resetPassword.label" defaultMessage="Reset password" />
        </Form.Button>
      )}
    </Form>
  );
};

LoginForm.defaultProps = {
  autoFocus: false,
  initialUsername: '',
  onSubmit: () => {},
  onPasswordReset: null,
};

LoginForm.propTypes = {
  autoFocus: PropTypes.bool,
  initialUsername: PropTypes.string,
  onSubmit: PropTypes.func,
  onPasswordReset: PropTypes.func,
};

export default LoginForm;
