import { defineMessages } from 'react-intl';


export default defineMessages({
  PRODUCTION: {
    id: 'DeviceUsages.production',
    defaultMessage: 'Production',
  },
  DEMO: {
    id: 'DeviceUsages.demo',
    defaultMessage: 'Demo',
  },
  TEST: {
    id: 'DeviceUsages.test',
    defaultMessage: 'Test',
  },
  UNSERVICED: {
    id: 'DeviceUsages.unserviced',
    defaultMessage: 'Unserviced',
  },
  INOPERABLE: {
    id: 'DeviceUsages.inoperable',
    defaultMessage: 'Inoperable',
  },
});
