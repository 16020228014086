import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dimmer,
  Loader,
  Table,
  Icon,
  Ref,
} from 'semantic-ui-react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import clsx from 'clsx';

import { useEventListener } from '~/common/utils/hooks';
import attachCompounds from '~/common/utils/attachCompounds';
import { ErrorType } from '~/common/types';
import LoadingSpinner from '~/common/components/LoadingSpinner';
import PullToRefreshWrapper from '~/app/components/PullToRefreshWrapper';
import '~/common/styles/components/TabularList.css';


const TabularListRow = ({
  className, children, onClick, ...otherProps
}) => (
  <Table.Row
    {...otherProps}
    className={clsx(className, { clickable: onClick != null })}
    onClick={onClick}
  >
    {children}
    <Table.Cell collapsing className="arrow-cell" textAlign="right">
      <Icon name="angle right" color="grey" size="big" />
    </Table.Cell>
  </Table.Row>
);


const TabularList = ({
  arrow, header, className, scrollTop,
  placeholder, error, loading, hasMore,
  children, onScroll, onLoadMore, onRefresh,
}) => {
  const scrollContainerRef = useRef();
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (!loading && refreshing) {
      setRefreshing(false);
    }
  }, [loading, refreshing]);

  useEffect(() => {
    if (!loading && loadingMore) {
      setLoadingMore(false);
    }
  }, [loading, loadingMore]);

  const handleRefresh = useCallback(() => {
    if (onRefresh) {
      const result = onRefresh();
      setRefreshing(true);
      return result;
    }
  }, [onRefresh]);

  const handleLoadMore = useCallback(() => {
    if (onLoadMore) {
      const result = onLoadMore();
      setLoadingMore(true);
      return result;
    }
  }, [onLoadMore]);


  const [sentryRef, { rootRef: setRootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: handleLoadMore,
    disabled: !!error,
    rootMargin: '0% 0% 30% 0%',
  });

  // We need the ref internally for scrolling logic, but also need to pass it
  // to the useInfiniteScroll hook, which is why we use a callback ref here
  const setScrollContainerRef = useCallback((element) => {
    scrollContainerRef.current = element;
    setRootRef(element);
  }, [scrollContainerRef, setRootRef]);


  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollTop;
    }
  }, [scrollTop]);

  const handleScroll = useCallback((e) => {
    if (onScroll) {
      onScroll(e.target.scrollTop);
    }
  }, [onScroll]);

  useEventListener(scrollContainerRef.current, 'scroll', handleScroll, { debounce: 250 });

  const shouldPullToRefresh = useCallback(() => {
    const { current: scrollContainer } = scrollContainerRef;
    return onRefresh != null && (!scrollContainer || scrollContainer.scrollTop === 0);
  }, [scrollContainerRef, onRefresh]);

  const hasChildren = React.Children.count(children) !== 0;
  const loadingInitially = loading && !refreshing && !loadingMore;

  return (
    <Dimmer.Dimmable
      dimmed={loadingInitially}
      className={clsx('tabular-list', className, { arrow })}
    >
      <LoadingSpinner active={loadingInitially} />
      <PullToRefreshWrapper
        refresh={handleRefresh}
        shouldPullToRefresh={shouldPullToRefresh}
      >
        <div className="inner-container">
          {error && !loading && (
            <div className="error-container">
              {error}
            </div>
          )}

          {!error && !loading && !hasChildren && (
            <p className="placeholder-container">
              {placeholder}
            </p>
          )}

          {!error && hasChildren && (
            <>
              {header && (
                <div className="header-container">
                  {header}
                </div>
              )}
              <div ref={setScrollContainerRef} className="scroll-container">
                <Table striped unstackable basic="very" size="small">
                  <Table.Body>
                    {children}
                  </Table.Body>
                </Table>
                {hasMore && (
                  <Ref innerRef={sentryRef}>
                    <Loader active inline="centered" size="small" />
                  </Ref>
                )}
              </div>
            </>
          )}
        </div>
      </PullToRefreshWrapper>
    </Dimmer.Dimmable>
  );
};

TabularList.defaultProps = {
  arrow: false,
  header: null,
  className: null,
  placeholder: null,
  scrollTop: 0,
  error: null,
  loading: false,
  hasMore: false,
  children: null,
  onScroll: null,
  onLoadMore: null,
  onRefresh: null,
};

TabularList.propTypes = {
  arrow: PropTypes.bool,
  header: PropTypes.node,
  className: PropTypes.string,
  placeholder: PropTypes.node,
  scrollTop: PropTypes.number,
  error: ErrorType,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  children: PropTypes.node,
  onScroll: PropTypes.func,
  onLoadMore: PropTypes.func,
  onRefresh: PropTypes.func,
};


export default attachCompounds(TabularList, {
  Row: TabularListRow,
  Cell: Table.Cell,
});
