import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  DEVICE_TYPES,
  GATEWAY_RSSI_THRESHOLDS,
  SENSOR_SIGNAL_THRESHOLDS,
  UNIT_DBM,
} from '~/common/constants';
import { GatewayType, SensorType } from '~/common/types';
import HW_TYPE_MESSAGES from '~/common/locales/HardwareTypes';
import HW_VARIANT_MESSAGES from '~/common/locales/HardwareVariants';
import TabularList from '~/common/components/TabularList';
import InlineList from '~/common/components/InlineList';
import FormattedAddress from '~/common/components/FormattedAddress';
import FormattedFloor from '~/common/components/FormattedFloor';
import FormattedMetric from '~/common/components/FormattedMetric';
import HardwareOnlineStatus from '~/common/components/HardwareOnlineStatus';
import Placeholder from '~/common/components/Placeholder';
import '~/app/styles/components/NearbyDevicesList.css';


const formatHardwareType = (intl, hardwareType) => {
  const message = HW_TYPE_MESSAGES[hardwareType];
  if (!message) {
    return null;
  }

  return intl.formatMessage(message);
};

const formatHardwareVariant = (intl, hardwareVariant) => {
  const message = HW_VARIANT_MESSAGES[hardwareVariant];
  if (!message) {
    return null;
  }

  return intl.formatMessage(message);
};

const renderSignalMetric = (deviceType, status) => {
  const { isOnline, signal: { rssi, signal } = {} } = status || {};

  const isGateway = [DEVICE_TYPES.GATEWAY, DEVICE_TYPES.HYBRID].includes(deviceType);
  const label = isGateway ? (
    <FormattedMessage id="NearbyDevicesList.metrics.rssi" defaultMessage="RSSI" />
  ) : (
    <FormattedMessage id="NearbyDevicesList.metrics.signal" defaultMessage="Signal" />
  );
  const value = isGateway ? rssi : signal;
  const thresholds = isGateway ? GATEWAY_RSSI_THRESHOLDS : SENSOR_SIGNAL_THRESHOLDS;

  return (
    <div className="metric-container">
      <span className="metric-label">
        {label}
        :
      </span>
      <Placeholder show={!isOnline || value == null} text="-">
        <FormattedMetric hideRatingText value={value} unit={UNIT_DBM} thresholds={thresholds} />
      </Placeholder>
    </div>
  );
};


const NearbyDevicesList = ({
  deviceType,
  devices,
  onClick,
  ...remainingProps
}) => {
  const intl = useIntl();

  return (
    <TabularList arrow className="nearby-devices-list" {...remainingProps}>
      {devices.map(device => (
        <TabularList.Row key={device.uuid} onClick={() => onClick(device)}>
          <TabularList.Cell>
            <Header size="tiny">
              <InlineList>
                {formatHardwareType(intl, device.hardwareType)}
                {formatHardwareVariant(intl, device.hardwareVariant)}
              </InlineList>
            </Header>
            <p className="address">
              <Placeholder
                show={!device.address}
                text={(
                  <FormattedMessage
                    id="NearbyDevicesList.address.placeholder"
                    defaultMessage="(no address)"
                  />
                )}
              >
                <FormattedAddress address={device.address} />
              </Placeholder>
            </p>
            <p className="installation-location">
              <Placeholder
                show={!device.floor && !device.installationLocation}
                text={(
                  <FormattedMessage
                    id="NearbyDevicesList.installationLocation.placeholder"
                    defaultMessage="(no location)"
                  />
                )}
              >
                <InlineList>
                  {device.floor != null && (
                    <FormattedFloor value={device.floor} />
                  )}
                  {device.installationLocation}
                </InlineList>
              </Placeholder>
            </p>
            <p className="uuid">
              {device.uuid}
            </p>
            <p className="specifier">
              <Placeholder
                show={!device.specifier}
                text={(
                  <FormattedMessage
                    id="NearbyDevicesList.specifier.placeholder"
                    defaultMessage="(no specifier)"
                  />
                )}
              >
                {device.specifier}
              </Placeholder>
            </p>
          </TabularList.Cell>
          <TabularList.Cell>
            <HardwareOnlineStatus icon compact hardware={device} />
            {renderSignalMetric(deviceType, device.status)}
          </TabularList.Cell>
        </TabularList.Row>
      ))}
    </TabularList>
  );
};

NearbyDevicesList.defaultProps = {
  onClick: () => {},
};

NearbyDevicesList.propTypes = {
  ...TabularList.propTypes,
  deviceType: PropTypes.oneOf(Object.values(DEVICE_TYPES)).isRequired,
  devices: PropTypes.arrayOf(
    PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  ).isRequired,
  onClick: PropTypes.func,
};

export default NearbyDevicesList;
