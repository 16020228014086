import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import ComboBox from '~/common/components/ComboBox';


const OPTION_MESSAGES = defineMessages({
  kiwi: {
    id: 'CausingPartyComboBox.options.kiwi',
    defaultMessage: 'KIWI',
  },
  customer: {
    id: 'CausingPartyComboBox.options.customer',
    defaultMessage: 'Customer',
  },
});


const CausingPartyComboBox = (props) => {
  const intl = useIntl();

  const options = Object.values(OPTION_MESSAGES)
    .map(intl.formatMessage)
    .map(text => ({ text, value: text }));

  return (
    <ComboBox {...props} maxLength={64} options={options} />
  );
};

export default CausingPartyComboBox;
