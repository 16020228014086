import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import _ from 'lodash';

import { HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { rebootGateway, rebootSensor, stopRoutines } from '~/app/actions/HardwareActions';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import TimeoutProgress from '~/app/components/TimeoutProgress';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const TIMEOUT = 80000;

const ERROR_MESSAGES = defineMessages({
  NOT_ALLOWED: {
    id: 'RebootStep.jobs.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = {
  [HW_PRODUCTS.GATEWAY]: _.pick(FIXING_OPTION_MESSAGES,
    'gatewayInstalledProperly',
    'gatewayRestart'),
  [HW_PRODUCTS.SMART_ENTRY]: _.pick(FIXING_OPTION_MESSAGES,
    'smartEntryInstalledProperly',
    'smartEntryRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.SMART_ENTRY_HYBRID]: _.pick(FIXING_OPTION_MESSAGES,
    'smartEntryInstalledProperly',
    'smartEntryRestart'),
  [HW_PRODUCTS.SMART_DOOR]: _.pick(FIXING_OPTION_MESSAGES,
    'smartDoorInstalledProperly',
    'smartDoorClosed',
    'smartDoorRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.KNOB]: _.pick(FIXING_OPTION_MESSAGES,
    'knobInstalledProperly',
    'knobRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.HANDLE]: _.pick(FIXING_OPTION_MESSAGES,
    'handleInstalledProperly',
    'handleRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.SAFE]: _.pick(FIXING_OPTION_MESSAGES,
    'safeInstalledProperly',
    'safeRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.SAFE_TWO]: _.pick(FIXING_OPTION_MESSAGES,
    'safeInstalledProperly',
    'safeRestart',
    'gatewayRestart'),
};

class RebootStep extends Component {
  state = {
    next: false,
  }

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    this.props.stopRoutines();
  }

  get error() {
    const { next } = this.state;
    const { error, reboot: { loading, success } } = this.props.hardware;

    if (error) {
      return error;
    }
    if (next && !loading && !success) {
      return <FormattedMessage id="RebootStep.jobs.errors.timeout" defaultMessage="The device could not be restarted." />;
    }

    return null;
  }

  start = () => {
    const { task } = this.props.routine;

    if (task.hardware.sensorId != null) {
      this.props.rebootSensor({ id: task.hardware.sensorId });
    } else {
      this.props.rebootGateway({ id: task.hardware.gatewayId });
    }

    this.setState({ next: true });
  }

  render() {
    const { next } = this.state;
    const { job, task } = this.props.routine;
    const { reboot } = this.props.hardware;

    if (next && reboot.success) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="test" />}
        error={this.error}
        errorHeader={(
          <FormattedMessage
            id="RebootStep.jobs.errors.header"
            defaultMessage="Test failed"
          />
        )}
        errorMessages={ERROR_MESSAGES}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES[task.hardware.hardwareProduct]}
        retry={this.start}
      >
        <StepView.Content>
          <p>
            <FormattedMessage
              id="RebootStep.jobs.description"
              defaultMessage="Rebooting device..."
            />
          </p>
          <TimeoutProgress timeout={TIMEOUT} />
        </StepView.Content>
      </StepView>
    );
  }
}

RebootStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  rebootGateway,
  rebootSensor,
  stopRoutines,
}, dispatch);

export default withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(RebootStep)));
