import { Component } from 'react';
import PropTypes from 'prop-types';

import { withRoutine } from '~/app/contexts/RoutineContext';


class RoutineNavigation extends Component {
  state = {
    hasNavigated: false,
  }

  componentDidMount() {
    const { hasNavigated } = this.state;
    if (!hasNavigated) {
      this.navigate();
    }
  }

  componentDidUpdate() {
    const { hasNavigated } = this.state;
    if (!hasNavigated) {
      this.navigate();
    }
  }

  navigate = () => {
    const { nextStep, previousStep } = this.props;
    const { previous, next } = this.props.routine;

    let hasNavigated = false;
    if (previousStep) {
      hasNavigated = previous();
    } else if (nextStep) {
      hasNavigated = next();
    }

    if (hasNavigated) {
      this.setState({ hasNavigated });
    }
  }

  render() {
    return null;
  }
}

RoutineNavigation.defaultProps = {
  nextStep: false,
  previousStep: false,
};

RoutineNavigation.propTypes = {
  routine: PropTypes.shape({
    previous: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
  }).isRequired,
  previousStep: PropTypes.bool,
  nextStep: PropTypes.bool,
};

export default withRoutine(RoutineNavigation);
