import React from 'react';


export const ToolsContext = React.createContext({
  activeTool: null,
  availableTools: [],
  switchTool: () => {},
  showSidebar: () => {},
  hideSidebar: () => {},
});

export const withTools = WrappedComponent => props => (
  <ToolsContext.Consumer>
    {context => (
      <WrappedComponent
        {...props}
        tools={context}
      />
    )}
  </ToolsContext.Consumer>
);

export default ToolsContext;
