import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Menu } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';

import { dismissError } from '~/app/actions/HardwareActions';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import FormattedAddress from '~/common/components/FormattedAddress';
import PullToRefreshWrapper from '~/app/components/PullToRefreshWrapper';
import DeviceCheckHardwareProvider from '~/app/components/DeviceCheckHardwareProvider';
import HardwareActions from '~/app/components/HardwareActions';
import HardwareConnectivityDetails from '~/app/components/HardwareConnectivityDetails';
import HardwareDetails from '~/app/components/HardwareDetails';
import View from '~/app/views/View';
import '~/app/styles/views/device-check/DeviceCheckResultView.css';


const HEADER_MESSAGES = defineMessages({
  gateway: {
    id: 'DeviceCheckResultView.deviceCheck.header.primary.gateway',
    defaultMessage: 'Gateway: {uuid}',
  },
  sensor: {
    id: 'DeviceCheckResultView.deviceCheck.header.primary.sensor',
    defaultMessage: 'Sensor: {uuid}',
  },
  hybrid: {
    id: 'DeviceCheckResultView.deviceCheck.header.primary.hybrid',
    defaultMessage: 'Hybrid: {uuid}',
  },
});

const ERROR_MESSAGES = defineMessages({
  NOT_FOUND: {
    id: 'DeviceCheckResultView.deviceCheck.errors.notFound',
    defaultMessage: 'The device does not exist.',
  },
  CONF_TIMEOUT: {
    id: 'DeviceCheckResultView.deviceCheck.errors.confTimeout',
    defaultMessage: 'The device could not be configured.',
  },
  CONF_ERROR: {
    id: 'DeviceCheckResultView.deviceCheck.errors.confError',
    defaultMessage: 'The device could not be configured.',
  },
  DEVICE_OFFLINE: {
    id: 'DeviceCheckResultView.deviceCheck.errors.deviceOffline',
    defaultMessage: 'The action cannot be performed as the device is offline.',
  },
  RATE_LIMIT_EXCEEDED: {
    id: 'DeviceCheckResultView.deviceCheck.errors.rateLimitExceeded',
    defaultMessage: 'The rate limit for this action has been exceeded. Please try again in a couple of seconds.',
  },
});

class DeviceCheckResultView extends Component {
  state = {
    activeTab: 'connection',
  }

  componentWillUnmount() {
    this.props.dismissError();
  }

  get gatewayId() {
    const { gatewayId } = this.props.match.params;
    return parseInt(gatewayId, 10) || null;
  }

  get sensorId() {
    const { sensorId } = this.props.match.params;
    return parseInt(sensorId, 10) || null;
  }

  switchTab = (tab) => {
    this.props.dismissError();
    this.setState({ activeTab: tab });
  }

  goBack = ({ replace }) => {
    const { state: locationState } = this.props.location;
    replace(_.get(locationState, 'referer', '/device-check'));
  }

  renderHeaderPrimaryText = (deviceType, hardware) => {
    if (!hardware) {
      return (
        <FormattedMessage
          id="DeviceCheckResultView.deviceCheck.header.primary.placeholder"
          defaultMessage="Device Check"
        />
      );
    }

    return this.props.intl.formatMessage(HEADER_MESSAGES[deviceType], { uuid: hardware.uuid });
  }

  renderHeaderSecondaryText = (hardware) => {
    if (!hardware) {
      return null;
    }

    if ((!hardware.address && !hardware.specifier)) {
      return (
        <FormattedMessage
          id="DeviceCheckResultView.deviceCheck.header.secondary.placeholder"
          defaultMessage="(no address available)"
        />
      );
    }

    return (
      <span>
        <FormattedAddress address={hardware.address} />
        {hardware.address && hardware.specifier && ', '}
        {hardware.specifier}
      </span>
    );
  }

  render() {
    const { activeTab } = this.state;
    const { deviceType } = this.props.match.params;

    return (
      <DeviceCheckHardwareProvider
        deviceType={deviceType}
        gatewayId={this.gatewayId}
        sensorId={this.sensorId}
      >
        {({
          hardware,
          loading,
          error,
          isHardwareFetched,
          refreshHardware,
          editHardware,
        }) => (
          <View loading={loading && !isHardwareFetched} onGoBack={this.goBack}>
            <View.Header
              marquee
              primaryText={this.renderHeaderPrimaryText(deviceType, hardware)}
              secondaryText={this.renderHeaderSecondaryText(hardware)}
            />

            <View.Content compact fixed>
              <Menu compact pointing secondary widths={3}>
                <Menu.Item active={activeTab === 'connection'} onClick={() => this.switchTab('connection')}>
                  <FormattedMessage id="DeviceCheckResultView.deviceCheck.connection.header" defaultMessage="Connection" />
                </Menu.Item>
                <Menu.Item active={activeTab === 'details'} onClick={() => this.switchTab('details')}>
                  <FormattedMessage id="DeviceCheckResultView.deviceCheck.details.header" defaultMessage="Details" />
                </Menu.Item>
                <Menu.Item active={activeTab === 'actions'} onClick={() => this.switchTab('actions')}>
                  <FormattedMessage id="DeviceCheckResultView.deviceCheck.actions.header" defaultMessage="Actions" />
                </Menu.Item>
              </Menu>
            </View.Content>
            <View.Content compact>
              <PullToRefreshWrapper refresh={refreshHardware}>
                <div className="device-information-tab-container">
                  {error && (
                    <div className="error-container">
                      <ApiErrorMessage
                        error={error}
                        header={(
                          <FormattedMessage
                            id="DeviceCheckResultView.deviceCheck.errors.header"
                            defaultMessage="An error occurred!"
                          />
                        )}
                        messages={ERROR_MESSAGES}
                      />
                    </div>
                  )}

                  {isHardwareFetched && (
                    <>
                      <div className={`device-information-tab ${activeTab === 'connection' ? 'active' : ''}`}>
                        <HardwareConnectivityDetails hardware={hardware} />
                      </div>
                      <div className={`device-information-tab ${activeTab === 'details' ? 'active' : ''}`}>
                        <HardwareDetails
                          hardware={hardware}
                          error={error}
                          onEditHardware={editHardware}
                        />
                      </div>
                      <div className={`device-information-tab ${activeTab === 'actions' ? 'active' : ''}`}>
                        <HardwareActions hardware={hardware} />
                      </div>
                    </>
                  )}
                </div>
              </PullToRefreshWrapper>
            </View.Content>
          </View>
        )}
      </DeviceCheckHardwareProvider>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  dismissError,
}, dispatch);

export default injectIntl(connect(
  null,
  mapDispatchToProps,
)(DeviceCheckResultView));
