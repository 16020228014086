import React, { Component } from 'react';

import { HW_VARIANTS } from '~/common/constants';
import JobRoutineView from './JobRoutineView';
import NotesStep from './steps/NotesStep';
import UUIDInputStep from './steps/UUIDInputStep';
import GatewayInstallationDataInputStep from './steps/GatewayInstallationDataInputStep';
import HardwareRegistrationStep from './steps/HardwareRegistrationStep';
import ConnectivityTestingStep from './steps/ConnectivityTestingStep';
import LinkPerformanceTestingStep from './steps/LinkPerformanceTestingStep';
import RebootStep from './steps/RebootStep';
import ConfigurationStep from './steps/ConfigurationStep';
import TrackHardwareStatusStep from './steps/TrackHardwareStatusStep';
import SuccessStep from './steps/SuccessStep';


class GatewayInstallationView extends Component {
  render() {
    const { task: { hardware, dispatcherNotes } } = this.props;
    const { hardwareVariant } = hardware;

    const isApteryx = hardwareVariant === HW_VARIANTS.APTERYX;
    const isNds = hardwareVariant === HW_VARIANTS.NDS;

    return (
      <JobRoutineView {...this.props}>
        <NotesStep id="notes" disabled={!dispatcherNotes} />
        <UUIDInputStep id="uuidInput" />
        <HardwareRegistrationStep id="hardwareRegistration" skipBackwards />
        <GatewayInstallationDataInputStep id="dataInput" />
        {/* TODO: Disable signal strength check for now until further testing has been done */}
        <ConnectivityTestingStep id="connectivityTesting" ignoreSignalStrength skipBackwards />
        <ConfigurationStep id="pushConfig" disabled={isApteryx} full skipBackwards />
        <RebootStep id="reboot" disabled={isNds} skipBackwards />
        <LinkPerformanceTestingStep id="linkPerformanceTesting" disabled={!isNds} skipBackwards />
        <TrackHardwareStatusStep id="checkStatusEnd" atEnd skipBackwards />
        <SuccessStep id="success" />
      </JobRoutineView>
    );
  }
}

export default GatewayInstallationView;
