import {
  call, all, race, put, take, delay, takeLatest,
} from 'redux-saga/effects';
import _ from 'lodash';

import {
  GATEWAY_HW_TYPES, SENSOR_HW_TYPES, HW_PROTOCOLS,
  GATEWAY_RSSI_THRESHOLDS, GATEWAY_BER_THRESHOLDS,
  SENSOR_SIGNAL_THRESHOLDS,
} from '~/common/constants';
import {
  CONNECTIVITY_TIMEOUT, REBOOT_TIMEOUT,
  REBOOT_PING_DELAY, REBOOT_STATUS_DELAY,
  MQTT_GATEWAY_CONFIG_TIMEOUT,
  APTERYX_SENSOR_CONFIG_TIMEOUT, MQTT_SENSOR_CONFIG_TIMEOUT,
  UPDATE_GSM_INFO_TIMEOUT, UPDATE_868_INFO_TIMEOUT,
  BATTERY_INFO_TIMEOUT, BACKGROUND_TASK_TIMEOUTS,
  DEFAULT_POLL_INTERVAL, HW_RESPONSE_POLL_INTERVAL,
  HW_RESPONSE_TIMEOUT, HW_COMMAND_RETRIES,
  KI_SIGNAL_TEST_OPTIONS,
} from '~/app/constants';
import {
  fetchHardware, registerHardware,
  fetchHybrid,
  fetchGateway, fetchGateways, editGateway, removeGateway, getSensorWithStatus,
  getGatewayWithStatus, updateGatewayStatus, updateGatewayMetrics, updateGatewaySensors,
  checkGatewayConnectivity, checkGatewayLinkPerformance,
  getGatewayGsmInfo, pingGateway, rebootGateway,
  updateGatewayConfiguration, pushGatewayConfiguration, awaitGatewayConfigurationUpdate,
  fetchSensor, fetchSensors, editSensor, removeSensor,
  updateSensorStatus, updateSensorMetrics, updateSensorGateways,
  checkSensorConnectivity, getSensor868Info, pingSensor, rebootSensor, openSensor,
  getSensorBatteryInfo, initializeSensorRebatt, finalizeSensorRebatt,
  grantSensorPermission, revokeSensorPermission,
  updateSensorConfiguration, pushSensorConfiguration,
  awaitSensorKisUpdate, awaitSensorConfigurationUpdate,
  startKiSignalTest, kiSignalTestProgress,
  kiSignalTestDoorOpened, kiSignalTestRepositioned,
  linkPerformanceCheckProgress,
  gatewayConfigurationUpdated, sensorConfigurationUpdated, stopRoutines,
} from '~/app/actions/HardwareActions';
import compareVersions from '~/common/utils/compareVersions';
import { ignoreErrors, registerSagas, SagaError } from '~/app/sagas';
import { setConfigOption } from '~/common/utils/hardwareConfiguration';
import getGeolocation from '~/app/utils/geolocation';
import * as api from '~/common/services/ApiService';


function* fetchHardwareSaga({ gatewayId, sensorId, ...params }) {
  const effects = {};
  if (gatewayId != null && sensorId != null) {
    effects.hybrid = () => fetchHybridSaga({ ...params, gatewayId, sensorId });
  } else {
    effects.gateway = () => fetchGatewaySaga({ ...params, id: gatewayId });
    effects.sensor = () => fetchSensorSaga({ ...params, id: sensorId });
  }

  // Wrap effects with helper to ignore NOT_FOUND errors when fetching
  // by uuid where the type of device is unknown
  for (const [key, value] of Object.entries(effects)) {
    effects[key] = ignoreErrors(value, { code: 'NOT_FOUND' });
  }

  const results = yield all(effects);
  let gateway = results.gateway && results.gateway.gateway;
  let sensor = results.sensor && results.sensor.sensor;
  let hybrid = results.hybrid && results.hybrid.hybrid;

  // If fetched gateway and sensor are the same device, merge them to a hybrid
  if (gateway && sensor && gateway.uuid === sensor.uuid) {
    hybrid = _.merge({}, sensor, gateway);
    gateway = hybrid;
    sensor = hybrid;
  }

  if (!gateway && !sensor && !hybrid) {
    throw new SagaError('Hardware does not exist', 'NOT_FOUND');
  }

  return { gateway, sensor, hybrid };
}

function* registerHardwareSaga({
  uuid, hardwareProduct, hardwareVariant, task, unregister,
}) {
  if (uuid) {
    return yield call(api.registerHardware, uuid, hardwareProduct, hardwareVariant, unregister);
  }
  if (task) {
    return yield call(api.registerHardwareOfTask, task.id);
  }
}

function* fetchHybridSaga({ gatewayId, sensorId, include }) {
  if (gatewayId) {
    return yield call(api.fetchHybridByGatewayId, gatewayId, include);
  }
  if (sensorId) {
    return yield call(api.fetchHybridBySensorId, sensorId, include);
  }
}

function* fetchGatewaySaga({ id, uuid, include }) {
  if (id) {
    return yield call(api.fetchGateway, id, include);
  }
  if (uuid) {
    return yield call(api.fetchGatewayByUuid, uuid, include);
  }
}

function* fetchGatewaysSaga({
  pageNumber, pageSize, query = {}, include, clear = false,
}) {
  if (query.sortBy === 'distance' && !query.geolocation) {
    query.geolocation = yield call(getGeolocation);
  }

  const result = yield call(api.fetchGateways, {
    ...query,
    pageNumber,
    pageSize,
    include,
  });

  return {
    gateways: result.gateways,
    total: result.meta.total,
    pageNumber: result.meta.pageNumber,
    query,
    clear,
  };
}

function* editGatewaySaga({ id, ...attributes }) {
  return yield call(api.editGateway, id, attributes);
}

function* removeGatewaySaga({ id, uuid, lifecycleState }) {
  if (id) {
    yield call(api.removeGateway, id, lifecycleState);
    return { id };
  }
  if (uuid) {
    yield call(api.removeGatewayByUuid, uuid, lifecycleState);
    return { uuid };
  }
}

function* getGatewayWithStatusSaga({ id }) {
  const { gateway } = yield call(api.fetchGateway, id, ['status', 'status.signal']);
  return { gateway };
}

function* getSensorWithStatusSaga({ id }) {
  const { sensor } = yield call(api.fetchSensor, id, ['status', 'status.signal']);
  return { sensor };
}

function* updateGatewayStatusSaga({ id, include }) {
  const { status } = yield call(api.getGatewayStatus, id, include);
  return { id, status };
}

function* updateGatewayMetricsSaga({ id }) {
  const { metrics } = yield call(api.getGatewayMetrics, id);
  return { id, metrics };
}

function* updateGatewaySensorsSaga({ id, include }) {
  const { sensors } = yield call(api.getGatewaySensors, id, include);
  return { id, sensors };
}

function* checkGatewayConnectivitySaga({ id, ignoreSignalStrength }) {
  let timedOut = false;
  let { gateway } = yield fetchGatewaySaga({ id });

  const getConnectivity = (status, timeout = false) => {
    const { isOnline, signal: { timestamp, rssi, bitErrorRate } } = status;

    let rating = null;
    if (rssi != null && bitErrorRate != null) {
      const goodSignal = rssi >= GATEWAY_RSSI_THRESHOLDS.poor
                         && bitErrorRate <= GATEWAY_BER_THRESHOLDS.poor;
      rating = goodSignal ? 'good' : 'poor';
    }

    return {
      isOnline: isOnline && !timeout,
      signalStrength: {
        timestamp,
        rating,
      },
    };
  };

  // Ping apteryx gateways to check connectivity and retrieve fresh cell stats
  const startTime = yield getServerTime({ stripMilliseconds: true });
  if (gateway.protocol === HW_PROTOCOLS.APTERYX) {
    ({ timedOut } = yield pingGatewaySaga({ id, protocol: gateway.protocol }));
  }

  // For MQTT gateways ping is not necessary, but fresh cell stats are requested, too
  if (gateway.protocol === HW_PROTOCOLS.MQTT && !ignoreSignalStrength) {
    // Command might time out if it's not supported by the device
    // In this case, signal strength is ignored
    const params = {
      id,
      protocol: gateway.protocol,
      command: 'CELL-QUALITY',
    };
    const result = yield ignoreErrors(() => sendGatewayCommand(params), { code: 'DEVICE_OFFLINE' });
    if (!result || result.timedOut) {
      ignoreSignalStrength = true;
    }
  }

  // Wait for signal of gateway
  if (!timedOut) {
    ({ timedOut } = yield waitUntil({
      timeout: CONNECTIVITY_TIMEOUT,
      fetch: () => call(api.getGatewayStatus, id, ['signal']),
      exitCondition: ({ status }) => {
        const { isOnline, signalStrength: { timestamp } } = getConnectivity(status);
        return ignoreSignalStrength
          ? isOnline
          : isOnline && Date.parse(timestamp) >= startTime;
      },
    }));
  }

  // Fetch latest gateway information
  ({ gateway } = yield fetchGatewaySaga({ id, include: ['status', 'status.signal'] }));

  return {
    gateway,
    connectivity: getConnectivity(gateway.status, timedOut),
  };
}

function* checkGatewayLinkPerformanceSaga({ id }) {
  yield put(linkPerformanceCheckProgress({ status: 'initializing' }));

  const { taskId } = yield call(api.triggerGatewayLinkPerformanceTest, id);
  const { task: { result } } = yield waitForBackgroundTask({
    id: taskId,
    timeout: BACKGROUND_TASK_TIMEOUTS.linkPerformanceCheck,
    onStageChange: status => put(linkPerformanceCheckProgress({ status })),
  });

  yield put(linkPerformanceCheckProgress({ status: 'done' }));

  return { result };
}

function* pingGatewaySaga({ id, protocol }) {
  return yield sendGatewayCommand({ id, protocol, command: 'PING' });
}

function* getGatewayGsmInfoSaga({ id, protocol }) {
  if (!protocol) {
    ({ gateway: { protocol } } = yield fetchGatewaySaga({ id }));
  }

  let timedOut = false;
  const startTime = yield getServerTime({ stripMilliseconds: true });
  if (protocol === HW_PROTOCOLS.APTERYX) {
    // GSM info is sent along with pongs
    ({ timedOut } = yield sendGatewayCommand({ id, protocol, command: 'PING' }));
  } else {
    ({ timedOut } = yield sendGatewayCommand({ id, protocol, command: 'CELL-QUALITY' }));
  }

  // Wait for GSM info to update
  let status = null;
  if (!timedOut) {
    ({ data: { status }, timedOut } = yield waitUntil({
      timeout: UPDATE_GSM_INFO_TIMEOUT,
      fetch: () => call(api.getGatewayStatus, id, ['signal']),
      exitCondition: data => data.status && data.status.signal
        && Date.parse(data.status.signal.timestamp) >= startTime,
    }));
  }

  return {
    timedOut,
    gatewayId: id,
    status,
  };
}

function* rebootGatewaySaga({ id, protocol }) {
  if (!protocol) {
    ({ gateway: { protocol } } = yield fetchGatewaySaga({ id }));
  }

  const startTime = yield getServerTime();
  let { response, timedOut } = yield sendGatewayCommand({
    id,
    protocol,
    command: 'REBOOT',
    responseTypes: protocol === HW_PROTOCOLS.APTERYX ? ['REBOOT', 'HELLO'] : ['REBOOT'],
  });
  if (!timedOut) {
    if (protocol === HW_PROTOCOLS.APTERYX) {
      ({ timedOut } = yield awaitResponse({
        startTime,
        fetchResponse: () => call(api.getGatewayResponse, id, 'HELLO'),
        timeout: REBOOT_TIMEOUT,
      }));
    } else {
      ({ timedOut } = yield waitForGatewaySignal({
        id,
        fromTime: Date.parse(response.timestamp),
        timeout: REBOOT_TIMEOUT,
      }));
    }
  }

  return { timedOut };
}

function* updateGatewayConfigurationSaga({
  id, configuration, full = false, awaitCompletion = true,
}) {
  const result = yield call(api.updateGatewayConfiguration, id, configuration, full);
  yield put(gatewayConfigurationUpdated({ id, configuration: result.configuration }));

  if (awaitCompletion) {
    yield awaitGatewayConfigurationUpdateSaga({ id });
  }
}

function* pushGatewayConfigurationSaga({ id, full = false, awaitCompletion = true }) {
  yield call(api.pushGatewayConfiguration, id, full);

  if (awaitCompletion) {
    yield awaitGatewayConfigurationUpdateSaga({ id });
  }
}

function* awaitGatewayConfigurationUpdateSaga({ id }) {
  const { data: { gateway }, timedOut } = yield waitUntil({
    timeout: MQTT_GATEWAY_CONFIG_TIMEOUT,
    fetch: () => fetchGatewaySaga({ id, include: ['lastConfigurationUpdate'] }),
    exitCondition: ({ gateway: { lastConfigurationUpdate } }) => {
      const { pushed, updated } = lastConfigurationUpdate;
      return Date.parse(updated) > Date.parse(pushed);
    },
  });

  if (timedOut) {
    throw new SagaError('Configuration of the device was not updated within timeout', 'CONF_TIMEOUT');
  }
  if (!gateway.lastConfigurationUpdate.success) {
    throw new SagaError('Error while updating configuration of the device', 'CONF_ERROR');
  }

  // Wait for possible reboot of NDS devices after configuration update
  if ([GATEWAY_HW_TYPES.NDS, GATEWAY_HW_TYPES.NDS_HYBRID].includes(gateway.hardwareType)) {
    const time = yield getServerTime();
    const timeSinceUpdate = time - Date.parse(gateway.lastConfigurationUpdate.updated);

    // NDS firmware < v0.21 doesn't cleanly disconnect on reboots causing the device to still
    // show as online during reboots. As a workaround, wait for any communication of the device.
    // If there is no message within the reboot timeout, it's assumed that there was no reboot
    if (!gateway.firmware || compareVersions(gateway.firmware, '0.21') < 0) {
      const waitTimeout = REBOOT_TIMEOUT - timeSinceUpdate;
      if (waitTimeout > 0) {
        yield waitForGatewaySignal({
          id,
          fromTime: Date.parse(gateway.lastConfigurationUpdate.updated),
          timeout: waitTimeout,
        });
      }
    } else {
      // Wait some time after config update for status to update in case of a reboot
      const statusDelay = REBOOT_STATUS_DELAY - timeSinceUpdate;
      if (statusDelay > 0) {
        yield delay(statusDelay);
      }

      const waitTimeout = REBOOT_TIMEOUT + Math.min(0, statusDelay);
      if (waitTimeout > 0) {
        yield waitUntil({
          timeout: waitTimeout,
          fetch: () => call(api.getGatewayStatus, id),
          exitCondition: ({ status: { isOnline } }) => isOnline,
        });
      }
    }
  }
}

function* fetchSensorsSaga({
  pageNumber, pageSize, query = {}, include, clear = false,
}) {
  if (query.sortBy === 'distance' && !query.geolocation) {
    query.geolocation = yield call(getGeolocation);
  }

  const result = yield call(api.fetchSensors, {
    ...query,
    pageNumber,
    pageSize,
    include,
  });

  return {
    sensors: result.sensors,
    total: result.meta.total,
    pageNumber: result.meta.pageNumber,
    query,
    clear,
  };
}

function* fetchSensorSaga({ id, uuid, include }) {
  if (id) {
    return yield call(api.fetchSensor, id, include);
  }
  if (uuid) {
    return yield call(api.fetchSensorByUuid, uuid, include);
  }
}

function* editSensorSaga({ id, ...attributes }) {
  return yield call(api.editSensor, id, attributes);
}

function* removeSensorSaga({ id, uuid, lifecycleState }) {
  if (id) {
    yield call(api.removeSensor, id, lifecycleState);
    return { id };
  }
  if (uuid) {
    yield call(api.removeSensorByUuid, uuid, lifecycleState);
    return { uuid };
  }
}

function* updateSensorStatusSaga({ id, include }) {
  const { status } = yield call(api.getSensorStatus, id, include);
  return { id, status };
}

function* updateSensorMetricsSaga({ id }) {
  const { metrics } = yield call(api.getSensorMetrics, id);
  return { id, metrics };
}

function* updateSensorGatewaysSaga({ id, include }) {
  const { gateways } = yield call(api.getSensorGateways, id, include);
  return { id, gateways };
}

function* checkSensorConnectivitySaga({ id, ignoreSignalStrength }) {
  let timedOut = false;
  let { sensor } = yield fetchSensorSaga({ id });

  const getConnectivity = (status, timeout = false) => {
    const { isOnline, signal: { timestamp, signal } } = status;

    let rating = null;
    if (signal != null) {
      rating = signal >= SENSOR_SIGNAL_THRESHOLDS.poor ? 'good' : 'poor';
    }

    return {
      isOnline: isOnline && !timeout,
      signalStrength: {
        timestamp,
        rating,
      },
    };
  };

  // Ping apteryx sensors to check connectivity and retrieve fresh 868 stats
  const startTime = yield getServerTime({ stripMilliseconds: true });
  if (sensor.protocol === HW_PROTOCOLS.APTERYX) {
    ({ timedOut } = yield pingSensorSaga({ id, protocol: sensor.protocol }));
  }

  // For MQTT sensors ping is not necessary, but fresh 868 stats are requested, too
  if (sensor.protocol === HW_PROTOCOLS.MQTT && !ignoreSignalStrength) {
    // Command might time out if it's not supported by the device
    // In this case, signal strength is ignored
    const params = {
      id,
      protocol: sensor.protocol,
      command: 'LINK-CHECK',
    };
    const result = yield ignoreErrors(() => sendSensorCommand(params), { code: 'DEVICE_OFFLINE' });
    if (!result || result.timedOut) {
      ignoreSignalStrength = true;
    }
  }

  // Wait for signal of sensor
  if (!timedOut) {
    ({ timedOut } = yield waitUntil({
      timeout: CONNECTIVITY_TIMEOUT,
      fetch: () => call(api.getSensorStatus, id, ['signal']),
      exitCondition: ({ status }) => {
        const { isOnline, signalStrength: { timestamp } } = getConnectivity(status);
        return ignoreSignalStrength
          ? isOnline
          : isOnline && Date.parse(timestamp) >= startTime;
      },
    }));
  }

  // Fetch latest sensor information
  ({ sensor } = yield fetchSensorSaga({
    id,
    include: [
      'status',
      'status.signal',
      'status.gateway',
      'status.gateway.status',
      'status.gateway.status.signal',
    ],
  }));

  return {
    sensor,
    connectivity: getConnectivity(sensor.status, timedOut),
  };
}

function* pingSensorSaga({ id, protocol }) {
  return yield sendSensorCommand({ id, protocol, command: 'PING' });
}

function* getSensor868InfoSaga({ id, protocol }) {
  if (!protocol) {
    ({ sensor: { protocol } } = yield fetchSensorSaga({ id }));
  }

  let timedOut = false;
  const startTime = yield getServerTime({ stripMilliseconds: true });
  if (protocol === HW_PROTOCOLS.APTERYX) {
    // 868 info is sent along with pongs
    ({ timedOut } = yield sendSensorCommand({ id, protocol, command: 'PING' }));
  } else {
    ({ timedOut } = yield sendSensorCommand({ id, protocol, command: 'LINK-CHECK' }));
  }

  // Wait for 868 info to update
  let status = null;
  if (!timedOut) {
    ({ data: { status }, timedOut } = yield waitUntil({
      timeout: UPDATE_868_INFO_TIMEOUT,
      fetch: () => call(api.getSensorStatus, id, ['signal']),
      exitCondition: data => data.status && data.status.signal
        && Date.parse(data.status.signal.timestamp) >= startTime,
    }));
  }

  return {
    timedOut,
    sensorId: id,
    status,
  };
}

function* rebootSensorSaga({ id, protocol }) {
  if (!protocol) {
    ({ sensor: { protocol } } = yield fetchSensorSaga({ id }));
  }

  const startTime = yield getServerTime();
  let { response, timedOut } = yield sendSensorCommand({
    id,
    protocol,
    command: 'REBOOT',
    responseTypes: protocol === HW_PROTOCOLS.APTERYX ? ['REBOOT', 'HELLO'] : ['REBOOT'],
  });

  if (!timedOut) {
    if (protocol === HW_PROTOCOLS.APTERYX) {
      ({ timedOut } = yield awaitResponse({
        startTime,
        fetchResponse: () => call(api.getSensorResponse, id, 'HELLO'),
        timeout: REBOOT_TIMEOUT,
      }));
    } else {
      ({ timedOut } = yield waitForSensorSignal({
        id,
        fromTime: Date.parse(response.timestamp),
        timeout: REBOOT_TIMEOUT,
      }));
    }
  }

  // For apteryx devices reboot seems to have failed, if we
  // miss the HELLO message. To make sure that the device
  // has the current time, a ping is sent in any case
  if (protocol === HW_PROTOCOLS.APTERYX) {
    yield delay(REBOOT_PING_DELAY);
    yield pingSensorSaga({ id, protocol });
  }

  return { timedOut };
}

function* getSensorBatteryInfoSaga({ id }) {
  // Request latest battery information from sensor
  const startTime = yield getServerTime();
  const { timedOut: battTimedOut } = yield sendSensorCommand({ id, command: 'BATT' });
  if (battTimedOut) {
    throw new SagaError('Sensor did not respond within timeout', 'TIMEOUT');
  }

  // Fetch updated battery information
  const { data: { batteryInfo }, timedOut: infoTimedOut } = yield waitUntil({
    timeout: BATTERY_INFO_TIMEOUT,
    fetch: () => call(api.getSensorBatteryInfo, id),
    exitCondition: ({ batteryInfo: bi }) => {
      const timestamp = bi && bi.timestamp;
      return Date.parse(timestamp) > startTime;
    },
  });
  if (infoTimedOut) {
    throw new SagaError('Sensor did not respond within timeout', 'TIMEOUT');
  }

  return { batteryInfo };
}

function* initializeSensorRebattSaga({ id }) {
  const { timedOut } = yield sendSensorCommand({ id, command: 'REBATT' });
  if (timedOut) {
    throw new SagaError('Sensor did not respond within timeout', 'TIMEOUT');
  }
}

function* finalizeSensorRebattSaga({ id }) {
  const { timedOut } = yield sendSensorCommand({ id, command: 'MOUNT' });
  if (timedOut) {
    throw new SagaError('Sensor did not respond within timeout', 'TIMEOUT');
  }
}

function* grantSensorPermissionSaga({ id, timeout }) {
  const { taskId } = yield call(api.grantSensorPermission, id);
  if (taskId) {
    yield waitForBackgroundTask({
      id: taskId,
      timeout: BACKGROUND_TASK_TIMEOUTS.grantPermission,
    });
  }

  yield awaitSensorKisUpdateSaga({ id, timeout });
}

function* revokeSensorPermissionSaga({ id, timeout }) {
  const { taskIds } = yield call(api.revokeSensorPermission, id);
  if (taskIds && taskIds.length > 0) {
    yield all(taskIds.map(taskId => waitForBackgroundTask({
      id: taskId,
      timeout: BACKGROUND_TASK_TIMEOUTS.revokePermission,
    })));
  }

  yield awaitSensorKisUpdateSaga({ id, timeout });
}

function* openSensorSaga({ id, responseTimeout = HW_RESPONSE_TIMEOUT }) {
  const { timedOut } = yield sendSensorCommand({ id, command: 'OPEN', responseTimeout });
  if (timedOut) {
    throw new SagaError('Sensor did not respond within timeout', 'TIMEOUT');
  }
}

function* updateSensorConfigurationSaga({
  id, configuration, full = false, awaitCompletion = true,
}) {
  const result = yield call(api.updateSensorConfiguration, id, configuration, full);
  yield put(sensorConfigurationUpdated({ id, configuration: result.configuration }));

  if (awaitCompletion) {
    yield awaitSensorConfigurationUpdateSaga({ id });
  }
}

function* pushSensorConfigurationSaga({ id, full = false, awaitCompletion = true }) {
  yield call(api.pushSensorConfiguration, id, full);

  if (awaitCompletion) {
    yield awaitSensorConfigurationUpdateSaga({ id });
  }
}

function* kiSignalStrengthTestStage({
  id, configOption, signalStrength, stages, waitTimeout,
}) {
  yield updateSensorConfigurationSaga({
    id,
    configuration: setConfigOption({}, configOption, signalStrength),
  });

  let timeDiff = 0;
  const startTime = new Date();
  const stageProgress = stages.indexOf(signalStrength) / stages.length;
  do {
    timeDiff = (new Date() - startTime);
    const progress = (stageProgress + (timeDiff / waitTimeout / stages.length)) * 100;
    yield put(kiSignalTestProgress({ progress }));
    yield delay(100);
  } while (timeDiff < waitTimeout);
}

function* startKiSignalTestSaga({ id, hardwareType, waitTimeout }) {
  yield put(kiSignalTestProgress({ status: 'initializing', progress: 0 }));

  if (!hardwareType) {
    ({ sensor: { hardwareType } } = yield fetchSensorSaga({ id }));
  }

  const options = KI_SIGNAL_TEST_OPTIONS[hardwareType];
  if (!options) {
    throw new SagaError('This device does not support testing the Ki signal strength', 'UNSUPPORTED');
  }
  const { configOption, stages } = options;

  yield put(kiSignalTestProgress({ status: 'testing', progress: 0 }));

  let min = null;
  let max = null;
  for (const [i, stage] of stages.entries()) {
    const progressStages = min === null
      ? stages
      : stages.slice(stages.indexOf(min) + 1);

    const { opened } = yield race({
      opened: take(kiSignalTestDoorOpened.toString()),
      stage: kiSignalStrengthTestStage({
        id,
        configOption,
        signalStrength: stage,
        stages: progressStages,
        waitTimeout,
      }),
    });

    if (opened) {
      if (min === null) {
        min = stage;

        // If min signal strength is maximum supported value,
        // second test can be skipped as there is no bigger value to test with
        if (i === stages.length - 1) {
          max = stage;
        }
      } else {
        max = stage;
      }

      if (max === null) {
        yield put(kiSignalTestProgress({ status: 'repositioning', progress: 0 }));
        yield take(kiSignalTestRepositioned.toString());
        yield put(kiSignalTestProgress({ status: 'testing', progress: 0 }));
      } else {
        yield put(kiSignalTestProgress({ status: 'optimizing', progress: 0 }));
        break;
      }
    }
  }

  if (max === null) {
    throw new SagaError('Door did not open with maximum signal strength', 'MAX_SIGNAL_STRENGTH');
  }

  const minIndex = stages.indexOf(min);
  const maxIndex = stages.indexOf(max);
  const optimal = stages[Math.floor((minIndex + maxIndex) / 2)];

  if (optimal !== max) {
    yield updateSensorConfigurationSaga({
      id,
      configuration: setConfigOption({}, configOption, optimal),
    });
  } else {
    awaitSensorConfigurationUpdateSaga({ id });
  }

  yield put(kiSignalTestProgress({ status: 'done', progress: 0 }));
}

function* awaitSensorConfigurationUpdateSaga({ id, protocol }) {
  if (!protocol) {
    ({ sensor: { protocol } } = yield fetchSensorSaga({ id }));
  }

  const timeout = protocol === HW_PROTOCOLS.MQTT
    ? MQTT_SENSOR_CONFIG_TIMEOUT
    : APTERYX_SENSOR_CONFIG_TIMEOUT;
  const { data: { sensor }, timedOut } = yield waitUntil({
    timeout,
    fetch: () => fetchSensorSaga({ id, include: ['lastConfigurationUpdate'] }),
    exitCondition: ({ sensor: { lastConfigurationUpdate } }) => {
      const { pushed, updated } = lastConfigurationUpdate;
      return Date.parse(updated) > Date.parse(pushed);
    },
  });

  if (timedOut) {
    throw new SagaError('Configuration of the device was not updated within timeout', 'CONF_TIMEOUT');
  }
  if (!sensor.lastConfigurationUpdate.success) {
    throw new SagaError('Error while updating configuration of the device', 'CONF_ERROR');
  }

  // Wait for possible reboot of NDS devices after configuration update
  if ([SENSOR_HW_TYPES.NDS, SENSOR_HW_TYPES.NDS_HYBRID].includes(sensor.hardwareType)) {
    const time = yield getServerTime();
    const timeSinceUpdate = time - Date.parse(sensor.lastConfigurationUpdate.updated);

    // Wait some time after config update for status to update in case of a reboot
    const statusDelay = REBOOT_STATUS_DELAY - timeSinceUpdate;
    if (statusDelay > 0) {
      yield delay(statusDelay);
    }

    const waitTimeout = REBOOT_TIMEOUT + Math.min(0, statusDelay);
    if (waitTimeout > 0) {
      yield waitUntil({
        timeout: waitTimeout,
        fetch: () => call(api.getSensorStatus, id),
        exitCondition: ({ status: { isOnline } }) => isOnline,
      });
    }
  }
}

function* awaitSensorKisUpdateSaga({ id, protocol }) {
  if (!protocol) {
    ({ sensor: { protocol } } = yield fetchSensorSaga({ id }));
  }

  const timeout = protocol === HW_PROTOCOLS.MQTT
    ? MQTT_SENSOR_CONFIG_TIMEOUT
    : APTERYX_SENSOR_CONFIG_TIMEOUT;

  const { data: { sensor }, timedOut } = yield waitUntil({
    timeout,
    fetch: () => fetchSensorSaga({ id, include: ['lastKisUpdate'] }),
    exitCondition: ({ sensor: { lastKisUpdate } }) => {
      const { pushed, updated } = lastKisUpdate;
      return Date.parse(updated) > Date.parse(pushed);
    },
  });

  if (timedOut) {
    throw new SagaError('Kis of the device could not be updated within timeout', 'CONF_TIMEOUT');
  }
  if (!sensor.lastKisUpdate.success) {
    throw new SagaError('Error while updating kis of the device', 'CONF_ERROR');
  }
}

function* waitUntil({
  fetch, exitCondition, timeout, interval = DEFAULT_POLL_INTERVAL,
}) {
  let data;
  const startTime = new Date();
  do {
    data = yield fetch();
    if (exitCondition(data)) {
      return { data, timedOut: false };
    }
    yield delay(interval);
  } while (new Date() - startTime < timeout);

  return { data, timedOut: true };
}

function* waitForBackgroundTask({
  id, timeout, onStageChange, onProgressChange,
}) {
  let lastStage = null;
  let lastProgress = null;
  const { data: { task }, timedOut } = yield waitUntil({
    timeout,
    fetch: function* fetch() {
      const result = yield call(api.getBackgroundTask, id);

      if (result.task.stage !== lastStage && onStageChange) {
        lastStage = result.task.stage;
        yield onStageChange(result.task.stage);
      }

      if (result.task.progress !== lastProgress && onProgressChange) {
        lastProgress = result.task.progress;
        yield onProgressChange(result.task.progress);
      }

      return result;
    },
    exitCondition: ({ task: { created, finished } }) => Date.parse(finished) > Date.parse(created),
  });

  if (timedOut) {
    throw new SagaError('Background task did not finish within timeout', 'BACKGROUND_TASK_TIMEOUT');
  }
  if (task.status !== 'success') {
    const message = _.get(task, 'result.error.description') || 'Execution of the background task failed';
    const code = (_.get(task, 'result.error.reason') || 'BACKGROUND_TASK_ERROR').toUpperCase();

    throw new SagaError(message, code);
  }

  return { task };
}

function* waitForGatewaySignal({ id, fromTime, timeout }) {
  if (!fromTime) {
    fromTime = yield getServerTime();
  }

  return yield waitUntil({
    timeout,
    fetch: () => call(api.getGatewayStatus, id),
    exitCondition: ({ status }) => {
      const { isOnline, timestamp } = status;
      return isOnline && Date.parse(timestamp) > fromTime;
    },
  });
}

function* waitForSensorSignal({ id, fromTime, timeout }) {
  if (!fromTime) {
    fromTime = yield getServerTime();
  }

  return yield waitUntil({
    timeout,
    fetch: () => call(api.getSensorStatus, id),
    exitCondition: ({ status }) => {
      const { isOnline, timestamp } = status;
      return isOnline && Date.parse(timestamp) > fromTime;
    },
  });
}

function* sendGatewayCommand({
  id, protocol, command, responseTypes, responseTimeout = HW_RESPONSE_TIMEOUT,
}) {
  if (!protocol) {
    ({ gateway: { protocol } } = yield fetchGatewaySaga({ id }));
  }
  if (!responseTypes) {
    responseTypes = [command];
  }

  return yield sendCommandAwaitResponse({
    protocol,
    responseTimeout,
    sendCommand: () => call(api.sendGatewayCommand, id, command),
    fetchResponse: () => getLatestResponse({
      responseTypes,
      fetchFn: type => call(api.getGatewayResponse, id, type),
    }),
  });
}

function* sendSensorCommand({
  id, protocol, command, responseTypes, responseTimeout = HW_RESPONSE_TIMEOUT,
}) {
  if (!protocol) {
    ({ sensor: { protocol } } = yield fetchSensorSaga({ id }));
  }
  if (!responseTypes) {
    responseTypes = [command];
  }

  return yield sendCommandAwaitResponse({
    protocol,
    responseTimeout,
    sendCommand: () => call(api.sendSensorCommand, id, command),
    fetchResponse: () => getLatestResponse({
      responseTypes,
      fetchFn: type => call(api.getSensorResponse, id, type),
    }),
  });
}

function* getLatestResponse({ responseTypes, fetchFn }) {
  const responses = yield all(responseTypes.map(fetchFn));
  return _.orderBy(
    responses,
    ({ response }) => (response ? response.timestamp : ''),
    'desc',
  )[0];
}

function* sendCommandAwaitResponse({
  protocol, sendCommand, fetchResponse, responseTimeout,
}) {
  let tries = 0;
  const retries = protocol === HW_PROTOCOLS.APTERYX ? HW_COMMAND_RETRIES : 0;
  const startTime = yield getServerTime();
  while (tries < retries + 1) {
    yield sendCommand();
    if (!responseTimeout) {
      return { response: null, timedOut: false };
    }

    const { response, timedOut } = yield awaitResponse({
      startTime,
      fetchResponse,
      timeout: responseTimeout,
    });

    tries += 1;
    if (!timedOut) {
      return { response, timedOut: false };
    }
  }

  return { timedOut: true };
}

function* awaitResponse({ startTime, fetchResponse, timeout = HW_RESPONSE_TIMEOUT }) {
  const { data: { response }, timedOut } = yield waitUntil({
    timeout,
    interval: HW_RESPONSE_POLL_INTERVAL,
    fetch: fetchResponse,
    exitCondition: ({ response: resp }) => resp
      && resp.success !== false
      && Date.parse(resp.timestamp) > startTime,
  });

  return { response, timedOut };
}

function* getServerTime({ stripMilliseconds = false } = {}) {
  const { time } = yield call(api.getServerTime);

  const date = new Date(time);
  if (stripMilliseconds) {
    // This is needed when comparing with timestamps that are coming directly
    // from devices in the field as these only have a precision of 1 second
    date.setMilliseconds(0);
  }

  return date.getTime();
}

export default registerSagas([
  [fetchHardware, fetchHardwareSaga, { cancelAction: stopRoutines }],
  [registerHardware, registerHardwareSaga, { cancelAction: stopRoutines }],
  [fetchHybrid, fetchHybridSaga, { cancelAction: stopRoutines }],
  [fetchGateway, fetchGatewaySaga, { cancelAction: stopRoutines }],
  [fetchGateways, fetchGatewaysSaga, { cancelAction: stopRoutines, effectCreator: takeLatest }],
  [editGateway, editGatewaySaga, { cancelAction: stopRoutines }],
  [removeGateway, removeGatewaySaga, { cancelAction: stopRoutines }],
  [getGatewayWithStatus, getGatewayWithStatusSaga, { cancelAction: stopRoutines }],
  [getSensorWithStatus, getSensorWithStatusSaga, { cancelAction: stopRoutines }],
  [updateGatewayStatus, updateGatewayStatusSaga, { cancelAction: stopRoutines }],
  [updateGatewayMetrics, updateGatewayMetricsSaga, { cancelAction: stopRoutines }],
  [updateGatewaySensors, updateGatewaySensorsSaga, { cancelAction: stopRoutines }],
  [checkGatewayConnectivity, checkGatewayConnectivitySaga, { cancelAction: stopRoutines }],
  [checkGatewayLinkPerformance, checkGatewayLinkPerformanceSaga, { cancelAction: stopRoutines }],
  [getGatewayGsmInfo, getGatewayGsmInfoSaga, { cancelAction: stopRoutines }],
  [pingGateway, pingGatewaySaga, { cancelAction: stopRoutines }],
  [rebootGateway, rebootGatewaySaga, { cancelAction: stopRoutines }],
  [updateGatewayConfiguration, updateGatewayConfigurationSaga, { cancelAction: stopRoutines }],
  [pushGatewayConfiguration, pushGatewayConfigurationSaga, { cancelAction: stopRoutines }],
  [awaitGatewayConfigurationUpdate, awaitGatewayConfigurationUpdateSaga, {
    cancelAction: stopRoutines,
  }],
  [fetchSensor, fetchSensorSaga, { cancelAction: stopRoutines }],
  [fetchSensors, fetchSensorsSaga, { cancelAction: stopRoutines, effectCreator: takeLatest }],
  [editSensor, editSensorSaga, { cancelAction: stopRoutines }],
  [removeSensor, removeSensorSaga, { cancelAction: stopRoutines }],
  [updateSensorStatus, updateSensorStatusSaga, { cancelAction: stopRoutines }],
  [updateSensorMetrics, updateSensorMetricsSaga, { cancelAction: stopRoutines }],
  [updateSensorGateways, updateSensorGatewaysSaga, { cancelAction: stopRoutines }],
  [checkSensorConnectivity, checkSensorConnectivitySaga, { cancelAction: stopRoutines }],
  [getSensorBatteryInfo, getSensorBatteryInfoSaga, { cancelAction: stopRoutines }],
  [initializeSensorRebatt, initializeSensorRebattSaga, { cancelAction: stopRoutines }],
  [finalizeSensorRebatt, finalizeSensorRebattSaga, { cancelAction: stopRoutines }],
  [grantSensorPermission, grantSensorPermissionSaga, { cancelAction: stopRoutines }],
  [revokeSensorPermission, revokeSensorPermissionSaga, { cancelAction: stopRoutines }],
  [getSensor868Info, getSensor868InfoSaga, { cancelAction: stopRoutines }],
  [openSensor, openSensorSaga, { cancelAction: stopRoutines }],
  [pingSensor, pingSensorSaga, { cancelAction: stopRoutines }],
  [rebootSensor, rebootSensorSaga, { cancelAction: stopRoutines }],
  [updateSensorConfiguration, updateSensorConfigurationSaga, { cancelAction: stopRoutines }],
  [pushSensorConfiguration, pushSensorConfigurationSaga, { cancelAction: stopRoutines }],
  [awaitSensorKisUpdate, awaitSensorKisUpdateSaga, { cancelAction: stopRoutines }],
  [awaitSensorConfigurationUpdate, awaitSensorConfigurationUpdateSaga, {
    cancelAction: stopRoutines,
  }],
  [startKiSignalTest, startKiSignalTestSaga, { cancelAction: stopRoutines }],
]);
