import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Divider, Header } from 'semantic-ui-react';

import { withTools } from '~/app/contexts/ToolsContext';
import { withNavigation } from '~/app/contexts/NavigationContext';
import '~/app/styles/components/AppHeader.css';


class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.primaryTextRef = React.createRef();
    this.secondaryTextRef = React.createRef();
  }

  componentDidMount() {
    const { marquee } = this.props;

    if (marquee) {
      this.updateAnimation(this.primaryTextRef.current, marquee);
      this.updateAnimation(this.secondaryTextRef.current, marquee);
    }
  }

  componentDidUpdate(prevProps) {
    const { marquee, primaryText, secondaryText } = this.props;
    const {
      marquee: prevMarquee,
      primaryText: prevPrimaryText,
      secondaryText: prevSecondaryText,
    } = prevProps;

    if (marquee !== prevMarquee || primaryText !== prevPrimaryText) {
      this.updateAnimation(this.primaryTextRef.current, marquee);
    }
    if (marquee !== prevMarquee || secondaryText !== prevSecondaryText) {
      this.updateAnimation(this.secondaryTextRef.current, marquee);
    }
  }

  updateAnimation = (element, active) => {
    const widthDelta = element.offsetWidth - element.parentElement.offsetWidth;
    if (active && widthDelta > 0) {
      const duration = Math.min(Math.max(4, 0.1 * widthDelta), 20);
      element.style.setProperty('--marquee-duration', `${duration}s`);
      element.style.setProperty('--marquee-width-delta', `${widthDelta}px`);
      element.classList.add('marquee');
    } else {
      element.classList.remove('marquee');
    }
  }

  goBack = () => {
    const { navigator, back } = this.props;
    if (back) {
      back();
    } else {
      navigator.goBack();
    }
  }

  render() {
    const {
      hideBack, primaryText, secondaryText, action, actionIcon,
    } = this.props;
    const { showSidebar } = this.props.tools;

    return (
      <>
        <div className="header-content">
          {!hideBack && (
            <div className="header-column back">
              <Icon link name="angle left" color="grey" size="big" onClick={this.goBack} />
            </div>
          )}
          <div className="header-column text">
            <Header size="tiny">
              <span ref={this.primaryTextRef}>{primaryText}</span>
              <Header.Subheader>
                <span ref={this.secondaryTextRef}>{secondaryText}</span>
              </Header.Subheader>
            </Header>
          </div>
          {action && (
            <div className="header-column action">
              <Icon link name={actionIcon} color="grey" size="large" onClick={action} />
            </div>
          )}
          {showSidebar && (
            <div className="header-column action">
              <Icon link name="bars" color="grey" size="large" onClick={showSidebar} />
            </div>
          )}
        </div>
        <Divider fitted className="header-divider" />
      </>
    );
  }
}

AppHeader.defaultProps = {
  back: null,
  hideBack: false,
  secondaryText: '',
  marquee: false,
  actionIcon: null,
  action: null,
};

AppHeader.propTypes = {
  back: PropTypes.func,
  hideBack: PropTypes.bool,
  primaryText: PropTypes.node.isRequired,
  secondaryText: PropTypes.node,
  marquee: PropTypes.bool,
  action: PropTypes.func,
  actionIcon: PropTypes.string,
};

export default withTools(withNavigation(AppHeader));
