import React, { Component } from 'react';
import { Icon, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import View from '~/app/views/View';
import '~/app/styles/views/ErrorView.css';


export default class ErrorView extends Component {
  render() {
    return (
      <View>
        <View.Content>
          <div className="error-view-container">
            <div className="error-message">
              <Header size="medium">
                <FormattedMessage
                  id="ErrorView.header"
                  defaultMessage="An unexpected error occurred"
                />
              </Header>
              <p className="error-instructions">
                <FormattedMessage
                  id="ErrorView.instructions"
                  defaultMessage="Please try reloading the app by pressing the button below. If the problem persists, please contact the technical support."
                />
              </p>
              <Icon
                link
                name="refresh"
                className="reload-icon"
                onClick={() => window.location.reload()}
              />
            </div>
          </div>
        </View.Content>
      </View>
    );
  }
}
