import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Dropdown, Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import {
  GATEWAY_HW_TYPES, SENSOR_HW_TYPES,
  HW_PROTOCOLS, HW_CONFIG_OPTIONS,
  UNIT_DBM,
} from '~/common/constants';
import { getConfigOption, setConfigOption } from '~/common/utils/hardwareConfiguration';
import CONFIG_OPTION_MESSAGES from '~/common/locales/HardwareConfigOptions';
import { GatewayType, SensorType } from '~/common/types';
import {
  getGatewayGsmInfo, pingGateway, rebootGateway, updateGatewayConfiguration,
  getSensor868Info, pingSensor, rebootSensor, updateSensorConfiguration,
} from '~/app/actions/HardwareActions';
import SegmentDivider from '~/common/components/SegmentDivider';
import Placeholder from '~/common/components/Placeholder';
import '~/app/styles/components/HardwareActions.css';


const CONFIG_OPTION_LABELS = defineMessages({
  GATEWAY_868_MODE: {
    id: 'HardwareActions.configuration.gateway868Mode.label',
    defaultMessage: '868Mhz mode',
  },
  KI_SIGNAL_STRENGTH: {
    id: 'HardwareActions.configuration.kiSignalStrength.label',
    defaultMessage: '2.4GHz signal strength',
  },
  KI_TX_POWER: {
    id: 'HardwareActions.configuration.kiTxPower.label',
    defaultMessage: '2.4GHz signal strength',
  },
});

class HardwareActions extends Component {
  state = {
    currentAction: null,
    configValues: this.currentConfigValues,
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    const { error: prevError } = prevProps;

    if (error && !prevError) {
      this.resetConfigurationForm();
    }
  }

  get supportedConfigOptions() {
    const { hardware } = this.props;

    return Object.entries(HW_CONFIG_OPTIONS)
      .filter(([, value]) => value.hardwareTypes.includes(hardware.hardwareType))
      .map(([key]) => key);
  }

  get currentConfigValues() {
    const { hardware } = this.props;

    return Object.fromEntries(this.supportedConfigOptions
      .map(key => [key, getConfigOption(hardware.configuration, key)]));
  }

  ping = () => {
    const { hardware: { sensorId, gatewayId, protocol } } = this.props;
    if (sensorId != null) {
      this.props.pingSensor({ id: sensorId, protocol });
    } else {
      this.props.pingGateway({ id: gatewayId, protocol });
    }

    this.setState({ currentAction: 'ping' });
  }

  updateGsmInfo = () => {
    const { hardware: { gatewayId, protocol } } = this.props;
    if (gatewayId != null) {
      this.props.getGatewayGsmInfo({ id: gatewayId, protocol });
      this.setState({ currentAction: 'updateGsmInfo' });
    }
  }

  update868Info = () => {
    const { hardware: { sensorId, protocol } } = this.props;
    if (sensorId != null) {
      this.props.getSensor868Info({ id: sensorId, protocol });
      this.setState({ currentAction: 'update868Info' });
    }
  }

  reboot = () => {
    const { hardware: { sensorId, gatewayId, protocol } } = this.props;
    if (sensorId != null) {
      this.props.rebootSensor({ id: sensorId, protocol });
    } else {
      this.props.rebootGateway({ id: gatewayId, protocol });
    }

    this.setState({ currentAction: 'reboot' });
  }

  updateConfigOption = (key, value) => {
    this.setState(({ configValues }) => ({
      configValues: {
        ...configValues,
        [key]: value,
      },
    }));

    const { hardware } = this.props;
    if (value !== getConfigOption(hardware.configuration, key)) {
      const [actionCreator, id] = hardware.sensorId != null
        ? [this.props.updateSensorConfiguration, hardware.sensorId]
        : [this.props.updateGatewayConfiguration, hardware.gatewayId];

      actionCreator({
        id,
        configuration: setConfigOption({}, key, value),
      });
    }
  }

  resetConfigurationForm = () => {
    this.setState({ configValues: this.currentConfigValues });
  }

  renderCommandSection = () => {
    const {
      hardware, ping, reboot, gatewayGsmInfoUpdate, sensor868InfoUpdate,
    } = this.props;
    const { currentAction } = this.state;

    const supportsPing = hardware.protocol === HW_PROTOCOLS.APTERYX;

    const supportsReboot = hardware.protocol !== HW_PROTOCOLS.KLAP;

    const supportsGsmInfoUpdate = hardware.gatewayId != null
      && hardware.protocol === HW_PROTOCOLS.MQTT
      && hardware.hardwareType !== GATEWAY_HW_TYPES.SDS;

    const supports868InfoUpdate = hardware.gatewayId == null
      && hardware.protocol === HW_PROTOCOLS.MQTT
      && hardware.hardwareType !== SENSOR_HW_TYPES.SDS;

    if (
      !supportsPing
      && !supportsReboot
      && !supportsGsmInfoUpdate
      && !supports868InfoUpdate
    ) {
      return null;
    }

    return (
      <>
        <SegmentDivider>
          <FormattedMessage id="HardwareActions.commands.header" defaultMessage="Device control" />
        </SegmentDivider>

        {supportsPing && (
          <Button
            fluid
            className="action-button"
            disabled={ping.loading}
            loading={ping.loading}
            primary={currentAction === 'ping' && !ping.loading && ping.success}
            negative={currentAction === 'ping' && !ping.loading && !ping.success}
            onClick={this.ping}
          >
            <FormattedMessage id="HardwareActions.commands.ping" defaultMessage="Ping hardware" />
          </Button>
        )}

        {supportsGsmInfoUpdate && (
          <Button
            fluid
            className="action-button"
            disabled={gatewayGsmInfoUpdate.loading}
            loading={gatewayGsmInfoUpdate.loading}
            primary={currentAction === 'updateGsmInfo' && !gatewayGsmInfoUpdate.loading && gatewayGsmInfoUpdate.success}
            negative={currentAction === 'updateGsmInfo' && !gatewayGsmInfoUpdate.loading && !gatewayGsmInfoUpdate.success}
            onClick={this.updateGsmInfo}
          >
            <FormattedMessage id="HardwareActions.commands.cellQuality" defaultMessage="Request GSM information" />
          </Button>
        )}

        {supports868InfoUpdate && (
          <Button
            fluid
            className="action-button"
            disabled={sensor868InfoUpdate.loading}
            loading={sensor868InfoUpdate.loading}
            primary={currentAction === 'update868Info' && !sensor868InfoUpdate.loading && sensor868InfoUpdate.success}
            negative={currentAction === 'update868Info' && !sensor868InfoUpdate.loading && !sensor868InfoUpdate.success}
            onClick={this.update868Info}
          >
            <FormattedMessage id="HardwareActions.commands.linkCheck" defaultMessage="Request 868 information" />
          </Button>
        )}

        {supportsReboot && (
          <Button
            fluid
            className="action-button"
            disabled={reboot.loading}
            loading={reboot.loading}
            primary={currentAction === 'reboot' && !reboot.loading && reboot.success}
            negative={currentAction === 'reboot' && !reboot.loading && !reboot.success}
            onClick={this.reboot}
          >
            <FormattedMessage id="HardwareActions.commands.reboot" defaultMessage="Reboot hardware" />
          </Button>
        )}
      </>
    );
  }

  renderConfigurationSection = () => {
    const { configurationUpdate } = this.props;

    if (this.supportedConfigOptions.length === 0) {
      return null;
    }

    const options = {
      GATEWAY_868_MODE: HW_CONFIG_OPTIONS.GATEWAY_868_MODE.values
        .map(value => ({
          value,
          text: this.props.intl.formatMessage(CONFIG_OPTION_MESSAGES.GATEWAY_868_MODE[value]),
        })),
      KI_SIGNAL_STRENGTH: HW_CONFIG_OPTIONS.KI_SIGNAL_STRENGTH.values
        .map(value => ({
          value,
          text: this.props.intl.formatMessage(CONFIG_OPTION_MESSAGES.KI_SIGNAL_STRENGTH[value]),
        })),
      KI_TX_POWER: HW_CONFIG_OPTIONS.KI_TX_POWER.values
        .map(value => ({
          value,
          text: value + UNIT_DBM,
        })),
    };

    return (
      <>
        <SegmentDivider>
          <FormattedMessage id="HardwareActions.configuration.header" defaultMessage="Configuration" />
        </SegmentDivider>

        <Form>
          {this.supportedConfigOptions.map(key => (
            <Form.Field key={key}>
              <label>
                {this.props.intl.formatMessage(CONFIG_OPTION_LABELS[key])}
              </label>
              <Dropdown
                selection
                loading={configurationUpdate.loading}
                disabled={configurationUpdate.loading}
                options={options[key]}
                value={this.state.configValues[key]}
                onChange={(e, { value }) => this.updateConfigOption(key, value)}
              />
            </Form.Field>
          ))}
        </Form>
      </>
    );
  }

  render() {
    return (
      <div className="hardware-actions-container">
        <Placeholder
          text={(
            <FormattedMessage
              id="HardwareActions.notSupported"
              defaultMessage="There are no actions available for this device."
            />
          )}
        >
          {this.renderCommandSection()}
          {this.renderConfigurationSection()}
        </Placeholder>
      </div>
    );
  }
}

HardwareActions.propTypes = {
  hardware: PropTypes.oneOfType([
    GatewayType,
    SensorType,
  ]).isRequired,
};

const mapStateToProps = state => ({
  ping: state.hardware.ping,
  reboot: state.hardware.reboot,
  gatewayGsmInfoUpdate: state.hardware.gatewayGsmInfoUpdate,
  sensor868InfoUpdate: state.hardware.sensor868InfoUpdate,
  configurationUpdate: state.hardware.configurationUpdate,
  error: state.hardware.error,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  pingGateway,
  rebootGateway,
  getGatewayGsmInfo,
  updateGatewayConfiguration,
  pingSensor,
  rebootSensor,
  getSensor868Info,
  updateSensorConfiguration,
}, dispatch);

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(HardwareActions));
