import { combineReducers } from 'redux';

import { logout } from '~/app/actions/AuthActions';
import ErrorsReducer from './ErrorsReducer';
import AuthReducer from './AuthReducer';
import LocaleReducer from './LocaleReducer';
import JobsReducer from './JobsReducer';
import HardwareReducer from './HardwareReducer';
import UsersReducer from './UsersReducer';
import RoutineReducer from './RoutineReducer';

const appReducer = combineReducers({
  errors: ErrorsReducer,
  auth: AuthReducer,
  locale: LocaleReducer,
  jobs: JobsReducer,
  hardware: HardwareReducer,
  users: UsersReducer,
  routine: RoutineReducer,
});

export default (state, action) => {
  // Clear state on logout (except for locales)
  if (action.type === logout.SUCCESS) {
    state = {
      locale: state.locale,
    };
  }

  return appReducer(state, action);
};
