import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear, scaleTime } from 'd3-scale';
import { ResponsiveLine } from '@nivo/line';
import { DotsItem, useTheme } from '@nivo/core';
import moment from 'moment';
import _ from 'lodash';

import rateMetric from '~/common/utils/rateMetric';


const COLORS = {
  default: '#016936',
  ideal: '#016936',
  good: '#fe9a76',
  poor: '#b03060',
};


const aggregateData = (data) => {
  // Merge data points within windows of 10s
  const groups = _.groupBy(data, (dp) => {
    const x = moment(dp.x);
    return x
      .second(Math.round(x.second() / 10) * 10)
      .milliseconds(0)
      .valueOf();
  });

  return Object.entries(groups).map(([key, group]) => ({
    minX: moment.min(group.map(dp => moment(dp.x))),
    maxX: moment.max(group.map(dp => moment(dp.x))),
    x: moment(parseInt(key, 10)).toDate(),
    y: _.meanBy(group, dp => dp.y),
  }));
};

const segmentData = (data, holeThreshold, ratingThresholds) => {
  // Split data into segments based on
  // a) holes in the data that are greater than {holeThreshold} ms
  // b) quality rating of the data points for coloring areas differently according to the rating
  const segments = [];
  _.orderBy(data, [dp => dp.x]).forEach((dp) => {
    let currentSegment = _.last(segments);
    const lastDataPoint = _.last(segments.flatMap(s => s.data));
    const lastDataPointX = lastDataPoint && (lastDataPoint.maxX || lastDataPoint.x);

    const hole = holeThreshold && lastDataPointX && (dp.x - lastDataPointX) >= holeThreshold;
    const rating = rateMetric(dp.y, ratingThresholds) || 'default';
    const ratingChanged = currentSegment && currentSegment.rating !== rating;

    // Add current data point to current series to connect it
    // with new one created in the following
    if (!hole && ratingChanged && dp.y < lastDataPoint.y) {
      currentSegment.data.push(dp);
    }

    if (!currentSegment || hole || ratingChanged) {
      const id = segments.filter(s => s.rating === rating).length;
      currentSegment = {
        id: `${rating}-${id}`,
        rating,
        data: [],
      };
      segments.push(currentSegment);
    }

    // Add last data point to new series to connect it
    // with the previous series
    if (!hole && ratingChanged && dp.y > lastDataPoint.y) {
      currentSegment.data.push(lastDataPoint);
    }

    currentSegment.data.push(dp);
  });

  return segments;
};


const getTicksX = (data, count) => {
  // Tick interval should not be less than one minute
  const min = moment.min(
    ...data.map(dp => moment(dp.x)),
    moment().subtract(count, 'minutes'),
  ).toDate();
  const max = moment().toDate();
  const extent = [min, max];

  return scaleTime()
    .domain(extent)
    .ticks(count);
};


const getTicksY = (data, count) => {
  const min = Math.floor(Math.min(...data.map(dp => dp.y)));
  const max = Math.ceil(Math.max(...data.map(dp => dp.y)));
  const extent = min !== max ? [min, max] : [min - 1, max + 1];

  return _.uniq(
    scaleLinear()
      .domain(extent)
      .nice()
      .ticks(count)
      .map(scaleLinear().tickFormat(10)),
  );
};


const getScaleBounds = (data, axis, ticks, scaleFactor) => {
  const min = Math.min(...data.map(dp => dp[axis]));
  const max = Math.max(...data.map(dp => dp[axis]));
  const diff = max - min;

  const minBound = min - (diff * scaleFactor);
  const maxBound = max + (diff * scaleFactor);

  return [
    Math.min(minBound, ...ticks),
    Math.max(maxBound, ...ticks),
  ];
};


const PointsLayer = ({
  data, points, pointSymbol, pointSize, pointBorderWidth, pointLabelYOffset,
}) => {
  const theme = useTheme();

  // We only wanna render points for segments that consist
  // of a single data point only and are surrounded by
  // holes as those wouldn't be visible otherwise
  const serieIds = data
    .filter((segment, i) => {
      const dp = segment.data[0];
      const prevSegment = data[i - 1];
      const nextSegment = data[i + 1];

      return (
        segment.data.length === 1
        && (!prevSegment || !prevSegment.data.includes(dp))
        && (!nextSegment || !nextSegment.data.includes(dp))
      );
    })
    .map(s => s.id);

  const pointsToRender = points
    .reverse()
    .filter(point => serieIds.includes(point.serieId));

  return (
    <g>
      {pointsToRender.map(point => (
        <DotsItem
          key={point.id}
          x={point.x}
          y={point.y}
          datum={point.data}
          symbol={pointSymbol}
          size={pointSize}
          color={point.color}
          borderWidth={pointBorderWidth}
          borderColor={point.borderColor}
          label={point.label}
          labelYOffset={pointLabelYOffset}
          theme={theme}
        />
      ))}
    </g>
  );
};


const MetricGraph = ({
  data,
  lineWidth,
  pointSize,
  holeThreshold,
  ratingThresholds,
  ticksCountX,
  scaleFactorX,
  ticksCountY,
  scaleFactorY,
}) => {
  data = aggregateData(data);
  const ticksX = getTicksX(data, ticksCountX);
  const ticksY = getTicksY(data, ticksCountY);
  const [minX, maxX] = getScaleBounds(data, 'x', ticksX, scaleFactorX);
  const [minY, maxY] = getScaleBounds(data, 'y', ticksY, scaleFactorY);
  const segments = segmentData(data, holeThreshold, ratingThresholds);
  const colors = segments.map(({ rating }) => COLORS[rating]);

  const pointsLayer = props => <PointsLayer {...props} />;
  const layers = ['grid', 'axes', 'areas', 'lines', pointsLayer];

  return (
    <ResponsiveLine
      data={segments}
      curve="linear"
      xScale={{
        type: 'time',
        format: 'native',
        min: minX,
        max: maxX,
      }}
      yScale={{
        type: 'linear',
        min: minY,
        max: maxY,
      }}
      axisBottom={{
        legend: null,
        format: '%H:%M',
        tickValues: ticksX,
      }}
      axisLeft={{
        legend: null,
        tickValues: ticksY,
      }}
      enableArea
      layers={layers}
      areaBaselineValue={minY}
      enableGridX={false}
      animate={false}
      isInteractive={false}
      lineWidth={lineWidth}
      pointSize={pointSize}
      colors={colors}
      margin={{
        top: 10,
        right: 15,
        bottom: 30,
        left: 40,
      }}
    />
  );
};

MetricGraph.defaultProps = {
  data: [],
  ticksCountX: 6,
  scaleFactorX: 0,
  ticksCountY: 3,
  scaleFactorY: 0.1,
  lineWidth: 2,
  pointSize: 6,
  holeThreshold: null,
  ratingThresholds: null,
};

MetricGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.instanceOf(Date),
      y: PropTypes.number,
    }),
  ),
  ticksCountX: PropTypes.number,
  scaleFactorX: PropTypes.number,
  ticksCountY: PropTypes.number,
  scaleFactorY: PropTypes.number,
  lineWidth: PropTypes.number,
  pointSize: PropTypes.number,
  holeThreshold: PropTypes.number,
  ratingThresholds: PropTypes.shape({
    good: PropTypes.number.isRequired,
    ideal: PropTypes.number.isRequired,
  }),
};

export default MetricGraph;
