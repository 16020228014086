import { defineMessages } from 'react-intl';
import { TASK_TYPES } from '~/common/constants';

export default defineMessages({
  [TASK_TYPES.INSTALLATION]: {
    id: 'TaskListView.installation',
    defaultMessage: 'Installation',
  },
  [TASK_TYPES.FIXING]: {
    id: 'TaskListView.fixing',
    defaultMessage: 'Fixing',
  },
});
