import { handleActions } from 'redux-actions';

import messages from '~/app/locales';
import { changeLocale } from '~/app/actions/LocaleActions';
import getUserLanguage from '~/common/utils/getUserLanguage';


const defaultLanguage = getUserLanguage(Object.keys(messages));
const initialState = {
  messages,
  currentLocale: defaultLanguage,
};

const LocaleReducer = handleActions({
  // Routine SUCCESS

  [changeLocale.SUCCESS]: (state, { payload }) => ({
    ...state,
    currentLocale: payload.locale,
  }),

  // Routine FAILURE

  [changeLocale.FAILURE]: state => ({
    ...state,
    currentLocale: defaultLanguage,
  }),
}, initialState);

export default LocaleReducer;
