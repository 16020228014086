import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Grid, Header, Label, Icon,
} from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';

import {
  HW_PROTOCOLS,
  GATEWAY_RSSI_THRESHOLDS,
  SENSOR_SIGNAL_THRESHOLDS,
  UNIT_DBM,
} from '~/common/constants';
import {
  APTERYX_GATEWAY_PING_INTERVAL,
  APTERYX_SENSOR_PING_INTERVAL,
  MQTT_GATEWAY_STATS_INTERVAL,
  MQTT_SENSOR_STATS_INTERVAL,
} from '~/app/constants';
import { GatewayType, SensorType } from '~/common/types';
import HW_TYPE_MESSAGES from '~/common/locales/HardwareTypes';
import { getDeviceCheckPath } from '~/app/utils/routing';
import Placeholder from '~/common/components/Placeholder';
import SegmentDivider from '~/common/components/SegmentDivider';
import FormattedMetric from '~/common/components/FormattedMetric';
import FormattedAddress from '~/common/components/FormattedAddress';
import FormattedDateTime from '~/common/components/FormattedDateTime';
import HardwareOnlineStatus from '~/common/components/HardwareOnlineStatus';
import MetricGraph from '~/app/components/MetricGraph';
import '~/app/styles/components/HardwareConnectivityDetails.css';


class HardwareConnectivityDetails extends Component {
  getMetricOptions(hardware) {
    if (hardware.gatewayId != null) {
      return {
        metric: 'rssi',
        unit: UNIT_DBM,
        label: (
          <FormattedMessage id="HardwareConnectivityDetails.metric.gateway.rssi" defaultMessage="RSSI" />
        ),
        thresholds: GATEWAY_RSSI_THRESHOLDS,
      };
    }

    return {
      metric: 'signal',
      unit: UNIT_DBM,
      label: (
        <FormattedMessage id="HardwareConnectivityDetails.metric.sensor.signal" defaultMessage="Signal strength" />
      ),
      thresholds: SENSOR_SIGNAL_THRESHOLDS,
    };
  }

  formatMessage = (messages, ...path) => {
    const message = _.get(messages, path);
    if (!message) {
      return path.slice(-1)[0];
    }
    return this.props.intl.formatMessage(message);
  }

  selectDevice = (hardware) => {
    this.props.history.replace(getDeviceCheckPath(hardware));
  }

  renderDeviceStatus = (hardware) => {
    const isOnline = hardware.status && hardware.status.isOnline;
    const canShowMetricsGraph = hardware.metrics && hardware.metrics.length > 0;
    const {
      metric, unit, thresholds, label: metricLabel,
    } = this.getMetricOptions(hardware);

    let latestMetricValue = null;
    let latestMetricTimestamp = null;
    if (hardware.metrics && hardware.metrics.length > 0) {
      const latestEntry = _.orderBy(hardware.metrics, dp => dp.time, 'desc')[0];
      latestMetricValue = latestEntry[metric];
      latestMetricTimestamp = latestEntry.time;
    } else if (hardware.status && hardware.status.signal) {
      latestMetricValue = hardware.status.signal[metric];
      latestMetricTimestamp = hardware.status.signal.timestamp;
    }

    return (
      <Grid className="hardware-status-container">
        <Grid.Row>
          <Grid.Column width={6} className="label-column">
            <FormattedMessage id="HardwareConnectivityDetails.status.isOnline" defaultMessage="Status" />
          </Grid.Column>
          <Grid.Column width={10}>
            <HardwareOnlineStatus hardware={hardware} />
          </Grid.Column>
        </Grid.Row>
        {hardware.sensorId != null && hardware.primaryGateway && (
          <Grid.Row>
            <Grid.Column width={6} className="label-column">
              <FormattedMessage id="HardwareConnectivityDetails.status.primaryGateway" defaultMessage="Primary gateway" />
            </Grid.Column>
            <Grid.Column width={10}>
              {hardware.primaryGateway.uuid}
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={6} className="label-column">
            {metricLabel}
          </Grid.Column>
          <Grid.Column width={10}>
            <Placeholder show={!isOnline || latestMetricValue == null}>
              <FormattedMetric
                value={latestMetricValue}
                timestamp={latestMetricTimestamp}
                unit={unit}
                thresholds={thresholds}
              />
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
        {canShowMetricsGraph && (
          <Grid.Row className="metrics-graph-row">
            <Grid.Column width={16}>
              <div className="metrics-graph-container">
                {this.renderMetricsGraph(hardware)}
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  };

  renderMetricsGraph = (hardware) => {
    const { metric, thresholds: ratingThresholds } = this.getMetricOptions(hardware);

    const data = hardware.metrics
      .map(dp => ({
        x: moment(dp.time).toDate(),
        y: dp[metric],
      }))
      .filter(dp => dp.y != null);

    // Determine the thresholds for splitting the graph into segments
    // and render a hole in the data based on the interval of stats reports or pings
    const leeway = 10;
    let holeThreshold = null;
    if (hardware.protocol === HW_PROTOCOLS.APTERYX) {
      let pingInterval;
      if (hardware.gatewayId != null) {
        pingInterval = APTERYX_GATEWAY_PING_INTERVAL;
      } else if (hardware.configuration && hardware.configuration.pingInterval) {
        pingInterval = hardware.configuration.pingInterval * 60;
      } else {
        pingInterval = APTERYX_SENSOR_PING_INTERVAL;
      }
      holeThreshold = 2 * (pingInterval + leeway) * 1000;
    }
    if (hardware.protocol === HW_PROTOCOLS.MQTT) {
      const statsInterval = hardware.gatewayId != null
        ? MQTT_GATEWAY_STATS_INTERVAL
        : MQTT_SENSOR_STATS_INTERVAL;
      holeThreshold = (statsInterval + leeway) * 1000;
    }

    return (
      <MetricGraph
        data={data}
        holeThreshold={holeThreshold}
        ratingThresholds={ratingThresholds}
      />
    );
  };

  renderConnectedDevice = (hardware, isCurrentGateway = false) => (
    <Grid padded="vertically">
      <Grid.Row className="device-header" onClick={() => this.selectDevice(hardware)}>
        <Grid.Column width={14}>
          <Header size="tiny">
            <span className="uuid">{hardware.uuid}</span>
            {isCurrentGateway && (
              <Label horizontal color="green" className="current-gateway">
                <FormattedMessage id="HardwareConnectivityDetails.connectedDevice.currentGateway" defaultMessage="currently used" />
              </Label>
            )}
            <Header.Subheader>
              {hardware.address && (
                <p>
                  <FormattedAddress address={hardware.address} />
                </p>
              )}
              {hardware.specifier && (
                <p>{hardware.specifier}</p>
              )}
              {hardware.hardwareType && (
                <p>
                  {this.formatMessage(HW_TYPE_MESSAGES, hardware.hardwareType)}
                </p>
              )}
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={2} textAlign="right" verticalAlign="middle">
          <Icon name="angle right" color="grey" size="big" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="label-column">
          <FormattedMessage id="HardwareConnectivityDetails.connectedDevice.status" defaultMessage="Status" />
        </Grid.Column>
        <Grid.Column width={10}>
          <HardwareOnlineStatus hardware={hardware} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="label-column">
          <FormattedMessage id="HardwareConnectivityDetails.connectedDevice.lastUsed" defaultMessage="Last used" />
        </Grid.Column>
        <Grid.Column width={10}>
          <FormattedDateTime relative value={hardware.lastUsed.timestamp} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6} className="label-column">
          <FormattedMessage id="HardwareConnectivityDetails.connectedDevice.signal" defaultMessage="Signal strength" />
        </Grid.Column>
        <Grid.Column width={10}>
          <Placeholder show={hardware.lastUsed.signal == null}>
            <FormattedMetric
              value={hardware.lastUsed.signal}
              unit={UNIT_DBM}
              thresholds={SENSOR_SIGNAL_THRESHOLDS}
            />
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
      {hardware.gatewayId != null && (
        <Grid.Row>
          <Grid.Column width={6} className="label-column">
            <FormattedMessage id="HardwareConnectivityDetails.connectedDevice.rssi" defaultMessage="RSSI" />
          </Grid.Column>
          <Grid.Column width={10}>
            <Placeholder show={hardware.status.signal.rssi == null}>
              <FormattedMetric
                value={hardware.status.signal.rssi}
                unit={UNIT_DBM}
                thresholds={GATEWAY_RSSI_THRESHOLDS}
              />
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );

  render() {
    const { hardware } = this.props;
    const connectedDevices = hardware.gatewayId != null ? hardware.sensors : hardware.gateways;
    const currentGatewayUuid = hardware.gatewayId != null ? null : _.get(hardware, 'status.gatewayUuid');

    return (
      <div className="hardware-connections-container">
        {this.renderDeviceStatus(hardware)}

        <SegmentDivider>
          {hardware.gatewayId != null ? (
            <FormattedMessage id="HardwareConnectivityDetails.connectedDevices.sensors.header" defaultMessage="Sensors in range" />
          ) : (
            <FormattedMessage id="HardwareConnectivityDetails.connectedDevices.gateways.header" defaultMessage="Gateways in range" />
          )}
        </SegmentDivider>

        <div className="connected-devices-container">
          {connectedDevices && connectedDevices.map(device => (
            <div key={device.uuid} className={`connected-device ${device.uuid === currentGatewayUuid ? 'current-gateway' : ''}`}>
              {this.renderConnectedDevice(device, device.uuid === currentGatewayUuid)}
            </div>
          ))}
          {(!connectedDevices || connectedDevices.length === 0) && (
            <Placeholder
              show
              text={(
                hardware.gatewayId != null ? (
                  <FormattedMessage
                    id="HardwareConnectivityDetails.connectedDevices.sensors.empty"
                    defaultMessage="There are no sensors in range."
                  />
                ) : (
                  <FormattedMessage
                    id="HardwareConnectivityDetails.connectedDevices.gateways.empty"
                    defaultMessage="There are no gateways in range."
                  />
                )
              )}
            />
          )}
        </div>
      </div>
    );
  }
}

HardwareConnectivityDetails.propTypes = {
  hardware: PropTypes.oneOfType([
    GatewayType,
    SensorType,
  ]).isRequired,
};

export default withRouter(injectIntl(HardwareConnectivityDetails));
