import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Checkbox, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import { migrateTaskHardware, dismissError } from '~/app/actions/JobsActions';
import { splitSmartEntryHybrid } from '~/app/utils/taskHardwareMigrations';
import ButtonGroup from '~/common/components/ButtonGroup';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';
import '~/app/styles/views/jobs/routines/steps/SmartEntryInstallationPostCheckStep.css';


class SmartEntryInstallationPostCheckStep extends Component {
  state = {
    sensorButtonWorks: false,
    intercomWorks: false,
    openingWorks: false,
    migrateTask: false,
    next: false,
  }

  next = () => {
    this.setState({ next: true });
  }

  migrateHybridToSensor = () => {
    const { task, steps } = this.props.routine;
    this.props.migrateTaskHardware(splitSmartEntryHybrid({ task, steps }));
    this.setState({ migrateTask: true });
  };

  dismissError = () => {
    this.setState({ migrateTask: false, next: false });
    this.props.dismissError();
  }

  render() {
    const { job, task } = this.props.routine;
    const { loading, error } = this.props.jobs;
    const {
      sensorButtonWorks, intercomWorks, openingWorks,
      migrateTask, next,
    } = this.state;
    const canContinue = sensorButtonWorks && intercomWorks && openingWorks;

    if (next) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        loading={loading}
        header={<JobProgressHeader job={job} task={task} stage="installation" />}
      >
        <StepView.Content>
          <JobSaveErrorModal
            error={migrateTask ? error : null}
            onDismiss={this.dismissError}
          />

          <div className="check-container-sensor">
            <p>
              <FormattedMessage
                id="SmartEntryInstallationPostCheckStep.jobs.instructions.sensor"
                defaultMessage="Please install the sensor and run the following tests."
              />
            </p>
            <Form>
              <Form.Field>
                <FormattedMessage
                  id="SmartEntryInstallationPostCheckStep.jobs.checks.sensorButtonWorks"
                  defaultMessage="Button on the Smart Entry opens the door"
                >
                  {label => (
                    <Checkbox
                      label={label}
                      checked={this.state.sensorButtonWorks}
                      onChange={(e, { checked }) => this.setState({ sensorButtonWorks: checked })}
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
            </Form>
          </div>

          <div className="check-container-doorbell">
            <p>
              <FormattedMessage
                id="SmartEntryInstallationPostCheckStep.jobs.instructions.doorbell"
                defaultMessage="Please close the doorbell panel and run the following tests."
              />
            </p>
            <Form>
              <Form.Field>
                <FormattedMessage
                  id="SmartEntryInstallationPostCheckStep.jobs.checks.intercomWorks"
                  defaultMessage="Using the intercom with a tenant works"
                >
                  {label => (
                    <Checkbox
                      label={label}
                      checked={this.state.intercomWorks}
                      onChange={(e, { checked }) => this.setState({ intercomWorks: checked })}
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
              <Form.Field>
                <FormattedMessage
                  id="SmartEntryInstallationPostCheckStep.jobs.checks.openingWorks"
                  defaultMessage="Opening the door through a tenant works"
                >
                  {label => (
                    <Checkbox
                      label={label}
                      checked={this.state.openingWorks}
                      onChange={(e, { checked }) => this.setState({ openingWorks: checked })}
                    />
                  )}
                </FormattedMessage>
              </Form.Field>
            </Form>
          </div>
        </StepView.Content>

        <StepView.Footer>
          <ButtonGroup>
            <Button fluid color="red" disabled={canContinue} onClick={this.props.requestSkipTask}>
              <FormattedMessage
                id="SmartEntryInstallationPostCheckStep.jobs.notInstallable.label"
                defaultMessage="Device can't be installed"
              />
            </Button>
            {task.hardware.hardwareProduct === HW_PRODUCTS.SMART_ENTRY_HYBRID && (
              <Button fluid onClick={this.migrateHybridToSensor}>
                <FormattedMessage
                  id="SmartEntryInstallationPostCheckStep.jobs.migrateHybridToSensor.label"
                  defaultMessage="Install Gateway and Sensor separately"
                />
              </Button>
            )}
            <Button fluid primary disabled={!canContinue} onClick={this.next}>
              <FormattedMessage
                id="SmartEntryInstallationPostCheckStep.jobs.test.label"
                defaultMessage="Test Smart Entry"
              />
            </Button>
          </ButtonGroup>
        </StepView.Footer>
      </StepView>
    );
  }
}

SmartEntryInstallationPostCheckStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  jobs: state.jobs,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  requestSkipTask,
  migrateTaskHardware,
  dismissError,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmartEntryInstallationPostCheckStep));
