import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { HW_PRODUCTS, HW_VARIANTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import staticFile from '~/common/utils/staticFile';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const INSTRUCTION_MESSAGES = defineMessages({
  [HW_PRODUCTS.KNOB]: {
    id: 'SmartLockPowerOnStep.jobs.instructions.knob',
    defaultMessage: 'Please remove the cover of the knob and pull out the battery protection.',
  },
  [HW_PRODUCTS.HANDLE]: {
    id: 'SmartLockPowerOnStep.jobs.instructions.handle',
    defaultMessage: 'Please open the grip of the handle and insert the battery.',
  },
  [HW_PRODUCTS.SAFE]: {
    id: 'SmartLockPowerOnStep.jobs.instructions.safe',
    defaultMessage: 'Please remove the cover of the knob and pull out the battery protection.',
  },
  [HW_PRODUCTS.SAFE_TWO]: {
    id: 'SmartLockPowerOnStep.jobs.instructions.safeTwo',
    defaultMessage: 'Please remove the cover of the knob and pull out the battery protection.',
  },
});

const INSTRUCTION_IMAGES = {
  [HW_PRODUCTS.KNOB]: staticFile('smart_installation/images/knob-battery-tag.svg'),
  [HW_PRODUCTS.HANDLE]: staticFile('smart_installation/images/handle-battery.svg'),
  [HW_PRODUCTS.SAFE]: staticFile('smart_installation/images/knob-battery-tag.svg'),
  [HW_PRODUCTS.SAFE_TWO]: staticFile('smart_installation/images/knob-battery-tag.svg'),
};


class SmartLockPowerOnStep extends Component {
  state = {
    next: false,
  }

  render() {
    const { next } = this.state;
    const { job, task } = this.props.routine;
    const { hardwareProduct, hardwareVariant } = task.hardware;

    const isCompactKnob = hardwareProduct === HW_PRODUCTS.KNOB
        && [HW_VARIANTS.COMPACT, HW_VARIANTS.COMPACT_OUTDOOR].includes(hardwareVariant);

    if (next) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="installation" />}
      >
        <StepView.Content>
          <p>
            {this.props.intl.formatMessage(INSTRUCTION_MESSAGES[task.hardware.hardwareProduct])}
          </p>
          {isCompactKnob && (
            <Image
              centered
              bordered
              size="large"
              className="instructions-image"
              src={staticFile('smart_installation/images/compact-knob-cover-remove.svg')}
            />
          )}
          {INSTRUCTION_IMAGES[task.hardware.hardwareProduct] && (
            <Image
              centered
              bordered
              size="large"
              className="instructions-image"
              src={INSTRUCTION_IMAGES[task.hardware.hardwareProduct]}
            />
          )}
        </StepView.Content>

        <StepView.Footer>
          <Button primary fluid onClick={() => this.setState({ next: true })}>
            <FormattedMessage id="SmartLockPowerOnStep.jobs.next.label" defaultMessage="Next" />
          </Button>
        </StepView.Footer>
      </StepView>
    );
  }
}

SmartLockPowerOnStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

export default withRoutine(injectIntl(SmartLockPowerOnStep));
