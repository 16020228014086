import { handleActions, combineActions } from 'redux-actions';
import _ from 'lodash';


import {
  fetchHardware, registerHardware,
  fetchHybrid,
  fetchGateway, fetchGateways, editGateway, removeGateway,
  getGatewayWithStatus, getSensorWithStatus, updateGatewayStatus, updateGatewayMetrics,
  updateGatewaySensors, checkGatewayConnectivity, checkGatewayLinkPerformance,
  pingGateway, getGatewayGsmInfo, rebootGateway,
  updateGatewayConfiguration, pushGatewayConfiguration, gatewayConfigurationUpdated,
  awaitGatewayConfigurationUpdate,
  fetchSensor, fetchSensors, editSensor, removeSensor,
  updateSensorStatus, updateSensorMetrics, updateSensorGateways,
  checkSensorConnectivity, getSensor868Info, pingSensor, rebootSensor, openSensor,
  getSensorBatteryInfo, initializeSensorRebatt, finalizeSensorRebatt,
  grantSensorPermission, revokeSensorPermission,
  updateSensorConfiguration, pushSensorConfiguration, sensorConfigurationUpdated,
  awaitSensorKisUpdate, awaitSensorConfigurationUpdate,
  startKiSignalTest, resetKiSignalTest, kiSignalTestProgress,
  linkPerformanceCheckProgress,
  dismissError,
} from '~/app/actions/HardwareActions';


const initialState = {
  gateways: {
    byId: {},
    pages: {},
    total: null,
    query: {},
  },
  sensors: {
    byId: {},
    pages: {},
    total: null,
    query: {},
  },
  loading: false,
  error: null,
  ping: {
    loading: false,
    success: false,
  },
  reboot: {
    loading: false,
    success: false,
  },
  open: {
    loading: false,
    success: false,
  },
  rebatt: {
    loading: false,
    active: false,
  },
  gatewayGsmInfoUpdate: {
    loading: false,
    success: false,
  },
  sensor868InfoUpdate: {
    loading: false,
    success: false,
  },
  kisUpdate: {
    loading: false,
    success: false,
  },
  configurationUpdate: {
    loading: false,
    success: false,
  },
  permission: {
    loading: false,
    granted: false,
    revoked: false,
  },
  registration: {
    loading: false,
    success: false,
    gatewayId: null,
    sensorId: null,
    hardwareUuid: null,
  },
  hardwareEdited: false,
  hardwareRemoved: false,
  hardwareConnectivity: null,
  gatewayGsmInfo: null,
  sensor868Info: null,
  sensorBatteryInfo: null,
  linkPerformance: {
    status: null,
    result: null,
  },
  kiSignalTest: {
    status: null,
    progress: 0,
    success: false,
  },
};

const itemsFromPayload = (oldItems, payload, key) => {
  const {
    gateways, sensors, total, pageNumber, query, clear,
  } = payload;
  const items = gateways || sensors;
  let pages = {
    [pageNumber]: items.map(j => j[key]),
  };
  let byId = Object.assign({}, ...items.map(j => ({ [j[key]]: j })));

  if (!clear) {
    pages = {
      ...oldItems.pages,
      ...pages,
    };
    byId = {
      ...oldItems.byId,
      ...byId,
    };
  }

  return {
    total, pages, byId, query,
  };
};
const sensorsFromPayload = (...args) => itemsFromPayload(...args, 'sensorId');
const gatewayFromPayload = (...args) => itemsFromPayload(...args, 'gatewayId');

const updateSpecificItem = (oldItems, newItem, key, { ignoreNew = false, merge = false } = {}) => {
  const oldItem = newItem && oldItems.byId[newItem[key]];
  if (!newItem || (ignoreNew && !oldItem)) {
    return oldItems;
  }

  return {
    ...oldItems,
    byId: {
      ...oldItems.byId,
      [newItem[key]]: merge ? _.merge({}, oldItem, newItem) : { ...oldItem, ...newItem },
    },
  };
};
const updateItem = (state, newItem, options = {}) => {
  if (!newItem) {
    return state;
  }

  let { sensorId, gatewayId } = newItem;
  if (sensorId != null && gatewayId == null) {
    ({ gatewayId } = state.sensors.byId[sensorId] || {});
  }
  if (sensorId == null && gatewayId != null) {
    ({ sensorId } = state.gateways.byId[gatewayId] || {});
  }
  newItem = { ...newItem, sensorId, gatewayId };

  return {
    ...state,
    gateways: newItem.gatewayId != null
      ? updateSpecificItem(state.gateways, newItem, 'gatewayId', options)
      : state.gateways,
    sensors: newItem.sensorId != null
      ? updateSpecificItem(state.sensors, newItem, 'sensorId', options)
      : state.sensors,
  };
};
const updateItems = (state, newItems, options = {}) => newItems
  .reduce((newState, item) => ({ ...newState, ...updateItem(newState, item, options) }), state);

const removeSpecificItems = (oldItems, predicate, key) => {
  const idsToRemove = Object.values(oldItems.byId)
    .filter(predicate)
    .map(x => x[key]);

  return {
    ...oldItems,
    byId: _.omit(oldItems.byId, idsToRemove),
    pages: Object.fromEntries(
      Object.entries(oldItems.pages).map(
        ([num, page]) => ([num, page.filter(id => !idsToRemove.includes(id))]),
      ),
    ),
  };
};
const removeItems = (state, predicate) => ({
  ...state,
  gateways: removeSpecificItems(state.gateways, predicate, 'gatewayId'),
  sensors: removeSpecificItems(state.sensors, predicate, 'sensorId'),
});

const updateMetrics = (oldMetrics, newEntry) => {
  if (!oldMetrics) {
    return [newEntry];
  }

  // Ignore new entry, if it's already present in old metrics
  if (oldMetrics.some(dp => Object.entries(dp).every(([key, value]) => newEntry[key] === value))) {
    return oldMetrics;
  }

  return [
    newEntry,
    ...oldMetrics,
  ];
};

const HardwareReducer = handleActions({
  // Plain Actions

  [kiSignalTestProgress]: (state, { payload }) => ({
    ...state,
    kiSignalTest: {
      ...state.kiSignalTest,
      ...payload,
    },
  }),

  [linkPerformanceCheckProgress]: (state, { payload }) => ({
    ...state,
    linkPerformance: {
      ...state.linkPerformance,
      ...payload,
    },
  }),

  [resetKiSignalTest]: state => ({
    ...state,
    error: null,
    kiSignalTest: initialState.kiSignalTest,
  }),

  [gatewayConfigurationUpdated]: (state, { payload }) => updateItem(
    state,
    {
      gatewayId: payload.id,
      configuration: payload.configuration,
    },
    { ignoreNew: true },
  ),

  [sensorConfigurationUpdated]: (state, { payload }) => updateItem(
    state,
    {
      sensorId: payload.id,
      configuration: payload.configuration,
    },
    { ignoreNew: true },
  ),

  [dismissError]: state => ({
    ...state,
    error: null,
  }),

  // Routine TRIGGER

  [combineActions(
    fetchHardware.TRIGGER,
    fetchHybrid.TRIGGER,
    fetchGateway.TRIGGER,
    fetchGateways.TRIGGER,
    getGatewayWithStatus.TRIGGER,
    updateGatewayStatus.TRIGGER,
    updateGatewayMetrics.TRIGGER,
    updateGatewaySensors.TRIGGER,
    fetchSensor.TRIGGER,
    fetchSensors.TRIGGER,
    getSensorWithStatus.TRIGGER,
    updateSensorStatus.TRIGGER,
    updateSensorMetrics.TRIGGER,
    updateSensorGateways.TRIGGER,
    getSensorBatteryInfo.TRIGGER,
    initializeSensorRebatt.TRIGGER,
    finalizeSensorRebatt.TRIGGER,
  )]: state => ({
    ...state,
    error: null,
    loading: true,
  }),

  [registerHardware.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    registration: {
      ...state.registration,
      loading: true,
      success: false,
      gatewayId: null,
      sensorId: null,
      hardwareUuid: null,
    },
  }),

  [getGatewayWithStatus.TRIGGER]: (state, { payload }) => updateItem(
    state,
    {
      gatewayId: payload.id,
      loading: true,
    },
    { ignoreNew: false },
  ),

  [getSensorWithStatus.TRIGGER]: (state, { payload }) => updateItem(
    state,
    {
      sensorId: payload.id,
      loading: true,
    },
    { ignoreNew: false },
  ),

  [combineActions(
    editGateway.TRIGGER,
    editSensor.TRIGGER,
  )]: state => ({
    ...state,
    error: null,
    loading: true,
    hardwareEdited: false,
  }),

  [combineActions(
    removeGateway.TRIGGER,
    removeSensor.TRIGGER,
  )]: state => ({
    ...state,
    error: null,
    loading: true,
    hardwareRemoved: false,
  }),

  [combineActions(
    checkGatewayConnectivity.TRIGGER,
    checkSensorConnectivity.TRIGGER,
  )]: state => ({
    ...state,
    error: null,
    loading: true,
    hardwareConnectivity: null,
  }),

  [checkGatewayLinkPerformance.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    linkPerformance: {
      status: null,
      result: null,
    },
  }),

  [combineActions(
    pingGateway.TRIGGER,
    pingSensor.TRIGGER,
  )]: state => ({
    ...state,
    error: null,
    loading: true,
    ping: {
      ...state.ping,
      loading: true,
      success: false,
    },
  }),

  [combineActions(
    rebootGateway.TRIGGER,
    rebootSensor.TRIGGER,
  )]: state => ({
    ...state,
    error: null,
    loading: true,
    reboot: {
      ...state.reboot,
      loading: true,
      success: false,
    },
  }),

  [getGatewayGsmInfo.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    gatewayGsmInfoUpdate: {
      ...state.gatewayGsmInfoUpdate,
      loading: true,
      success: false,
    },
  }),

  [getSensor868Info.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    sensor868InfoUpdate: {
      ...state.sensor868InfoUpdate,
      loading: true,
      success: false,
    },
  }),

  [openSensor.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    open: {
      loading: true,
      success: false,
    },
  }),

  [grantSensorPermission.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    permission: {
      loading: true,
      granted: false,
    },
    kisUpdate: {
      loading: true,
      success: false,
    },
  }),

  [revokeSensorPermission.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    permission: {
      loading: true,
      revoked: false,
    },
    kisUpdate: {
      loading: true,
      success: false,
    },
  }),

  [awaitSensorKisUpdate.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    kisUpdate: {
      loading: true,
      success: false,
    },
  }),

  [combineActions(
    pushGatewayConfiguration.TRIGGER,
    updateGatewayConfiguration.TRIGGER,
    awaitGatewayConfigurationUpdate.TRIGGER,
    pushSensorConfiguration.TRIGGER,
    updateSensorConfiguration.TRIGGER,
    awaitSensorConfigurationUpdate.TRIGGER,
  )]: state => ({
    ...state,
    error: null,
    loading: true,
    configurationUpdate: {
      loading: true,
      success: false,
    },
  }),

  [startKiSignalTest.TRIGGER]: state => ({
    ...state,
    error: null,
    loading: true,
    kiSignalTest: {
      ...state.kiSignalTest,
      progress: 0,
      success: false,
    },
  }),

  // Routine SUCCESS

  [combineActions(
    fetchHardware.SUCCESS,
    fetchHybrid.SUCCESS,
    fetchGateway.SUCCESS,
    fetchSensor.SUCCESS,
  )]: (state, { payload }) => (payload.hybrid
    ? updateItem(state, payload.hybrid)
    : updateItems(state, [payload.gateway, payload.sensor])),

  [fetchGateways.SUCCESS]: (state, { payload }) => ({
    ...state,
    gateways: gatewayFromPayload(state.gateways, payload),
  }),

  [fetchSensors.SUCCESS]: (state, { payload }) => ({
    ...state,
    sensors: sensorsFromPayload(state.sensors, payload),
  }),

  [updateGatewayStatus.SUCCESS]: (state, { payload }) => updateItem(
    state,
    {
      gatewayId: payload.id,
      status: payload.status,
    },
    { ignoreNew: true, merge: true },
  ),

  [updateGatewayMetrics.SUCCESS]: (state, { payload }) => updateItem(
    state,
    {
      gatewayId: payload.id,
      metrics: payload.metrics,
    },
    { ignoreNew: true },
  ),

  [updateGatewaySensors.SUCCESS]: (state, { payload }) => updateItem(
    state,
    {
      gatewayId: payload.id,
      sensors: payload.sensors,
    },
    { ignoreNew: true },
  ),

  [getSensorWithStatus.SUCCESS]: (state, { payload }) => updateItem(
    state,
    {
      sensorId: payload.sensor.sensorId,
      ...payload.sensor,
    },
    { ignoreNew: false, merge: true },
  ),

  [getGatewayWithStatus.SUCCESS]: (state, { payload }) => updateItem(
    state,
    {
      gatewayId: payload.gateway.gatewayId,
      ...payload.gateway,
    },
    { ignoreNew: false, merge: true },
  ),

  [updateSensorStatus.SUCCESS]: (state, { payload }) => updateItem(
    state,
    {
      sensorId: payload.id,
      status: payload.status,
    },
    { ignoreNew: true, merge: true },
  ),

  [updateSensorMetrics.SUCCESS]: (state, { payload }) => updateItem(
    state,
    {
      sensorId: payload.id,
      metrics: payload.metrics,
    },
    { ignoreNew: true },
  ),

  [updateSensorGateways.SUCCESS]: (state, { payload }) => updateItem(
    state,
    {
      sensorId: payload.id,
      gateways: payload.gateways,
    },
    { ignoreNew: true },
  ),

  [registerHardware.SUCCESS]: (state, { payload }) => ({
    ...state,
    ...updateItems(state, [payload.gateway, payload.sensor]),
    registration: {
      ...state.registration,
      success: true,
      gatewayId: payload.gateway ? payload.gateway.gatewayId : null,
      sensorId: payload.sensor ? payload.sensor.sensorId : null,
      hardwareUuid: (payload.gateway || payload.sensor).uuid,
    },
  }),

  [removeGateway.SUCCESS]: (state, { payload }) => ({
    ...state,
    ...removeItems(
      state,
      x => x.gatewayId === payload.id || x.uuid === payload.uuid,
    ),
    hardwareRemoved: true,
  }),

  [removeSensor.SUCCESS]: (state, { payload }) => ({
    ...state,
    ...removeItems(
      state,
      x => x.sensorId === payload.id || x.uuid === payload.uuid,
    ),
    hardwareRemoved: true,
  }),

  [editGateway.SUCCESS]: (state, { payload }) => ({
    ...state,
    ...updateItem(state, payload.gateway),
    hardwareEdited: true,
  }),

  [editSensor.SUCCESS]: (state, { payload }) => ({
    ...state,
    ...updateItem(state, payload.sensor),
    hardwareEdited: true,
  }),

  [checkGatewayConnectivity.SUCCESS]: (state, { payload }) => ({
    ...state,
    ...updateItem(state, payload.gateway),
    hardwareConnectivity: payload.connectivity,
  }),

  [checkSensorConnectivity.SUCCESS]: (state, { payload }) => ({
    ...state,
    ...updateItem(state, payload.sensor),
    hardwareConnectivity: payload.connectivity,
  }),

  [checkGatewayLinkPerformance.SUCCESS]: (state, { payload }) => ({
    ...state,
    linkPerformance: {
      ...state.linkPerformance,
      result: payload.result,
    },
  }),

  [combineActions(
    pingGateway.SUCCESS,
    pingSensor.SUCCESS,
  )]: (state, { payload }) => ({
    ...state,
    ping: {
      ...state.ping,
      success: !payload.timedOut,
    },
  }),

  [combineActions(
    rebootGateway.SUCCESS,
    rebootSensor.SUCCESS,
  )]: (state, { payload }) => ({
    ...state,
    reboot: {
      ...state.reboot,
      success: !payload.timedOut,
    },
  }),

  [getGatewayGsmInfo.SUCCESS]: (state, { payload }) => {
    const { gatewayId, timedOut, status } = payload;

    let gateway = state.gateways.byId[gatewayId];
    if (gateway && !timedOut) {
      const { signal: { timestamp, ...values } } = status;

      const metrics = gateway.metrics
        ? updateMetrics(gateway.metrics, { time: timestamp, ...values })
        : gateway.metrics;

      gateway = {
        ...gateway,
        metrics,
        status: {
          ...gateway.status,
          ...status,
        },
      };
    }

    return {
      ...state,
      ...updateItem(state, gateway),
      gatewayGsmInfoUpdate: {
        ...state.gatewayGsmInfoUpdate,
        success: !timedOut,
      },
      gatewayGsmInfo: timedOut ? null : status.signal,
    };
  },

  [getSensor868Info.SUCCESS]: (state, { payload }) => {
    const { sensorId, timedOut, status } = payload;

    let sensor = state.sensors.byId[sensorId];
    if (sensor && !timedOut) {
      const { signal: { timestamp, ...values } } = status;

      const metrics = sensor.metrics
        ? updateMetrics(sensor.metrics, { time: timestamp, ...values })
        : sensor.metrics;

      sensor = {
        ...sensor,
        metrics,
        status: {
          ...sensor.status,
          ...status,
        },
      };
    }

    return {
      ...state,
      ...updateItem(state, sensor),
      sensor868InfoUpdate: {
        ...state.sensor868InfoUpdate,
        success: !timedOut,
      },
      sensor868Info: timedOut ? null : status.signal,
    };
  },

  [getSensorBatteryInfo.SUCCESS]: (state, { payload }) => ({
    ...state,
    sensorBatteryInfo: payload.batteryInfo,
  }),

  [initializeSensorRebatt.SUCCESS]: state => ({
    ...state,
    rebatt: {
      ...state.rebatt,
      active: true,
    },
  }),

  [finalizeSensorRebatt.SUCCESS]: state => ({
    ...state,
    rebatt: {
      ...state.rebatt,
      active: false,
    },
  }),

  [openSensor.SUCCESS]: state => ({
    ...state,
    open: {
      ...state.open,
      success: true,
    },
  }),

  [grantSensorPermission.SUCCESS]: state => ({
    ...state,
    permission: {
      ...state.permission,
      granted: true,
    },
    kisUpdate: {
      ...state.kisUpdate,
      success: true,
    },
  }),

  [revokeSensorPermission.SUCCESS]: state => ({
    ...state,
    permission: {
      ...state.permission,
      revoked: true,
    },
    kisUpdate: {
      ...state.kisUpdate,
      success: true,
    },
  }),

  [awaitSensorKisUpdate.SUCCESS]: state => ({
    ...state,
    kisUpdate: {
      ...state.kisUpdate,
      success: true,
    },
  }),

  [combineActions(
    updateGatewayConfiguration.SUCCESS,
    pushGatewayConfiguration.SUCCESS,
    awaitGatewayConfigurationUpdate.SUCCESS,
    updateSensorConfiguration.SUCCESS,
    pushSensorConfiguration.SUCCESS,
    awaitSensorConfigurationUpdate.SUCCESS,
  )]: state => ({
    ...state,
    configurationUpdate: {
      ...state.configurationUpdate,
      success: true,
    },
  }),

  [startKiSignalTest.SUCCESS]: state => ({
    ...state,
    kiSignalTest: {
      ...state.kiSignalTest,
      success: true,
    },
  }),

  // Routine FAILURE

  [combineActions(
    fetchHardware.FAILURE,
    registerHardware.FAILURE,
    fetchHybrid.FAILURE,
    fetchGateway.FAILURE,
    fetchGateways.FAILURE,
    getGatewayWithStatus.FAILURE,
    updateGatewayStatus.FAILURE,
    updateGatewayMetrics.FAILURE,
    updateGatewaySensors.FAILURE,
    editGateway.FAILURE,
    removeGateway.FAILURE,
    checkGatewayConnectivity.FAILURE,
    checkGatewayLinkPerformance.FAILURE,
    pingGateway.FAILURE,
    rebootGateway.FAILURE,
    getGatewayGsmInfo.FAILURE,
    fetchSensor.FAILURE,
    fetchSensors.FAILURE,
    getSensorWithStatus.FAILURE,
    updateSensorStatus.FAILURE,
    updateSensorMetrics.FAILURE,
    updateSensorGateways.FAILURE,
    editSensor.FAILURE,
    removeSensor.FAILURE,
    getSensorBatteryInfo.FAILURE,
    initializeSensorRebatt.FAILURE,
    finalizeSensorRebatt.FAILURE,
    checkSensorConnectivity.FAILURE,
    grantSensorPermission.FAILURE,
    revokeSensorPermission.FAILURE,
    getSensor868Info.FAILURE,
    openSensor.FAILURE,
    pingSensor.FAILURE,
    rebootSensor.FAILURE,
    updateGatewayConfiguration.FAILURE,
    pushGatewayConfiguration.FAILURE,
    awaitGatewayConfigurationUpdate.FAILURE,
    updateSensorConfiguration.FAILURE,
    pushSensorConfiguration.FAILURE,
    awaitSensorConfigurationUpdate.FAILURE,
    awaitSensorKisUpdate.FAILURE,
    startKiSignalTest.FAILURE,
  )]: (state, { payload }) => ({
    ...state,
    error: {
      ...payload.error,
    },
  }),

  // Routine FULFILL

  [registerHardware.FULFILL]: state => ({
    ...state,
    loading: false,
    registration: {
      ...state.registration,
      loading: false,
    },
  }),

  [combineActions(
    pingGateway.FULFILL,
    pingSensor.FULFILL,
  )]: state => ({
    ...state,
    loading: false,
    ping: {
      ...state.ping,
      loading: false,
    },
  }),

  [combineActions(
    rebootGateway.FULFILL,
    rebootSensor.FULFILL,
  )]: state => ({
    ...state,
    loading: false,
    reboot: {
      ...state.reboot,
      loading: false,
    },
  }),

  [getGatewayGsmInfo.FULFILL]: state => ({
    ...state,
    loading: false,
    gatewayGsmInfoUpdate: {
      ...state.gatewayGsmInfoUpdate,
      loading: false,
    },
  }),

  [getSensor868Info.FULFILL]: state => ({
    ...state,
    loading: false,
    sensor868InfoUpdate: {
      ...state.sensor868InfoUpdate,
      loading: false,
    },
  }),

  [openSensor.FULFILL]: state => ({
    ...state,
    loading: false,
    open: {
      ...state.open,
      loading: false,
    },
  }),

  [combineActions(
    initializeSensorRebatt.FULFILL,
    finalizeSensorRebatt.FULFILL,
  )]: state => ({
    ...state,
    loading: false,
    rebatt: {
      ...state.rebatt,
      loading: false,
    },
  }),

  [combineActions(
    grantSensorPermission.FULFILL,
    revokeSensorPermission.FULFILL,
  )]: state => ({
    ...state,
    loading: false,
    permission: {
      ...state.permission,
      loading: false,
    },
    kisUpdate: {
      ...state.kisUpdate,
      loading: false,
    },
  }),

  [awaitSensorKisUpdate.FULFILL]: state => ({
    ...state,
    loading: false,
    kisUpdate: {
      ...state.kisUpdate,
      loading: false,
    },
  }),

  [getSensorWithStatus.FULFILL]: (state, { payload }) => updateItem(
    state,
    {
      sensorId: payload.id,
      loading: false,
    },
    { ignoreNew: false, merge: true },
  ),

  [getGatewayWithStatus.FULFILL]: (state, { payload }) => updateItem(
    state,
    {
      gatewayId: payload.id,
      loading: false,
    },
    { ignoreNew: false, merge: true },
  ),

  [combineActions(
    pushGatewayConfiguration.FULFILL,
    updateGatewayConfiguration.FULFILL,
    awaitGatewayConfigurationUpdate.FULFILL,
    pushSensorConfiguration.FULFILL,
    updateSensorConfiguration.FULFILL,
    awaitSensorConfigurationUpdate.FULFILL,
  )]: state => ({
    ...state,
    loading: false,
    configurationUpdate: {
      ...state.configurationUpdate,
      loading: false,
    },
  }),

  [combineActions(
    fetchHardware.FULFILL,
    fetchHybrid.FULFILL,
    fetchGateway.FULFILL,
    fetchGateways.FULFILL,
    getGatewayWithStatus.FULFILL,
    updateGatewayStatus.FULFILL,
    updateGatewayMetrics.FULFILL,
    updateGatewaySensors.FULFILL,
    editGateway.FULFILL,
    removeGateway.FULFILL,
    checkGatewayConnectivity.FULFILL,
    checkGatewayLinkPerformance.FULFILL,
    fetchSensor.FULFILL,
    fetchSensors.FULFILL,
    getSensorWithStatus.FULFILL,
    updateSensorStatus.FULFILL,
    updateSensorMetrics.FULFILL,
    updateSensorGateways.FULFILL,
    editSensor.FULFILL,
    removeSensor.FULFILL,
    getSensorBatteryInfo.FULFILL,
    checkSensorConnectivity.FULFILL,
    startKiSignalTest.FULFILL,
  )]: state => ({
    ...state,
    loading: false,
  }),
}, initialState);

export default HardwareReducer;
