import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form, Header, List, Input, Button,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { TASK_TYPES } from '~/common/constants';
import { grantSensorPermissions } from '~/app/actions/JobsActions';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import ButtonGroup from '~/common/components/ButtonGroup';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import SignaturePad from '~/common/components/SignaturePad';
import FormattedUser from '~/common/components/FormattedUser';
import FormattedAddress from '~/common/components/FormattedAddress';
import FormattedDateTime from '~/common/components/FormattedDateTime';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';
import '~/app/styles/views/jobs/routines/steps/PermissionApprovalStep.css';


const PermissionApprovalStep = (props) => {
  const { routine: { job, task } } = props;

  const [date] = useState(new Date().toISOString());
  const [signatoryName, setSignatoryName] = useState('');
  const [signatureDataUrl, setSignatureDataUrl] = useState();

  const dispatch = useDispatch();
  const error = useSelector(state => state.jobs.error);
  const loading = useSelector(state => state.jobs.loading);
  const currentLocale = useSelector(state => state.locale.currentLocale);

  const canContinue = !!signatoryName && !!signatureDataUrl;
  const tasksWithoutPermissions = job.tasks.filter(t => t.type !== TASK_TYPES.INSTALLATION
    && t.hardware.sensorId != null
    && !t.hardware.hasPermission);
  const sortedSensors = _.sortBy(
    tasksWithoutPermissions.map(t => t.hardware),
    sensor => sensor.name,
  );

  const grantPermissions = () => dispatch(grantSensorPermissions({
    job,
    tasks: tasksWithoutPermissions,
    approval: {
      signatoryName,
      signatureB64: signatureDataUrl.split(',')[1],
      lang: currentLocale,
    },
  }));

  if (task.hardware.hasPermission) {
    return <RoutineNavigation nextStep />;
  }

  return (
    <StepView
      {...props}
      noSkip
      loading={loading}
      header={<JobProgressHeader job={job} task={task} stage="fixing" />}
    >
      {/* The texts below need to match with the ones in the document generated by the API. */}
      <StepView.Content>
        <Header size="small">
          <FormattedMessage id="PermissionApprovalStep.jobs.header" defaultMessage="Permission approval" />
        </Header>

        {error && (
          <ApiErrorMessage
            error={error}
            header={(
              <FormattedMessage
                id="PermissionApprovalStep.jobs.errors.header"
                defaultMessage="Permissions could not be granted"
              />
            )}
          />
        )}

        <p className="approval-text">
          <FormattedMessage
            id="PermissionApprovalStep.jobs.approvalText1"
            defaultMessage="
              With my signature I confirm that the technician {installer} of the company {company}
              gets access permissions to the following doors of the property {address}
              for the purpose of error analysis and correction:"
            values={{
              company: <i>{job.company && job.company.name}</i>,
              installer: <i><FormattedUser show="fullName" user={job.installer} /></i>,
              address: <i><FormattedAddress address={job.address} /></i>,
            }}
          />
        </p>

        <List bulleted>
          {sortedSensors.map(sensor => (
            <List.Item key={sensor.sensorId}>
              {sensor.name || (
                <FormattedMessage id="PermissionApprovalStep.jobs.unnamedSensor" defaultMessage="Unnamed door" />
              )}
            </List.Item>
          ))}
        </List>

        <p className="approval-text">
          <FormattedMessage
            id="PermissionApprovalStep.jobs.approvalText2"
            defaultMessage="The permissions will be granted immediately and removed automatically after completion of the job."
          />
        </p>

        <Form className="signature-form">
          <Form.Field>
            <label>
              <FormattedMessage id="PermissionApprovalStep.jobs.date.label" defaultMessage="Date" />
            </label>
            <FormattedDateTime value={date} />
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="PermissionApprovalStep.jobs.signatoryName.label" defaultMessage="First and last name" />
            </label>
            <FormattedMessage id="PermissionApprovalStep.jobs.signatoryName.placeholder" defaultMessage="First and last name">
              {placeholder => (
                <Input
                  fluid
                  placeholder={placeholder}
                  value={signatoryName}
                  onChange={(e, { value }) => setSignatoryName(value)}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="PermissionApprovalStep.jobs.signature.label" defaultMessage="Signature" />
            </label>
            <SignaturePad onChange={setSignatureDataUrl} />
          </Form.Field>
        </Form>
      </StepView.Content>

      <StepView.Footer>
        <ButtonGroup>
          <Button fluid color="red" onClick={() => dispatch(requestSkipTask())}>
            <FormattedMessage id="PermissionApprovalStep.jobs.decline.label" defaultMessage="Decline" />
          </Button>
          <Button primary fluid disabled={!canContinue} onClick={grantPermissions}>
            <FormattedMessage id="PermissionApprovalStep.jobs.approve.label" defaultMessage="Approve" />
          </Button>
        </ButtonGroup>
      </StepView.Footer>
    </StepView>
  );
};

PermissionApprovalStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

export default withRoutine(PermissionApprovalStep);
