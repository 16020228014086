import React from 'react';
import PropTypes from 'prop-types';

import { AddressType } from '~/common/types';


const DISPLAYED_FIELDS = ['street', 'postalCode', 'city'];

const FormattedAddress = ({ address, breakLines }) => {
  if (!address || DISPLAYED_FIELDS.every(key => address[key] == null)) {
    return null;
  }

  return (
    <span>
      {address.street?.replace(/ (?=\d)/, '\xa0')}
      {breakLines ? <br /> : ', '}
      {address.postalCode}
      {' '}
      {address.city}
    </span>
  );
};

FormattedAddress.defaultProps = {
  address: null,
  breakLines: false,
};

FormattedAddress.propTypes = {
  address: AddressType,
  breakLines: PropTypes.bool,
};

export default FormattedAddress;
