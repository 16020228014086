import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import NavigationContext from '~/app/contexts/NavigationContext';


class NavigationProvider extends Component {
  state = {
    backActions: [],
  }

  componentDidMount() {
    // Disable browser's back button
    const { history } = this.props;
    window.history.pushState({}, '');
    history.listen((_, action) => {
      if (action === 'POP') {
        this.goBack();
      }
      window.history.pushState({}, '');
    });
  }

  setBackAction = (action) => {
    this.setState({ backActions: [action] });
  }

  pushBackAction = (action) => {
    this.setState(prevState => ({
      backActions: [action, ...prevState.backActions],
    }));
  }

  popBackAction = (action) => {
    this.setState((prevState) => {
      const [poppedAction, ...remaining] = prevState.backActions;
      if (action === poppedAction) {
        return { backActions: remaining };
      }
      return {};
    });
  }

  goBack = () => {
    const action = this.state.backActions[0];
    if (action) {
      action(this.props.history);
    }
  }

  render() {
    return (
      <NavigationContext.Provider
        value={{
          goBack: this.goBack,
          setBackAction: this.setBackAction,
          popBackAction: this.popBackAction,
          pushBackAction: this.pushBackAction,
        }}
      >
        {this.props.children}
      </NavigationContext.Provider>
    );
  }
}

export default withRouter(NavigationProvider);
