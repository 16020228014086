import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import ImageWithPlaceholder from '~/common/components/ImageWithPlaceholder';
import '~/common/styles/components/HorizontalImageGallery.css';


const GalleryImage = ({ src, onClick }) => {
  const [loading, setLoading] = useState(true);

  const link = !loading && onClick != null;

  return (
    <ImageWithPlaceholder
      src={src}
      className={link ? 'link' : ''}
      onClick={link ? onClick : null}
      onLoad={() => setLoading(false)}
    />
  );
};


const HorizontalImageGallery = ({ images, size, onClick }) => (
  <Image.Group size={size} className="horizontal-image-gallery">
    {images.map(src => (
      <GalleryImage
        key={src}
        src={src}
        onClick={onClick && (() => onClick(src))}
      />
    ))}
  </Image.Group>
);

HorizontalImageGallery.defaultProps = {
  images: [],
  size: 'small',
  onClick: () => {},
};

HorizontalImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf([
    'mini',
    'tiny',
    'small',
    'medium',
    'large',
    'big',
    'huge',
    'massive',
  ]),
  onClick: PropTypes.func,
};

export default HorizontalImageGallery;
