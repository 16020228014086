export const JOB_STATUSES = {
  UNASSIGNED: 'unassigned',
  READY: 'ready',
  IN_PROGRESS: 'in_progress',
  FAILED: 'failed',
  SUCCESS: 'success',
  APPROVED: 'approved',
};

export const JOB_STATUSES_DONE = [
  JOB_STATUSES.SUCCESS,
  JOB_STATUSES.FAILED,
  JOB_STATUSES.APPROVED,
];

export const TASK_TYPES = {
  INSTALLATION: 'installation',
  FIXING: 'fixing',
};

export const TASK_STATUSES = {
  READY: 'ready',
  IN_PROGRESS: 'in_progress',
  FAILED: 'failed',
  SUCCESS: 'success',
  APPROVED: 'approved',
  UNNECESSARY: 'unnecessary',
};

export const TASK_STATUSES_PENDING = [
  TASK_STATUSES.READY,
  TASK_STATUSES.IN_PROGRESS,
];

export const TASK_STATUSES_DONE = [
  TASK_STATUSES.SUCCESS,
  TASK_STATUSES.FAILED,
  TASK_STATUSES.APPROVED,
  TASK_STATUSES.UNNECESSARY,
];

export const TASK_STATUSES_SKIPPED = [
  TASK_STATUSES.FAILED,
  TASK_STATUSES.UNNECESSARY,
];

export const DEFAULT_JOB_PRIORITY = 1;

export const HW_PRODUCTS = {
  GATEWAY: 'GATEWAY',
  SMART_ENTRY: 'SMART_ENTRY',
  SMART_ENTRY_HYBRID: 'SMART_ENTRY_HYBRID',
  SMART_DOOR: 'SMART_DOOR',
  KNOB: 'KNOB',
  HANDLE: 'HANDLE',
  SAFE: 'SAFE',
  SAFE_TWO: 'SAFE_TWO',
};

export const SAFE_HW_PRODUCTS = [
  HW_PRODUCTS.SAFE,
  HW_PRODUCTS.SAFE_TWO,
];

export const GATEWAY_HW_PRODUCTS = [
  HW_PRODUCTS.GATEWAY,
  HW_PRODUCTS.SMART_ENTRY_HYBRID,
];

export const SENSOR_HW_PRODUCTS = [
  HW_PRODUCTS.SMART_ENTRY,
  HW_PRODUCTS.SMART_ENTRY_HYBRID,
  HW_PRODUCTS.SMART_DOOR,
  HW_PRODUCTS.KNOB,
  HW_PRODUCTS.HANDLE,
  ...SAFE_HW_PRODUCTS,
];

export const HW_PRODUCT_VARIANTS = {
  REGULAR: 'REGULAR',
  GW1: 'GW1',
  GWSDS: 'GWSDS',
  GW2: 'GW2',
  SE1: 'SE1',
  SE2: 'SE2',
  OUTDOOR: 'OUTDOOR',
  COMPACT: 'COMPACT',
  COMPACT_OUTDOOR: 'COMPACT_OUTDOOR',
  PREMIUM: 'PREMIUM',
  COMFORT: 'COMFORT',
  STEEL: 'STEEL',
  PORTABLE: 'PORTABLE',
};

export const GATEWAY_HW_TYPES = {
  GLORIA: 'GLORIA',
  SDS: 'SDS_GATEWAY',
  NDS: 'NDS_GATEWAY',
  NDS_HYBRID: 'NDS_HYBRID',
};

export const SENSOR_HW_TYPES = {
  DOOR: 'DOOR',
  KIWI_UZ_KNOB: 'KIWI_UZ_KNOB',
  KIWI_UZ_KNOB_SB: 'KIWI_UZ_KNOB_SB',
  KIWI_UZ_COMPACT_KNOB_SB: 'KIWI_UZ_COMPACT_KNOB_SB',
  UZ_KNOB_KIWI_DOOR_INTEGRATION: 'UZ_KNOB_KIWI_DOOR_INTEGRATION',
  KIWI_UZ_KNOB_OUTDOOR: 'KIWI_UZ_KNOB_OUTDOOR',
  UZ_KNOB_KIWI_DOOR_HUB: 'UZ_KNOB_KIWI_DOOR_HUB',
  UZ_KNOB_KIWI_DOOR_HUB_OUTDOOR: 'UZ_KNOB_KIWI_DOOR_HUB_OUTDOOR',
  KIWI_UZ_HANDLE_SB: 'KIWI_UZ_HANDLE_SB',
  UZ_HANDLE_KIWI_DOOR_INTEGRATION: 'UZ_HANDLE_KIWI_DOOR_INTEGRATION',
  KIWI_UZ_HANDLE_OUTDOOR: 'KIWI_UZ_HANDLE_OUTDOOR',
  UZ_HANDLE_KIWI_DOOR_HUB: 'UZ_HANDLE_KIWI_DOOR_HUB',
  UZ_HANDLE_KIWI_DOOR_HUB_OUTDOOR: 'UZ_HANDLE_KIWI_DOOR_HUB_OUTDOOR',
  KIWI_SAFE: 'KIWI_SAFE',
  KIWI_SAFE_TWO: 'KIWI_SAFE_TWO',
  SDS: 'SDS_SENSOR',
  NDS: 'NDS_SENSOR',
  NDS_HYBRID: 'NDS_HYBRID',
  LYCHEE: 'LYCHEE',
};

export const UZ_HW_TYPES = [
  SENSOR_HW_TYPES.UZ_KNOB_KIWI_DOOR_INTEGRATION,
  SENSOR_HW_TYPES.UZ_HANDLE_KIWI_DOOR_INTEGRATION,
  SENSOR_HW_TYPES.KIWI_SAFE,
  SENSOR_HW_TYPES.KIWI_SAFE_TWO,
  SENSOR_HW_TYPES.KIWI_UZ_KNOB,
  SENSOR_HW_TYPES.KIWI_UZ_KNOB_OUTDOOR,
  SENSOR_HW_TYPES.KIWI_UZ_HANDLE_OUTDOOR,
  SENSOR_HW_TYPES.KIWI_UZ_KNOB_SB,
  SENSOR_HW_TYPES.KIWI_UZ_COMPACT_KNOB_SB,
  SENSOR_HW_TYPES.KIWI_UZ_HANDLE_SB,
];

export const HYBRID_HW_TYPES = Object.fromEntries(
  Object.entries(GATEWAY_HW_TYPES)
    .filter(([, value]) => Object.values(SENSOR_HW_TYPES).includes(value)),
);

export const HW_TYPES = {
  ...GATEWAY_HW_TYPES,
  ...SENSOR_HW_TYPES,
};

export const HW_VARIANTS = {
  REGULAR: 'REGULAR',
  APTERYX: 'APTERYX',
  MQTT: 'MQTT',
  NDS: 'NDS',
  OUTDOOR: 'OUTDOOR',
  COMPACT: 'COMPACT',
  COMPACT_OUTDOOR: 'COMPACT_OUTDOOR',
  PREMIUM: 'PREMIUM',
  COMFORT: 'COMFORT',
  STEEL: 'STEEL',
  PORTABLE: 'PORTABLE',
};

export const HW_PRODUCT_HW_VARIANTS = {
  [HW_PRODUCTS.GATEWAY]: [
    HW_VARIANTS.APTERYX,
    HW_VARIANTS.MQTT,
    HW_VARIANTS.NDS,
  ],
  [HW_PRODUCTS.SMART_ENTRY]: [
    HW_VARIANTS.REGULAR,
    HW_VARIANTS.NDS,
  ],
  [HW_PRODUCTS.SMART_ENTRY_HYBRID]: [
    HW_VARIANTS.NDS,
  ],
  [HW_PRODUCTS.KNOB]: [
    HW_VARIANTS.REGULAR,
    HW_VARIANTS.OUTDOOR,
    HW_VARIANTS.COMPACT,
    HW_VARIANTS.COMPACT_OUTDOOR,
    HW_VARIANTS.PORTABLE,
  ],
  [HW_PRODUCTS.HANDLE]: [
    HW_VARIANTS.REGULAR,
    HW_VARIANTS.OUTDOOR,
  ],
  [HW_PRODUCTS.SAFE]: [
    HW_VARIANTS.REGULAR,
  ],
  [HW_PRODUCTS.SAFE_TWO]: [
    HW_VARIANTS.REGULAR,
  ],
  [HW_PRODUCTS.SMART_DOOR]: [
    HW_VARIANTS.PREMIUM,
    HW_VARIANTS.COMFORT,
    HW_VARIANTS.STEEL,
  ],
};

export const DEVICE_TYPES = {
  GATEWAY: 'gateway',
  SENSOR: 'sensor',
  HYBRID: 'hybrid',
};

export const HW_PROTOCOLS = {
  APTERYX: 'APTERYX',
  MQTT: 'MQTT',
  KLAP: 'KLAP',
};

export const DEVICE_USAGES = {
  PRODUCTION: 'PRODUCTION',
  DEMO: 'DEMO',
  TEST: 'TEST',
  UNSERVICED: 'UNSERVICED',
  INOPERABLE: 'INOPERABLE',
};

export const DOOR_TYPES = {
  COMMUNAL: 'COMMUNAL',
  PRIVATE: 'PRIVATE',
};

export const HW_LIFECYCLE_STATES = {
  MANUFACTURED: 'manufactured',
  UNMANUFACTURED: 'unmanufactured',
  INSTALLED: 'installed',
  UNINSTALLED: 'uninstalled',
};

export const HW_CONFIG_OPTIONS = {
  GATEWAY_868_MODE: {
    path: 'radio.868.apteryx',
    values: [true, false],
    defaultValue: false,
    hardwareTypes: [
      GATEWAY_HW_TYPES.NDS,
      SENSOR_HW_TYPES.NDS_HYBRID,
    ],
  },
  KI_SIGNAL_STRENGTH: {
    path: 'kiSignalStrength',
    values: [0, 1, 2, 3, 4],
    defaultValue: 0,
    hardwareTypes: [
      SENSOR_HW_TYPES.DOOR,
    ],
  },
  KI_TX_POWER: {
    path: 'sensor.config.kiTxPower',
    values: [-40, -20, -16, -12, -8, -4, 0, 2, 3, 4, 5, 6, 7, 8],
    defaultValue: -16,
    hardwareTypes: [
      SENSOR_HW_TYPES.NDS,
      SENSOR_HW_TYPES.NDS_HYBRID,
    ],
  },
};

export const MAX_BATTERY_LEVELS = {
  [HW_TYPES.UZ_HANDLE_KIWI_DOOR_INTEGRATION]: 125,
  [HW_TYPES.KIWI_UZ_HANDLE_SB]: 125,
  [HW_TYPES.KIWI_UZ_HANDLE_OUTDOOR]: 125,
  [HW_TYPES.KIWI_UZ_COMPACT_KNOB_SB]: 125,
  [HW_TYPES.UZ_KNOB_KIWI_DOOR_INTEGRATION]: 250,
  [HW_TYPES.KIWI_UZ_KNOB]: 250,
  [HW_TYPES.KIWI_UZ_KNOB_SB]: 250,
  [HW_TYPES.KIWI_UZ_KNOB_OUTDOOR]: 250,
  [HW_TYPES.KIWI_SAFE]: 250,
  [HW_TYPES.KIWI_SAFE_TWO]: 250,
};

export const GATEWAY_RSSI_THRESHOLDS = {
  ideal: -81,
  good: -90,
};

export const GATEWAY_BER_THRESHOLDS = {
  ideal: 0,
  good: 5,
};

export const SENSOR_SIGNAL_THRESHOLDS = {
  ideal: -87,
  good: -92,
};

export const SENSOR_SNR_THRESHOLDS = {
  ideal: 44,
  good: 38,
};

export const SENSOR_BATTERY_STEP_THRESHOLDS = {
  ideal: 4,
  good: 3,
};

export const UNIT_DBM = 'dBm';
