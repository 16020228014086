import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Menu, Loader, Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';

import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { dismissError } from '~/app/actions/HardwareActions';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import { withRoutine } from '~/app/contexts/RoutineContext';
import ButtonGroup from '~/common/components/ButtonGroup';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import PullToRefreshWrapper from '~/app/components/PullToRefreshWrapper';
import DeviceCheckHardwareProvider from '~/app/components/DeviceCheckHardwareProvider';
import FixingTaskDetails from '~/app/components/FixingTaskDetails';
import HardwareActions from '~/app/components/HardwareActions';
import HardwareConnectivityDetails from '~/app/components/HardwareConnectivityDetails';
import HardwareDetails from '~/app/components/HardwareDetails';
import StepView from './StepView';
import '~/app/styles/views/jobs/routines/steps/CheckFixingActionsStep.css';


const ERROR_MESSAGES = defineMessages({
  NOT_FOUND: {
    id: 'CheckFixingActionsStep.jobs.errors.notFound',
    defaultMessage: 'The device does not exist.',
  },
  CONF_TIMEOUT: {
    id: 'CheckFixingActionsStep.jobs.errors.confTimeout',
    defaultMessage: 'The device could not be configured.',
  },
  CONF_ERROR: {
    id: 'CheckFixingActionsStep.jobs.errors.confError',
    defaultMessage: 'The device could not be configured.',
  },
});


const CheckFixingActionsStep = (props) => {
  const { routine: { job, task } } = props;
  const { hardware: { sensorId, gatewayId } } = task;

  const [activeTab, setActiveTab] = useState('task');
  const [next, setNext] = useState(false);

  const dispatch = useDispatch();

  const switchTab = (tab) => {
    dispatch(dismissError());
    setActiveTab(tab);
  };

  useEffect(() => () => {
    dispatch(dismissError());
  }, [dispatch]);

  const hasCheckedActions = task.fixingActions.some(a => a.checked != null);

  if (next) {
    return <RoutineNavigation nextStep />;
  }

  return (
    <StepView
      {...props}
      header={<JobProgressHeader job={job} task={task} stage="fixing" />}
    >
      <StepView.Content compact fixed>
        <Menu compact pointing secondary widths={4}>
          <Menu.Item active={activeTab === 'task'} onClick={() => switchTab('task')}>
            <FormattedMessage id="CheckFixingActionsStep.jobs.task.header" defaultMessage="Task" />
          </Menu.Item>
          <Menu.Item active={activeTab === 'connection'} onClick={() => switchTab('connection')}>
            <FormattedMessage id="CheckFixingActionsStep.jobs.connection.header" defaultMessage="Connection" />
          </Menu.Item>
          <Menu.Item active={activeTab === 'details'} onClick={() => switchTab('details')}>
            <FormattedMessage id="CheckFixingActionsStep.jobs.details.header" defaultMessage="Details" />
          </Menu.Item>
          <Menu.Item active={activeTab === 'actions'} onClick={() => switchTab('actions')}>
            <FormattedMessage id="CheckFixingActionsStep.jobs.actions.header" defaultMessage="Actions" />
          </Menu.Item>
        </Menu>
      </StepView.Content>
      <StepView.Content compact>
        <DeviceCheckHardwareProvider gatewayId={gatewayId} sensorId={sensorId}>
          {({
            hardware,
            error,
            isHardwareFetched,
            refreshHardware,
            editHardware,
          }) => (
            <PullToRefreshWrapper refresh={refreshHardware}>
              <div className="fixing-tab-container">
                {error && (
                  <div className="error-container">
                    <ApiErrorMessage
                      error={error}
                      header={(
                        <FormattedMessage
                          id="CheckFixingActionsStep.jobs.errors.header"
                          defaultMessage="An error occurred!"
                        />
                      )}
                      messages={ERROR_MESSAGES}
                    />
                  </div>
                )}

                <div className={`fixing-tab ${activeTab === 'task' ? 'active' : ''}`}>
                  <FixingTaskDetails task={task} />
                </div>

                <div className={`fixing-tab ${activeTab === 'connection' ? 'active' : ''}`}>
                  {isHardwareFetched ? (
                    <HardwareConnectivityDetails hardware={hardware} />
                  ) : (
                    <Loader active size="medium" />
                  )}
                </div>

                <div className={`fixing-tab ${activeTab === 'details' ? 'active' : ''}`}>
                  {isHardwareFetched ? (
                    <HardwareDetails
                      hardware={hardware}
                      error={error}
                      onEditHardware={editHardware}
                    />
                  ) : (
                    <Loader active size="medium" />
                  )}
                </div>

                <div className={`fixing-tab ${activeTab === 'actions' ? 'active' : ''}`}>
                  {isHardwareFetched ? (
                    <HardwareActions hardware={hardware} />
                  ) : (
                    <Loader active size="medium" />
                  )}
                </div>
              </div>
            </PullToRefreshWrapper>
          )}
        </DeviceCheckHardwareProvider>
      </StepView.Content>

      <StepView.Footer>
        <ButtonGroup>
          <Button fluid color="red" onClick={() => dispatch(requestSkipTask())}>
            <FormattedMessage id="CheckFixingActionsStep.jobs.notFixable.label" defaultMessage="Problem can't be fixed" />
          </Button>
          <Button primary fluid disabled={!hasCheckedActions} onClick={() => setNext(true)}>
            <FormattedMessage id="CheckFixingActionsStep.jobs.fixed.label" defaultMessage="Problem has been fixed" />
          </Button>
        </ButtonGroup>
      </StepView.Footer>
    </StepView>
  );
};

CheckFixingActionsStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

export default withRoutine(CheckFixingActionsStep);
