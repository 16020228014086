import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Ref } from 'semantic-ui-react';

import { withNavigation } from '~/app/contexts/NavigationContext';
import attachCompounds from '~/common/utils/attachCompounds';
import AppHeader from '~/app/components/AppHeader';
import LoadingSpinner from '~/common/components/LoadingSpinner';
import '~/app/styles/views/View.css';


const TEXT_INPUT_TYPES = [
  'email', 'number', 'password',
  'search', 'text', 'tel', 'url',
  'textarea',
];

class ViewSection extends Component {
  render() {
    const { fixed, compact, className } = this.props;
    const classNames = [
      'view-section',
      ...Object.entries({ fixed, compact })
        .filter(([, value]) => value)
        .map(([name]) => name),
    ];
    if (className) {
      classNames.push(className);
    }

    return (
      <div className={classNames.join(' ')}>
        {this.props.children}
      </div>
    );
  }
}
ViewSection.defaultProps = {
  fixed: false,
  compact: false,
  className: null,
};
ViewSection.propTypes = {
  fixed: PropTypes.bool,
  compact: PropTypes.bool,
  className: PropTypes.string,
};

const ViewHeader = props => (
  <ViewSection compact className="header-container">
    <AppHeader {...props} />
  </ViewSection>
);

const ViewContent = props => (
  <ViewSection {...props} className="content-container" />
);

const ViewFooter = props => (
  <ViewSection {...props} className="footer-container" />
);

class View extends Component {
  constructor(props) {
    super(props);
    this.viewRef = React.createRef();
  }

  state = {
    height: window.innerHeight,
    initialHeight: window.innerHeight,
    textInputFocussed: false,
    keyboardOpen: false,
  }

  componentDidMount() {
    const { navigator, onGoBack } = this.props;
    navigator.setBackAction(onGoBack);

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', this.handleViewportResize);
    } else {
      window.addEventListener('resize', this.handleWindowResize);
    }
    this.viewRef.current.addEventListener('focus', this.handleFocus, true);
    this.viewRef.current.addEventListener('blur', this.handleBlur, true);
  }

  componentWillUnmount() {
    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', this.handleViewportResize);
    } else {
      window.removeEventListener('resize', this.handleWindowResize);
    }
    this.viewRef.current.removeEventListener('focus', this.handleFocus, true);
    this.viewRef.current.removeEventListener('blur', this.handleBlur, true);
  }

  handleViewportResize = e => this.handleResize(e.target.height);

  handleWindowResize = () => this.handleResize(window.innerHeight);

  handleResize = (height) => {
    this.setState(({ initialHeight, textInputFocussed }) => ({
      height,
      initialHeight: textInputFocussed ? initialHeight : height,
      keyboardOpen: textInputFocussed && height < initialHeight,
    }));
  }

  handleFocus = (e) => {
    // Footer should be hidden if any text input has focus that is not in the footer
    if (!e.target.closest('.footer-container') && TEXT_INPUT_TYPES.includes(e.target.type)) {
      this.setState({ textInputFocussed: true });
    }
  }

  handleBlur = () => {
    this.setState({ textInputFocussed: false });
  }

  render() {
    const { height, keyboardOpen } = this.state;
    const { children, loading } = this.props;

    let className = 'view-container';
    if (keyboardOpen) {
      className += ' keyboard-open';
    }

    return (
      <Ref innerRef={this.viewRef}>
        <Container className={className} style={{ height }}>
          <LoadingSpinner active={loading} />
          {children}
        </Container>
      </Ref>
    );
  }
}

View.defaultProps = {
  loading: false,
  children: null,
  onGoBack: () => {},
};

View.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  onGoBack: PropTypes.func,
  navigator: PropTypes.shape({
    setBackAction: PropTypes.func.isRequired,
  }).isRequired,
};

export default attachCompounds(withNavigation(View), {
  Header: ViewHeader,
  Content: ViewContent,
  Footer: ViewFooter,
});
