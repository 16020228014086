import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';


export const login = createRoutine('AUTH_LOGIN');
export const logout = createRoutine('AUTH_LOGOUT');
export const requestPasswordReset = createRoutine('AUTH_RESET_PASSWORD_REQUEST');
export const confirmPasswordReset = createRoutine('AUTH_RESET_PASSWORD_CONFIRM');

export const invalidateSession = createAction('AUTH_INVALIDATE_SESSION');
export const dismissError = createAction('AUTH_ERRORS_DISMISS');
