
import React from 'react';
import {
  Modal, Form, Radio, Popup, Icon,
} from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import { usePermission } from '~/common/utils/hooks';
import '~/app/styles/components/JobSortingOrderModal.css';


const JobSortingOrderModal = ({
  open, sortBy, onChange, onClose,
}) => {
  const { state: geoPermissionState } = usePermission('geolocation');
  const hasGeoPermission = geoPermissionState !== 'denied';

  return (
    <Modal
      size="mini"
      dimmer="inverted"
      className="job-sorting-order-modal"
      open={open}
      onClose={onClose}
    >
      <Modal.Header>
        <FormattedMessage id="JobSortingOrderModal.header" defaultMessage="Sorting order" />
      </Modal.Header>

      <Modal.Content>
        <Form>
          <Form.Field>
            <FormattedMessage id="JobSortingOrderModal.appointment.label" defaultMessage="Appointment (ascending)">
              {label => (
                <Radio
                  label={label}
                  checked={sortBy === 'appointment'}
                  onClick={() => onChange('appointment', 'asc')}
                />
              )}
            </FormattedMessage>
          </Form.Field>

          <Form.Field>
            <FormattedMessage id="JobSortingOrderModal.priority.label" defaultMessage="Priority (descending)">
              {label => (
                <Radio
                  label={label}
                  checked={sortBy === 'priority'}
                  onClick={() => onChange('priority', 'desc')}
                />
              )}
            </FormattedMessage>
          </Form.Field>

          {navigator.geolocation && (
            <Form.Field>
              <FormattedMessage id="JobSortingOrderModal.distance.label" defaultMessage="Distance (ascending)">
                {label => (
                  <Radio
                    label={label}
                    disabled={!hasGeoPermission}
                    checked={sortBy === 'distance'}
                    onClick={hasGeoPermission ? () => onChange('distance', 'asc') : null}
                  />
                )}
              </FormattedMessage>
              {!hasGeoPermission && (
                <Popup
                  size="small"
                  position="bottom right"
                  trigger={(
                    <Icon name="warning sign" color="grey" className="geolocation-warning" />
                  )}
                  content={(
                    <FormattedMessage
                      id="JobSortingOrderModal.distance.permissionDenied"
                      defaultMessage="You need to allow the app to access your position to sort by distance."
                    />
                  )}
                />
              )}
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default JobSortingOrderModal;
