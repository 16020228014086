import { takeLatest, call, select } from 'redux-saga/effects';
import imageCompression from 'browser-image-compression';

import { TASK_TYPES } from '~/common/constants';
import {
  fetchJobs, refreshJob, refreshJobs, saveJob, handoverJob, resetJob,
  fetchJobTasks, refreshTask, createTask, saveTask,
  migrateTaskHardware, handoverTask, resetTask,
  createTaskImage, saveTaskImage, deleteTaskImage,
  fetchFixingActions, addTaskFixingAction, saveTaskFixingAction,
  grantSensorPermissions, revokeSensorPermissions,
} from '~/app/actions/JobsActions';
import { registerSagas, takeAndWaitEvery } from '~/app/sagas';
import fetchAll from '~/common/utils/fetchAll';
import getGeolocation from '~/app/utils/geolocation';
import * as api from '~/common/services/ApiService';


function* fetchJobTasksSaga(job) {
  const result = yield call(api.fetchJobTasks, job);
  return {
    job,
    items: result.tasks,
  };
}

function* refreshTaskSaga(task) {
  const result = yield call(api.fetchTask, task.id);
  return { task: result.task };
}

function* saveTaskSaga(task) {
  const result = yield call(api.saveTask, task);
  return { task: result.task };
}

function* createTaskSaga(task) {
  const result = yield call(api.createTask, task);
  return { task: result.task };
}

function* migrateTaskHardwareSaga({
  task, hardwareProduct, hardwareVariant, step,
}) {
  const result = yield call(api.migrateTaskHardware, {
    id: task.id,
    hardwareProduct,
    hardwareVariant,
    step,
  });

  return {
    task: result.task,
    newTasks: result.newTasks,
  };
}

function* handoverTaskSaga(task) {
  yield call(api.handoverTask, task.id);
}

function* resetTaskSaga(task) {
  const result = yield call(api.resetTask, task.id);
  return {
    task: result.deleted ? task : result.task,
    deleted: result.deleted,
  };
}

function* createTaskImageSaga({ task, file, description }) {
  const compressedFile = yield call(imageCompression, file, {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  });

  const { image } = yield call(api.createTaskImage, {
    taskId: task.id,
    file: compressedFile,
    description,
  });

  return { task, image };
}

function* saveTaskImageSaga({ task, image }) {
  ({ image } = yield call(api.saveTaskImage, task.id, image));
  return { task, image };
}

function* deleteTaskImageSaga({ task, image }) {
  yield call(api.deleteTaskImage, task.id, image.id);
  return { task, image };
}

function* fetchFixingActionsSaga({ pageSize = 100 } = {}) {
  const actions = yield call(fetchAll, {
    fetchPageFn: pageNumber => api.fetchFixingActions({ pageNumber, pageSize }),
    parseResponseFn: ({ meta: { total }, actions: results }) => ({ total, results }),
  });

  return { actions };
}

function* addTaskFixingActionSaga({ task, action }) {
  if (!action.id) {
    const { action: createdAction } = yield call(api.createFixingAction, action);
    action = { ...action, ...createdAction };
  }
  ({ action } = yield call(api.addTaskFixingAction, task.id, action));

  return { task, action };
}

function* saveTaskFixingActionSaga({ task, action }) {
  ({ action } = yield call(api.saveTaskFixingAction, task.id, action));
  return { task, action };
}

function* fetchJobsSaga({ pageNumber, pageSize, query = {} }) {
  if (query.sortBy === 'distance' && !query.geolocation) {
    query.geolocation = yield call(getGeolocation);
  }

  const fetchParams = {
    pageNumber,
    pageSize,
    userId: query.userId,
    companyId: query.companyId,
    status: query.status,
    search: query.search,
  };

  if (query.sortBy === 'distance') {
    fetchParams.geolocation = query.geolocation;
  } else {
    fetchParams.sortBy = query.sortBy;
    fetchParams.orderBy = query.orderBy;
  }

  const result = yield call(api.fetchJobs, fetchParams);

  return {
    jobs: result.jobs,
    total: result.meta.total,
    pageNumber: result.meta.pageNumber,
    query,
  };
}

function* refreshJobSaga(job) {
  const result = yield call(api.fetchJob, job.id);
  return { job: result.job };
}

function* refreshJobsSaga({ pageSize }) {
  const query = yield select(state => state.jobs.items.query);

  return yield fetchJobsSaga({
    pageNumber: 1,
    pageSize,
    query: {
      ...query,
      geolocation: null,
    },
  });
}

function* saveJobSaga(job) {
  const result = yield call(api.saveJob, job);
  return { job: result.job };
}

function* handoverJobSaga(job) {
  yield call(api.handoverJob, job.id);
}

function* resetJobSaga(job) {
  const result = yield call(api.resetJob, job.id);
  return { job: result.job };
}

function* grantSensorPermissionsSaga({
  job, tasks, approval: { signatoryName, signatureB64, lang } = {},
}) {
  const installationTasks = tasks.filter(t => t.type === TASK_TYPES.INSTALLATION);
  for (const task of installationTasks) {
    yield call(api.grantTaskSensorPermission, task.id);
  }

  const otherTasks = tasks.filter(t => t.type !== TASK_TYPES.INSTALLATION);
  if (otherTasks.length > 0) {
    const sensorIds = otherTasks
      .filter(t => t.hardware != null)
      .map(t => t.hardware.sensorId)
      .filter(id => !!id);

    yield call(api.grantJobSensorPermissions, {
      id: job.id,
      sensorIds,
      signatoryName,
      signatureB64,
      lang,
    });
  }

  return { job, tasks };
}

function* revokeSensorPermissionsSaga({ job, tasks }) {
  if (tasks) {
    for (const task of tasks) {
      yield call(api.grantTaskSensorPermission, task.id);
    }
  } else {
    yield call(api.revokeJobSensorPermissions, job.id);
  }

  return { job, tasks };
}

export default registerSagas([
  [fetchJobs, fetchJobsSaga, { effectCreator: takeLatest }],
  [refreshJob, refreshJobSaga],
  [refreshJobs, refreshJobsSaga],
  [saveJob, saveJobSaga],
  [migrateTaskHardware, migrateTaskHardwareSaga],
  [handoverJob, handoverJobSaga],
  [resetJob, resetJobSaga],
  [fetchJobTasks, fetchJobTasksSaga],
  [refreshTask, refreshTaskSaga],
  [createTask, createTaskSaga],
  [saveTask, saveTaskSaga],
  [handoverTask, handoverTaskSaga],
  [resetTask, resetTaskSaga],
  [createTaskImage, createTaskImageSaga],
  [saveTaskImage, saveTaskImageSaga],
  [deleteTaskImage, deleteTaskImageSaga],
  [fetchFixingActions, fetchFixingActionsSaga],
  [addTaskFixingAction, addTaskFixingActionSaga],
  [saveTaskFixingAction, saveTaskFixingActionSaga, { effectCreator: takeAndWaitEvery }],
  [grantSensorPermissions, grantSensorPermissionsSaga],
  [revokeSensorPermissions, revokeSensorPermissionsSaga],
]);
