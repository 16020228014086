import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import attachCompounds from '~/common/utils/attachCompounds';


const ConfirmModal = ({
  onConfirm, onClose, children, ...remainingProps
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal
      {...remainingProps}
      closeOnEscape
      closeOnDimmerClick
      dimmer="inverted"
      onClose={onClose}
    >
      {children}

      <Modal.Actions>
        <Button primary onClick={handleConfirm}>
          <FormattedMessage id="ConfirmModal.yes.label" defaultMessage="Yes" />
        </Button>
        <Button onClick={onClose}>
          <FormattedMessage id="ConfirmModal.no.label" defaultMessage="No" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  children: null,
};

ConfirmModal.propTypes = {
  ...Modal.propTypes,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default attachCompounds(ConfirmModal, {
  Header: Modal.Header,
  Content: Modal.Content,
});
