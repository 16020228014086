import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import { JobType, TaskType } from '~/common/types';
import HW_PRODUCT_MESSAGES from '~/common/locales/HardwareProducts';
import { getAlikeTaskProgress } from '~/app/utils/alikeTasks';


const STAGES = {
  INSTALLATION: 'installation',
  FIXING: 'fixing',
  TEST: 'test',
  SUCCESS: 'success',
  SKIP: 'skip',
};

const PROGRESS_MESSAGES = defineMessages({
  [STAGES.INSTALLATION]: {
    id: 'JobProgressHeader.installation',
    defaultMessage: 'Installing {hardwareName} {index}/{total}',
  },
  [STAGES.FIXING]: {
    id: 'JobProgressHeader.fixing',
    defaultMessage: 'Fixing {hardwareName} {index}/{total}',
  },
  [STAGES.TEST]: {
    id: 'JobProgressHeader.test',
    defaultMessage: 'Testing {hardwareName} {index}/{total}',
  },
  [STAGES.SUCCESS]: {
    id: 'JobProgressHeader.success',
    defaultMessage: '{hardwareName} {index}/{total}',
  },
  [STAGES.SKIP]: {
    id: 'JobProgressHeader.skip',
    defaultMessage: 'Skip {hardwareName} {index}/{total}',
  },
});

class JobProgressHeader extends Component {
  render() {
    const { job, task, stage } = this.props;
    const { type, hardware: { hardwareProduct } } = task;
    const { done, total } = getAlikeTaskProgress(
      job.tasks,
      { type, hardware: { hardwareProduct } },
    );
    const hardwareName = this.props.intl.formatMessage(HW_PRODUCT_MESSAGES[hardwareProduct]);

    return (
      <span>
        {this.props.intl.formatMessage(PROGRESS_MESSAGES[stage], {
          hardwareName,
          index: done + 1,
          total,
        })}
      </span>
    );
  }
}

JobProgressHeader.propTypes = {
  job: JobType.isRequired,
  task: TaskType.isRequired,
  stage: PropTypes.oneOf(Object.values(STAGES)).isRequired,
};

export default injectIntl(JobProgressHeader);
