import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Header, Segment, List, Input, Button,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { fetchGateway } from '~/app/actions/HardwareActions';
import { fetchCurrentOpsUser } from '~/app/actions/UsersActions';
import CheckList from '~/app/components/CheckList';
import View from '~/app/views/View';
import '~/app/styles/views/manufacturing/ManufacturingStartView.css';

const CHECK_RESULTS = {
  PENDING: 'pending',
  IN_PROGRESS: 'in-progress',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

const CHECK_RESULT_MESSAGES = {
  [CHECK_RESULTS.PENDING]: defineMessages({
    internetCheck: {
      id: 'ManufacturingStartView.checks.pending.internet',
      defaultMessage: 'Internet connectivity',
    },
    backendCheck: {
      id: 'ManufacturingStartView.checks.pending.backend',
      defaultMessage: 'Connectivity of KIWI servers',
    },
    gatewayCheck: {
      id: 'ManufacturingStartView.checks.pending.gateway',
      defaultMessage: 'Connectivity of gateway',
    },
  }),
  [CHECK_RESULTS.IN_PROGRESS]: defineMessages({
    internetCheck: {
      id: 'ManufacturingStartView.checks.inProgress.internet',
      defaultMessage: 'Checking Internet connectivity',
    },
    backendCheck: {
      id: 'ManufacturingStartView.checks.inProgress.backend',
      defaultMessage: 'Checking connectivity of KIWI servers',
    },
    gatewayCheck: {
      id: 'ManufacturingStartView.checks.inProgress.gateway',
      defaultMessage: 'Checking connectivity of gateway',
    },
  }),
  [CHECK_RESULTS.SUCCEEDED]: defineMessages({
    internetCheck: {
      id: 'ManufacturingStartView.checks.succeeded.internet',
      defaultMessage: 'Device is online',
    },
    backendCheck: {
      id: 'ManufacturingStartView.checks.succeeded.backend',
      defaultMessage: 'KIWI servers are online',
    },
    gatewayCheck: {
      id: 'ManufacturingStartView.checks.succeeded.gateway',
      defaultMessage: 'Gateway is online',
    },
  }),
  [CHECK_RESULTS.FAILED]: defineMessages({
    internetCheck: {
      id: 'ManufacturingStartView.checks.failed.internet',
      defaultMessage: 'Device is offline',
    },
    backendCheck: {
      id: 'ManufacturingStartView.checks.failed.backend',
      defaultMessage: 'KIWI servers are offline',
    },
    gatewayCheck: {
      id: 'ManufacturingStartView.checks.failed.gateway',
      defaultMessage: 'Gateway is offline',
    },
  }),
};

const ERROR_MESSAGES = defineMessages({
  internetCheck: {
    id: 'ManufacturingStartView.errors.internetCheck',
    defaultMessage: 'The device that is running this application seems to be offline!',
  },
  backendCheck: {
    id: 'ManufacturingStartView.errors.backendCheck',
    defaultMessage: 'The KIWI servers seem to be offline!',
  },
  gatewayCheck: {
    id: 'ManufacturingStartView.errors.gatewayCheck',
    defaultMessage: 'The gateway seems to be offline!',
  },
});

const FIXING_OPTIONS = {
  internetCheck: defineMessages({
    checkConnection: {
      id: 'ManufacturingStartView.fixingOptions.internet.checkConnection',
      defaultMessage: 'Make sure that this device is connected to the Internet',
    },
    restartApplication: {
      id: 'ManufacturingStartView.fixingOptions.internet.restartApplication',
      defaultMessage: 'Restart the application',
    },
    restartDevice: {
      id: 'ManufacturingStartView.fixingOptions.internet.restartDevice',
      defaultMessage: 'Restart the device',
    },
  }),
  backendCheck: defineMessages({
    contactSupport: {
      id: 'ManufacturingStartView.fixingOptions.backend.contactSupport',
      defaultMessage: 'Contact the KIWI support',
    },
  }),
  gatewayCheck: defineMessages({
    checkGatewayPower: {
      id: 'ManufacturingStartView.fixingOptions.gateway.checkGatewayPower',
      defaultMessage: 'Make sure the gateway is connected to power',
    },
    restartGateway: {
      id: 'ManufacturingStartView.fixingOptions.gateway.restartGateway',
      defaultMessage: 'Unplug the power cable of the gateway and plug it back in',
    },
    contactSupport: {
      id: 'ManufacturingStartView.fixingOptions.gateway.contactSupport',
      defaultMessage: 'Contact the KIWI support',
    },
  }),
};


class ManufacturingStartView extends Component {
  constructor(props) {
    super(props);
    this.uuidInputRef = React.createRef();
  }

  state = {
    internetCheck: CHECK_RESULTS.IN_PROGRESS,
    hardwareUuid: '',
    start: false,
  }

  componentDidMount() {
    this.runChecks();
  }

  componentDidUpdate(prevProps) {
    const { current: prevUser } = prevProps.users;
    const { current: user } = this.props.users;

    // Run gateway check after user has been fetched
    if (user && user !== prevUser) {
      this.props.fetchGateway({ uuid: this.gatewayUuid, include: ['status'] });
    }

    // Focus UUID input field as users will use a handheld scanner device
    if (this.uuidInputRef.current) {
      this.uuidInputRef.current.focus();
    }
  }

  get gatewayUuid() {
    const { current: user } = this.props.users;
    if (user && user.manufacturing) {
      return user.manufacturing.gatewayUuid;
    }

    return null;
  }

  get checkResults() {
    const { internetCheck } = this.state;
    const { loading: userLoading, error: userError } = this.props.users;
    const { gateways, loading: hardwareLoading, error: hardwareError } = this.props.hardware;
    const loading = userLoading || hardwareLoading;
    const error = userError || hardwareError;

    let backendCheck = CHECK_RESULTS.IN_PROGRESS;
    let gatewayCheck = CHECK_RESULTS.IN_PROGRESS;

    if (internetCheck === CHECK_RESULTS.SUCCEEDED && !loading) {
      const gateway = Object.values(gateways.byId).filter(x => x.uuid === this.gatewayUuid)[0];
      if (gateway) {
        backendCheck = CHECK_RESULTS.SUCCEEDED;
        gatewayCheck = gateway.status.isOnline ? CHECK_RESULTS.SUCCEEDED : CHECK_RESULTS.FAILED;
      }
      if (error) {
        if (error.code === 'NETWORK' || error.status > 500) {
          backendCheck = CHECK_RESULTS.FAILED;
          gatewayCheck = CHECK_RESULTS.PENDING;
        } else {
          backendCheck = CHECK_RESULTS.SUCCEEDED;
          gatewayCheck = CHECK_RESULTS.FAILED;
        }
      }
    }

    if (internetCheck === CHECK_RESULTS.FAILED) {
      backendCheck = CHECK_RESULTS.PENDING;
      gatewayCheck = CHECK_RESULTS.PENDING;
    }

    return { internetCheck, backendCheck, gatewayCheck };
  }

  runChecks = () => {
    // Check internet connection
    this.setState({
      internetCheck: navigator.onLine ? CHECK_RESULTS.SUCCEEDED : CHECK_RESULTS.FAILED,
    });

    // Fetch user to check backend connection and get assigned gateway UUID
    // Status of gateway is checked after the user has been fetched
    this.props.fetchCurrentOpsUser();
  }

  runTests = () => {
    this.setState({ start: true });
  }

  handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.runTests();
    }
  }

  render() {
    const { hardwareUuid, start } = this.state;

    const { checkResults } = this;
    const { internetCheck, backendCheck, gatewayCheck } = checkResults;
    const checksSucceeded = Object.values(checkResults).every(x => x === CHECK_RESULTS.SUCCEEDED);
    const failedCheck = Object.entries(checkResults)
      .filter(([, res]) => res === CHECK_RESULTS.FAILED)
      .map(([name]) => name)[0];

    if (start) {
      return <Redirect to={`/manufacturing/smart-door/${hardwareUuid}`} />;
    }

    return (
      <View>
        <View.Header
          hideBack
          primaryText={<FormattedMessage id="ManufacturingStartView.header.primary" defaultMessage="Manufacture hardware" />}
          secondaryText={<FormattedMessage id="ManufacturingStartView.header.secondary" defaultMessage="Select device" />}
          action={this.props.logout}
          actionIcon="sign-out"
        />

        <View.Content>
          <Segment vertical className="system-check-container">
            <Header size="tiny">
              <FormattedMessage id="ManufacturingStartView.checks.header" defaultMessage="System checks" />
            </Header>
            <CheckList>
              <CheckList.Item
                loading={internetCheck === CHECK_RESULTS.IN_PROGRESS}
                success={internetCheck === CHECK_RESULTS.SUCCEEDED}
                error={internetCheck === CHECK_RESULTS.FAILED}
                unknown={internetCheck === CHECK_RESULTS.PENDING}
              >
                {this.props.intl.formatMessage(CHECK_RESULT_MESSAGES[internetCheck].internetCheck)}
              </CheckList.Item>
              <CheckList.Item
                loading={backendCheck === CHECK_RESULTS.IN_PROGRESS}
                success={backendCheck === CHECK_RESULTS.SUCCEEDED}
                error={backendCheck === CHECK_RESULTS.FAILED}
                unknown={backendCheck === CHECK_RESULTS.PENDING}
              >
                {this.props.intl.formatMessage(CHECK_RESULT_MESSAGES[backendCheck].backendCheck)}
              </CheckList.Item>
              <CheckList.Item
                loading={gatewayCheck === CHECK_RESULTS.IN_PROGRESS}
                success={gatewayCheck === CHECK_RESULTS.SUCCEEDED}
                error={gatewayCheck === CHECK_RESULTS.FAILED}
                unknown={gatewayCheck === CHECK_RESULTS.PENDING}
              >
                {this.props.intl.formatMessage(CHECK_RESULT_MESSAGES[gatewayCheck].gatewayCheck)}
                <CheckList.Description>
                  {this.gatewayUuid}
                </CheckList.Description>
              </CheckList.Item>
            </CheckList>
          </Segment>

          {checksSucceeded && (
            <Segment vertical className="instructions-container">
              <Header size="tiny">
                <FormattedMessage id="ManufacturingStartView.instructions.header" defaultMessage="Instructions" />
              </Header>
              <List ordered>
                <List.Item>
                  <FormattedMessage id="ManufacturingStartView.instructions.smartDoor.placeDoor" defaultMessage="Place door on testbed" />
                </List.Item>
                <List.Item>
                  <FormattedMessage id="ManufacturingStartView.instructions.smartDoor.mountSensor" defaultMessage="Mount KIWI sensor" />
                </List.Item>
                <List.Item>
                  <FormattedMessage id="ManufacturingStartView.instructions.smartDoor.connectSensor" defaultMessage="Connect sensor cables" />
                </List.Item>
                <List.Item>
                  <FormattedMessage id="ManufacturingStartView.instructions.smartDoor.connectPower" defaultMessage="Connect door to power and magnet rig" />
                </List.Item>
                <List.Item>
                  <FormattedMessage id="ManufacturingStartView.instructions.smartDoor.scanQrCode" defaultMessage="Scan door's QR code" />
                </List.Item>
              </List>
            </Segment>
          )}

          {failedCheck && (
            <Segment vertical>
              <Header size="tiny">
                {this.props.intl.formatMessage(ERROR_MESSAGES[failedCheck])}
              </Header>
              <List ordered className="instructions-list">
                {Object.entries(FIXING_OPTIONS[failedCheck]).map(([key, message]) => (
                  <List.Item key={key}>
                    {this.props.intl.formatMessage(message)}
                  </List.Item>
                ))}
              </List>
            </Segment>
          )}
        </View.Content>

        <View.Footer>
          {checksSucceeded && (
            <>
              <FormattedMessage id="ManufacturingStartView.uuidInput.placeholder" defaultMessage="Enter UUID or scan QR code...">
                {placeholder => (
                  <Input
                    fluid
                    value={hardwareUuid}
                    ref={this.uuidInputRef}
                    placeholder={placeholder}
                    onKeyDown={this.handleInputKeyDown}
                    onChange={(e, { value }) => this.setState({ hardwareUuid: value })}
                  />
                )}
              </FormattedMessage>
              <Button primary fluid disabled={!hardwareUuid} onClick={this.runTests}>
                <FormattedMessage id="ManufacturingStartView.startTests.label" defaultMessage="Start tests" />
              </Button>
            </>
          )}
          {failedCheck && (
            <Button fluid onClick={this.runChecks}>
              <FormattedMessage id="ManufacturingStartView.retryChecks.label" defaultMessage="Retry" />
            </Button>
          )}
        </View.Footer>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchCurrentOpsUser,
  fetchGateway,
}, dispatch);

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManufacturingStartView));
