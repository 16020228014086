import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


const FormattedFloor = ({ value }) => {
  if (value == null) {
    return null;
  }

  if (value < 0) {
    return (
      <FormattedMessage
        id="FormattedFloor.basement"
        defaultMessage="{value, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} basement"
        values={{ value: Math.abs(value) }}
      />
    );
  }

  if (value === 0) {
    return <FormattedMessage id="FormattedFloor.groundFloor" defaultMessage="Ground floor" />;
  }

  return (
    <FormattedMessage
      id="FormattedFloor.floor"
      defaultMessage="{value, selectordinal, one {#st} two {#nd} few {#rd} other {#th}} floor"
      values={{ value }}
    />
  );
};

FormattedFloor.defaultProps = {
  value: null,
};

FormattedFloor.propTypes = {
  value: PropTypes.number,
};

export default FormattedFloor;
