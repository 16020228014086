import common from '~/common/locales';
import en from '~/app/locales/en.json';
import de from '~/app/locales/de.json';

export default {
  en: {
    ...common.en,
    ...en,
  },
  de: {
    ...common.de,
    ...de,
  },
};
