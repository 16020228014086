import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, defineMessages } from 'react-intl';

import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { saveTask } from '~/app/actions/JobsActions';
import {
  fetchGateway,
  fetchSensor,
  checkGatewayConnectivity,
  checkSensorConnectivity,
  stopRoutines,
} from '~/app/actions/HardwareActions';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';
import TimeoutProgress from '~/app/components/TimeoutProgress';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const TIMEOUT = 45000;

const ERROR_MESSAGES = defineMessages({
  NOT_ALLOWED: {
    id: 'TrackHardwareStatusStep.jobs.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
  RATE_LIMIT_EXCEEDED: {
    id: 'TrackHardwareStatusStep.jobs.errors.rateLimitExceeded',
    defaultMessage: 'The rate limit has been exceeded. Please try again in a couple of seconds.',
  },
});


class TrackHardwareStatusStep extends Component {
  state = {
    next: false,
  }

  componentDidMount() {
    const { fresh } = this.props;
    if (fresh) {
      this.checkConnectivity();
    } else {
      this.fetchHardware();
    }
  }

  componentDidUpdate(prevProps) {
    const { sensor: prevSensor, gateway: prevGateway } = prevProps.routine;
    const { sensor, gateway } = this.props.routine;
    const { hardwareConnectivity: prevConnectivity } = prevProps.hardware;
    const { hardwareConnectivity: connectivity } = this.props.hardware;

    if (prevSensor !== sensor || prevGateway !== gateway || (!prevConnectivity && connectivity)) {
      this.updateTask();
    }
  }

  componentWillUnmount() {
    this.props.stopRoutines();
  }

  fetchHardware = () => {
    const { task } = this.props.routine;
    if (task.hardware.gatewayId != null) {
      this.props.fetchGateway({ id: task.hardware.gatewayId, include: ['status', 'status.signal'] });
    } else {
      this.props.fetchSensor({ id: task.hardware.sensorId, include: ['status', 'status.signal'] });
    }
  }

  checkConnectivity = () => {
    const { task } = this.props.routine;
    if (task.hardware.gatewayId != null) {
      this.props.checkGatewayConnectivity({ id: task.hardware.gatewayId });
    } else {
      this.props.checkSensorConnectivity({ id: task.hardware.sensorId });
    }
  }

  updateTask = () => {
    const { atStart, atEnd } = this.props;
    const { task, sensor, gateway } = this.props.routine;

    let status;
    if (gateway) {
      const {
        isOnline: online,
        signal: { rssi, bitErrorRate },
      } = gateway.status;

      status = {
        online, rssi, bitErrorRate,
      };
    } else {
      const {
        isOnline: online,
        signal: { signal, noise, snr },
      } = sensor.status;

      status = {
        online, signal, noise, snr,
      };
    }

    this.props.saveTask({
      id: task.id,
      hardware: {
        statusStart: atStart ? status : task.statusStart,
        statusEnd: atEnd ? status : task.statusEnd,
      },
    });

    this.setState({ next: true });
  }

  render() {
    const { next } = this.state;
    const { atStart, atEnd } = this.props;
    const { job, task } = this.props.routine;
    const { error: hardwareError } = this.props.hardware;
    const { taskSaved, error: taskError } = this.props.jobs;

    if (next && taskSaved) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="test" />}
        error={hardwareError}
        errorMessages={ERROR_MESSAGES}
        errorHeader={(
          <FormattedMessage
            id="TrackHardwareStatusStep.jobs.errors.header"
            defaultMessage="Test failed"
          />
        )}
        retry={this.checkConnectivity}
      >
        <StepView.Content>
          <JobSaveErrorModal
            error={next ? taskError : null}
            onDismiss={this.updateTask}
          />
          <p>
            {atStart && (
              <FormattedMessage
                id="TrackHardwareStatusStep.jobs.description.atStart"
                defaultMessage="Checking initial status of device..."
              />
            )}
            {atEnd && (
              <FormattedMessage
                id="TrackHardwareStatusStep.jobs.description.atEnd"
                defaultMessage="Checking final status of device..."
              />
            )}
          </p>
          <TimeoutProgress timeout={TIMEOUT} />
        </StepView.Content>
      </StepView>
    );
  }
}

TrackHardwareStatusStep.defaultProps = {
  fresh: false,
  atStart: false,
  atEnd: false,
};

TrackHardwareStatusStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
  fresh: PropTypes.bool,
  atStart: PropTypes.bool,
  atEnd: PropTypes.bool,
};

const mapStateToProps = state => ({
  jobs: state.jobs,
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchGateway,
  fetchSensor,
  checkGatewayConnectivity,
  checkSensorConnectivity,
  stopRoutines,
  saveTask,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrackHardwareStatusStep));
