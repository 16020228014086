import React from 'react';
import PropTypes from 'prop-types';

import '~/common/styles/components/MonospaceText.css';


const MonospaceText = ({ children }) => (
  <span className="monospace-text">
    {children}
  </span>
);

MonospaceText.defaultProps = {
  children: null,
};

MonospaceText.propTypes = {
  children: PropTypes.node,
};

export default MonospaceText;
