import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Segment, Button, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import SignaturePad from 'signature_pad';
import trimCanvas from 'trim-canvas';

import { useEventListener } from '~/common/utils/hooks';
import '~/common/styles/components/SignaturePad.css';


// Scales and translates the paths of the signature to optimally
// fill the available space while preserving the aspect ratio
const scaleAndTranslateData = ({
  data, maxWidth, maxHeight, margin = 0,
}) => {
  const valuesX = data.flatMap(({ points }) => points.map(({ x }) => x));
  const valuesY = data.flatMap(({ points }) => points.map(({ y }) => y));
  const minX = Math.min(...valuesX);
  const maxX = Math.max(...valuesX);
  const minY = Math.min(...valuesY);
  const maxY = Math.max(...valuesY);

  const boundsWidth = maxX - minX;
  const boundsHeight = maxY - minY;
  const availableWidth = maxWidth - 2 * margin;
  const availableHeight = maxHeight - 2 * margin;
  const scaleFactorX = availableWidth / boundsWidth;
  const scaleFactorY = availableHeight / boundsHeight;
  const scaleFactor = Math.min(scaleFactorX, scaleFactorY);

  // Vertically center signature
  const offsetX = margin;
  const offsetY = margin + (availableHeight - (boundsHeight * scaleFactor)) / 2;

  return data.map(group => ({
    ...group,
    points: group.points.map(point => ({
      ...point,
      x: (point.x - minX) * scaleFactor + offsetX,
      y: (point.y - minY) * scaleFactor + offsetY,
    })),
  }));
};


const getTrimmedDataURL = (sourceCanvas, type) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Strip whitespace around signature
  canvas.width = sourceCanvas.width;
  canvas.height = sourceCanvas.height;
  ctx.drawImage(sourceCanvas, 0, 0);
  trimCanvas(canvas);

  // Fill background in case output format doesn't support transparency
  if (type === 'image/jpeg') {
    ctx.fillStyle = '#ffffff';
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  return canvas.toDataURL(type);
};


const CustomSignaturePad = ({ outputType, onChange }) => {
  const canvasRef = useRef();
  const signaturePadRef = useRef();
  const [isLocked, setIsLocked] = useState(true);
  const [isEmpty, setIsEmpty] = useState(true);

  const handleResize = useCallback(() => {
    const { current: canvas } = canvasRef;
    const { current: signaturePad } = signaturePadRef;

    if (!canvas || !signaturePad) {
      return;
    }

    // When changing the size of the canvas, it will be cleared by the browser.
    // To circumvent this the data is stored and adapted to the new canvas
    // dimensions before and drawn again afterwards.
    const data = scaleAndTranslateData({
      data: signaturePad.toData(),
      maxWidth: canvas.offsetWidth,
      maxHeight: canvas.offsetHeight,
      margin: 20,
    });

    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);

    signaturePad.clear();
    signaturePad.fromData(data);
  }, []);

  useEventListener(window, 'resize', handleResize, { debounce: 100 });

  useEffect(() => {
    signaturePadRef.current = new SignaturePad(canvasRef.current);
    signaturePadRef.current.on();
    signaturePadRef.current.onEnd = () => setIsEmpty(false);
    handleResize();

    return () => signaturePadRef.current.off();
  }, [handleResize]);

  useEffect(() => {
    if (isLocked) {
      signaturePadRef.current.off();
    } else {
      signaturePadRef.current.on();
    }
  }, [isLocked]);

  const clear = useCallback(() => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }

    setIsEmpty(true);
    onChange(null);
  }, [onChange]);

  const handleResetAndUnlock = useCallback(() => {
    if (isLocked) {
      clear();
      setIsLocked(false);
    }
  }, [isLocked, clear]);

  const handleConfirm = useCallback(() => {
    setIsLocked(true);
    onChange(getTrimmedDataURL(canvasRef.current, outputType));
  }, [canvasRef, outputType, onChange]);

  const handleCancel = useCallback(() => {
    clear();
    setIsLocked(true);
  }, [clear]);

  return (
    <div className="signature-pad-wrapper">
      <Segment
        attached="top"
        className={`canvas-container ${isLocked ? 'locked' : ''}`}
        onClick={handleResetAndUnlock}
      >
        <Icon name={isLocked ? 'lock' : 'pencil'} />
        <canvas ref={canvasRef} />
      </Segment>

      <Button.Group widths="2" attached="bottom">
        {isLocked ? (
          <>
            <Button primary disabled={!isEmpty} onClick={handleResetAndUnlock}>
              <FormattedMessage id="SignaturePad.sign.label" defaultMessage="Sign" />
            </Button>
            <Button disabled={isEmpty} onClick={handleResetAndUnlock}>
              <FormattedMessage id="SignaturePad.reset.label" defaultMessage="Reset" />
            </Button>
          </>
        ) : (
          <>
            <Button primary disabled={isEmpty} onClick={handleConfirm}>
              <FormattedMessage id="SignaturePad.confirm.label" defaultMessage="Confirm" />
            </Button>
            <Button onClick={handleCancel}>
              <FormattedMessage id="SignaturePad.cancel.label" defaultMessage="Cancel" />
            </Button>
          </>
        )}
      </Button.Group>
    </div>
  );
};

CustomSignaturePad.defaultProps = {
  outputType: 'image/png',
  onChange: () => {},
};

CustomSignaturePad.propTypes = {
  outputType: PropTypes.oneOf([
    'image/png',
    'image/jpeg',
  ]),
  onChange: PropTypes.func,
};

export default CustomSignaturePad;
