import React from 'react';
import _ from 'lodash';

import { HW_CONFIG_OVERRIDES } from '~/app/constants';
import JobRoutineView from './JobRoutineView';
import NotesStep from './steps/NotesStep';
import UUIDInputStep from './steps/UUIDInputStep';
import SmartEntryInstallationPreCheckStep from './steps/SmartEntryInstallationPreCheckStep';
import SmartEntryInstallationDataInputStep from './steps/SmartEntryInstallationDataInputStep';
import HardwareRegistrationStep from './steps/HardwareRegistrationStep';
import SmartEntryInstallationPostCheckStep from './steps/SmartEntryInstallationPostCheckStep';
import ConnectivityTestingStep from './steps/ConnectivityTestingStep';
import RemoteOpeningTestingStep from './steps/RemoteOpeningTestingStep';
import ConfigurationStep from './steps/ConfigurationStep';
import GrantSensorPermissionStep from './steps/GrantSensorPermissionStep';
import KiSignalStrengthAdjustmentStep from './steps/KiSignalStrengthAdjustmentStep';
import KiOpeningTestingStep from './steps/KiOpeningTestingStep';
import LinkPerformanceTestingStep from './steps/LinkPerformanceTestingStep';
import TrackHardwareStatusStep from './steps/TrackHardwareStatusStep';
import SuccessStep from './steps/SuccessStep';


const SmartEntryHybridInstallationView = (props) => {
  const { task: { hardware, dispatcherNotes } } = props;
  const { hardwareProduct, hardwareVariant } = hardware;
  const configOverrides = _.get(HW_CONFIG_OVERRIDES, [hardwareProduct, hardwareVariant], {});

  return (
    <JobRoutineView {...props}>
      <NotesStep id="notes" disabled={!dispatcherNotes} />
      <SmartEntryInstallationPreCheckStep id="preCheck" />
      <UUIDInputStep id="uuidInput" />
      <HardwareRegistrationStep id="hardwareRegistration" skipBackwards />
      <SmartEntryInstallationDataInputStep id="dataInput" />
      <SmartEntryInstallationPostCheckStep id="postCheck" />
      {/* TODO: Disable signal strength check for now until further testing has been done */}
      <ConnectivityTestingStep id="connectivityTesting" ignoreSignalStrength skipBackwards />
      <ConfigurationStep id="pushTestingConfig" override={configOverrides.TESTING} full skipBackwards />
      <RemoteOpeningTestingStep id="remoteOpeningTesting" skipBackwards />
      <GrantSensorPermissionStep id="grantingPermission" skipBackwards />
      <KiSignalStrengthAdjustmentStep id="kiSignalStrengthAdjustment" />
      <KiOpeningTestingStep id="kiClickOpeningTesting" trigger="click" />
      <ConfigurationStep id="pushProductionConfig" override={configOverrides.PRODUCTION} disabled={!configOverrides.PRODUCTION} skipBackwards />
      <LinkPerformanceTestingStep id="linkPerformanceTesting" skipBackwards />
      <TrackHardwareStatusStep id="checkStatusEnd" atEnd skipBackwards />
      <SuccessStep id="success" />
    </JobRoutineView>
  );
};

export default SmartEntryHybridInstallationView;
