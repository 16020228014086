import { defineMessages } from 'react-intl';
import { TOOLS } from '~/app/constants';


export default defineMessages({
  [TOOLS.MANUFACTURING]: {
    id: 'Tools.manufacturing',
    defaultMessage: 'Manufacturing',
  },
  [TOOLS.JOBS]: {
    id: 'Tools.jobs',
    defaultMessage: 'Jobs',
  },
  [TOOLS.DEVICE_CHECK]: {
    id: 'Tools.deviceCheck',
    defaultMessage: 'Device Check',
  },
});
