export default (entity, relationPaths) => {
  if (!entity) {
    return false;
  }

  for (const path of relationPaths) {
    let value = entity;
    for (const key of path.split('.')) {
      value = value[key];
      if (typeof value === 'undefined') {
        return false;
      }
      if (value === null) {
        break;
      }
      if (Array.isArray(value)) {
        if (value.length === 0) {
          break;
        }

        ([value] = value);
      }
    }
  }

  return true;
};
