import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber, defineMessages, injectIntl } from 'react-intl';

import rateMetric from '~/common/utils/rateMetric';
import FormattedDateTime from '~/common/components/FormattedDateTime';
import '~/common/styles/components/FormattedMetric.css';


const RATING_MESSAGES = defineMessages({
  ideal: {
    id: 'FormattedMetric.rating.ideal',
    defaultMessage: 'ideal',
  },
  good: {
    id: 'FormattedMetric.rating.good',
    defaultMessage: 'good',
  },
  poor: {
    id: 'FormattedMetric.rating.poor',
    defaultMessage: 'poor',
  },
});

const FormattedMetric = ({
  intl, value, displayValue, unit, timestamp, thresholds,
  minimumFractionDigits, maximumFractionDigits, hideRatingText,
}) => {
  if (value == null) {
    return null;
  }
  if (displayValue == null) {
    displayValue = value;
  }

  const rating = rateMetric(value, thresholds);
  return (
    <div className="formatted-metric">
      <p className={rating}>
        <FormattedNumber
          value={displayValue}
          minimumFractionDigits={minimumFractionDigits}
          maximumFractionDigits={maximumFractionDigits}
        />
        {unit}
        {rating && !hideRatingText && (
          <span className="rating-text">
            {intl.formatMessage(RATING_MESSAGES[rating])}
          </span>
        )}
      </p>
      {timestamp && (
        <p className="timestamp">
          <FormattedDateTime relative value={timestamp} />
        </p>
      )}
    </div>
  );
};

FormattedMetric.defaultProps = {
  value: null,
  displayValue: null,
  unit: null,
  timestamp: null,
  thresholds: null,
  minimumFractionDigits: null,
  maximumFractionDigits: null,
  hideRatingText: false,
};

FormattedMetric.propTypes = {
  value: PropTypes.number,
  displayValue: PropTypes.number,
  unit: PropTypes.string,
  timestamp: PropTypes.string,
  thresholds: PropTypes.shape({
    good: PropTypes.number.isRequired,
    ideal: PropTypes.number.isRequired,
  }),
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
  hideRatingText: PropTypes.bool,
};

export default injectIntl(FormattedMetric);
