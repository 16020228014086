import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header } from 'semantic-ui-react';

import { changeLocale } from '~/app/actions/LocaleActions';
import KiwiLogo from '~/common/components/KiwiLogo';
import LanguageSelector from '~/app/components/LanguageSelector';
import View from '~/app/views/View';
import '~/app/styles/views/AuthView.css';


class AuthView extends Component {
  render() {
    const { loading, header, children } = this.props;
    const { currentLocale } = this.props.locale;

    return (
      <View loading={loading}>
        <View.Content>
          <div className="auth-container">
            <KiwiLogo centered size="medium" />
            <Header textAlign="center">
              Smart Installation
              {header && (
                <Header.Subheader>{header}</Header.Subheader>
              )}
            </Header>
            {children}
          </div>
        </View.Content>

        <View.Footer>
          <LanguageSelector value={currentLocale} onChange={this.props.changeLocale} />
        </View.Footer>
      </View>
    );
  }
}

AuthView.defaultProps = {
  loading: false,
  header: null,
  children: null,
};

AuthView.propTypes = {
  loading: PropTypes.bool,
  header: PropTypes.node,
  children: PropTypes.node,
};

const mapStateToProps = state => ({
  locale: state.locale,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  changeLocale,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthView);
