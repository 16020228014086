import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Message, Icon, Header, Form, TextArea, Button,
} from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { TASK_TYPES, TASK_STATUSES_PENDING, HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { finishTask } from '~/app/actions/RoutineActions';
import { withRoutine } from '~/app/contexts/RoutineContext';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import CheckList from '~/app/components/CheckList';
import CausingPartyComboBox from '~/app/components/CausingPartyComboBox';
import StepView from './StepView';
import '~/app/styles/views/jobs/routines/steps/SuccessStep.css';


const SUCCESS_MESSAGES = {
  [TASK_TYPES.INSTALLATION]: defineMessages({
    header: {
      id: 'SuccessStep.jobs.message.installation.header',
      defaultMessage: 'Installation successful!',
    },
    text: {
      id: 'SuccessStep.jobs.message.installation.text',
      defaultMessage: 'The device has been successfully set up.',
    },
  }),
  [TASK_TYPES.FIXING]: defineMessages({
    header: {
      id: 'SuccessStep.jobs.message.fixing.header',
      defaultMessage: 'Fixing successful!',
    },
    text: {
      id: 'SuccessStep.jobs.message.fixing.text',
      defaultMessage: 'The device has been successfully fixed.',
    },
  }),
};

const SUMMARY_MESSAGES = defineMessages({
  hardwareRegistration: {
    id: 'SuccessStep.jobs.summary.registration',
    defaultMessage: 'Device is registered in infrastructure',
  },
  connectivityTestingOnline: {
    id: 'SuccessStep.jobs.summary.connectivityTesting.online',
    defaultMessage: 'Device is connected to the Internet',
  },
  connectivityTestingGsm: {
    id: 'SuccessStep.jobs.summary.connectivityTesting.gsm',
    defaultMessage: 'Device has a good GSM connection (RSSI:\u00a0{rssi, number},\u00a0BER:\u00a0{bitErrorRate, number})',
  },
  connectivityTesting868Mhz: {
    id: 'SuccessStep.jobs.summary.connectivityTesting.868Mhz',
    defaultMessage: 'Device has a good 868MHz connection (SNR:\u00a0{snr, number})',
  },
  batteryTesting: {
    id: 'SuccessStep.jobs.summary.batteryTesting',
    defaultMessage: 'The sensor has a decent battery level.',
  },
  remoteOpeningTesting: {
    id: 'SuccessStep.jobs.summary.remoteOpeningTesting',
    defaultMessage: 'Sensor can be activated via the Internet',
  },
  kiOpeningTesting: {
    id: 'SuccessStep.jobs.summary.kiOpeningTesting',
    defaultMessage: 'Sensor can be activated with the KIWI transponder',
  },
  kiSignalStrengthAdjustment: {
    id: 'SuccessStep.jobs.summary.kiSignalStrengthAdjustment',
    defaultMessage: 'Signal strength of device was optimized',
  },
});

const INSTALLATION_SUMMARY_MESSAGES_BY_PRODUCT = {
  [HW_PRODUCTS.GATEWAY]: [
    SUMMARY_MESSAGES.hardwareRegistration,
    SUMMARY_MESSAGES.connectivityTestingOnline,
    // TODO: Hide message as long as signal strength check is disabled
    // SUMMARY_MESSAGES.connectivityTestingGsm,
  ],
  [HW_PRODUCTS.SMART_ENTRY]: [
    SUMMARY_MESSAGES.hardwareRegistration,
    SUMMARY_MESSAGES.connectivityTestingOnline,
    // TODO: Hide message as long as signal strength check is disabled
    // SUMMARY_MESSAGES.connectivityTesting868Mhz,
    SUMMARY_MESSAGES.remoteOpeningTesting,
    SUMMARY_MESSAGES.kiOpeningTesting,
    SUMMARY_MESSAGES.kiSignalStrengthAdjustment,
  ],
  [HW_PRODUCTS.SMART_ENTRY_HYBRID]: [
    SUMMARY_MESSAGES.hardwareRegistration,
    SUMMARY_MESSAGES.connectivityTestingOnline,
    // TODO: Hide message as long as signal strength check is disabled
    // SUMMARY_MESSAGES.connectivityTestingGsm,
    SUMMARY_MESSAGES.remoteOpeningTesting,
    SUMMARY_MESSAGES.kiOpeningTesting,
    SUMMARY_MESSAGES.kiSignalStrengthAdjustment,
  ],
  [HW_PRODUCTS.SMART_DOOR]: [
    SUMMARY_MESSAGES.hardwareRegistration,
    SUMMARY_MESSAGES.connectivityTestingOnline,
    SUMMARY_MESSAGES.remoteOpeningTesting,
    SUMMARY_MESSAGES.kiOpeningTesting,
  ],
  [HW_PRODUCTS.KNOB]: [
    SUMMARY_MESSAGES.hardwareRegistration,
    SUMMARY_MESSAGES.connectivityTestingOnline,
    // TODO: Hide message as long as signal strength check is disabled
    // SUMMARY_MESSAGES.connectivityTesting868Mhz,
    SUMMARY_MESSAGES.batteryTesting,
    SUMMARY_MESSAGES.remoteOpeningTesting,
    SUMMARY_MESSAGES.kiOpeningTesting,
  ],
  [HW_PRODUCTS.HANDLE]: [
    SUMMARY_MESSAGES.hardwareRegistration,
    SUMMARY_MESSAGES.connectivityTestingOnline,
    // TODO: Hide message as long as signal strength check is disabled
    // SUMMARY_MESSAGES.connectivityTesting868Mhz,
    SUMMARY_MESSAGES.batteryTesting,
    SUMMARY_MESSAGES.remoteOpeningTesting,
    SUMMARY_MESSAGES.kiOpeningTesting,
  ],
  [HW_PRODUCTS.SAFE]: [
    SUMMARY_MESSAGES.hardwareRegistration,
    SUMMARY_MESSAGES.connectivityTestingOnline,
    // TODO: Hide message as long as signal strength check is disabled
    // SUMMARY_MESSAGES.connectivityTesting868Mhz,
    SUMMARY_MESSAGES.batteryTesting,
    SUMMARY_MESSAGES.remoteOpeningTesting,
    SUMMARY_MESSAGES.kiOpeningTesting,
  ],
  [HW_PRODUCTS.SAFE_TWO]: [
    SUMMARY_MESSAGES.hardwareRegistration,
    SUMMARY_MESSAGES.connectivityTestingOnline,
    // TODO: Hide message as long as signal strength check is disabled
    // SUMMARY_MESSAGES.connectivityTesting868Mhz,
    SUMMARY_MESSAGES.batteryTesting,
    SUMMARY_MESSAGES.remoteOpeningTesting,
    SUMMARY_MESSAGES.kiOpeningTesting,
  ],
};

class SuccessStep extends Component {
  state = {
    notes: '',
    causingParty: null,
  }

  finishTask = () => {
    const { notes, causingParty } = this.state;
    const { job, task } = this.props.routine;

    if (notes) {
      task.installerNotes = notes;
    }
    if (causingParty) {
      task.causingParty = causingParty;
    }

    this.props.finishTask({ job, task });
  }

  render() {
    const { notes, causingParty } = this.state;
    const { job, task } = this.props.routine;

    const summaryMessages = task.type === TASK_TYPES.INSTALLATION
      ? INSTALLATION_SUMMARY_MESSAGES_BY_PRODUCT[task.hardware.hardwareProduct]
      : [];
    const canFinish = task.type === TASK_TYPES.FIXING
      ? !!causingParty && !!notes
      : true;
    const hasPendingTasks = job.tasks.some(t => t !== task
      && TASK_STATUSES_PENDING.includes(t.status));

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="success" />}
      >
        <StepView.Content>
          <Message icon positive className="task-success-message">
            <Icon name="check circle" />
            <Message.Content>
              <Message.Header>
                {this.props.intl.formatMessage(SUCCESS_MESSAGES[task.type].header)}
              </Message.Header>
              {this.props.intl.formatMessage(SUCCESS_MESSAGES[task.type].text)}
            </Message.Content>
          </Message>

          {summaryMessages.length > 0 && (
            <>
              <Header size="small">
                <FormattedMessage id="SuccessStep.jobs.summary.header" defaultMessage="Task summary" />
              </Header>
              <CheckList className="summary-list">
                {summaryMessages.map(message => (
                  <CheckList.Item success key={message.id}>
                    {this.props.intl.formatMessage(message, task.hardware.statusEnd)}
                  </CheckList.Item>
                ))}
              </CheckList>
            </>
          )}

          {task.type === TASK_TYPES.FIXING && (
            <>
              <Header size="small">
                <FormattedMessage id="SuccessStep.jobs.causingParty.header" defaultMessage="Who is to blame for the problem?" />
              </Header>
              <FormattedMessage
                id="SuccessStep.jobs.causingParty.placeholder"
                defaultMessage="Who is to blame for the problem?"
              >
                {placeholder => (
                  <CausingPartyComboBox
                    fluid
                    placeholder={placeholder}
                    value={causingParty}
                    onChange={value => this.setState({ causingParty: value })}
                  />
                )}
              </FormattedMessage>
            </>
          )}

          <Header size="small">
            <FormattedMessage id="SuccessStep.jobs.notes.header" defaultMessage="Additional notes" />
          </Header>
          <Form onSubmit={canFinish ? this.finishTask : null}>
            <FormattedMessage id="SuccessStep.jobs.notes.placeholder" defaultMessage="Additional notes...">
              {placeholder => (
                <Form.Field
                  control={TextArea}
                  placeholder={placeholder}
                  value={notes}
                  onChange={(e, { value }) => this.setState({ notes: value })}
                />
              )}
            </FormattedMessage>
          </Form>
        </StepView.Content>

        <StepView.Footer>
          <Button primary fluid disabled={!canFinish} onClick={this.finishTask}>
            {hasPendingTasks && (
              <FormattedMessage id="SuccessStep.jobs.continue.label" defaultMessage="Continue with next task" />
            )}
            {!hasPendingTasks && (
              <FormattedMessage id="SuccessStep.jobs.backToOverview.label" defaultMessage="Back to overview" />
            )}
          </Button>
        </StepView.Footer>
      </StepView>
    );
  }
}

SuccessStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  jobs: state.jobs,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  finishTask,
}, dispatch);

export default withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SuccessStep)));
