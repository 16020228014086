import React from 'react';
import PropTypes from 'prop-types';

import '~/common/styles/components/FormattedText.css';


const FormattedText = ({ text }) => (
  <span className="formatted-text">
    {text}
  </span>
);

FormattedText.defaultProps = {
  text: '',
};

FormattedText.propTypes = {
  text: PropTypes.string,
};

export default FormattedText;
