import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Icon } from 'semantic-ui-react';

import attachCompounds from '~/common/utils/attachCompounds';
import '~/app/styles/components/CheckList.css';


class CheckList extends Component {
  render() {
    const { children, className, ...props } = this.props;

    return (
      <List relaxed className={`check-list ${className}`} {...props}>
        {children}
      </List>
    );
  }
}

class CheckListItem extends Component {
  render() {
    const {
      loading, success, error, unknown, children,
    } = this.props;

    let iconName;
    if (loading) {
      iconName = 'spinner';
    } else if (success) {
      iconName = 'check circle';
    } else if (error) {
      iconName = 'times circle';
    } else if (unknown) {
      iconName = 'question circle';
    }

    return (
      <List.Item>
        <List.Icon verticalAlign="middle">
          <Icon loading={loading} name={iconName} />
        </List.Icon>
        <List.Content>
          {children}
        </List.Content>
      </List.Item>
    );
  }
}

CheckListItem.defaultProps = {
  loading: false,
  success: false,
  error: false,
  unknown: false,
  children: null,
};

CheckListItem.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  unknown: PropTypes.bool,
  children: PropTypes.node,
};

CheckList.defaultProps = {
  className: '',
  children: null,
};

CheckList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default attachCompounds(CheckList, {
  Item: CheckListItem,
  Header: List.Header,
  Description: List.Description,
});
