import _ from 'lodash';


export default function (availableLanguages) {
  // Get user's locale from browser and fallback to "en"
  let languages;
  if (navigator.languages) {
    languages = navigator.languages;
  } else {
    languages = [navigator.language || navigator.userLanguage || 'en'];
  }

  // Split locales with a region code
  languages = languages.map(language => language.toLowerCase().split(/[_-]+/)[0]);

  // Get best language with locales available
  return _.intersection(languages, availableLanguages)[0];
}
