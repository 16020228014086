import { createStore, compose, applyMiddleware } from 'redux';
import {
  persistStore, persistReducer, createTransform, createMigrate,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import _ from 'lodash';

import rootSaga from '~/app/sagas';
import rootReducer from '~/app/reducers';


const migrations = {
  // replace installation scope with jobs scope
  1: state => ({
    ...state,
    auth: {
      ...state.auth,
      scopes: state.auth.scopes.map(scope => (scope === 'installation' ? 'jobs' : scope)),
    },
  }),
};

const blacklistTransform = createTransform((inboundState, key) => {
  if (key === 'auth') {
    return _.omit(inboundState, ['loading', 'error', 'passwordResetRequested', 'passwordResetConfirmed']);
  }
  if (key === 'locale') {
    return _.omit(inboundState, ['messages']);
  }
  return inboundState;
});

const persistConfig = {
  key: 'smart-installation',
  version: 1,
  storage,
  whitelist: ['auth', 'locale'],
  transforms: [blacklistTransform],
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations),
};

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  applyMiddleware(sagaMiddleware),
].filter(Boolean);

const pReducer = persistReducer(persistConfig, rootReducer);

const enhancers = [
  ...middleware,
];
/* eslint-disable no-underscore-dangle */
if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}
/* eslint-enable */

export const store = createStore(pReducer, undefined, compose(...enhancers));
sagaMiddleware.run(rootSaga);
sagaMiddleware.run(routinePromiseWatcherSaga);

export const persistor = persistStore(store);
