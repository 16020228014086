import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import LocaleContext from '~/admin/contexts/LocaleContext';
import { startJob, dismissStartJob, dismissError } from '~/app/actions/RoutineActions';
import View from '~/app/views/View';
import DurationInput from '~/common/components/DurationInput';
import FormattedAddress from '~/common/components/FormattedAddress';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';


const JobStartView = ({ job }) => {
  const { locale } = useContext(LocaleContext);

  const dispatch = useDispatch();
  const user = useSelector(state => state.users.current);
  const error = useSelector(state => state.routine.error);

  const [drivingDistance, setDrivingDistance] = useState(job.drivingDistance);
  const [drivingTime, setDrivingTime] = useState(job.drivingTime);

  const handleDrivingDistanceChange = (e, { value }) => {
    const parsedValue = parseFloat(value.replace(',', '.'), 10);
    setDrivingDistance(Number.isNaN(parsedValue) ? null : parsedValue);
  };

  const handleStartJob = () => {
    dispatch(startJob({
      job: {
        ...job,
        drivingDistance,
        drivingTime,
      },
      user,
    }));
  };

  return (
    <View onGoBack={() => dispatch(dismissStartJob())}>
      <View.Header
        primaryText={<FormattedMessage id="JobStartView.header.primary" defaultMessage="Job" />}
        secondaryText={<FormattedAddress address={job.address} />}
      />

      <View.Content>
        <JobSaveErrorModal
          error={error}
          onDismiss={() => dispatch(dismissError())}
        />
        <Form onSubmit={handleStartJob}>
          <Form.Field>
            <label>
              <FormattedMessage id="JobStartView.drivingDistance.label" defaultMessage="How long was the access route to get to this job?" />
            </label>
            <FormattedMessage id="JobStartView.drivingDistance.placeholder" defaultMessage="Driving distance">
              {placeholder => (
                <Input
                  fluid
                  type="number"
                  min="0"
                  step="any"
                  label={{ basic: true, content: 'km' }}
                  labelPosition="right"
                  value={drivingDistance != null ? drivingDistance : ''}
                  placeholder={placeholder}
                  onChange={handleDrivingDistanceChange}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="JobStartView.drivingTime.label" defaultMessage="How long did it take to get there for this job?" />
            </label>
            <FormattedMessage id="JobStartView.drivingTime.placeholder" defaultMessage="Driving time">
              {placeholder => (
                <DurationInput
                  fluid
                  closable
                  hideMobileKeyboard
                  icon={false}
                  duration={0}
                  animation="none"
                  localization={locale}
                  closeOnMouseLeave={false}
                  popupPosition="bottom left"
                  placeholder={placeholder}
                  value={drivingTime}
                  onChange={(e, { value }) => setDrivingTime(value)}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form>
      </View.Content>

      <View.Footer>
        <Button primary fluid onClick={handleStartJob}>
          <FormattedMessage id="JobStartView.next.label" defaultMessage="Next" />
        </Button>
      </View.Footer>
    </View>
  );
};

export default JobStartView;
