import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { registerHardware } from '~/app/actions/HardwareActions';
import { refreshTask } from '~/app/actions/JobsActions';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import TimeoutProgress from '~/app/components/TimeoutProgress';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const TIMEOUT = 10000;

const ERROR_MESSAGES = defineMessages({
  ALREADY_INSTALLED: {
    id: 'HardwareRegistrationStep.jobs.errors.alreadyInstalled',
    defaultMessage: 'This device is already installed.',
  },
  CONFLICTING_SPECS: {
    id: 'HardwareRegistrationStep.jobs.errors.conflictingSpecs',
    defaultMessage: 'This device does not match the variant that was specified in the installation task.',
  },
});

class HardwareRegistrationStep extends Component {
  componentDidMount() {
    this.register();
  }

  componentDidUpdate(prevProps) {
    const { task } = this.props.routine;
    const { registration } = this.props.hardware;
    const { registration: prevRegistration } = prevProps.hardware;
    if (
      !prevRegistration.success
      && registration.success
      && registration.hardwareUuid === task.hardware.uuid
    ) {
      this.props.refreshTask(task);
    }
  }

  register = () => {
    const { task } = this.props.routine;
    this.props.registerHardware({ task });
  }

  render() {
    const {
      job, task, sensor, gateway,
    } = this.props.routine;
    const { error: jobsError } = this.props.jobs;
    const { error: hwError } = this.props.hardware;

    const sensorUuid = sensor && sensor.uuid;
    const gatewayUuid = gateway && gateway.uuid;
    if ([sensorUuid, gatewayUuid].includes(task.hardware.uuid)) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        noSkip
        header={<JobProgressHeader job={job} task={task} stage="installation" />}
        error={jobsError || hwError}
        errorHeader={(
          <FormattedMessage
            id="HardwareRegistrationStep.jobs.errors.header"
            defaultMessage="Registration error"
          />
        )}
        errorMessages={ERROR_MESSAGES}
        retry={this.register}
      >
        <StepView.Content>
          <p>
            <FormattedMessage
              id="HardwareRegistrationStep.jobs.description"
              defaultMessage="Registering device..."
            />
          </p>
          <TimeoutProgress timeout={TIMEOUT} />
        </StepView.Content>
      </StepView>
    );
  }
}

HardwareRegistrationStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  jobs: state.jobs,
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  registerHardware,
  refreshTask,
}, dispatch);

export default withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(HardwareRegistrationStep)));
