import { HW_PRODUCTS, HW_VARIANTS } from '~/common/constants';


// eslint-disable-next-line import/prefer-default-export
export const splitSmartEntryHybrid = ({ task, steps }) => {
  const uuidInputStepIndex = steps.indexOf('uuidInput');
  const step = uuidInputStepIndex < 0
    ? steps[0]
    : steps[uuidInputStepIndex];

  return {
    task,
    step,
    hardwareProduct: HW_PRODUCTS.GATEWAY,
    hardwareVariant: HW_VARIANTS.NDS,
  };
};
