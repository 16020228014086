import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'semantic-ui-react';

const CallButton = ({ telephone, children }) => (
  <div>
    <a href={`tel:${telephone}`}>
      <Button icon labelPosition="left" size="mini">
        <Icon name="call" />
        {children}
      </Button>
    </a>
  </div>
);

CallButton.defaulProps = {
  telphone: '',
  children: null,
};

CallButton.propTypes = {
  telephone: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default CallButton;
