const initialState = {
  error: null,
};

export default (state = initialState, { type, payload }) => {
  if (type.endsWith('/TRIGGER') && type.endsWith('/SUCCESS')) {
    return {
      ...state,
      error: null,
    };
  }

  if (type.endsWith('/FAILURE')) {
    const { error } = payload;
    return {
      ...state,
      error,
    };
  }

  return state;
};
