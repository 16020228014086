import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';

import '~/common/styles/components/SegmentDivider.css';


const SegmentDivider = ({ children }) => (
  <Divider horizontal className="segment-divider">
    {children}
  </Divider>
);

SegmentDivider.defaultProps = {
  children: null,
};

SegmentDivider.propTypes = {
  children: PropTypes.node,
};

export default SegmentDivider;
