import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { registerHardware } from '~/app/actions/HardwareActions';
import { withRoutine } from '~/app/contexts/RoutineContext';
import ConfirmModal from '~/common/components/ConfirmModal';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import StepView from './StepView';


const ERROR_MESSAGES = defineMessages({
  EXISTING_UUID: {
    id: 'HardwareRegistrationStep.manufacturing.errors.existingUuid',
    defaultMessage: 'This device is already registered.',
  },
  NOT_ALLOWED: {
    id: 'HardwareRegistrationStep.manufacturing.errors.notAllowed',
    defaultMessage: 'This device is already installed and therefore cannot be deleted.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = _.pick(FIXING_OPTION_MESSAGES,
  'correctUuid',
  'contactSupport');


class HardwareRegistrationStep extends Component {
  state = {
    showUnregisterModal: false,
  }

  componentDidMount() {
    this.register();
  }

  componentDidUpdate(prevProps) {
    const { hardwareUuid } = this.props.routine;
    const { registration } = this.props.hardware;
    const { registration: prevRegistration } = prevProps.hardware;
    if (
      !prevRegistration.success
      && registration.success
      && registration.hardwareUuid === hardwareUuid
    ) {
      this.props.routine.setSensorId(registration.sensorId);
    }
  }

  register = ({ unregister = false } = {}) => {
    const {
      sensorId, hardwareUuid, hardwareProduct, hardwareVariant,
    } = this.props.routine;

    if (unregister) {
      this.setState({ showUnregisterModal: false });
    }

    if (!sensorId) {
      this.props.registerHardware({
        unregister,
        hardwareProduct,
        hardwareVariant,
        uuid: hardwareUuid,
      });
    }
  }

  renderFixingActions = () => {
    const { error } = this.props.hardware;
    if (!error || error.code !== 'EXISTING_UUID') {
      return null;
    }

    const { showUnregisterModal } = this.state;
    return (
      <>
        <Button fluid negative onClick={() => this.setState({ showUnregisterModal: true })}>
          <FormattedMessage
            id="HardwareRegistrationStep.manufacturing.fixingActions.deleteHardware"
            defaultMessage="Delete existing device"
          />
        </Button>
        <ConfirmModal
          open={showUnregisterModal}
          onConfirm={() => this.register({ unregister: true })}
          onClose={() => this.setState({ showUnregisterModal: false })}
        >
          <ConfirmModal.Header>
            <FormattedMessage
              id="HardwareRegistrationStep.manufacturing.unregisterModal.header"
              defaultMessage="Delete existing device"
            />
          </ConfirmModal.Header>
          <ConfirmModal.Content>
            <p>
              <FormattedMessage
                id="HardwareRegistrationStep.manufacturing.unregisterModal.content"
                defaultMessage="Do you really want to delete and replace the existing device?"
              />
            </p>
            <p>
              <FormattedMessage
                id="HardwareRegistrationStep.manufacturing.unregisterModal.warning"
                defaultMessage="This action cannot be undone!"
              />
            </p>
          </ConfirmModal.Content>
        </ConfirmModal>
      </>
    );
  }

  render() {
    const { sensorId } = this.props.routine;
    const { registration, error } = this.props.hardware;

    if (registration.success && registration.sensorId === sensorId) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        instructions={(
          <FormattedMessage
            id="HardwareRegistrationStep.manufacturing.instructions"
            defaultMessage="Please wait until the sensor has been registered in the KIWI infrastructure."
          />
        )}
        error={error}
        errorMessages={ERROR_MESSAGES}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES}
        fixingActions={this.renderFixingActions()}
        retry={this.props.routine.cancel}
      />
    );
  }
}

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  registerHardware,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(HardwareRegistrationStep));
