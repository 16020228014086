import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import { DEVICE_CHECK_INCLUDED_RELATIONS } from '~/app/constants';
import { fetchHardware, dismissError } from '~/app/actions/HardwareActions';
import { getDeviceCheckPath } from '~/app/utils/routing';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import ButtonGroup from '~/common/components/ButtonGroup';
import UUIDScanner from '~/app/components/UUIDScanner';
import UUIDInput from '~/app/components/UUIDInput';
import View from '~/app/views/View';


const ERROR_MESSAGES = defineMessages({
  NOT_FOUND: {
    id: 'SelectDeviceView.deviceCheck.errors.notFound',
    defaultMessage: 'A device with this UUID is not registered.',
  },
});


class SelectDeviceView extends Component {
  state = {
    uuid: '',
    manualInput: false,
    submitted: false,
  }

  componentDidMount() {
    this.props.dismissError();
  }

  handleScan = (uuid) => {
    this.handleChange(uuid);
    setTimeout(this.submit, 2000);
  };

  handleScanError = (err) => {
    console.warn(err);
    this.setState({ manualInput: true });
  }

  handleChange = (uuid) => {
    this.setState({ uuid });
  }

  submit = () => {
    const { uuid } = this.state;

    this.setState({ submitted: true });
    this.props.fetchHardware({
      uuid,
      include: _.uniq(_.flatten(
        Object.values(DEVICE_CHECK_INCLUDED_RELATIONS),
      )),
    });
  }

  reset = () => {
    this.setState({
      uuid: '',
      submitted: false,
    });
    this.props.dismissError();
  }

  render() {
    const { uuid, manualInput, submitted } = this.state;
    const {
      loading, error, gateways, sensors,
    } = this.props.hardware;

    const selectedHardware = [
      ...Object.values(gateways.byId),
      ...Object.values(sensors.byId),
    ].filter(x => x.uuid === uuid)[0];

    if (submitted && !loading && selectedHardware) {
      return <Redirect to={getDeviceCheckPath(selectedHardware)} />;
    }

    return (
      <View loading={loading}>
        <View.Header
          hideBack
          primaryText={<FormattedMessage id="SelectDeviceView.deviceCheck.header.primary" defaultMessage="Device Check" />}
          secondaryText={<FormattedMessage id="SelectDeviceView.deviceCheck.header.secondary" defaultMessage="Select device" />}
        />

        <View.Content>
          {error && (
            <ApiErrorMessage
              error={error}
              header={(
                <FormattedMessage
                  id="SelectDeviceView.deviceCheck.errors.header"
                  defaultMessage="Device information could not be retrieved"
                />
              )}
              messages={ERROR_MESSAGES}
            />
          )}
          {!error && !manualInput && (
            <>
              <p>
                <FormattedMessage
                  id="SelectDeviceView.deviceCheck.scan.instructions"
                  defaultMessage="Please point your camera towards the QR code of the device you want to inspect to scan it's UUID."
                />
              </p>
              <UUIDScanner className="uuid-scanner" value={uuid} onScan={this.handleScan} onError={this.handleScanError} />
            </>
          )}
          {!error && manualInput && (
            <>
              <p>
                <FormattedMessage
                  id="SelectDeviceView.deviceCheck.manual.instructions"
                  defaultMessage="Please enter the UUID of the device you want to inspect."
                />
              </p>
              <UUIDInput
                autoFocus
                value={uuid}
                onChange={this.handleChange}
                onSubmit={this.submit}
              />
            </>
          )}
        </View.Content>

        <View.Footer>
          <ButtonGroup>
            {error && (
              <Button primary fluid onClick={this.reset}>
                <FormattedMessage id="SelectDeviceView.deviceCheck.tryAgain.label" defaultMessage="Try again" />
              </Button>
            )}
            {!error && manualInput && (
              <Button primary fluid disabled={!uuid} onClick={this.submit}>
                <FormattedMessage id="SelectDeviceView.deviceCheck.manual.confirm.label" defaultMessage="OK" />
              </Button>
            )}
            {!error && !manualInput && !uuid && (
              <Button fluid onClick={() => this.setState({ manualInput: true })}>
                <FormattedMessage id="SelectDeviceView.deviceCheck.manual.activate.label" defaultMessage="Enter UUID manually" />
              </Button>
            )}
            {navigator.geolocation && (
              <Button fluid as={Link} to="/device-check/nearby-devices">
                <FormattedMessage id="SelectDeviceView.deviceCheck.nearbyDevices.label" defaultMessage="Show nearby devices" />
              </Button>
            )}
          </ButtonGroup>
        </View.Footer>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchHardware,
  dismissError,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectDeviceView);
