import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Image } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { HW_PRODUCTS, SAFE_HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import staticFile from '~/common/utils/staticFile';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import ButtonGroup from '~/common/components/ButtonGroup';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const INSTRUCTION_MESSAGES = defineMessages({
  [HW_PRODUCTS.KNOB]: {
    id: 'SmartLockInstallationInstructions.jobs.instructions.knob',
    defaultMessage: 'Please install the prepared lock cylinder in door\u00a0#{doorNumber} on floor\u00a0{floor} and mount the knob.',
  },
  [HW_PRODUCTS.HANDLE]: {
    id: 'SmartLockInstallationInstructions.jobs.instructions.handle',
    defaultMessage: 'Please install the mortise lock and the handle in door\u00a0#{doorNumber} on floor\u00a0{floor}.',
  },
  [HW_PRODUCTS.SAFE]: {
    id: 'SmartLockInstallationInstructions.jobs.instructions.safe',
    defaultMessage: 'Please install the safe and mount the knob.',
  },
  [HW_PRODUCTS.SAFE_TWO]: {
    id: 'SmartLockInstallationInstructions.jobs.instructions.safeTwo',
    defaultMessage: 'Please install the safe and mount the knob.',
  },
});

const INSTRUCTION_IMAGES = {
  [HW_PRODUCTS.KNOB]: staticFile('smart_installation/images/knob-installation.svg'),
  [HW_PRODUCTS.HANDLE]: staticFile('smart_installation/images/handle-installation.svg'),
  [HW_PRODUCTS.SAFE]: staticFile('smart_installation/images/safe-installation.svg'),
  [HW_PRODUCTS.SAFE_TWO]: staticFile('smart_installation/images/safe-installation.svg'),
};


class SmartLockInstallationInstructions extends Component {
  state = {
    next: false,
  }

  render() {
    const { next } = this.state;
    const { job, task } = this.props.routine;

    if (next) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="installation" />}
      >
        <StepView.Content>
          <p>
            {this.props.intl.formatMessage(INSTRUCTION_MESSAGES[task.hardware.hardwareProduct], {
              floor: task.hardware.floor,
              doorNumber: task.hardware.doorNumber,
            })}
          </p>

          {!SAFE_HW_PRODUCTS.includes(task.hardware.hardwareProduct) && (
            <p>
              <FormattedMessage
                id="SmartLockInstallationInstructions.jobs.instructions.leaveDoorOpen"
                defaultMessage="Leave the door open during the entire installation process."
              />
            </p>
          )}

          <Image centered bordered size="large" src={INSTRUCTION_IMAGES[task.hardware.hardwareProduct]} />
        </StepView.Content>

        <StepView.Footer>
          <ButtonGroup>
            <Button fluid color="red" onClick={this.props.requestSkipTask}>
              <FormattedMessage id="SmartLockInstallationInstructions.jobs.notInstallable.label" defaultMessage="Device can't be installed" />
            </Button>
            <Button primary fluid onClick={() => this.setState({ next: true })}>
              <FormattedMessage id="SmartLockInstallationInstructions.jobs.next.label" defaultMessage="Next" />
            </Button>
          </ButtonGroup>
        </StepView.Footer>
      </StepView>
    );
  }
}

SmartLockInstallationInstructions.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  requestSkipTask,
}, dispatch);

export default withRoutine(injectIntl(connect(
  null,
  mapDispatchToProps,
)(SmartLockInstallationInstructions)));
