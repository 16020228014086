import { defineMessages } from 'react-intl';


export default defineMessages({
  REGULAR: {
    id: 'HardwareProductVariants.regular',
    defaultMessage: 'Regular',
  },
  GW1: {
    id: 'HardwareProductVariants.gw1',
    defaultMessage: 'GW1',
  },
  GWSDS: {
    id: 'HardwareProductVariants.gwsds',
    defaultMessage: 'GWSDS',
  },
  GW2: {
    id: 'HardwareProductVariants.gw2',
    defaultMessage: 'GW2',
  },
  SE1: {
    id: 'HardwareProductVariants.se1',
    defaultMessage: 'SE1',
  },
  SE2: {
    id: 'HardwareProductVariants.se2',
    defaultMessage: 'SE2',
  },
  OUTDOOR: {
    id: 'HardwareProductVariants.outdoor',
    defaultMessage: 'Outdoor',
  },
  COMPACT: {
    id: 'HardwareProductVariants.compact',
    defaultMessage: 'Compact',
  },
  COMPACT_OUTDOOR: {
    id: 'HardwareProductVariants.compactOutdoor',
    defaultMessage: 'Compact Outdoor',
  },
  PREMIUM: {
    id: 'HardwareProductVariants.premium',
    defaultMessage: 'Premium',
  },
  COMFORT: {
    id: 'HardwareProductVariants.comfort',
    defaultMessage: 'Comfort',
  },
  STEEL: {
    id: 'HardwareProductVariants.steel',
    defaultMessage: 'Steel',
  },
  PORTABLE: {
    id: 'HardwareProductVariants.portable',
    defaultMessage: 'Portable',
  },
});
