import React from 'react';
import { Image } from 'semantic-ui-react';

import staticFile from '~/common/utils/staticFile';
import '~/common/styles/components/KiwiLogo.css';


const KiwiLogo = props => (
  <Image
    {...props}
    wrapped
    className="kiwi-logo"
    src={staticFile('smart_installation/images/logo.svg')}
  />
);

KiwiLogo.propTypes = Image.propTypes;

export default KiwiLogo;
