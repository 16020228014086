import React, { Component } from 'react';
import {
  Menu, Input, Button, Icon, Modal, Form,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { promisifyRoutine, bindPromiseCreators } from 'redux-saga-routines';
import { Redirect } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import {
  DEVICE_TYPES,
  GATEWAY_HW_TYPES,
  SENSOR_HW_TYPES,
  HW_VARIANTS,
} from '~/common/constants';
import HW_TYPE_MESSAGES from '~/common/locales/HardwareTypes';
import HW_VARIANT_MESSAGES from '~/common/locales/HardwareVariants';
import { fetchGateways, fetchSensors, stopRoutines } from '~/app/actions/HardwareActions';
import { getDeviceCheckPath } from '~/app/utils/routing';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import OptionalDropdown from '~/common/components/OptionalDropdown';
import NearbyDevicesList from '~/app/components/NearbyDevicesList';
import View from '~/app/views/View';
import '~/app/styles/views/device-check/NearbyDevicesView.css';


const PAGE_SIZE = 20;

const MAX_DISTANCE = 0.5;

const DEVICE_COUNT_MESSAGES = defineMessages({
  gateway: {
    id: 'NearbyDevicesView.deviceCountMessages.gateway',
    defaultMessage: '{n} {n, plural, one {gateway} other {gateways}} nearby',
  },
  sensor: {
    id: 'NearbyDevicesView.deviceCountMessages.sensor',
    defaultMessage: '{n} {n, plural, one {sensor} other {sensors}} nearby',
  },
});

const EMPTY_LIST_MESSAGES = defineMessages({
  gateway: {
    id: 'NearbyDevicesView.emptyListMessages.gateway',
    defaultMessage: 'There are no matching gateways nearby.',
  },
  sensor: {
    id: 'NearbyDevicesView.emptyListMessages.sensor',
    defaultMessage: 'There are no matching sensors nearby.',
  },
});

const ERROR_MESSAGES = defineMessages({
  GEOLOCATION_UNSUPPORTED: {
    id: 'NearbyDevicesView.errors.geolocation.unsupported',
    defaultMessage: 'The device does not support retrieving your position.',
  },
  GEOLOCATION_PERMISSION_DENIED: {
    id: 'NearbyDevicesView.errors.geolocation.permissionDenied',
    defaultMessage: 'Either GPS is disabled or the app is not allowed to retrieve your current position.',
  },
  GEOLOCATION_UNAVAILABLE: {
    id: 'NearbyDevicesView.errors.geolocation.unavailable',
    defaultMessage: 'Your current position could not be determined.',
  },
});

class NearbyDevicesView extends Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }

  state = {
    scrollTop: 0,
    deviceType: DEVICE_TYPES.GATEWAY,
    showFiltersModal: false,
    search: '',
    filters: {
      hardwareType: null,
      hardwareVariant: null,
    },
    filtersToApply: {
      hardwareType: null,
      hardwareVariant: null,
    },
    selectedDevice: null,
    ...this.props.location.state,
  }

  componentDidMount() {
    this.fetchDevices({ refresh: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { deviceType, search, filters } = this.state;
    const { deviceType: prevDeviceType, search: prevSearch, filters: prevFilters } = prevState;

    const deviceTypeChanged = deviceType !== prevDeviceType;
    const searchChanged = search !== prevSearch;
    const filtersChanged = Object.entries(filters).some(([k, v]) => prevFilters[k] !== v);
    if (deviceTypeChanged || searchChanged || filtersChanged) {
      this.fetchDevices({
        refresh: true,
        debounce: searchChanged && search.length > 0,
      });
    }
  }

  get items() {
    const { deviceType } = this.state;
    const { gateways, sensors } = this.props.hardware;
    return deviceType === DEVICE_TYPES.GATEWAY ? gateways : sensors;
  }

  fetchDevices = ({ pageNumber = 1, refresh = false, debounce = false } = {}) => {
    const { deviceType, search, filters } = this.state;
    const { geolocation } = this.items.query;

    this.props.stopRoutines();

    const params = {
      debounce,
      pageNumber,
      pageSize: PAGE_SIZE,
      query: {
        ...filters,
        search,
        orderBy: 'asc',
        sortBy: 'distance',
        geolocation: refresh ? null : geolocation,
        maxDistance: MAX_DISTANCE,
      },
      include: ['status', 'status.signal'],
      clear: refresh,
    };

    const promise = deviceType === DEVICE_TYPES.GATEWAY
      ? this.props.fetchGateways(params)
      : this.props.fetchSensors(params);

    return promise.catch(() => {});
  }

  loadMoreDevices = () => {
    const { deviceType } = this.state;
    const { gateways, sensors } = this.props.hardware;
    const { pages } = (deviceType === DEVICE_TYPES.GATEWAY ? gateways : sensors);

    const pageNumber = Math.max(...Object.keys(pages)) + 1;
    this.fetchDevices({ pageNumber });
  }

  handleDeviceTypeChange = (deviceType) => {
    this.setState({ deviceType });
    this.resetSearchAndFilters();
  }

  handleSearchChange = (search) => {
    this.setState({ search });
    this.scrollToTop();
  }

  resetSearch = () => {
    this.setState({ search: '' });
    this.scrollToTop();
  }

  handleFilterChange = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      filtersToApply: {
        ...prevState.filtersToApply,
        [key]: value,
      },
    }));
  }

  applyFilters = () => {
    this.setState(({ filtersToApply }) => ({
      showFiltersModal: false,
      filters: filtersToApply,
    }));
    this.scrollToTop();
  }

  resetFilters = () => {
    this.setState(({ filters }) => {
      const newFilters = Object.keys(filters)
        .map(key => ({ [key]: null }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});

      return {
        showFiltersModal: false,
        filters: newFilters,
        filtersToApply: newFilters,
      };
    });
    this.scrollToTop();
  }

  openFilterModal = () => {
    this.setState({ showFiltersModal: true });
  }

  closeFilterModal = () => {
    this.setState(prevState => ({
      showFiltersModal: false,
      filtersToApply: prevState.filters,
    }));
  }

  resetSearchAndFilters = () => {
    this.resetSearch();
    this.resetFilters();
  }

  scrollToTop = () => {
    this.setState({ scrollTop: 0 });
  }

  renderFilterModal = () => {
    const { deviceType, showFiltersModal } = this.state;
    const { hardwareType, hardwareVariant } = this.state.filtersToApply;

    const hardwareTypes = deviceType === DEVICE_TYPES.GATEWAY
      ? GATEWAY_HW_TYPES
      : SENSOR_HW_TYPES;
    const hardwareTypeOptions = Object.values(hardwareTypes).map(value => ({
      value,
      text: this.props.intl.formatMessage(HW_TYPE_MESSAGES[value]),
    }));

    const hardwareVariantOptions = Object.values(HW_VARIANTS).map(value => ({
      value,
      text: this.props.intl.formatMessage(HW_VARIANT_MESSAGES[value]),
    }));

    return (
      <Modal
        size="tiny"
        dimmer="inverted"
        open={showFiltersModal}
        onOpen={this.openFilterModal}
        onClose={this.closeFilterModal}
      >
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                <FormattedMessage id="NearbyDevicesView.filters.hardwareType.label" defaultMessage="Hardware type" />
              </label>
              <OptionalDropdown
                selection
                sortOptions
                noneText={<FormattedMessage id="NearbyDevicesView.filters.hardwareType.none" defaultMessage="All" />}
                value={hardwareType}
                options={hardwareTypeOptions}
                onChange={(e, { value }) => this.handleFilterChange('hardwareType', value)}
              />
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage id="NearbyDevicesView.filters.hardwareVariant.label" defaultMessage="Hardware variant" />
              </label>
              <OptionalDropdown
                selection
                sortOptions
                noneText={<FormattedMessage id="NearbyDevicesView.filters.hardwareVariant.none" defaultMessage="All" />}
                value={hardwareVariant}
                options={hardwareVariantOptions}
                onChange={(e, { value }) => this.handleFilterChange('hardwareVariant', value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.resetFilters}>
            <FormattedMessage id="NearbyDevicesView.filters.reset.label" defaultMessage="Reset all" />
          </Button>
          <Button primary onClick={this.applyFilters}>
            <FormattedMessage id="NearbyDevicesView.filters.apply.label" defaultMessage="Apply" />
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render() {
    const {
      scrollTop, deviceType, filters, search, selectedDevice,
    } = this.state;
    const { loading, error } = this.props.hardware;
    const hasFilters = Object.values(filters).some(x => x != null);
    const devices = [].concat(...Object.values(this.items.pages)).map(id => this.items.byId[id]);

    if (selectedDevice) {
      return (
        <Redirect
          to={{
            pathname: getDeviceCheckPath(selectedDevice),
            state: {
              referer: {
                ...this.props.location,
                state: {
                  ...this.state,
                  selectedDevice: null,
                },
              },
            },
          }}
        />
      );
    }

    return (
      <View onGoBack={({ replace }) => replace('/device-check')}>
        <View.Header
          primaryText={<FormattedMessage id="NearbyDevicesView.header.primary" defaultMessage="Device Check" />}
          secondaryText={<FormattedMessage id="NearbyDevicesView.header.secondary" defaultMessage="Nearby devices" />}
        />

        <View.Content compact fixed>
          {this.renderFilterModal()}

          <Menu compact pointing secondary widths={2}>
            <Menu.Item
              active={deviceType === DEVICE_TYPES.GATEWAY}
              onClick={() => this.handleDeviceTypeChange(DEVICE_TYPES.GATEWAY)}
            >
              <FormattedMessage id="NearbyDevicesView.tabs.gateways" defaultMessage="Gateways" />
            </Menu.Item>
            <Menu.Item
              active={deviceType === DEVICE_TYPES.SENSOR}
              onClick={() => this.handleDeviceTypeChange(DEVICE_TYPES.SENSOR)}
            >
              <FormattedMessage id="NearbyDevicesView.tabs.sensors" defaultMessage="Sensors" />
            </Menu.Item>
          </Menu>

          <Menu compact pointing secondary widths={1}>
            <Menu.Item>
              <FormattedMessage id="NearbyDevicesView.search.placeholder" defaultMessage="Search...">
                {placeholder => (
                  <Input
                    fluid
                    action
                    icon
                    iconPosition="left"
                    className="nearby-devices-search-bar"
                    maxLength="255"
                    value={search}
                    placeholder={placeholder}
                    onChange={(e, { value }) => this.handleSearchChange(value)}
                  >
                    <Icon name="search" />
                    <input />
                    <Button
                      icon
                      basic
                      active={hasFilters}
                      primary={hasFilters}
                      onClick={() => this.setState({ showFiltersModal: true })}
                    >
                      <Icon name="filter" />
                    </Button>
                    <Button
                      icon="close"
                      onClick={this.resetSearchAndFilters}
                    />
                  </Input>
                )}
              </FormattedMessage>
            </Menu.Item>
          </Menu>
        </View.Content>

        <View.Content compact>
          <NearbyDevicesList
            deviceType={deviceType}
            devices={devices}
            loading={loading}
            scrollTop={scrollTop}
            hasMore={this.items.total > devices.length}
            onLoadMore={this.loadMoreDevices}
            onRefresh={() => this.fetchDevices({ refresh: true })}
            onClick={device => this.setState({ selectedDevice: device })}
            onScroll={value => this.setState({ scrollTop: value })}
            header={(
              this.props.intl.formatMessage(
                DEVICE_COUNT_MESSAGES[deviceType],
                { n: this.items.total },
              )
            )}
            placeholder={(
              this.props.intl.formatMessage(EMPTY_LIST_MESSAGES[deviceType])
            )}
            error={error && (
              <ApiErrorMessage
                error={error}
                header={(
                  <FormattedMessage
                    id="NearbyDevicesView.errors.header"
                    defaultMessage="Devices could not be retrieved"
                  />
                )}
                messages={ERROR_MESSAGES}
              />
            )}
          />
        </View.Content>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    stopRoutines,
  }, dispatch),
  ...bindPromiseCreators({
    fetchGateways: promisifyRoutine(fetchGateways),
    fetchSensors: promisifyRoutine(fetchSensors),
  }, dispatch),
});

export default injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(NearbyDevicesView));
