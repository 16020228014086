import axios from 'axios';
import _ from 'lodash';
import humps from 'humps';

import {
  JOB_STATUSES, TASK_TYPES, TASK_STATUSES,
} from '~/common/constants';


const CONTENT_DISPOSITION_REGEX = /attachment;.*filename[^;=\n]*=['"](?<filename>.*?)['"]/;

const CAMELIZE_KEY_BLACKLIST = [
  ...Object.values(JOB_STATUSES),
  ...Object.values(TASK_TYPES),
  ...Object.values(TASK_STATUSES),
];


const camelizeKeys = obj => humps.camelizeKeys(obj, {
  process: (key, convert) => (CAMELIZE_KEY_BLACKLIST.includes(key)
    ? key
    : convert(key)),
});


const readBlobAsText = blob => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.addEventListener('abort', reject);
  reader.addEventListener('error', reject);
  reader.addEventListener('loadend', () => resolve(reader.result));
  reader.readAsText(blob);
});


class ApiError extends Error {
  constructor(message, code, status, extra) {
    super(message);
    this.code = code;
    this.status = status;
    this.extra = extra;
  }
}

const instance = axios.create({
  baseURL: '/smart_installation/api/',
  headers: {
    'Content-Type': 'application/json',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});
instance.interceptors.request.use(
  (config) => {
    const {
      url, headers, params, data,
      decamelizeRequest = true,
    } = config;
    const contentType = headers['Content-Type'];

    const documentUrl = new URL(document.baseURI);
    const requestUrl = new URL(url, document.baseURI);
    if (documentUrl.origin === requestUrl.origin) {
      if (global.APP) {
        config.headers['X-App'] = global.APP;
      }
      if (global.APP_VERSION) {
        config.headers['X-App-Version'] = global.APP_VERSION;
      }
    }

    if (decamelizeRequest) {
      if (params) {
        config.params = humps.decamelizeKeys(params);
        for (const [key, value] of Object.entries(config.params)) {
          if (Array.isArray(value)) {
            config.params[key] = value.map(item => (typeof item === 'string'
              ? humps.decamelize(item)
              : item));
          }
        }
      }
      if (data instanceof FormData) {
        config.data = Array.from(data.entries())
          .reduce((acc, [key, value]) => {
            acc.append(humps.decamelize(key), value);
            return acc;
          }, new FormData());
      } else if (data && contentType === 'application/json') {
        config.data = humps.decamelizeKeys(data);
      }
    }

    // Convert arrays in query params to comma-separated values
    if (config.params) {
      for (const [key, value] of Object.entries(config.params)) {
        if (Array.isArray(value)) {
          config.params[key] = value.length > 0 ? value.join(',') : null;
        }
      }
    }

    return config;
  },
  (e) => {
    if (e.message === 'Network Error') {
      return Promise.reject(new ApiError(e.message, 'NETWORK'));
    }

    return Promise.reject(e);
  },
);
instance.interceptors.response.use(
  (response) => {
    const { config, headers, data } = response;
    const { camelizeResponse = true } = config;

    const contentType = headers['content-type'];
    if (contentType === 'application/json') {
      let { result } = data;
      if (camelizeResponse) {
        result = camelizeKeys(result);
      }

      return result;
    }

    if (data instanceof Blob) {
      let filename = null;
      const disposition = headers['content-disposition'];
      const match = CONTENT_DISPOSITION_REGEX.exec(disposition);
      if (match) {
        filename = match.groups.filename;
      }

      return { data, filename };
    }

    return data;
  },
  async (e) => {
    if (e.message === 'Network Error') {
      throw new ApiError(e.message, 'NETWORK');
    }
    if (e.response) {
      const { config } = e;
      const { camelizeResponse = true } = config;
      const { status } = e.response;
      let { data } = e.response;

      if (data instanceof Blob && data.type === 'application/json') {
        data = JSON.parse(await readBlobAsText(data));
      }
      const { error } = data;

      // Retry request if opsapp is currently unavailable
      config.retryCount = e.config.retryCount || 1;
      if ([502, 503].includes(status)) {
        if (e.config.retryCount >= 5) {
          throw new ApiError('The API is currently unavailable.', 'UNAVAILABLE', status);
        }

        config.retryCount += 1;
        return new Promise(resolve => setTimeout(() => resolve(instance(config)), 1000));
      }

      if (error) {
        const { message, code } = error;

        let { extra } = error;
        if (extra && camelizeResponse) {
          extra = camelizeKeys(extra);
        }

        throw new ApiError(message, code, status, extra);
      }
    }

    throw e;
  },
);

// Configuration

export const setAcceptedLanguage = (lang) => {
  instance.defaults.headers['Accept-Language'] = lang;
};

// Auth

export const login = ({ username, password }) => instance
  .post('auth/login/', { username, password });

export const logout = () => instance
  .post('auth/logout/');

export const requestPasswordReset = username => instance
  .post('auth/reset_password/', { username });

export const confirmPasswordReset = (uid, token, password) => instance
  .post('auth/reset_password/confirm/', { uid, token, password });

// Operation users

export const fetchOpsUsers = () => instance
  .get('ops-users/');

export const fetchCurrentOpsUser = () => instance
  .get('ops-users/me/');

// Users

export const fetchUser = id => instance
  .get(`users/${id}/`);

export const fetchUserByUsername = username => instance
  .get(`users/username/${username}/`);

// Job Coordinator Users

export const fetchCoordinatorUsers = () => instance
  .get('coordinators/');

// Organizations

export const fetchOrganizations = () => instance
  .get('organizations/');

// Installer companies

export const fetchInstallerCompanies = () => instance
  .get('installer-companies/');

export const fetchInstallersOfCompany = id => instance
  .get(`installer-companies/${id}/users/`);

// Addresses

export const fetchAddresses = ({ street, postalCode, city }) => instance
  .get('addresses/', { params: { street, postalCode, city } });

export const fetchAddress = id => instance
  .get(`addresses/${id}/`);

export const createAddress = address => instance
  .post('addresses/', _.pick(address, [
    'street',
    'postalCode',
    'city',
    'state',
    'country',
    'lat',
    'lng',
    'force',
  ]));

export const fetchAddressDevices = (id, include = []) => instance
  .get(`addresses/${id}/devices/`, { params: { include } });

// Jobs

export const fetchJobs = (
  {
    userId, organizationId, addressId, companyId, installerId,
    minAppointment, maxAppointment, minStartTime, maxStartTime,
    minEndTime, maxEndTime, status, search, geolocation, maxDistance,
    pageNumber, pageSize, sortBy, orderBy, include, coordinatorId,
    priority, createdById,
  } = {},
) => {
  if (sortBy) {
    sortBy = humps.decamelize(sortBy);
  }

  const params = {
    userId,
    organizationId,
    addressId,
    companyId,
    installerId,
    coordinatorId,
    createdById,
    minAppointment,
    maxAppointment,
    minStartTime,
    maxStartTime,
    minEndTime,
    maxEndTime,
    status,
    pageSize,
    pageNumber,
    priority,
    sortBy,
    orderBy,
    include,
  };
  if (search) {
    params.search = search;
  }
  if (geolocation) {
    params.lat = geolocation.lat;
    params.lng = geolocation.lng;
    if (maxDistance != null) {
      params.maxDistance = maxDistance;
    }
  }

  return instance.get('jobs/', { params });
};

export const createJob = job => instance
  .post('jobs/', _.pick(job, [
    'organization.id',
    'address.id',
    'company.id',
    'installer.id',
    'coordinator.id',
    'owner.id',
    'admin.id',
    'priority',
    'appointment',
    'contactName',
    'contactPhone',
    'notes',
  ]));

export const createJobs = jobs => instance
  .post('jobs/bulk/', {
    jobs: jobs.map(job => ({
      organizationId: job.organization && job.organization.id,
      address: job.address && _.pick(job.address, job.address.id != null
        ? ['id']
        : ['street', 'postalCode', 'city', 'state', 'country', 'lat', 'lng']),
      companyId: job.company && job.company.id,
      installerId: job.installer && job.installer.id,
      ownerId: job.owner && job.owner.id,
      adminId: job.admin && job.admin.id,
      coordinatorId: job.coordinator && job.coordinator.id,
      priority: job.priority,
      appointment: job.appointment,
      startTime: job.startTime,
      endTime: job.endTime,
      contactName: job.contactName,
      contactPhone: job.contactPhone,
      notes: job.notes,
      tasks: job.tasks.map((task) => {
        if (task.type === TASK_TYPES.INSTALLATION) {
          return {
            type: task.type,
            hardwareProduct: task.hardwareProduct,
            hardwareVariant: task.hardwareVariant,
            specifier: task.specifier,
            floor: task.floor,
            doorNumber: task.doorNumber,
            doorType: task.doorType,
            usage: task.usage,
            dispatcherNotes: task.dispatcherNotes,
            settingsOverride: task.settingsOverride,
            configurationOverride: task.configurationOverride,
          };
        }

        if (task.type === TASK_TYPES.FIXING) {
          return {
            type: task.type,
            problemId: task.problem && task.problem.id,
            gatewayId: task.gatewayId,
            sensorId: task.sensorId,
            allowHardwareExchange: task.allowHardwareExchange,
            dispatcherNotes: task.dispatcherNotes,
          };
        }

        return task;
      }),
    })),
  });

export const fetchJob = (id, include = []) => instance
  .get(`jobs/${id}/`, { params: { include } });

export const fetchJobTasks = job => instance
  .get(`jobs/${job.id}/tasks/`);

export const saveJob = (job) => {
  const data = _.pick(job, [
    'organization.id',
    'address.id',
    'company',
    'installer',
    'coordinator',
    'owner',
    'admin',
    'priority',
    'appointment',
    'startTime',
    'endTime',
    'contactName',
    'contactPhone',
    'notes',
    'drivingDistance',
    'drivingTime',
    'workingTime',
  ]);

  // Handle nested nullable fields
  ['company', 'installer', 'owner', 'admin', 'coordinator']
    .filter(key => data[key] != null)
    .forEach((key) => {
      const { id } = data[key];
      data[key] = { id };
    });

  return instance.post(`jobs/${job.id}/`, data);
};

export const deleteJob = id => instance
  .delete(`jobs/${id}/`);

export const grantJobSensorPermissions = ({
  id, sensorIds, signatoryName, signatureB64, lang,
}) => instance
  .post(`jobs/${id}/sensor_permissions/`, {
    sensorIds,
    signatoryName,
    signatureB64,
    lang,
  });

export const revokeJobSensorPermissions = id => instance
  .delete(`jobs/${id}/sensor_permissions/`);

export const handoverJob = id => instance
  .post(`jobs/${id}/handover/`);

export const resetJob = id => instance
  .post(`jobs/${id}/reset/`);

export const forceFinishJob = id => instance
  .post(`jobs/${id}/force-finish/`);

export const preprocessJobsCsv = ({ file }) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const formData = new FormData();
  formData.append('file', file);

  return instance.post('jobs/bulk/preprocess/', formData, { headers });
};

// Tasks

export const createTask = (task) => {
  let paths = [
    'type',
    'jobId',
    'planned',
    'contextData',
    'dispatcherNotes',
  ];

  if (task.type === TASK_TYPES.INSTALLATION) {
    paths = [
      ...paths,
      'hardware.hardwareProduct',
      'hardware.hardwareVariant',
      'hardware.specifier',
      'hardware.floor',
      'hardware.doorNumber',
      'hardware.doorType',
      'hardware.usage',
      'hardware.settingsOverride',
      'hardware.configurationOverride',
    ];
  } else if (task.type === TASK_TYPES.FIXING) {
    paths = [
      ...paths,
      'hardware.sensorId',
      'hardware.gatewayId',
      'hardware.hardwareProduct',
      'problem.id',
      'fixingActions',
      'allowHardwareExchange',
    ];
  }

  const data = _.pick(task, paths);
  if (data.fixingActions) {
    data.fixingActions = data.fixingActions.map(({ id }) => ({ id }));
  }

  return instance.post('tasks/', data);
};

export const fetchTask = id => instance
  .get(`tasks/${id}/`);

export const saveTask = (task) => {
  const data = _.pick(task, [
    'step',
    'status',
    'startTime',
    'endTime',
    'planned',
    'contextData',
    'dispatcherNotes',
    'installerNotes',
    'hardware.sensorId',
    'hardware.gatewayId',
    'hardware.uuid',
    'hardware.hardwareProduct',
    'hardware.hardwareVariant',
    'hardware.floor',
    'hardware.specifier',
    'hardware.doorNumber',
    'hardware.doorType',
    'hardware.usage',
    'hardware.settingsOverride',
    'hardware.configurationOverride',
    'hardware.statusStart',
    'hardware.statusEnd',
    'problem.id',
    'fixingActions',
    'causingParty',
    'allowHardwareExchange',
  ]);

  if (data.fixingActions) {
    data.fixingActions = data.fixingActions.map(({ id }) => ({ id }));
  }

  return instance.post(`tasks/${task.id}/`, data);
};

export const migrateTaskHardware = ({
  id, hardwareProduct, hardwareVariant, step,
}) => {
  const payload = _.omitBy({
    hardwareProduct,
    hardwareVariant,
    step,
  }, _.isNil);

  return instance.post(`tasks/${id}/migrate_hardware/`, payload);
};

export const handoverTask = id => instance
  .post(`tasks/${id}/handover/`);

export const resetTask = id => instance
  .post(`tasks/${id}/reset/`);

export const deleteTask = id => instance
  .delete(`tasks/${id}/`);

export const grantTaskSensorPermission = id => instance
  .post(`tasks/${id}/sensor_permission/`);

export const revokeTaskSensorPermission = id => instance
  .delete(`tasks/${id}/sensor_permission/`);

export const createTaskImage = async ({ taskId, file, description }) => {
  const result = await instance.request({
    url: `tasks/${taskId}/images/`,
    method: 'POST',
    data: {
      filename: file.name,
      mimeType: file.type,
      description,
    },
    camelizeResponse: false,
  });
  const { upload } = result;
  const image = humps.decamelizeKeys(result.image);

  const data = new FormData();
  if (upload.fields) {
    Object.entries(upload.fields)
      .forEach(([key, value]) => data.append(key, value));
  }
  data.append('file', file);

  const { file: { url } } = await instance.request({
    url: upload.url,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    decamelizeRequest: false,
  });

  return {
    image: { ...image, url },
  };
};

export const saveTaskImage = async (taskId, image) => instance
  .post(`tasks/${taskId}/images/${image.id}/`, _.pick(image, [
    'description',
  ]));

export const deleteTaskImage = async (taskId, imageId) => instance
  .delete(`tasks/${taskId}/images/${imageId}/`);

export const addTaskFixingAction = (taskId, action) => instance
  .post(`tasks/${taskId}/fixing_actions/`, {
    actionId: action.id,
    checked: action.checked,
  });

export const saveTaskFixingAction = (taskId, action) => instance
  .post(`tasks/${taskId}/fixing_actions/${action.id}/`, _.pick(action, [
    'checked',
  ]));

export const generateTaskReport = ({
  type, taskTypes, organizationId, addressId, companyId,
  coordinatorIds, createdById, installerId, minAppointment,
  maxAppointment, minStartTime, maxStartTime, minEndTime,
  maxEndTime, priority, statuses,
}) => instance
  .get('/tasks/generate_report/', {
    params: {
      type,
      taskType: taskTypes,
      organizationId,
      addressId,
      companyId,
      coordinatorId: coordinatorIds,
      createdById,
      installerId,
      minAppointment,
      maxAppointment,
      minStartTime,
      maxStartTime,
      minEndTime,
      maxEndTime,
      priority,
      status: statuses,
    },
  });

export const fetchTaskReport = ({ reportUrl, reportType }) => instance
  .get('/tasks/report/', {
    responseType: 'blob',
    params: { reportUrl, reportType },
  });

// Fixing problems

export const fetchFixingProblems = ({
  name, pageNumber, pageSize, include = [],
}) => instance
  .get('fixing_problems/', {
    params: {
      name,
      pageNumber,
      pageSize,
      include,
    },
  });

export const fetchFixingProblem = (id, include = []) => instance
  .get(`fixing_problems/${id}/`, { params: { include } });

export const createFixingProblem = problem => instance
  .post('fixing_problems/', {
    name: problem.name,
    fixingActions: problem.fixingActions.map(({ id }) => ({ id })),
  });

export const saveFixingProblem = (problem) => {
  const data = _.pick(problem, [
    'name',
    'fixingActions',
  ]);
  if (data.fixingActions) {
    data.fixingActions = data.fixingActions.map(({ id }) => ({ id }));
  }

  return instance.post(`fixing_problems/${problem.id}/`, data);
};

export const deleteFixingProblem = id => instance
  .delete(`fixing_problems/${id}/`);

// Fixing actions

export const fetchFixingActions = ({ name, pageNumber, pageSize }) => instance
  .get('fixing_actions/', {
    params: {
      name,
      pageNumber,
      pageSize,
    },
  });

export const fetchFixingAction = id => instance
  .get(`fixing_actions/${id}/`);

export const createFixingAction = action => instance
  .post('fixing_actions/', _.pick(action, [
    'name',
  ]));

export const saveFixingAction = action => instance
  .post(`fixing_actions/${action.id}/`, _.pick(action, [
    'name',
  ]));

export const deleteFixingAction = id => instance
  .delete(`fixing_actions/${id}/`);

// Hardware registration

export const registerHardware = (
  hardwareUuid, hardwareProduct, hardwareVariant, unregisterExisting = false,
) => instance
  .post('register/', {
    hardwareUuid,
    hardwareProduct,
    hardwareVariant,
    unregisterExisting,
  });

export const registerHardwareOfTask = taskId => instance
  .post('register/', { taskId });

// Gateways

export const fetchGateways = (
  {
    pageNumber, pageSize, sortBy, orderBy,
    search, hardwareType, hardwareVariant,
    maxDistance, geolocation, include,
  } = {},
) => instance
  .get('gateways/', {
    params: {
      sortBy,
      orderBy,
      pageSize,
      pageNumber,
      search,
      hardwareType,
      hardwareVariant,
      ...geolocation,
      maxDistance,
      include,
    },
  });

export const fetchGateway = (id, include = []) => instance
  .get(`gateways/${id}/`, { params: { include } });

export const fetchGatewayByUuid = (uuid, include = []) => instance
  .get(`gateways/uuid/${uuid}/`, { params: { include } });

export const editGateway = (id, attributes) => instance
  .post(`/gateways/${id}/`, attributes);

export const removeGateway = (id, lifecycleState = null) => instance
  .delete(`gateways/${id}/`, { data: { lifecycleState } });

export const removeGatewayByUuid = (uuid, lifecycleState = null) => instance
  .delete(`gateways/uuid/${uuid}/`, { data: { lifecycleState } });

export const getGatewayStatus = (id, include = []) => instance
  .get(`gateways/${id}/status/`, { params: { include } });

export const getGatewayMetrics = id => instance
  .get(`gateways/${id}/metrics/`);

export const getGatewaySensors = (id, include = []) => instance
  .get(`gateways/${id}/sensors/`, { params: { include } });

export const sendGatewayCommand = (id, command) => instance
  .post(`gateways/${id}/command/${command}/`);

export const getGatewayResponse = (id, messageType) => instance
  .get(`gateways/${id}/response/${messageType}/`);

export const updateGatewayConfiguration = (id, configuration, full = false) => instance
  .post(`gateways/${id}/config/`, { configuration, full });

export const pushGatewayConfiguration = (id, full = false) => instance
  .post(`gateways/${id}/config/push/`, { full });

export const triggerGatewayLinkPerformanceTest = id => instance
  .post(`gateways/${id}/link_performance/`);

// Sensors

export const fetchSensors = (
  {
    pageNumber, pageSize, sortBy, orderBy,
    search, hardwareType, hardwareVariant,
    maxDistance, geolocation, include,
  } = {},
) => instance
  .get('sensors/', {
    params: {
      sortBy,
      orderBy,
      pageSize,
      pageNumber,
      search,
      hardwareType,
      hardwareVariant,
      ...geolocation,
      maxDistance,
      include,
    },
  });

export const fetchSensor = (id, include = []) => instance
  .get(`sensors/${id}/`, { params: { include } });

export const fetchSensorByUuid = (uuid, include = []) => instance
  .get(`sensors/uuid/${uuid}/`, { params: { include } });

export const editSensor = (id, attributes) => instance
  .post(`sensors/${id}/`, attributes);

export const removeSensor = (id, lifecycleState = null) => instance
  .delete(`sensors/${id}/`, { data: { lifecycleState } });

export const removeSensorByUuid = (uuid, lifecycleState = null) => instance
  .delete(`sensors/uuid/${uuid}/`, { data: { lifecycleState } });

export const getSensorStatus = (id, include = []) => instance
  .get(`sensors/${id}/status/`, { params: { include } });

export const getSensorMetrics = id => instance
  .get(`sensors/${id}/metrics/`);

export const getSensorGateways = (id, include = []) => instance
  .get(`sensors/${id}/gateways/`, { params: { include } });

export const grantSensorPermission = id => instance
  .post(`sensors/${id}/permission/`);

export const revokeSensorPermission = id => instance
  .delete(`sensors/${id}/permission/`);

export const sendSensorCommand = (id, command) => instance
  .post(`sensors/${id}/command/${command}/`);

export const getSensorResponse = (id, messageType) => instance
  .get(`sensors/${id}/response/${messageType}/`);

export const getSensorBatteryInfo = id => instance
  .get(`sensors/${id}/battery/`);

export const updateSensorConfiguration = (id, configuration, full = false) => instance
  .post(`sensors/${id}/config/`, { configuration, full });

export const pushSensorConfiguration = (id, full = false) => instance
  .post(`sensors/${id}/config/push/`, { full });

// Hybrids

export const fetchHybridByGatewayId = (gatewayId, include = []) => instance
  .get(`hybrids/gateway/${gatewayId}/`, { params: { include } });

export const fetchHybridBySensorId = (sensorId, include = []) => instance
  .get(`hybrids/sensor/${sensorId}/`, { params: { include } });

// Background task

export const getBackgroundTask = id => instance
  .get(`background_tasks/${id}/`);

// Time

export const getServerTime = () => instance
  .get('time/');
