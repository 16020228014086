import React from 'react';
import _ from 'lodash';

import { HW_CONFIG_OVERRIDES } from '~/app/constants';
import JobRoutineView from './JobRoutineView';
import PermissionApprovalStep from './steps/PermissionApprovalStep';
import CheckFixingActionsStep from './steps/CheckFixingActionsStep';
import ConnectivityTestingStep from './steps/ConnectivityTestingStep';
import RemoteOpeningTestingStep from './steps/RemoteOpeningTestingStep';
import KiSignalStrengthAdjustmentStep from './steps/KiSignalStrengthAdjustmentStep';
import KiOpeningTestingStep from './steps/KiOpeningTestingStep';
import TrackHardwareStatusStep from './steps/TrackHardwareStatusStep';
import SuccessStep from './steps/SuccessStep';
import ConfigurationStep from './steps/ConfigurationStep';


const SmartEntryFixingView = (props) => {
  const { task: { hardware } } = props;
  const { hardwareProduct, hardwareVariant } = hardware;
  const configOverrides = _.get(HW_CONFIG_OVERRIDES, [hardwareProduct, hardwareVariant], {});

  return (
    <JobRoutineView {...props}>
      <PermissionApprovalStep id="permissionApproval" skipBackwards />
      <TrackHardwareStatusStep id="checkStatusStart" atStart skipBackwards />
      <ConfigurationStep id="pushTestingConfig" override={configOverrides.TESTING} disabled={!configOverrides.TESTING} skipWaiting skipBackwards />
      <CheckFixingActionsStep id="checkActions" />
      {/* TODO: Disable signal strength check for now until further testing has been done */}
      <ConnectivityTestingStep id="connectivityTesting" ignoreSignalStrength skipBackwards />
      <ConfigurationStep id="awaitConfigUpdate" disabled={!configOverrides.TESTING} awaitOnly skipBackwards />
      <RemoteOpeningTestingStep id="remoteOpeningTesting" skipBackwards />
      <KiSignalStrengthAdjustmentStep id="kiSignalStrengthAdjustment" />
      <KiOpeningTestingStep id="kiClickOpeningTesting" trigger="click" />
      <ConfigurationStep id="pushProductionConfig" override={configOverrides.PRODUCTION} skipBackwards />
      <TrackHardwareStatusStep id="checkStatusEnd" fresh atEnd skipBackwards />
      <SuccessStep id="success" />
    </JobRoutineView>
  );
};

export default SmartEntryFixingView;
