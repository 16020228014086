import React, { useEffect, useState } from 'react';
import { Placeholder, Image } from 'semantic-ui-react';


const ImageWithPlaceholder = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const image = new window.Image();
    image.onload = () => setLoading(false);
    image.src = props.src;
  }, [props.src]);

  return (
    <Image {...props}>
      {loading ? (
        <Placeholder>
          <Placeholder.Image rectangular />
        </Placeholder>
      ) : null}
    </Image>
  );
};

export default ImageWithPlaceholder;
