import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';


export const dismissError = createAction('JOBS_ERRORS_DISMISS');

export const fetchJobs = createRoutine('JOBS_FETCH_LIST');
export const refreshJob = createRoutine('JOBS_REFRESH');
export const refreshJobs = createRoutine('JOBS_REFRESH_LIST');
export const saveJob = createRoutine('JOBS_SAVE');
export const handoverJob = createRoutine('JOBS_HANDOVER');
export const resetJob = createRoutine('JOBS_RESET');

export const fetchJobTasks = createRoutine('JOBS_TASKS_FETCH_LIST');
export const refreshTask = createRoutine('JOBS_TASKS_REFRESH');
export const createTask = createRoutine('JOBS_TASKS_CREATE');
export const saveTask = createRoutine('JOBS_TASKS_SAVE');
export const migrateTaskHardware = createRoutine('JOBS_TASKS_MIGRATE_HARDWARE');
export const handoverTask = createRoutine('JOBS_TASKS_HANDOVER');
export const resetTask = createRoutine('JOBS_TASKS_RESET');

export const createTaskImage = createRoutine('JOBS_TASKS_IMAGE_CREATE');
export const saveTaskImage = createRoutine('JOBS_TASKS_IMAGE_SAVE');
export const deleteTaskImage = createRoutine('JOBS_TASKS_IMAGE_DELETE');

export const fetchFixingActions = createRoutine('JOBS_FIXING_ACTION_LIST');
export const addTaskFixingAction = createRoutine('JOBS_TASKS_FIXING_ACTION_CREATE');
export const saveTaskFixingAction = createRoutine('JOBS_TASKS_FIXING_ACTION_SAVE');

export const grantSensorPermissions = createRoutine('JOBS_SENSOR_PERMISSIONS_GRANT');
export const revokeSensorPermissions = createRoutine('JOBS_SENSOR_PERMISSIONS_REVOKE');
