import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import { MAX_CONFIG_TIMEOUT } from '~/app/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import {
  updateGatewayConfiguration, pushGatewayConfiguration, awaitGatewayConfigurationUpdate,
  updateSensorConfiguration, pushSensorConfiguration, awaitSensorConfigurationUpdate,
  stopRoutines,
} from '~/app/actions/HardwareActions';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import TimeoutProgress from '~/app/components/TimeoutProgress';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const ERROR_MESSAGES = defineMessages({
  NOT_ALLOWED: {
    id: 'ConfigurationStep.jobs.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
  CONF_TIMEOUT: {
    id: 'ConfigurationStep.jobs.errors.timeout',
    defaultMessage: 'The device could not be configured',
  },
  CONF_ERROR: {
    id: 'ConfigurationStep.jobs.errors.confError',
    defaultMessage: 'The device could not be configured',
  },
});


const ConfigurationStep = (props) => {
  const {
    routine: { job, task },
    override,
    full,
    awaitOnly,
    skipWaiting,
  } = props;

  const [next, setNext] = useState(false);

  const dispatch = useDispatch();
  const error = useSelector(state => state.hardware.error);
  const configurationUpdate = useSelector(state => state.hardware.configurationUpdate);

  const configurationOverride = useMemo(
    () => _.merge({}, task.hardware.configurationOverride, override),
    [task, override],
  );
  const needsUpdate = Object.keys(configurationOverride).length > 0;

  const updateConfiguration = useCallback(() => {
    const [actionCreator, id] = task.hardware.sensorId != null
      ? [updateSensorConfiguration, task.hardware.sensorId]
      : [updateGatewayConfiguration, task.hardware.gatewayId];

    dispatch(actionCreator({
      id,
      configuration: configurationOverride,
      full,
      awaitCompletion: !skipWaiting,
    }));

    setNext(true);
  }, [task, configurationOverride, full, skipWaiting, dispatch]);

  const pushConfiguration = useCallback(() => {
    const [actionCreator, id] = task.hardware.sensorId != null
      ? [pushSensorConfiguration, task.hardware.sensorId]
      : [pushGatewayConfiguration, task.hardware.gatewayId];

    dispatch(actionCreator({
      id,
      full,
      awaitCompletion: !skipWaiting,
    }));

    setNext(true);
  }, [task, full, skipWaiting, dispatch]);

  const awaitConfigurationUpdate = useCallback(() => {
    const [actionCreator, id] = task.hardware.sensorId != null
      ? [awaitSensorConfigurationUpdate, task.hardware.sensorId]
      : [awaitGatewayConfigurationUpdate, task.hardware.gatewayId];

    dispatch(actionCreator({ id }));

    setNext(true);
  }, [task, dispatch]);

  const configureDevice = useCallback(() => {
    if (awaitOnly) {
      awaitConfigurationUpdate();
    } else if (needsUpdate) {
      updateConfiguration();
    } else {
      pushConfiguration();
    }
  }, [
    awaitOnly, needsUpdate,
    updateConfiguration, pushConfiguration, awaitConfigurationUpdate,
  ]);

  const retry = useCallback(() => {
    if (error && error.code === 'CONF_TIMEOUT') {
      awaitConfigurationUpdate();
    } else {
      configureDevice();
    }
  }, [error, awaitConfigurationUpdate, configureDevice]);

  useEffect(() => {
    if (!next) {
      configureDevice();
    }
  }, [next, configureDevice]);

  useEffect(() => () => dispatch(stopRoutines()), [dispatch]);

  if (next && configurationUpdate.success) {
    return <RoutineNavigation nextStep />;
  }

  return (
    <StepView
      {...props}
      header={<JobProgressHeader job={job} task={task} stage="test" />}
      error={error}
      errorHeader={(
        <FormattedMessage
          id="ConfigurationStep.jobs.errors.header"
          defaultMessage="Configuration error"
        />
      )}
      errorMessages={ERROR_MESSAGES}
      retry={retry}
    >
      <StepView.Content>
        <p>
          <FormattedMessage
            id="ConfigurationStep.jobs.description"
            defaultMessage="Configuring device..."
          />
        </p>
        <TimeoutProgress timeout={MAX_CONFIG_TIMEOUT} />
      </StepView.Content>
    </StepView>
  );
};

ConfigurationStep.defaultProps = {
  override: {},
  full: false,
  awaitOnly: false,
  skipWaiting: false,
};

ConfigurationStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  override: PropTypes.object,
  full: PropTypes.bool,
  awaitOnly: PropTypes.bool,
  skipWaiting: PropTypes.bool,
};

export default withRoutine(ConfigurationStep);
