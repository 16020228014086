import _ from 'lodash';

import { HW_CONFIG_OPTIONS } from '~/common/constants';
import setDeep from '~/common/utils/setDeep';


const assertValidOption = (key) => {
  if (!HW_CONFIG_OPTIONS[key]) {
    throw new Error(`${key} is not a valid config option`);
  }
};


export const hasConfigOption = (configuration, key) => {
  assertValidOption(key);

  const { path } = HW_CONFIG_OPTIONS[key];
  return _.has(configuration, path);
};


export const getConfigOption = (configuration, key) => {
  assertValidOption(key);

  const { path, defaultValue } = HW_CONFIG_OPTIONS[key];
  return _.get(configuration, path, defaultValue);
};


export const setConfigOption = (configuration, key, value) => {
  assertValidOption(key);

  const { path, values } = HW_CONFIG_OPTIONS[key];
  if (values && !values.includes(value)) {
    throw new Error(`The value ${value} is invalid for config option ${key}`);
  }

  return setDeep(_.cloneDeep(configuration), path, value);
};
