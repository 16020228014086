import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import attachCompounds from '~/common/utils/attachCompounds';
import '~/app/styles/components/ExpandableList.css';


class ExpandableList extends Component {
  state = {
    collapsed: true,
  }

  handleMouseDown = (event) => {
    // Prevent firing focus on clicks to prevent the browser
    // from showing the outline of the list
    event.preventDefault();
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.toggle();
    }
  }

  toggle = () => {
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  }

  render() {
    const { collapsed } = this.state;
    const { minItems } = this.props;

    const children = React.Children.toArray(this.props.children);
    if (children.length <= minItems + 1) {
      return <List>{children}</List>;
    }

    const end = collapsed ? minItems : children.length;
    const shownItems = children.slice(0, end);
    const hiddenItems = children.slice(end);

    return (
      <div
        role="button"
        tabIndex={0}
        className="expandable-list"
        onClick={this.toggle}
        onMouseDown={this.handleMouseDown}
        onKeyDown={this.handleKeyDown}
      >
        <List>{shownItems}</List>
        {hiddenItems.length > 0 && (
          <span className="expand-message">
            <FormattedMessage
              id="ExpandableList.expand"
              defaultMessage="Show {count} more..."
              values={{ count: hiddenItems.length }}
            />
          </span>
        )}
      </div>
    );
  }
}

ExpandableList.defaultProps = {
  minItems: 3,
  children: null,
};

ExpandableList.propTypes = {
  minItems: PropTypes.number,
  children: PropTypes.node,
};

export default attachCompounds(ExpandableList, {
  Item: List.Item,
});
