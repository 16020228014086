import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import _ from 'lodash';

import { HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import { requestSkipTask } from '~/app/actions/RoutineActions';
import {
  saveTask, migrateTaskHardware,
  dismissError as dismissJobsError,
} from '~/app/actions/JobsActions';
import { fetchSensor, editSensor, dismissError as dismissHardwareError } from '~/app/actions/HardwareActions';
import { splitSmartEntryHybrid } from '~/app/utils/taskHardwareMigrations';
import ComboBox from '~/common/components/ComboBox';
import ButtonGroup from '~/common/components/ButtonGroup';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const DOORBELL_PANEL_BRAND_OPTIONS = [
  { value: 'bosch', text: 'Bosch' },
  { value: 'bticino', text: 'Bticino' },
  { value: 'comelit', text: 'Comelit' },
  { value: 'elcom', text: 'ELCOM' },
  { value: 'gira', text: 'Gira' },
  { value: 'grothe', text: 'Grothe' },
  { value: 'lippert', text: 'Lippert' },
  { value: 'lt', text: 'LT' },
  { value: 'ritto', text: 'Ritto' },
  { value: 'seko', text: 'Seko' },
  { value: 'siedle', text: 'Siedle' },
  { value: 'sks', text: 'SKS' },
  { value: 'str', text: 'STR' },
  { value: 'tcs', text: 'TCS' },
  { value: 'terraneo', text: 'Terraneo' },
  { value: 'urmet', text: 'Urmet' },
];

const DOORBELL_OPENING_MODE_OPTIONS = [
  // Screwdrivers
  {
    value: 'screwdriver_cross',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.options.screwdriver.cross" defaultMessage="Cross Screwdriver" />,
  },
  {
    value: 'screwdriver_slot_drive',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.options.screwdriver.slotDrive" defaultMessage="Slot Drive Screwdriver" />,
  },
  {
    value: 'screwdriver_torx',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.options.screwdriver.torx" defaultMessage="Torx Screwdriver" />,
  },
  {
    value: 'screwdriver_spanner',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.options.screwdriver.spanner" defaultMessage="Spanner Screwdriver" />,
  },
  // Universal keys
  {
    value: 'universal_key_tcs_winclip',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.options.universalKey.tcsWinclip" defaultMessage="TCS Winclip" />,
  },
  {
    value: 'universal_key_siedle_5162',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.options.universalKey.siedle5162" defaultMessage="Siedle 5162 Silver" />,
  },
  {
    value: 'universal_key_siedle_15599',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.options.universalKey.siedle15599" defaultMessage="Siedle 15599 Black" />,
  },
  // Other
  {
    value: 'apartment_manager_key',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.options.apartmentManagerKey" defaultMessage="Key of Apartment Manager" />,
  },
];

const DOORBELL_PANEL_CONDITION_OPTIONS = [
  {
    value: 'old',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellPanelCondition.options.old" defaultMessage="Old" />,
  },
  {
    value: 'normal',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellPanelCondition.options.normal" defaultMessage="Normal" />,
  },
  {
    value: 'new',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellPanelCondition.options.new" defaultMessage="New" />,
  },
];

const POWER_TYPE_OPTIONS = [
  {
    value: 'intercom',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.powerType.options.intercom" defaultMessage="Connected to intercom" />,
  },
  {
    value: 'transformer',
    text: <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.powerType.options.transformer" defaultMessage="Connected to transformer" />,
  },
];

const LOCATION_OPTIONS_MESSAGES = defineMessages({
  behindPanel: {
    id: 'SmartEntryInstallationDataInputStep.jobs.location.options.behindPanel',
    defaultMessage: 'Behind doorbell panel',
  },
});

class SmartEntryInstallationDataInputStep extends Component {
  state = {
    location: _.get(this.props.routine.sensor, 'installationLocation') || null,
    doorbellPanelBrand: this.props.routine.task.contextData.doorbellPanelBrand || null,
    doorbellOpeningMode: this.props.routine.task.contextData.doorbellOpeningMode || null,
    doorbellPanelCondition: this.props.routine.task.contextData.doorbellPanelCondition || null,
    powerType: this.props.routine.task.contextData.powerType || null,
    migrateTask: false,
    next: false,
  }

  componentDidMount() {
    const { task } = this.props.routine;
    this.props.fetchSensor({ id: task.hardware.sensorId });
  }

  componentDidUpdate(prevProps) {
    const { sensor } = this.props.routine;
    const { sensor: prevSensor } = prevProps.routine;

    if (sensor && sensor !== prevSensor) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ location: sensor.installationLocation });
    }
  }

  validate = () => {
    const {
      doorbellPanelBrand, doorbellOpeningMode,
      doorbellPanelCondition, location,
      powerType,
    } = this.state;

    return [
      doorbellPanelBrand,
      doorbellOpeningMode,
      doorbellPanelCondition,
      location,
      powerType,
    ].every(value => value != null && value.length > 0);
  }

  next = () => {
    if (this.validate()) {
      const { task } = this.props.routine;
      const {
        doorbellPanelBrand, doorbellOpeningMode,
        doorbellPanelCondition,
        powerType, location,
      } = this.state;

      this.props.editSensor({
        id: task.hardware.sensorId,
        installationLocation: location,
      });
      this.props.saveTask({
        id: task.id,
        contextData: {
          ...task.contextData,
          doorbellPanelBrand,
          doorbellOpeningMode,
          doorbellPanelCondition,
          powerType,
        },
      });
      this.setState({ next: true });
    }
  }

  migrateHybridToSensor = () => {
    const { task, steps } = this.props.routine;
    this.props.migrateTaskHardware(splitSmartEntryHybrid({ task, steps }));
    this.setState({ migrateTask: true });
  };

  dismissError = () => {
    this.setState({ next: false, migrateTask: false });
    this.props.dismissJobsError();
    this.props.dismissHardwareError();
  }

  render() {
    const {
      doorbellPanelBrand, doorbellOpeningMode,
      doorbellPanelCondition, location,
      powerType, migrateTask, next,
    } = this.state;
    const { job, task } = this.props.routine;
    const { taskSaved, loading: jobsLoading, error: jobsError } = this.props.jobs;
    const { hardwareEdited, loading: hardwareLoading, error: hardwareError } = this.props.hardware;
    const validForm = this.validate();

    let error = null;
    if (migrateTask) {
      error = jobsError;
    } else if (next) {
      error = jobsError || hardwareError;
    }

    const loading = error == null
      ? (jobsLoading || hardwareLoading)
      : false;

    const locationOptions = Object.values(LOCATION_OPTIONS_MESSAGES)
      .map(this.props.intl.formatMessage)
      .map(text => ({ text, value: text }));

    if (next && hardwareEdited && taskSaved) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        loading={loading}
        header={<JobProgressHeader job={job} task={task} stage="installation" />}
      >
        <StepView.Content>
          <JobSaveErrorModal
            error={error}
            onDismiss={this.dismissError}
          />
          <p>
            <FormattedMessage
              id="SmartEntryInstallationDataInputStep.jobs.instructions"
              defaultMessage="Please answer the following questions:"
            />
          </p>
          <Form onSubmit={this.next}>
            <Form.Field>
              <label>
                <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellPanelBrand.label" defaultMessage="Doorbell panel brand" />
              </label>
              <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellPanelBrand.placeholder" defaultMessage="Doorbell panel brand">
                {placeholder => (
                  <ComboBox
                    fluid
                    value={doorbellPanelBrand}
                    placeholder={placeholder}
                    options={DOORBELL_PANEL_BRAND_OPTIONS}
                    onChange={value => this.setState({ doorbellPanelBrand: value })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.label" defaultMessage="Panel opened through..." />
              </label>
              <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellOpeningMode.placeholder" defaultMessage="Panel opened through...">
                {placeholder => (
                  <ComboBox
                    fluid
                    value={doorbellOpeningMode}
                    placeholder={placeholder}
                    options={DOORBELL_OPENING_MODE_OPTIONS}
                    onChange={value => this.setState({ doorbellOpeningMode: value })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellPanelCondition.label" defaultMessage="Condition of the panel" />
              </label>
              <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.doorbellPanelCondition.placeholder" defaultMessage="Condition of the panel">
                {placeholder => (
                  <ComboBox
                    fluid
                    value={doorbellPanelCondition}
                    placeholder={placeholder}
                    options={DOORBELL_PANEL_CONDITION_OPTIONS}
                    onChange={value => this.setState({ doorbellPanelCondition: value })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.location.label" defaultMessage="Location of the sensor" />
              </label>
              <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.location.placeholder" defaultMessage="Location of the sensor">
                {placeholder => (
                  <ComboBox
                    fluid
                    value={location}
                    placeholder={placeholder}
                    options={locationOptions}
                    onChange={value => this.setState({ location: value })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.powerType.label" defaultMessage="Type of power connection" />
              </label>
              <FormattedMessage id="SmartEntryInstallationDataInputStep.jobs.powerType.placeholder" defaultMessage="Type of power connection">
                {placeholder => (
                  <ComboBox
                    fluid
                    value={powerType}
                    placeholder={placeholder}
                    options={POWER_TYPE_OPTIONS}
                    onChange={value => this.setState({ powerType: value })}
                  />
                )}
              </FormattedMessage>
            </Form.Field>
          </Form>
        </StepView.Content>

        <StepView.Footer>
          <ButtonGroup>
            <Button fluid color="red" disabled={validForm} onClick={this.props.requestSkipTask}>
              <FormattedMessage
                id="SmartEntryInstallationDataInputStep.jobs.notInstallable.label"
                defaultMessage="Device can't be installed"
              />
            </Button>
            {task.hardware.hardwareProduct === HW_PRODUCTS.SMART_ENTRY_HYBRID && (
              <Button fluid onClick={this.migrateHybridToSensor}>
                <FormattedMessage
                  id="SmartEntryInstallationDataInputStep.jobs.migrateHybridToSensor.label"
                  defaultMessage="Install Gateway and Sensor separately"
                />
              </Button>
            )}
            <Button primary fluid disabled={!validForm} onClick={this.next}>
              <FormattedMessage
                id="SmartEntryInstallationDataInputStep.jobs.next.label"
                defaultMessage="Next"
              />
            </Button>
          </ButtonGroup>
        </StepView.Footer>
      </StepView>
    );
  }
}

SmartEntryInstallationDataInputStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  jobs: state.jobs,
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveTask,
  migrateTaskHardware,
  dismissJobsError,
  fetchSensor,
  editSensor,
  dismissHardwareError,
  requestSkipTask,
}, dispatch);

export default withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmartEntryInstallationDataInputStep)));
