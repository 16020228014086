import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Image } from 'semantic-ui-react';
import { FormattedMessage, defineMessages } from 'react-intl';
import _ from 'lodash';

import { HW_PRODUCTS } from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import staticFile from '~/common/utils/staticFile';
import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { getSensorBatteryInfo, stopRoutines } from '~/app/actions/HardwareActions';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import TimeoutProgress from '~/app/components/TimeoutProgress';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const TIMEOUT = 35000;
const MIN_BATTERY_STEP = 4;

const ERROR_MESSAGES = defineMessages({
  TIMEOUT: {
    id: 'SmartLockBatteryCheckStep.jobs.errors.timeout',
    defaultMessage: 'Battery level of the sensor could not be retrieved.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = {
  [HW_PRODUCTS.KNOB]: _.pick(FIXING_OPTION_MESSAGES,
    'knobInstalledProperly',
    'knobRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.HANDLE]: _.pick(FIXING_OPTION_MESSAGES,
    'handleInstalledProperly',
    'handleRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.SAFE]: _.pick(FIXING_OPTION_MESSAGES,
    'safeInstalledProperly',
    'safeRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.SAFE_TWO]: _.pick(FIXING_OPTION_MESSAGES,
    'safeInstalledProperly',
    'safeRestart',
    'gatewayRestart'),
};

const INSTRUCTION_IMAGES = {
  [HW_PRODUCTS.KNOB]: staticFile('smart_installation/images/knob-battery.svg'),
  [HW_PRODUCTS.HANDLE]: staticFile('smart_installation/images/handle-battery.svg'),
  [HW_PRODUCTS.SAFE]: staticFile('smart_installation/images/knob-battery.svg'),
  [HW_PRODUCTS.SAFE_TWO]: staticFile('smart_installation/images/knob-battery.svg'),
};


class SmartLockBatteryCheckStep extends Component {
  state = {
    next: false,
    replaceBattery: false,
  }

  componentDidMount() {
    this.updateBatteryInfo();
  }

  componentDidUpdate(prevProps) {
    const { sensorBatteryInfo: prevBatteryInfo } = prevProps.hardware;
    const { sensorBatteryInfo: batteryInfo } = this.props.hardware;

    // Check battery info if new data has been fetched
    const timestamp = batteryInfo && Date.parse(batteryInfo.timestamp);
    const prevTimestamp = prevBatteryInfo && Date.parse(prevBatteryInfo.timestamp);
    if (batteryInfo && (!prevBatteryInfo || timestamp > prevTimestamp)) {
      this.checkBatteryInfo();
    }
  }

  componentWillUnmount() {
    this.props.stopRoutines();
  }

  updateBatteryInfo = () => {
    const { task } = this.props.routine;
    this.setState({ replaceBattery: false });
    this.props.getSensorBatteryInfo({ id: task.hardware.sensorId });
  }

  checkBatteryInfo = () => {
    const { sensorBatteryInfo: batteryInfo } = this.props.hardware;
    if (batteryInfo.step >= MIN_BATTERY_STEP) {
      this.setState({ next: true, replaceBattery: false });
    } else {
      this.setState({ replaceBattery: true });
    }
  }

  render() {
    const { next, replaceBattery } = this.state;
    const { job, task } = this.props.routine;
    const { error } = this.props.hardware;

    if (next) {
      return <RoutineNavigation nextStep />;
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="test" />}
        error={error}
        errorHeader={(
          <FormattedMessage
            id="SmartLockBatteryCheckStep.jobs.errors.header"
            defaultMessage="Test failed"
          />
        )}
        errorMessages={ERROR_MESSAGES}
        fixingOptionMessages={CURRENT_FIXING_OPTION_MESSAGES[task.hardware.hardwareProduct]}
        retry={this.updateBatteryInfo}
      >
        <StepView.Content>
          {!replaceBattery && (
            <>
              <p>
                <FormattedMessage id="SmartLockBatteryCheckStep.jobs.check" defaultMessage="Checking battery status of sensor..." />
              </p>
              <TimeoutProgress timeout={TIMEOUT} />
            </>
          )}

          {replaceBattery && (
            <>
              <p>
                <FormattedMessage
                  id="SmartLockBatteryCheckStep.jobs.lowBattery"
                  defaultMessage="Battery level of the sensor is low. Please replace the battery and run the test again."
                />
              </p>
              {INSTRUCTION_IMAGES[task.hardware.hardwareProduct] && (
                <Image
                  centered
                  bordered
                  size="large"
                  className="instructions-image"
                  src={INSTRUCTION_IMAGES[task.hardware.hardwareProduct]}
                />
              )}
            </>
          )}
        </StepView.Content>

        <StepView.Footer>
          {replaceBattery && (
            <Button primary fluid onClick={this.updateBatteryInfo}>
              <FormattedMessage id="SmartLockBatteryCheckStep.jobs.checkAgain.label" defaultMessage="Check battery again" />
            </Button>
          )}
        </StepView.Footer>
      </StepView>
    );
  }
}

SmartLockBatteryCheckStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
};

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getSensorBatteryInfo,
  stopRoutines,
}, dispatch);

export default withRoutine(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmartLockBatteryCheckStep));
