import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { pauseJob, startTask } from '~/app/actions/RoutineActions';
import { TASK_STATUSES_PENDING } from '~/common/constants';
import { JobType } from '~/common/types';
import View from '~/app/views/View';
import TaskList from '~/app/components/TaskList';
import FormattedAddress from '~/common/components/FormattedAddress';


const TaskListView = ({ job }) => {
  const dispatch = useDispatch();
  const tasks = job.tasks.filter(t => TASK_STATUSES_PENDING.includes(t.status));

  return (
    <View onGoBack={() => dispatch(pauseJob())}>
      <View.Header
        primaryText={<FormattedMessage id="TaskListView.header.primary" defaultMessage="Tasks" />}
        secondaryText={<FormattedAddress address={job.address} />}
      />
      <View.Content compact>
        <TaskList
          job={job}
          tasks={tasks}
          onClick={task => dispatch(startTask({ job, task }))}
          header={(
            <FormattedMessage
              id="TaskListView.pendingTaskMessage"
              defaultMessage="{n} pending {n, plural, one {task} other {tasks}}"
              values={{ n: tasks.length }}
            />
          )}
        />
      </View.Content>
    </View>
  );
};

TaskListView.propTypes = {
  job: JobType.isRequired,
};

export default TaskListView;
