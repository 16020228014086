import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';

import FormattedTimespan from '~/common/components/FormattedTimespan';


const FormattedDateTime = ({
  value, relative, multiLine,
  dayFormat, monthFormat, yearFormat,
  hourFormat, minuteFormat,
}) => {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    if (relative) {
      const interval = setInterval(() => setNow(Date.now()), 1000);
      return () => clearInterval(interval);
    }
  }, [relative]);

  if (!value) {
    return null;
  }

  if (relative) {
    const difference = (Date.parse(value) - now) / 1000;

    return (
      <FormattedTimespan
        totalSeconds={Math.abs(difference)}
        context={difference <= 0 ? 'past' : 'future'}
        precision={Math.abs(difference) < 60 ? 'seconds' : 'minutes'}
      />
    );
  }

  return (
    <span>
      <FormattedDate
        value={value}
        day={dayFormat}
        month={monthFormat}
        year={yearFormat}
      />

      {multiLine ? <br /> : ' '}

      <FormattedTime
        value={value}
        hour={hourFormat}
        minute={minuteFormat}
      />
    </span>
  );
};

FormattedDateTime.defaultProps = {
  value: '',
  relative: false,
  multiLine: false,
  dayFormat: '2-digit',
  monthFormat: '2-digit',
  yearFormat: 'numeric',
  hourFormat: '2-digit',
  minuteFormat: '2-digit',
};

FormattedDateTime.propTypes = {
  value: PropTypes.string,
  relative: PropTypes.bool,
  multiLine: PropTypes.bool,
  dayFormat: PropTypes.oneOf(['numeric', '2-digit']),
  monthFormat: PropTypes.oneOf(['numeric', '2-digit', 'narrow', 'short', 'long']),
  yearFormat: PropTypes.oneOf(['numeric', '2-digit']),
  hourFormat: PropTypes.oneOf(['numeric', '2-digit']),
  minuteFormat: PropTypes.oneOf(['numeric', '2-digit']),
};

export default FormattedDateTime;
