import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header } from 'semantic-ui-react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import _ from 'lodash';

import {
  HW_PRODUCTS, GATEWAY_HW_PRODUCTS,
  TASK_TYPES, TASK_STATUSES_DONE,
} from '~/common/constants';
import {
  JobType, TaskType, GatewayType, SensorType,
} from '~/common/types';
import FIXING_OPTION_MESSAGES from '~/app/locales/FixingOptions';
import { checkGatewayConnectivity, checkSensorConnectivity, stopRoutines } from '~/app/actions/HardwareActions';
import JobProgressHeader from '~/app/components/JobProgressHeader';
import RoutineNavigation from '~/app/components/RoutineNavigation';
import TimeoutProgress from '~/app/components/TimeoutProgress';
import HardwareConnectivitySummary from '~/app/components/HardwareConnectivitySummary';
import { withRoutine } from '~/app/contexts/RoutineContext';
import StepView from './StepView';


const TIMEOUT = 45000;

const ERROR_MESSAGES = defineMessages({
  NOT_ALLOWED: {
    id: 'ConnectivityTestingStep.jobs.errors.notAllowed',
    defaultMessage: 'You are not allowed to access this device.',
  },
  RATE_LIMIT_EXCEEDED: {
    id: 'ConnectivityTestingStep.jobs.errors.rateLimitExceeded',
    defaultMessage: 'The rate limit has been exceeded. Please try again in a couple of seconds.',
  },
});

const CURRENT_FIXING_OPTION_MESSAGES = {
  [HW_PRODUCTS.GATEWAY]: _.pick(FIXING_OPTION_MESSAGES,
    'gatewayInstalledProperly',
    'gatewayRepositionGsmAntenna',
    'gatewayRestart'),
  [HW_PRODUCTS.SMART_ENTRY]: _.pick(FIXING_OPTION_MESSAGES,
    'smartEntryInstalledProperly',
    'smartEntryReposition868MhzAntenna',
    'gatewayReposition868MhzAntenna',
    'smartEntryRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.SMART_ENTRY_HYBRID]: _.pick(FIXING_OPTION_MESSAGES,
    'smartEntryInstalledProperly',
    'smartEntryRepositionGsmAntenna',
    'smartEntryRestart'),
  [HW_PRODUCTS.SMART_DOOR]: _.pick(FIXING_OPTION_MESSAGES,
    'smartDoorInstalledProperly',
    'smartDoorClosed',
    'smartDoorRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.KNOB]: _.pick(FIXING_OPTION_MESSAGES,
    'knobInstalledProperly',
    'gatewayReposition868MhzAntenna',
    'knobRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.HANDLE]: _.pick(FIXING_OPTION_MESSAGES,
    'handleInstalledProperly',
    'gatewayReposition868MhzAntenna',
    'handleRestart',
    'gatewayRestart'),
  [HW_PRODUCTS.KNOB]: _.pick(FIXING_OPTION_MESSAGES,
    'safeInstalledProperly',
    'gatewayReposition868MhzAntenna',
    'safeRestart',
    'gatewayRestart'),
};


class ConnectivityTestingStep extends Component {
  state = {
    next: false,
  }

  componentDidMount() {
    this.checkConnectivity();
  }

  componentWillUnmount() {
    this.props.stopRoutines();
  }

  get error() {
    const { ignoreSignalStrength } = this.props;
    const { hardwareConnectivity: connectivity, error } = this.props.hardware;

    if (error) {
      return error;
    }
    if (connectivity) {
      const { isOnline, signalStrength: { rating } } = connectivity;
      if (!isOnline) {
        return (
          <FormattedMessage
            id="ConnectivityTestingStep.jobs.errors.noConnection"
            defaultMessage="Connection to device could not be established!"
          />
        );
      }
      if (!rating && !ignoreSignalStrength) {
        return (
          <FormattedMessage
            id="ConnectivityTestingStep.jobs.errors.noSignalData"
            defaultMessage="The device hasn't sent any signal data, yet."
          />
        );
      }
      if (rating === 'poor' && !ignoreSignalStrength) {
        return (
          <FormattedMessage
            id="ConnectivityTestingStep.jobs.errors.poorConnection"
            defaultMessage="Connection quality of the device is poor!"
          />
        );
      }
    }

    return null;
  }

  get errorContext() {
    const { sensor, gateway } = this.props.routine;
    if ((!sensor || !sensor.status) && (!gateway || !gateway.status)) {
      return null;
    }

    return (
      <>
        <Header size="tiny" className="device-info-header">
          <FormattedMessage
            id="ConnectivityTestingStep.jobs.deviceInfo.header"
            defaultMessage="Device information"
          />
        </Header>
        <HardwareConnectivitySummary forceOffline gateway={gateway} sensor={sensor} />
      </>
    );
  }

  checkConnectivity = () => {
    const { task } = this.props.routine;
    const { ignoreSignalStrength } = this.props;

    const [actionCreator, id] = task.hardware.gatewayId != null
      ? [this.props.checkGatewayConnectivity, task.hardware.gatewayId]
      : [this.props.checkSensorConnectivity, task.hardware.sensorId];

    actionCreator({ id, ignoreSignalStrength });

    this.setState({ next: true });
  }

  render() {
    const { next } = this.state;
    const { ignoreSignalStrength } = this.props;
    const { job, task } = this.props.routine;
    const { hardwareConnectivity: connectivity } = this.props.hardware;
    const { hardware: { hardwareProduct } } = task;

    const isGateway = GATEWAY_HW_PRODUCTS.includes(hardwareProduct);
    const allowUnplannedGateway = task.type === TASK_TYPES.INSTALLATION && !isGateway;
    const gatewayInstalled = job.tasks.some(t => t.type === TASK_TYPES.INSTALLATION
        && TASK_STATUSES_DONE.includes(t.status)
        && GATEWAY_HW_PRODUCTS.includes(t.hardware.hardwareProduct));

    let fixingOptionMessages = CURRENT_FIXING_OPTION_MESSAGES[hardwareProduct];
    if (!isGateway && !gatewayInstalled) {
      fixingOptionMessages = _.omit(fixingOptionMessages, 'gatewayRestart');
    }

    if (next && connectivity) {
      const { isOnline, signalStrength: { rating } } = connectivity;
      if (isOnline && (rating === 'good' || ignoreSignalStrength)) {
        return <RoutineNavigation nextStep />;
      }
    }

    return (
      <StepView
        {...this.props}
        header={<JobProgressHeader job={job} task={task} stage="test" />}
        error={this.error}
        errorHeader={(
          <FormattedMessage
            id="ConnectivityTestingStep.jobs.errors.header"
            defaultMessage="Test failed"
          />
        )}
        errorMessages={ERROR_MESSAGES}
        errorContext={this.errorContext}
        allowUnplannedGateway={allowUnplannedGateway}
        fixingOptionMessages={fixingOptionMessages}
        retry={this.checkConnectivity}
      >
        <StepView.Content>
          <p>
            <FormattedMessage
              id="ConnectivityTestingStep.jobs.description"
              defaultMessage="Waiting for internet connection of the device..."
            />
          </p>
          <TimeoutProgress timeout={TIMEOUT} />
        </StepView.Content>
      </StepView>
    );
  }
}

ConnectivityTestingStep.defaultProps = {
  ignoreSignalStrength: false,
};

ConnectivityTestingStep.propTypes = {
  routine: PropTypes.shape({
    job: JobType.isRequired,
    task: TaskType.isRequired,
    hardware: PropTypes.oneOfType([
      GatewayType,
      SensorType,
    ]),
  }).isRequired,
  ignoreSignalStrength: PropTypes.bool,
};

const mapStateToProps = state => ({
  hardware: state.hardware,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  checkGatewayConnectivity,
  checkSensorConnectivity,
  stopRoutines,
}, dispatch);

export default withRoutine(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectivityTestingStep)));
