import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Message } from 'semantic-ui-react';

import { FormattedMessage, defineMessages } from 'react-intl';

import { login, dismissError } from '~/app/actions/AuthActions';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import ErrorMessage from '~/common/components/ErrorMessage';
import LoginForm from '~/common/components/LoginForm';
import AuthView from '~/app/views/AuthView';


const ERROR_MESSAGES = defineMessages({
  INVALID_CREDENTIALS: {
    id: 'LoginView.errors.invalidCredentials',
    defaultMessage: 'Wrong username or password.',
  },
  INACTIVE_ACCOUNT: {
    id: 'LoginView.errors.inactiveAccount',
    defaultMessage: 'The account is inactive.',
  },
  NOT_ALLOWED: {
    id: 'LoginView.errors.notAllowed',
    defaultMessage: 'The account has not been setup for the smart installation app.',
  },
  NO_API_USER: {
    id: 'LoginView.errors.noApiUser',
    defaultMessage: 'The account is not associated with a KIWI account',
  },
});


class LoginView extends Component {
  componentDidMount() {
    this.props.dismissError();
  }


  login = ({ username, password }) => {
    this.props.login({
      username,
      password,
    });
  }

  resetPassword = ({ username }) => {
    this.props.history.replace({
      pathname: '/password-reset',
      state: { username },
    });
  }

  render() {
    const {
      loggedIn,
      sessionExpired,
      passwordReset,
      loading,
      error,
    } = this.props.auth;
    const { state: locationState } = this.props.location;

    if (loggedIn) {
      let path = '/';
      if (locationState && locationState.redirectTo) {
        path = locationState.redirectTo;
      }
      return <Redirect to={path} />;
    }

    return (
      <AuthView
        loading={loading}
        header={(
          <FormattedMessage id="LoginView.header" defaultMessage="Login" />
        )}
      >
        {!error && passwordReset.requested && (
          <Message positive>
            <Message.Header>
              <FormattedMessage id="LoginView.passwordResetRequested.header" defaultMessage="Your password has been reset" />
            </Message.Header>
            <FormattedMessage id="LoginView.passwordResetRequested.message" defaultMessage="Please check your e-mail inbox for further instructions on how to change you password." />
          </Message>
        )}

        {!error && passwordReset.confirmed && (
          <Message positive>
            <Message.Header>
              <FormattedMessage id="LoginView.passwordResetConfirmed.header" defaultMessage="Your password has been changed successfully" />
            </Message.Header>
            <FormattedMessage id="LoginView.passwordResetConfirmed.message" defaultMessage="You can now login with your new password." />
          </Message>
        )}

        {!error && sessionExpired && (
          <ErrorMessage
            header={(
              <FormattedMessage id="LoginView.sessionExpired.header" defaultMessage="Your session has expired" />
            )}
            message={(
              <FormattedMessage id="LoginView.sessionExpired.message" defaultMessage="Please login again to continue." />
            )}
          />
        )}

        {error && (
          <ApiErrorMessage
            error={error}
            header={(
              <FormattedMessage id="LoginView.errorHeader" defaultMessage="An error occurred!" />
            )}
            messages={ERROR_MESSAGES}
          />
        )}

        <LoginForm
          autoFocus
          initialUsername={locationState && locationState.username}
          onSubmit={this.login}
          onPasswordReset={this.resetPassword}
        />
      </AuthView>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  login,
  dismissError,
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginView));
