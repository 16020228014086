import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import LocaleContext from '~/admin/contexts/LocaleContext';
import {
  finishJob, dismissFinishJob, requestCancelJob, dismissError,
} from '~/app/actions/RoutineActions';
import View from '~/app/views/View';
import DurationInput from '~/common/components/DurationInput';
import FormattedAddress from '~/common/components/FormattedAddress';
import JobSaveErrorModal from '~/app/components/JobSaveErrorModal';


const JobFinishView = ({ job }) => {
  const { locale } = useContext(LocaleContext);

  const dispatch = useDispatch();
  const error = useSelector(state => state.routine.error);

  const [workingTime, setWorkingTime] = useState(job.workingTime);

  const handleFinish = () => {
    dispatch(finishJob({
      ...job,
      workingTime,
    }));
  };

  return (
    <View onGoBack={() => dispatch(dismissFinishJob())}>
      <View.Header
        primaryText={<FormattedMessage id="JobFinishView.header.primary" defaultMessage="Job" />}
        secondaryText={<FormattedAddress address={job.address} />}
        action={() => dispatch(requestCancelJob(job))}
        actionIcon="cancel"
      />

      <View.Content>
        <JobSaveErrorModal
          error={error}
          onDismiss={() => dispatch(dismissError())}
        />
        <Form onSubmit={handleFinish}>
          <Form.Field>
            <label>
              <FormattedMessage id="JobFinishView.workingTime.label" defaultMessage="How long did it take to complete the job?" />
            </label>
            <FormattedMessage id="JobFinishView.workingTime.placeholder" defaultMessage="Working time">
              {placeholder => (
                <DurationInput
                  fluid
                  closable
                  hideMobileKeyboard
                  icon={false}
                  duration={0}
                  animation="none"
                  localization={locale}
                  closeOnMouseLeave={false}
                  popupPosition="bottom left"
                  placeholder={placeholder}
                  value={workingTime}
                  onChange={(e, { value }) => setWorkingTime(value)}
                />
              )}
            </FormattedMessage>
          </Form.Field>
        </Form>
      </View.Content>

      <View.Footer>
        <Button primary fluid onClick={handleFinish}>
          <FormattedMessage id="JobFinishView.finish.label" defaultMessage="Finish job" />
        </Button>
      </View.Footer>
    </View>
  );
};

export default JobFinishView;
