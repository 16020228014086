import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Input } from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';

import { confirmPasswordReset, dismissError } from '~/app/actions/AuthActions';
import ErrorMessage from '~/common/components/ErrorMessage';
import ApiErrorMessage from '~/common/components/ApiErrorMessage';
import AuthView from '~/app/views/AuthView';


const ERROR_MESSAGES = defineMessages({
  INVALID_UID: {
    id: 'PasswordResetConfirmView.errors.invalidUid',
    defaultMessage: 'The reset link is invalid or has expired. Please try again to reset you password.',
  },
  INVALID_TOKEN: {
    id: 'PasswordResetConfirmView.errors.invalidToken',
    defaultMessage: 'The reset link is invalid or has expired. Please try again to reset you password.',
  },
});


class PasswordResetConfirmView extends Component {
  state = {
    password: '',
    passwordConfirm: '',
    passwordsMatch: true,
    submitted: false,
  }

  componentDidMount() {
    this.props.dismissError();
  }

  confirmPasswordReset = () => {
    const { password, passwordConfirm } = this.state;
    const { uid, token } = this.props.match.params;

    const passwordsMatch = password === passwordConfirm;
    if (passwordsMatch) {
      this.props.confirmPasswordReset({ uid, token, password });
    } else {
      this.props.dismissError();
    }

    this.setState({
      password: '',
      passwordConfirm: '',
      passwordsMatch,
      submitted: true,
    });
  }

  render() {
    const {
      password, passwordConfirm, passwordsMatch, submitted,
    } = this.state;
    const { loading, error, passwordReset } = this.props.auth;

    if (submitted && passwordReset.confirmed) {
      return <Redirect to={{ pathname: '/login', state: { username: passwordReset.username } }} />;
    }

    return (
      <AuthView
        loading={loading}
        header={(
          <FormattedMessage id="PasswordResetConfirmView.header" defaultMessage="Password Reset" />
        )}
      >
        {!passwordsMatch && (
          <ErrorMessage
            header={(
              <FormattedMessage id="PasswordResetConfirmView.passwordMismatch.header" defaultMessage="The passwords don't match!" />
            )}
            message={(
              <FormattedMessage id="PasswordResetConfirmView.passwordMismatch.message" defaultMessage="Please enter the new password again." />
            )}
          />
        )}

        {error && (
          <ApiErrorMessage
            error={error}
            header={(
              <FormattedMessage id="PasswordResetConfirmView.errorHeader" defaultMessage="An error occurred!" />
            )}
            messages={ERROR_MESSAGES}
          />
        )}

        <Form onSubmit={this.confirmPasswordReset}>
          <Form.Field>
            <label>
              <FormattedMessage id="PasswordResetConfirmView.password.label" defaultMessage="New password" />
            </label>
            <FormattedMessage id="PasswordResetConfirmView.password.placeholder" defaultMessage="New password">
              {placeholder => (
                <Input
                  placeholder={placeholder}
                  type="password"
                  icon="lock"
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="PasswordResetConfirmView.passwordConfirm.label" defaultMessage="Confirm new password" />
            </label>
            <FormattedMessage id="PasswordResetConfirmView.passwordConfirm.placeholder" defaultMessage="Confirm new password">
              {placeholder => (
                <Input
                  placeholder={placeholder}
                  type="password"
                  icon="lock"
                  value={passwordConfirm}
                  onChange={e => this.setState({ passwordConfirm: e.target.value })}
                />
              )}
            </FormattedMessage>
          </Form.Field>
          <Form.Field>
            <Button primary fluid disabled={password.length === 0 || passwordConfirm.length === 0}>
              <FormattedMessage id="PasswordResetConfirmView.changePassword.label" defaultMessage="Change password" />
            </Button>
          </Form.Field>
          <Form.Field>
            <Link to="/login">
              <Button fluid>
                <FormattedMessage id="PasswordResetConfirmView.backToLogin.label" defaultMessage="Back to login" />
              </Button>
            </Link>
          </Form.Field>
        </Form>
      </AuthView>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  confirmPasswordReset,
  dismissError,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordResetConfirmView);
